import { generateValidationFunc } from 'src/helpers';
import firebase from 'firebase';
import * as yup from 'yup';
import { liveStoryTypes, getDeletedStoryNodeIds, getOptions } from 'src/helpers';
import type {
  Story,
  FeatureStorySnapshot,
  FeatureStory,
  StorySnapshot,
  LiveStorySnapshot,
  SnapshotOptions,
  Node,
  // StoryCore,
  LiveStory,
  LiveNode,
  NodeResult,
} from 'types';
import dayjs from 'dayjs';

// interface StoryData extends StoryCore {
//   createdBy: firestore.DocumentReference<firestore.DocumentData>;
//   createdAt: firestore.Timestamp;
//   updatedAt?: firestore.Timestamp;
// }
const { firestore } = firebase;

export const nodeResultConverter: firebase.firestore.FirestoreDataConverter<NodeResult> = {
  toFirestore(nodeResult: NodeResult) {
    return nodeResult;
  },
  fromFirestore(snapshot: firebase.firestore.QueryDocumentSnapshot<NodeResult>, options: SnapshotOptions): NodeResult {
    const data = snapshot.data(options);
    return data;
  },
};
export const storyConverter: firebase.firestore.FirestoreDataConverter<Story> = {
  toFirestore(story: Story) {
    switch (story.type) {
      case 'live': {
        const snapshot = {
          ...story,
          createdBy: firestore().doc('adminUsers/' + story.createdBy),
          createdAt: firebase.firestore.Timestamp.fromMillis(story.createdAt),
          updatedAt: (story.updatedAt !== undefined) ? firebase.firestore.Timestamp.fromMillis(story.updatedAt) : undefined,
        };
        if ('updatedAt' in snapshot && snapshot.updatedAt === undefined) {
          delete snapshot.updatedAt;
        }
        return snapshot;
      }
      default: {
        const snapshot = {
          ...story,
          createdBy: firestore().doc('adminUsers/' + story.createdBy),
          createdAt: firestore.Timestamp.fromMillis(story.createdAt),
          updatedAt: (story.updatedAt !== undefined) ? firestore.Timestamp.fromMillis(story.updatedAt) : undefined,
        };
        if ('updatedAt' in snapshot && snapshot.updatedAt === undefined) {
          delete snapshot.updatedAt;
        }
        return snapshot;
      }
    }
  },
  fromFirestore(snapshot: StorySnapshot | LiveStorySnapshot, options: SnapshotOptions): Story | LiveStory {
    const data = snapshot.data(options);
    if (data.appTags === undefined) {
      data.appTags = [];
    }
    switch (data.type) {
      case 'live': {
        const story: LiveStory = {
          ...data,
          description: data.description ?? 'placeholder description',
          status: data.status ?? 'staged',
          isFree: data.isFree ?? false,
          createdBy: data.createdBy.id,
          createdAt: data.createdAt.toMillis(),
          updatedAt: data.updatedAt?.toMillis(),
        };
        return story;
      }
      default: {
        const story: Story = {
          ...data,
          description: data.description ?? 'placeholder description',
          status: data.status ?? 'staged',
          isFree: data.isFree ?? false,
          createdBy: data.createdBy.id,
          createdAt: data.createdAt.toMillis(),
          updatedAt: data.updatedAt?.toMillis(),
        };
        return story;
      }
    }
  },
};

type IgnoreFields = 'order' | 'nodes' | 'createdBy' | 'createdAt' | 'changesSincePublished' | 'startingNodeId';
// type FeatureStoryDetails = Pick<FeatureStory, 'title' | 'type' | 'coverImage' | 'tags' | 'updatedAt'>;
// type LiveStoryDetails = Pick<LiveStory, 'title' | 'type' | 'coverImage' | 'tags' | 'updatedAt' | 'video' | 'startTime'>;

const featureStoryDetailsSchema: yup.ObjectSchema<Omit<FeatureStory, IgnoreFields>> = yup.object().required().shape<Omit<FeatureStory, IgnoreFields>>({
  title: yup.string().required().label('Title'),
  description: yup.string().required().label('Description'),
  searchKeywords: yup.string().required().label('Searchable keywords'),
  tags: yup.array<string>().required().label('Tags'),
  appTags: yup.array<string>().required().label('App Tags'),
  characterProfileId: yup.string().required().label('Character/Performer Profile'),
  type: yup.string().required().oneOf(['feature', 'mini', 'checkpoint']).label('Type'),
  coverImage: generateValidationFunc('image').required().label('Cover Image'),
  minsToComplete: yup.number().required().label('Minutes To Complete'),
  classKitTitle: yup.string().required().label('ClassKit Title'),
  discussionQuestions: yup.array<string>().required().label('Discussion Questions'),
  status: yup.string().required().oneOf(["staged", "published", "archived", "preview"]).label('Status'),
  isDeleted: yup.boolean().required(),
  isFree: yup.boolean().required().label('Is Free'),
  updatedAt: yup.number().required().default(() => dayjs().valueOf()).transform(() => dayjs().valueOf()),
});
const liveStoryDetailsSchema: yup.ObjectSchema<Omit<LiveStory, IgnoreFields>> = yup.object().required().shape<Omit<LiveStory, IgnoreFields>>({
  title: yup.string().required().label('Title'),
  description: yup.string().required().label('Description'),
  searchKeywords: yup.string().required().label('Searchable keywords'),
  tags: yup.array<string>().required().label('Tags'),
  appTags: yup.array<string>().required().label('App Tags'),
  characterProfileId: yup.string().required().label('Character/Performer Profile'), 
  type: yup.string().required().oneOf(["live"]).label('Type'),
  subType: yup.string().required().oneOf(liveStoryTypes).label('Sub Type'),
  coverImage: generateValidationFunc('image').required().label('Cover Image'),
  minsToComplete: yup.number().required().label('Minutes To Complete'),
  classKitTitle: yup.string().required().label('ClassKit Title'),
  discussionQuestions: yup.array<string>().required().label('Discussion Questions'),
  status: yup.string().required().oneOf(["staged", "published", "archived", "preview", "live"]).label('Status'),
  isDeleted: yup.boolean().required(),
  isFree: yup.boolean().required().label('Is Free'),
  video: generateValidationFunc('video').required().label('Video'),
  startTime: yup.number().required().label('Start Time'),
  actualStartTime: yup.number().required().label('Actual Start Time'),
  actualGoLiveTime: yup.number().required().label('Actual Go Live Time'),
  actualEndTime: yup.number().required().label('Actual End Time'),
  updatedAt: yup.number().required().default(() => dayjs().valueOf()).transform(() => dayjs().valueOf()),
});

type StoryDetailsReturn<T extends Partial<Story>> = T extends Partial<LiveStory> ? typeof liveStoryDetailsSchema : T extends Partial<FeatureStory> ? typeof featureStoryDetailsSchema : void;

export function generateStoryDetailsSchema(story: Partial<Story>): StoryDetailsReturn<typeof story> {
  switch (story.type) {
    case 'live': {
      return liveStoryDetailsSchema;
    }
    case 'feature': {
      return featureStoryDetailsSchema;
    }
    case 'checkpoint': {
      return featureStoryDetailsSchema;
    }
    case 'mini': {
      return featureStoryDetailsSchema;
    }
    default:
      return featureStoryDetailsSchema;
  }
}



// type PublishStorySchema = Pick<Story, 'status' | 'updatedAt' | 'changesSincePublished'>;
export const publishStorySchema: yup.ObjectSchema = yup.object().required().shape({
  // status: yup.string().required().oneOf(['published']).label('Status'),
  // changesSincePublished: yup.boolean().required().default(() => false),
  // updatedAt: yup.number().required().default(() => moment().valueOf()).transform(() => moment().valueOf()),
  // nodes: yup.lazy<{ [nodeId: string]: Node }>((nodeDefinitions = {}) => {
  //   // const answerDefinitionsSchema: { [answerDefId: string]: yup.ObjectSchema<AnswerDefinition> } = {};
  //   let nodeDefinitionsSchema: yup.ObjectSchema<{ [nodeId: string]: Node }> = yup.object()
  //     .label('Node Definitions')
  //     .defined()
  //     .shape<{ [nodeId: string]: Node }>({})
  //     .default(() => ({}));

  //   // const nodeIds = Object.keys(nodeDefinitions);

  //   // const replaceIds: [string, string][] = [];
  //   Object.entries(nodeDefinitions).forEach(([nodeId, nodeDefinition]) => {

  //     const nodeSchema = generateNodeDefinitionSchema(nodeDefinition);

  //     nodeDefinitionsSchema = nodeDefinitionsSchema.shape({
  //       [nodeId]: nodeSchema,
  //     });

  //   });

  //   /**
  //    * This dynamically created validation schema allows us to add any
  //    * number of nodeDefinitions and make sure their fields are properly populated
  //    */
  //   return nodeDefinitionsSchema;
  // }),
}).test('minimum-nodes-size', 'Story must have at least one node', (story: Story): boolean => {
  return Object.keys(story.nodes).length > 0;
}).test('no-dangling-nodes', 'The story graph cannot have dangling nodes', (story: FeatureStory): boolean | yup.ValidationError => {
  const nodes = story.nodes;
  const deletedStoryNodeIds = getDeletedStoryNodeIds(nodes);
  const danglingNodeIds: string[] = [];
  Object.entries(nodes).forEach(([nodeId, node]) => {
    const { edges, isDeleted, isEndingNode } = node;
    if (!isDeleted && !isEndingNode) {
      const options = getOptions(node, deletedStoryNodeIds);
      if (options !== null) {
        if (options.filter(({ disabled }) => !disabled).length !== 0) {
          danglingNodeIds.push(nodeId);
        }
      } else {
        const numberOfChildrenNodes = Object.entries(edges).filter(([, { targetNodeId }]) => !deletedStoryNodeIds.includes(targetNodeId)).length;
        if (numberOfChildrenNodes === 0) {
          danglingNodeIds.push(nodeId);
        }
      }
    }
  });
  if (danglingNodeIds.length === 0) {
    return true;
  } else {
    throw new yup.ValidationError('This story still has dangling nodes', danglingNodeIds, '');
  }
});


// export const createNewStorySchema: yup.ObjectSchema<Story> = yup.object().required().shape<Story>({
//   title: yup.string().required().label('Title'),
//   description: yup.string().required().label('Description'),
//   order: yup.number().required(),
//   tags: yup.array<string>().required().label('Tags'),
//   coverImage: generateValidationFunc('image').required().label('Cover Image'),
//   type: yup.string().required().oneOf(["feature", "mini", "checkpoint"]).label('Type'),
//   status: yup.string().required().oneOf(["staged", "published", "archived", "preview"]).label('Status'),
//   startingNodeId: yup.string().required().label('Starting Node'),
//   createdBy: yup.string().required().label('Created By'),
//   changesSincePublished: yup.boolean().default(() => false),
//   isDeleted: yup.boolean().required(),
//   createdAt: yup.number().required().default(() => dayjs().valueOf()),
//   isFree: yup.boolean().required().label('Is Free'),
//   nodes: yup.object()
//     .label('Node Definitions')
//     .defined()
//     .shape<{ [nodeId: string]: Node }>({})
//     .default(() => ({})),
// });

const createFeatureStorySchema: yup.ObjectSchema<FeatureStory> = yup.object().required().shape<FeatureStory>({
  title: yup.string().required().label('Title'),
  description: yup.string().required().label('Description'),
  searchKeywords: yup.string().required().label('Searchable keywords'),
  order: yup.number().required(),
  tags: yup.array<string>().required().label('Tags'),
  appTags: yup.array<string>().required().label('App Tags'),
  characterProfileId: yup.string().required().label('Character/Performer Profile'),
  minsToComplete: yup.number().required().label('Minutes To Complete'),
  classKitTitle: yup.string().required().label('ClassKit Title'),
  discussionQuestions: yup.array<string>().required().label('Discussion Questions'),
  coverImage: generateValidationFunc('image').required().label('Cover Image'),
  type: yup.string().required().oneOf(["feature", "mini", "checkpoint"]).label('Type'),
  status: yup.string().required().oneOf(["staged", "published", "archived", "preview"]).label('Status'),
  startingNodeId: yup.string().required().label('Starting Node'),
  createdBy: yup.string().required().label('Created By'),
  changesSincePublished: yup.boolean().default(() => false),
  isDeleted: yup.boolean().required(),
  createdAt: yup.number().required().default(() => dayjs().valueOf()),
  isFree: yup.boolean().required().label('Is Free'),
  nodes: yup.object()
    .label('Node Definitions')
    .defined()
    .shape<{ [nodeId: string]: Node }>({})
    .default(() => ({})),
});

const createLiveStorySchema: yup.ObjectSchema<LiveStory> = yup.object().required().shape<LiveStory>({
  title: yup.string().required().label('Title'),
  description: yup.string().required().label('Description'),
  searchKeywords: yup.string().required().label('Searchable keywords'),
  order: yup.number().required(),
  tags: yup.array<string>().required().label('Tags'),
  appTags: yup.array<string>().required().label('App Tags'),
  characterProfileId: yup.string().required().label('Character/Performer Profile'),
  coverImage: generateValidationFunc('image').required().label('Cover Image'),
  minsToComplete: yup.number().required().label('Minutes To Complete'),
  classKitTitle: yup.string().required().label('ClassKit Title'),
  discussionQuestions: yup.array<string>().required().label('Discussion Questions'),
  type: yup.string().required().oneOf(["live"]).label('Type'),
  subType: yup.string().required().oneOf(liveStoryTypes).label('Sub Type'),
  status: yup.string().required().oneOf(["staged", "published", "archived", "preview"]).label('Status'),
  startingNodeId: yup.string().required().label('Starting Node'),
  createdBy: yup.string().required().label('Created By'),
  changesSincePublished: yup.boolean().default(() => false),
  isDeleted: yup.boolean().required(),
  createdAt: yup.number().required().default(() => dayjs().valueOf()),
  startTime: yup.number().required().label('Start Time (milliseconds)').default(0),
  actualStartTime: yup.number().required().label('Actual Start Time'),
  actualGoLiveTime: yup.number().required().label('Actual Go Live Time'),
  actualEndTime: yup.number().required().label('Actual End Time'),
  video: generateValidationFunc('video').required().label('Video File'),
  isFree: yup.boolean().required().label('Is Free'),
  nodes: yup.object()
    .label('Node Definitions')
    .defined()
    .shape<{ [nodeId: string]: LiveNode }>({})
    .default(() => ({})),
});
// const createLiveStorySchema: yup.ObjectSchema<Omit<LiveStory, IgnoreFields>> = yup.object().required().shape<Omit<LiveStory, IgnoreFields>>({
//   title: yup.string().required().label('Title'),
//   description: yup.string().required().label('Description'),
//   tags: yup.array<string>().required().label('Tags'),
//   coverImage: generateValidationFunc('image').required().label('Cover Image'),
//   status: yup.string().required().oneOf(["staged", "published", "archived", "preview", "live"]).label('Status'),
//   isDeleted: yup.boolean().required(),
//   isFree: yup.boolean().required().label('Is Free'),
//   type: yup.string().required().oneOf(["live"]).label('Type'),
//   video: generateValidationFunc('video').required().label('Video'),
//   startTime: yup.number().required().label('Start Time'),
//   updatedAt: yup.number().required().default(() => dayjs().valueOf()).transform(() => dayjs().valueOf()),
// });

export function generateCreateNewStorySchema(story: Partial<Story>): yup.ObjectSchema<Story> {
  switch (story.type) {
    case 'live': {
      return createLiveStorySchema;
    }
    case 'feature': {
      return createFeatureStorySchema;
    }
    case 'checkpoint': {
      return createFeatureStorySchema;
    }
    case 'mini': {
      return createFeatureStorySchema;
    }
    default:
      return createFeatureStorySchema;
  }
}


// Replaced by generateStoryDetailsSchema
// type IgnoreFields = 'order' | 'nodes' | 'createdBy' | 'createdAt' | 'changesSincePublished' | 'startingNodeId';
// export const updateStoryMetaDataSchema: yup.ObjectSchema<Omit<Story, IgnoreFields>> = yup.object().required().shape<Omit<Story, IgnoreFields>>({
//   title: yup.string().required().label('Title'),
//   description: yup.string().required().label('Description'),
//   tags: yup.array<string>().required().label('Tags'),
//   coverImage: generateValidationFunc('image').required().label('Cover Image'),
//   type: yup.string().required().oneOf(["feature", "mini", "checkpoint"]).label('Type'),
//   status: yup.string().required().oneOf(["staged", "published", "archived", "preview"]).label('Status'),
//   isDeleted: yup.boolean().required(),
//   isFree: yup.boolean().required().label('Is Free'),
//   updatedAt: yup.number().required().default(() => dayjs().valueOf()).transform(() => dayjs().valueOf()),
// });