import { combineReducers } from '@reduxjs/toolkit';
import userReducer from 'src/features/authentication/userSlice';
import nodeDefinitionsReducer from 'src/features/nodes/nodeDefinitionSlice';
import tagDefinitionsReducer from 'src/features/tagDefinitions/tagDefinitionsSlice';
import storageReducer from 'src/features/storage/storageSlice';
import placeholderAssetReducer from 'src/features/storage/placeholderAssetSlice';
import usersManagerReducer from 'src/features/admin/usersManagerSlice';
import seasonsReducer from 'src/features/seasons/seasonsSlice';
import singleSeasonReducer from 'src/features/seasons/singleSeasonSlice';
import storiesReducer from 'src/features/stories/storiesSlice';
import singleStoryReducer from 'src/features/stories/singleStorySlice';
import characterProfilesReducer from 'src/features/characterProfiles/characterProfilesSlice';
import chipPartsReducer from 'src/features/chipParts/chipPartsSlice';
import liveEventsReducer from 'src/features/liveEvents/liveEventsSlice';
import youtubeLiveStreamsReducer from 'src/features/liveEvents/youtubeLiveStreamsSlice';
import appTagDefinitionsReducer from 'src/features/appTagDefinitions/appTagDefinitionsSlice';

const rootReducer = combineReducers({
  userState: userReducer,
  nodeDefinitionsState: nodeDefinitionsReducer,
  tagDefinitionsState: tagDefinitionsReducer,
  storageState: storageReducer,
  placeholderAssetState: placeholderAssetReducer,
  usersManagerState: usersManagerReducer,
  seasonsState: seasonsReducer,
  seasonState: singleSeasonReducer,
  storiesState: storiesReducer,
  storyState: singleStoryReducer,
  characterProfilesState: characterProfilesReducer,
  chipPartsState: chipPartsReducer,
  liveEventsState: liveEventsReducer,
  youtubeLiveStreamsState: youtubeLiveStreamsReducer,
  appTagDefinitionsState: appTagDefinitionsReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;