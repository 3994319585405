import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import {
  fetchStories,
} from 'src/features/stories/storiesSlice';
import {
  ExclamationCircleOutlined,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
} from '@ant-design/icons';
import {
  fetchSeason,
} from 'src/features/seasons/singleSeasonSlice';
import StoryFormContainer from './StoryFormContainer';
import SeasonDetails from 'src/components/seasons/SeasonDetails';
import SeasonFormContainer from 'src/components/seasons/SeasonFormContainer';
import StoriesOrderFormContainer from 'src/components/stories/storiesOrder';
import moment from 'moment-timezone';
import {
  Button,
  Table,
  Drawer,
  Row,
  Col,
  Tag,
} from 'antd';
import {
  useParams,
  useHistory,
} from 'react-router-dom';
import { momentFormat, storyTypeOptions, storyStatusOptions } from 'src/helpers';
/** type imports */
import type { RootState } from 'src/app/rootReducer';
import type { AppDispatch } from 'src/app/store';
import type { ColumnType } from 'antd/es/table';
import type {
  milliseconds,
  antdColorOptions,
  Story,
} from 'types';

type TableDatum = Story & { id: string };

const Stories: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { seasonId } = useParams<{ seasonId: string }>();
  const history = useHistory();
  const [editSeason, setEditSeason] = React.useState<boolean>(false);
  const [addNewStory, setAddNewStory] = React.useState<boolean>(false);
  const [editStoriesOrder, setStoriesOrder] = React.useState<boolean>(false);
  const {
    seasonState: {
      selectedSeason,
      fetchingSeason,
    },
    storiesState: {
      stories,
      fetchingStories,
    },
  } = useSelector((state: RootState) => state);


  React.useEffect(() => {
    dispatch(fetchSeason(seasonId));
    dispatch(fetchStories(seasonId));
  }, [dispatch, seasonId]);

  let currentMaxOrder = 0;
  const tableData: TableDatum[] = Object.entries(stories)
    .map(([id, story]) => {
      if (story.order > currentMaxOrder) {
        currentMaxOrder = story.order;
      }
      return ({
        id,
        ...story,
      });
    }).sort(({ order: orderA }, { order: orderB }) => orderA - orderB);


  const renderType: React.FC<keyof typeof storyTypeOptions> = (type: keyof typeof storyTypeOptions) => {
    let color: antdColorOptions = 'green';
    switch (type) {
      case 'feature': {
        color = 'geekblue';
        break;
      }
      case 'mini': {
        color = 'magenta';
        break;
      }
      case 'checkpoint': {
        color = 'purple';
        break;
      }
    }
    return <Tag color={color}>{storyTypeOptions[type]}</Tag>;
  };

  const renderStatus: React.FC<keyof typeof storyStatusOptions> = (status: keyof typeof storyStatusOptions, { changesSincePublished }: TableDatum) => {
    let color: antdColorOptions = 'green';
    let icon;
    switch (status) {
      case 'staged': {
        color = 'processing';
        break;
      }
      case 'published': {
        color = 'success';
        if (changesSincePublished) {
          color = 'warning';
          icon = <ExclamationCircleOutlined />;
        }
        break;
      }
      case 'archived': {
        color = 'error';
        break;
      }
    }

    return (
      <Tag icon={icon} color={color}>
        {storyStatusOptions[status]}
      </Tag>
    );

  };


  const storyOrderStyle: React.CSSProperties = {
    fontFamily: 'monospace',
    fontWeight: 'bold',
    fontSize: 16,
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 3,
    backgroundColor: '#fff7c9',
    paddingLeft: 2,
    paddingRight: 2,
  };
  const renderOrder: React.FC<number> = (order: number) => <span style={storyOrderStyle}> {order}</span >;


  const storyTypeFilters = Object.entries(storyTypeOptions)
    .map(([storyTypeKey, storyTypeLabel]) => ({ text: storyTypeLabel, value: storyTypeKey }));


  const storyStatusFilters = Object.entries(storyStatusOptions)
    .map(([storyStatusKey, storyStatusLabel]) => ({ text: storyStatusLabel, value: storyStatusKey }));

    const renderIsFree: React.FC<boolean> = (comingSoon: boolean) => {
      if (comingSoon) {
        return <CheckCircleTwoTone style={{ fontSize: '20px' }} twoToneColor='#52c41a' />;
      } else {
        return <CloseCircleTwoTone style={{ fontSize: '20px' }} twoToneColor='#a6a6a6' />;
      }
    };
  
  const isFreeFilters = [
    { value: true, text: <CheckCircleTwoTone style={{ fontSize: '20px' }} twoToneColor='#52c41a' /> },
    { value: false, text: <CloseCircleTwoTone style={{ fontSize: '20px' }} twoToneColor='#a6a6a6' /> }
  ];


  const tableColumns: ColumnType<TableDatum>[] = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      width: 250,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: 350,
    },
    {
      title: 'Is Free',
      dataIndex: 'isFree',
      key: 'isFree',
      width: 150,
      filters: isFreeFilters,
      render: renderIsFree,
      onFilter: (value, record) => record.isFree === value,
    },
    {
      title: 'Order',
      dataIndex: 'order',
      key: 'order',
      width: 10,
      sorter: ({ order: orderA }, { order: orderB }) => orderA - orderB,
      render: renderOrder,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      width: 125,
      filters: storyTypeFilters,
      render: renderType,
      onFilter: (value, record) => record.type === value,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 125,
      filters: storyStatusFilters,
      render: renderStatus,
      defaultFilteredValue: Object.keys(storyStatusOptions).filter(status => status !== 'archived'),
      onFilter: (value, record) => record.status === value,
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 200,
      sorter: (a, b) => a.createdAt - b.createdAt,
      render: (createdAt: milliseconds) => moment(createdAt).format(momentFormat),
    },
    {
      title: 'Updated At',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      width: 200,
      sorter: ({ updatedAt: updatedAtA }, { updatedAt: updatedAtB }) => {
        if (updatedAtA !== undefined && updatedAtB !== undefined) {
          return updatedAtA - updatedAtB;
        } else if (updatedAtA !== undefined) {
          return 1;
        } else if (updatedAtB !== undefined) {
          return -1;
        }
        return 0;
      },
      render: (updatedAt: milliseconds | undefined) => (updatedAt) ? moment(updatedAt).format(momentFormat) : 'N/A',
    }
  ];

  return (
    <React.Fragment>
      <Row
        justify='space-around'
        style={{
          padding: '1em',
          margin: '1em',
          backgroundColor: 'white',
        }}
      >
        <Col xs={11} sm={11} md={5} lg={5} xl={5}>
          <SeasonDetails
            selectedSeason={selectedSeason}
            fetchingSeason={fetchingSeason}
            setEditSeason={() => setEditSeason(true)}
          />
        </Col>
        <Col xs={11} sm={11} md={17} lg={17} xl={17}>
          <Table
            title={() => (
              <Row justify='space-between' align='middle'>
                <Col>
                  <span
                    style={{
                      fontSize: 16,
                      fontWeight: 'bold',
                      color: 'black',
                    }}
                  >
                    Stories
                  </span>
                </Col>
                <Col>
                  <Button type='primary' disabled={editStoriesOrder} onClick={() => setStoriesOrder(true)}>
                    Edit Stories Order
                  </Button>
                  <Button style={{ marginLeft: 5 }} type='primary' disabled={editStoriesOrder} onClick={() => setAddNewStory(true)}>
                    New Story
                  </Button>
                </Col>
              </Row>
            )}
            pagination={{
              total: tableData.length,
              defaultPageSize: 100,
              showTotal: (total) => `${total} Total Stories`,
            }}
            loading={fetchingStories}
            dataSource={tableData}
            columns={tableColumns}
            bordered
            rowKey={({ id }) => id}
            onRow={(record) => {
              const rowStyle: React.CSSProperties = {
                cursor: 'pointer',
              };
              return {
                onClick: () => history.push(`/seasons/${seasonId}/stories/${record.id}`), // click row
                style: rowStyle,
              };
            }}
          />
        </Col>
      </Row>
      <Drawer
        width={600}
        placement={'right'}
        visible={addNewStory}
        closable={false}
      >
        {(!fetchingStories && addNewStory) && <StoryFormContainer
          editMode={false}
          storyFormState={{
            seasonId: seasonId,
            id: uuidv4(),
            story: {
              title: '',
              tags: [],
              order: currentMaxOrder + 1,
              nodes: {},
              status: 'staged',
              startingNodeId: uuidv4(),
              isDeleted: false,
              isFree: false,
            },
          }}
          onCancel={() => setAddNewStory(false)}
        />}
      </Drawer>
      <Drawer
        width={600}
        placement={'right'}
        visible={editSeason}
        closable={false}
      >
        {(selectedSeason && !fetchingSeason) && <SeasonFormContainer
          editMode={true}
          seasonFormState={{
            id: selectedSeason.seasonId,
            season: selectedSeason.season,
          }}
          onCancel={() => setEditSeason(false)}
        />}
      </Drawer>
      <Drawer
        width={600}
        placement={'right'}
        visible={editStoriesOrder}
        closable={false}
      >
        {selectedSeason && <StoriesOrderFormContainer
          seasonId={seasonId}
          seasonTitle={selectedSeason.season.title}
          stories={stories}
          onCancel={() => setStoriesOrder(false)}
        />}
      </Drawer>
    </React.Fragment>
  );
};

export default Stories;