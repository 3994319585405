import React from 'react';
import moment from 'moment-timezone';
import useInterval from '@use-it/interval';

interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  unixMilliseconds: number;
}
const Stopwatch: React.FC<Props> = ({ unixMilliseconds, ...rest }: Props) => {
  const [seconds, setSeconds] = React.useState<number>(moment().diff(moment(unixMilliseconds), 'seconds'));
  useInterval(() => {
    setSeconds(moment().diff(moment(unixMilliseconds), 'seconds'));
  }, 1000);
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const leftSeconds = seconds - (hours * 3600) - (minutes * 60);
  return (
    <div {...rest}>
      {hours ? hours + ':' : ''}{minutes ? minutes + ':' : ''}{leftSeconds < 10 ? '0' + leftSeconds : leftSeconds}
    </div>
  );
};

export default Stopwatch;
