import React from 'react';
import BotMultiChoiceQuestionForm from './BotMultiChoiceQuestionFormBody';
import VideoNodeForm from './VideoNodeFormBody';
import DrawingNodeFormBody from './DrawingNodeFormBody';
import IDBadgeNodeFormBody from './IDBadgeNodeFormBody';
import LiveActionMultipleChoiceQuestionFormBody from './LiveActionMultipleChoiceQuestionFormBody';
import PhoneCallNodeFormBody from './PhoneCallNodeFormBody';
import PulleyNodeFormBody from './PulleyNodeFormBody';
import ScreenCleaningNodeFormBody from './ScreenCleaningNodeFormBody';
import SelfieNodeFormBody from './SelfieNodeFormBody';
import XRayNodeFormBody from './XRayNodeFormBody';
import ThreeDModelNodeFormBody from './ThreeDModelNodeFormBody';
import PointsDisplayNodeFormBody from './PointsDisplayNodeFormBody';
import TravelNodeFormBody from './TravelNodeFormBody';
import LiveTrueOrFalseQuestionNodeFormBody from './LiveTrueOrFalseQuestionNodeFormBody';
import LivePollQuestionNodeFormBody from './LivePollQuestionNodeFormBody';
import LiveImageOverlayNodeFormBody from './LiveImageOverlayNodeFormBody';
import LiveBuilderImageSelectorNodeFormBody from './LiveBuilderImageSelectorNodeFormBody';

/** type imports */
import type { Node, LiveNode } from 'types';
import type { FormikErrors } from 'formik';
import type { FormInstance } from 'antd/lib/form';

interface NodeFormState {
  id: string;
  nodeDefinition: Partial<Node | LiveNode>
}

interface Props {
  nodeDefinition: Partial<Node | LiveNode>;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => any;
  handleChange: (eventOrPath: string | React.ChangeEvent<any>) => void | ((eventOrTextValue: string | React.ChangeEvent<any>) => void);
  handleBlur: (eventOrString: any) => void | ((e: any) => void);
  isSubmitting: boolean;
  form: FormInstance;
  errors: FormikErrors<NodeFormState>;
  setErrors: (errors: FormikErrors<NodeFormState>) => void;
}

const NodeRouter: React.FC<Props> = (props: Props) => {

  const {
    nodeDefinition,
    setFieldValue,
    handleChange,
    handleBlur,
    isSubmitting,
    errors,
    form,
    setErrors,
  } = props;

  switch (nodeDefinition.type) {
    case 'Bot multi-choice question': {
      return (
        <BotMultiChoiceQuestionForm
          nodeDefinition={nodeDefinition}
          setFieldValue={setFieldValue}
          handleChange={handleChange}
          handleBlur={handleBlur}
          errors={errors}
          isSubmitting={isSubmitting}
          setErrors={setErrors}
          form={form}
        />
      );
    }
    case 'Video': {
      return (
        <VideoNodeForm
          nodeDefinition={nodeDefinition}
          setFieldValue={setFieldValue}
          handleChange={handleChange}
          handleBlur={handleBlur}
          errors={errors}
          isSubmitting={isSubmitting}
          setErrors={setErrors}
          form={form}
        />
      );
    }
    case 'ID Badge': {
      return (
        <IDBadgeNodeFormBody
          nodeDefinition={nodeDefinition}
          setFieldValue={setFieldValue}
          handleChange={handleChange}
          handleBlur={handleBlur}
          errors={errors}
          isSubmitting={isSubmitting}
          setErrors={setErrors}
          form={form}
        />
      );
    }
    case 'Live Action Multiple Choice Question': {
      return (
        <LiveActionMultipleChoiceQuestionFormBody
          nodeDefinition={nodeDefinition}
          setFieldValue={setFieldValue}
          handleChange={handleChange}
          handleBlur={handleBlur}
          errors={errors}
          isSubmitting={isSubmitting}
          setErrors={setErrors}
          form={form}
        />
      );
    }
    case 'Phone': {
      return (
        <PhoneCallNodeFormBody
          nodeDefinition={nodeDefinition}
          setFieldValue={setFieldValue}
          handleChange={handleChange}
          handleBlur={handleBlur}
          errors={errors}
          isSubmitting={isSubmitting}
          setErrors={setErrors}
          form={form}
        />
      );
    }
    case 'Pulley': {
      return (
        <PulleyNodeFormBody
          nodeDefinition={nodeDefinition}
          setFieldValue={setFieldValue}
          handleChange={handleChange}
          handleBlur={handleBlur}
          errors={errors}
          isSubmitting={isSubmitting}
          setErrors={setErrors}
          form={form}
        />
      );
    }
    case 'Screen Cleaning': {
      return (
        <ScreenCleaningNodeFormBody
          nodeDefinition={nodeDefinition}
          setFieldValue={setFieldValue}
          handleChange={handleChange}
          handleBlur={handleBlur}
          errors={errors}
          isSubmitting={isSubmitting}
          setErrors={setErrors}
          form={form}
        />
      );
    }
    case 'Selfie': {
      return (
        <SelfieNodeFormBody
          nodeDefinition={nodeDefinition}
          setFieldValue={setFieldValue}
          handleChange={handleChange}
          handleBlur={handleBlur}
          errors={errors}
          isSubmitting={isSubmitting}
          setErrors={setErrors}
          form={form}
        />
      );
    }
    case 'Drawing': {
      return (
        <DrawingNodeFormBody
          nodeDefinition={nodeDefinition}
          setFieldValue={setFieldValue}
          handleChange={handleChange}
          handleBlur={handleBlur}
          errors={errors}
          isSubmitting={isSubmitting}
          setErrors={setErrors}
          form={form}
        />
      );
    }
    case '3D Model': {
      return (
        <ThreeDModelNodeFormBody
          nodeDefinition={nodeDefinition}
          setFieldValue={setFieldValue}
          handleChange={handleChange}
          handleBlur={handleBlur}
          errors={errors}
          isSubmitting={isSubmitting}
          setErrors={setErrors}
          form={form}
        />
      );
    }
    case 'X-ray': {
      return (
        <XRayNodeFormBody
          nodeDefinition={nodeDefinition}
          setFieldValue={setFieldValue}
          handleChange={handleChange}
          handleBlur={handleBlur}
          errors={errors}
          isSubmitting={isSubmitting}
          setErrors={setErrors}
          form={form}
        />
      );
    }
    case 'Points Display': {
      return (
        <PointsDisplayNodeFormBody
          nodeDefinition={nodeDefinition}
          setFieldValue={setFieldValue}
          handleChange={handleChange}
          handleBlur={handleBlur}
          errors={errors}
          isSubmitting={isSubmitting}
          setErrors={setErrors}
          form={form}
        />
      );
    }
    case 'Travel': {
      return (
        <TravelNodeFormBody
          nodeDefinition={nodeDefinition}
          setFieldValue={setFieldValue}
          handleChange={handleChange}
          handleBlur={handleBlur}
          errors={errors}
          isSubmitting={isSubmitting}
          setErrors={setErrors}
          form={form}
        />
      );
    }
    case 'Live True Or False Question': {
      return (
        <LiveTrueOrFalseQuestionNodeFormBody
          nodeDefinition={nodeDefinition}
          setFieldValue={setFieldValue}
          handleChange={handleChange}
          handleBlur={handleBlur}
          errors={errors}
          isSubmitting={isSubmitting}
          setErrors={setErrors}
          form={form}
        />
      );
    }
    case 'Live Poll Question': {
      return (
        <LivePollQuestionNodeFormBody
          nodeDefinition={nodeDefinition}
          setFieldValue={setFieldValue}
          handleChange={handleChange}
          handleBlur={handleBlur}
          errors={errors}
          isSubmitting={isSubmitting}
          setErrors={setErrors}
          form={form}
        />
      );
    }
    case 'Live Image Overlay': {
      return (
        <LiveImageOverlayNodeFormBody
          nodeDefinition={nodeDefinition}
          setFieldValue={setFieldValue}
          handleChange={handleChange}
          handleBlur={handleBlur}
          errors={errors}
          isSubmitting={isSubmitting}
          setErrors={setErrors}
          form={form}
        />
      );
    }
    case 'Live Builder Image Selector': {
      return (
        <LiveBuilderImageSelectorNodeFormBody
          nodeDefinition={nodeDefinition}
          setFieldValue={setFieldValue}
          handleChange={handleChange}
          handleBlur={handleBlur}
          errors={errors}
          isSubmitting={isSubmitting}
          setErrors={setErrors}
          form={form}
        />
      );
    }
    default: {
      return (<div>Nothing to see here</div>);
    }
  }
};

export default NodeRouter;
