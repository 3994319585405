import React from 'react';
import {
  Form,
  Input,
  Button,
  Select,
  Divider,
  PageHeader,
  Alert,
  Switch,
  InputNumber,
} from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchTagDefinitions,
} from 'src/features/tagDefinitions/tagDefinitionsSlice';
import {
  fetchFileReferences,
} from 'src/features/storage/storageSlice';
import {
  addSeason,
  updateSeason,
} from 'src/features/seasons/seasonsSlice';
import merge from 'deepmerge';
import isPlainObject from 'is-plain-object';
import { useFormik } from 'formik';
import {
  transformFiles,
  overwriteMerge,
  colorThemeOptions,
  seasonStatusOptions,
} from 'src/helpers';
/** type imports */
import type { Store } from 'rc-field-form/es/interface';
import type { AppDispatch } from 'src/app/store';
import type { RootState } from 'src/app/rootReducer';
import type {
  Season,
  SeasonFormState,
  // ColorScheme,
  FormItemProps,
} from 'types';
import { fetchAppTagDefinitions } from 'src/features/appTagDefinitions/appTagDefinitionsSlice';

const themeColorOptions = Object.entries(colorThemeOptions)
  .map(([colorKey, colorDisplay]) => ({ label: colorDisplay, value: colorKey }));

interface Props {
  seasonFormState: SeasonFormState;
  onCancel: () => void;
  editMode: boolean;
}

const SeasonFormContainer: React.FC<Props> = ({ seasonFormState, onCancel, editMode }: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    tagDefinitions,
    fetchingTagDefinitions,
  } = useSelector((state: RootState) => state.tagDefinitionsState);
  const {
    appTagDefinitions,
    fetchingAppTagDefinitions,
  } = useSelector((state: RootState) => state.appTagDefinitionsState);

  const {
    fileReferences: {
      imageFiles,
      // videoFiles,
      // audioFiles,
    },
    fetchingFileReferences,
  } = useSelector((state: RootState) => state.storageState);

  const [antdForm] = Form.useForm();

  const tagDefinitionOptions = Object.entries(tagDefinitions)
    .map(([id, { displayName }]) => ({
      label: displayName,
      value: id,
    }));

  const appTagDefinitionOptions = Object.entries(appTagDefinitions)
    .map(([id, { displayName }]) => ({
      label: displayName,
      value: id,
    }));

  const formik = useFormik<SeasonFormState>({
    initialValues: seasonFormState,
    enableReinitialize: true,
    onSubmit: async (values, formikHelpers): Promise<void> => {
      const { id, season } = values;
      const { setSubmitting, resetForm, setErrors } = formikHelpers;
      let success, validationError;
      try {
        if (editMode) {
          ({ success, validationError } = await dispatch(updateSeason(id, season)));
        } else {
          ({ success, validationError } = await dispatch(addSeason(id, season)));
        }
        setSubmitting(false);
        if (success) {
          if (!editMode) {
            resetForm();
            antdForm.resetFields();
          }
        } else if (validationError) {
          console.log(validationError);
          const { path, message } = validationError;
          setErrors({
            [path]: message,
          });
        }
      } catch (error) {
        console.log('Non validation error:', error);
      }
    },
  });

  const {
    values: formikValues,
    errors,
    // handleChange,
    // handleBlur,
    handleSubmit,
    isSubmitting,
    // setFieldValue,
    // setSubmitting,
    // setErrors,
    /* and other goodies */
    initialValues: formikInitialValues,
    setValues,
    dirty,
  } = formik;

  React.useEffect(() => {
    antdForm.resetFields();
  }, [antdForm, formikInitialValues]);

  React.useEffect(() => {
    dispatch(fetchTagDefinitions());
    dispatch(fetchFileReferences());
    dispatch(fetchAppTagDefinitions());
  }, [dispatch]);


  const imageOptions = transformFiles(imageFiles);
  // const videoOptions = transformFiles(videoFiles);
  // const audioOptions = transformFiles(audioFiles);

  // const audioPlaceholder = 'audio/example.mp3';
  const imagePlaceholder = 'image/example.jpeg';
  // const videoPlaceholder = 'video/example.mp4';


  // function displayNameOnChange(event: React.ChangeEvent<HTMLInputElement>): void {
  //   const { target: { value } } = event;
  //   if (!editMode) {
  //     setFieldValue('id', snakeCase(value));
  //   }
  // }

  /**
   * not sure how to type this function
   * this is used to clean out the new state of the form
   * @param obj 
   */
  // function removeEmpty(obj: any): void {
  //   return Object.entries(obj).forEach(([key, val]) => {
  //     if (val && typeof val === 'object' && isPlainObject(val)) {
  //       removeEmpty(val);
  //     } else if (val == null) {
  //       delete obj[key];
  //     }
  //   });
  // }

  function handleValuesChange(changedValues: Store /*, currentValues: Store */): void {
    const newValues = merge<SeasonFormState, Store>(formikValues, changedValues, {
      arrayMerge: overwriteMerge,
      isMergeableObject: isPlainObject,
    });

    // removeEmpty(newValues);

    // if (!editMode) {
    //   const { season: { title } } = newValues;
    //   newValues.id = snakeCase(title);
    // }
    setValues(newValues);
  }


  type FormErrors = {
    [K in keyof Season]?: string;
  }

  function generateFormItemProps(itemName: keyof Season): FormItemProps {
    const hasError = itemName in errors;
    return {
      name: ['season', itemName],
      validateStatus: hasError ? 'error' : '',
      hasFeedback: hasError,
      help: hasError ? (errors as FormErrors)[itemName] : false,
    };
  }

  const { id, season } = formikValues;
  const {
    title,
    subTitle,
    longDescription,
    status,
    primaryColor,
    secondaryColor,
    comingSoon,
    coverImage,
    listImage,
    priority,
    tags,
    appTags,
  } = season;

  const questionDefStyle = {};
  // if (isDeleted) {
  //   questionDefStyle = {
  //     textDecorationLine: 'line-through',
  //     textDecorationStyle: 'solid',
  //   };
  // }

  // async function onDelete(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): Promise<void> {
  //   event.preventDefault();
  //   try {
  //     setSubmitting(true);
  //     // await dispatch(deleteNodeDefinition(id));
  //     console.log('deleted');
  //   } catch (error) {
  //     console.log('error:', error);
  //   }
  //   setSubmitting(false);
  // }
  const BottomButtons: React.FC = () => {
    if (editMode) {
      // let deleteButtonContent = 'Archive Story';
      // if (status === 'archived') {
      //   deleteButtonContent = 'Re-Publish Story';
      // }
      let updateButtonContent = 'Update Season';
      if (!dirty) {
        updateButtonContent = 'Make a change to update';
      }
      return (
        <Form.Item>
          <Button
            block
            size={'large'}
            type={'primary'}
            htmlType={'submit'}
            loading={isSubmitting}
            disabled={isSubmitting || !dirty}
          >
            {updateButtonContent}
          </Button>
          {/* <Button
            block
            danger
            onClick={onDelete}
            size={'large'}
            type={'primary'}
            loading={isSubmitting}
            disabled={isSubmitting}
          >
            {deleteButtonContent}
          </Button> */}
        </Form.Item>
      );
    } else {
      return (
        <Form.Item>
          <Button
            block
            size={'large'}
            type={'primary'}
            htmlType={'submit'}
            loading={isSubmitting}
            disabled={isSubmitting}
          >
            Create new season
          </Button>
        </Form.Item>
      );
    }
  };
  return (
    <React.Fragment>
      <Form
        size={'middle'}
        layout={'vertical'}
        initialValues={formikInitialValues}
        form={antdForm}
        onValuesChange={handleValuesChange}
        onSubmitCapture={handleSubmit}
      >
        <PageHeader
          title={<Form.Item name={'id'}><span style={questionDefStyle}>ID: {id}</span></Form.Item>}
          extra={[
            <Button
              key={'close_sidebar'}
              danger
              icon={<CloseOutlined />}
              onClick={onCancel}
            />
          ]}
        >
          <Form.Item
            {...generateFormItemProps('title')}
            label={'Title'}
          >
            <Input
              placeholder={'Science Madness'}
              disabled={isSubmitting}
              value={title}
            />
          </Form.Item>
          <Form.Item
            {...generateFormItemProps('subTitle')}
            label={'Sub-Title'}
          >
            <Input
              placeholder={'Scientific method & physics'}
              disabled={isSubmitting}
              value={subTitle}
            />
          </Form.Item>
          <Form.Item
            {...generateFormItemProps('longDescription')}
            label={'User Facing Description'}
          >
            <Input.TextArea
              disabled={isSubmitting}
              value={longDescription}
            />
          </Form.Item>
          <Form.Item
            {...generateFormItemProps('status')}
            label={'Status'}
          >
            <Select
              options={Object.entries(seasonStatusOptions).map(([value, label]) => ({ value, label }))}
              disabled={isSubmitting}
              value={status}
              placeholder={'Staged'}
            />
          </Form.Item>
          <Form.Item
            {...generateFormItemProps('primaryColor')}
            label={'Primary Color'}
          >
            <Select
              allowClear
              options={themeColorOptions}
              disabled={isSubmitting}
              value={primaryColor}
              placeholder={'Green'}
            />
          </Form.Item>
          <Form.Item
            {...generateFormItemProps('secondaryColor')}
            label={'Secondary Color'}
          >
            <Select
              allowClear
              options={themeColorOptions}
              disabled={isSubmitting}
              value={secondaryColor}
              placeholder={'Blue'}
            />
          </Form.Item>
          <Form.Item
            {...generateFormItemProps('tags')}
            label={'Tags'}
          >
            <Select
              allowClear
              mode={'multiple'}
              options={tagDefinitionOptions}
              disabled={isSubmitting}
              loading={fetchingTagDefinitions}
              value={tags}
              placeholder='Dinosaurs, Biology...'
            />
          </Form.Item>
          <Form.Item
            {...generateFormItemProps('appTags')}
            label={'App Tags'}
          >
            <Select
              allowClear
              mode={'multiple'}
              options={appTagDefinitionOptions}
              disabled={isSubmitting}
              loading={fetchingAppTagDefinitions}
              value={appTags}
              placeholder='Dinosaurs, Biology...'
            />
          </Form.Item>
          <Form.Item
            {...generateFormItemProps('coverImage')}
            label={'Cover Image File'}
          >
            <Select
              allowClear
              showSearch
              options={imageOptions}
              disabled={isSubmitting}
              loading={fetchingFileReferences}
              value={coverImage}
              placeholder={imagePlaceholder}
            />
          </Form.Item>
          <Form.Item
            {...generateFormItemProps('listImage')}
            label={'List Image File'}
          >
            <Select
              allowClear
              showSearch
              options={imageOptions}
              disabled={isSubmitting}
              loading={fetchingFileReferences}
              value={listImage}
              placeholder={imagePlaceholder}
            />
          </Form.Item>
          <Form.Item
            {...generateFormItemProps('comingSoon')}
            label={'Coming Soon'}
          >
            <Switch
              disabled={isSubmitting}
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              checked={comingSoon}
            />
          </Form.Item>
          <Form.Item
            {...generateFormItemProps('priority')}
            label={'Priority'}
          >
            <InputNumber
              disabled={isSubmitting}
              value={priority}
            />
          </Form.Item>
          <Divider />
          {Object.values(errors).map((error, index) => <Alert style={{ marginBottom: 10 }} showIcon type={'error'} key={index} message={error} />)}
          <BottomButtons />
        </PageHeader>
      </Form>
    </React.Fragment>
  );
};

export default SeasonFormContainer;