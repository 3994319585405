import React from 'react';
// import {
//   Form,
//   Input,
//   Button,
//   Select,
//   Divider,
//   PageHeader,
//   Alert,
//   Switch,
//   InputNumber,
// } from 'antd';
// import {
//   CloseOutlined,
//   CheckOutlined,
// } from '@ant-design/icons';
// import { useSelector, useDispatch } from 'react-redux';
// import {
//   fetchTagDefinitions,
// } from 'src/features/tagDefinitions/tagDefinitionsSlice';
// import {
//   fetchFileReferences,
// } from 'src/features/storage/storageSlice';
// import {
//   addStory,
//   updateStory,
// } from 'src/features/stories/storiesSlice';
// import merge from 'deepmerge';
// import isPlainObject from 'is-plain-object';
// import { useFormik } from 'formik';
// import {
//   transformFiles,
//   overwriteMerge,
//   storyTypeOptions,

// } from 'src/helpers';
/** type imports */
// import type { Store } from 'rc-field-form/es/interface';
// import type { AppDispatch } from 'src/app/store';
// import type { RootState } from 'src/app/rootReducer';
import type { FormInstance } from 'antd/es/form';
import type { FormikErrors } from 'formik';
import type {
  FeatureStory,
  // StoryFormState,
  // ColorScheme,
  FormItemProps,
} from 'types';


interface Props {
  story: Partial<FeatureStory>;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => any;
  handleChange: (eventOrPath: string | React.ChangeEvent<any>) => void | ((eventOrTextValue: string | React.ChangeEvent<any>) => void);
  handleBlur: (eventOrString: any) => void | ((e: any) => void);
  form: FormInstance;
  isSubmitting: boolean;
  errors: FormikErrors<{ id: string; nodeDefinition: Partial<FeatureStory>; }>;
  setErrors: (errors: FormikErrors<{ id: string; nodeDefinition: Partial<FeatureStory>; }>) => void;
}

const FeatureStoryFormBody: React.FC<Props> = (props: Props) => {
  // const dispatch = useDispatch<AppDispatch>();
  // const {
  //   tagDefinitions,
  //   fetchingTagDefinitions,
  // } = useSelector((state: RootState) => state.tagDefinitionsState);

  // const {
  //   fileReferences: {
  //     imageFiles,
  //     // videoFiles,
  //     audioFiles,
  //   },
  //   fetchingFileReferences,
  // } = useSelector((state: RootState) => state.storageState);

  // React.useEffect(() => {
  //   dispatch(fetchFileReferences());
  // }, [dispatch]);

  const {
    // story,
    // setFieldValue,
    // handleChange,
    // handleBlur,
    // isSubmitting,
    errors,
    // form,
  } = props;

  // const { } = story;


  type StoryFormErrors = {
    [K in keyof FeatureStory]?: string;
  }

  // interface AnswerFormErrors {
  //   [answerDefKey: string]: string;
  // }

  function generateFormItemProps(itemName: keyof FeatureStory): FormItemProps {
    const hasError = itemName in errors;
    return {
      name: ['story', itemName],
      validateStatus: hasError ? 'error' : '',
      hasFeedback: hasError,
      help: hasError ? (errors as StoryFormErrors)[itemName] : false,
    };
  }

  // const imageOptions = transformFiles(imageFiles);
  // const videoOptions = transformFiles(videoFiles);
  // const audioOptions = transformFiles(audioFiles);

  // const audioPlaceholder = 'audio/example.mp3';
  // const videoPlaceholder = 'video/example.mp4';
  // const imagePlaceholder = 'image/example.jpeg';

  if (Object.keys(errors).length) {
    console.log(errors);
  }

  return (
    <React.Fragment>
    </React.Fragment>
  );
};

export default FeatureStoryFormBody;