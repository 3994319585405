import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import {
  fetchStory,
  publishStory,
  updateStoryStatus,
} from 'src/features/stories/singleStorySlice';

import NodeDefinitionFormContainer from './nodes/NodeDefinitionFormContainer';
import UserResponseConfigurationForm from './nodes/UserResponseConfigurationForm';
import StoryDetails from './StoryDetails';
import StoryFormContainer from './StoryFormContainer';
// import moment from 'moment';
import {
  // Button,
  Tabs,
  Drawer,
  Row,
  Col,
  notification,
} from 'antd';
import StoryGraph from './StoryGraph';
import {
  useParams,
} from 'react-router-dom';
import { getOptions, getDeletedStoryNodeIds } from 'src/helpers';
import { useRect } from './helpers';
/** type imports */
import type { RootState } from 'src/app/rootReducer';
import type { AppDispatch } from 'src/app/store';
import type {
  Node,
  Story,
  Edge,
  LiveNode,
} from 'types';

interface NodeFormState {
  id: string;
  nodeDefinition: Partial<Node | LiveNode>;
  edgeConfiguration: null | {
    parentNodeId: string;
    parentNode: Node | LiveNode;
    edgeId: string;
    edge: Partial<Edge>;
  };
}


interface Params {
  seasonId: string;
  storyId: string;
}

const Stories: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { seasonId, storyId } = useParams<Params>();
  const containerRef = React.useRef<HTMLDivElement>(null);
  const [selectedNodeId, selectNodeId] = React.useState<string | null>(null);
  const [errorNodeIds, setErrorNodeIds] = React.useState<string[]>([]);
  const [edgeConfig, setEdgConfig] = React.useState<{ edgId: string; parentNodeId: string; } | null>(null);
  const [userResponseParentNodeId, setUserResponseParentNodeId] = React.useState<string | null>(null);
  const [editStory, setEditStory] = React.useState<boolean>(false);
  const containerRect = useRect(containerRef);

  const {
    selectedStory,
    fetchingStory,
  } = useSelector((state: RootState) => state.storyState);

  React.useEffect(() => {
    dispatch(fetchStory(seasonId, storyId));
  }, [dispatch, seasonId, storyId]);


  let nodes: { [nodeId: string]: Node | LiveNode } | null = null;
  const story: Story | null = (selectedStory) ? selectedStory.story : null;
  if (story) {
    nodes = story.nodes;
  }

  const editNode = (nodeId: string): void => {
    if (typeof nodeId === 'string') {
      if (nodeId === '') {
        console.log('nodeId is the empty string ""');
      } else {
        /** finally narrowed down to a non-empty string nodeId */
        selectNodeId(nodeId);
      }
    } else {
      console.log('nodeId is not a string', nodeId);
    }
  };
  const addNewNode = (sourceNodeId: string): void => {
    const nodeId = sourceNodeId;
    if (typeof nodeId === 'string') {
      if (nodeId === '') {
        console.log('nodeId is the empty string ""');
      } else {
        /** finally narrowed down to a non-empty string nodeId */
        if (story && Object.keys(story.nodes).length === 0 && story.startingNodeId === sourceNodeId) {
          selectNodeId(sourceNodeId);
        } else {
          selectNodeId('');
          setEdgConfig({
            parentNodeId: nodeId,
            edgId: '',
          });
        }
      }
    } else {
      console.log('nodeId is not a string', nodeId);
    }
  };

  async function handlePublishStory(): Promise<void> {
    try {
      const { success, validationError } = await dispatch(publishStory());
      console.log(success, validationError);
      if (success) {
        if (nodes) {
          notification['success']({
            message: 'Story Published!!',
            duration: 4,
            description: "Hurray!",
          });
        }
        setErrorNodeIds([]);
      } else if (validationError) {
        const errorIds = validationError.value;
        if (validationError.type === 'minimum-nodes-size') {
          notification['error']({
            message: 'Empty Story',
            duration: 10,
            description: 'Must have at least one node',
          });
        } else if (Array.isArray(errorIds)) {
          setErrorNodeIds(errorIds);
          errorIds.forEach((errorNodeId) => {
            if (nodes) {
              const errorNode = nodes[errorNodeId];
              notification['error']({
                message: 'Dangling Node',
                duration: 10,
                description: `The node "${errorNode.displayName}" is open ended. Please add a child node or mark it as an "ending node"`,
              });
            }
          });
        }
      }
    } catch (error) {
      console.log('error:', error);
    }
  }

  async function handleStoryStatusChange(newStatus: 'archived' | 'staged' | 'preview'): Promise<void> {
    try {
      const { success, validationError } = await dispatch(updateStoryStatus(newStatus));
      console.log(success, validationError);
    } catch (error) {
      console.log('error:', error);
    }
  }

  const editUserResponseConfig = (parentNodeId: string): void => {
    if (typeof parentNodeId === 'string') {
      if (parentNodeId === '') {
        console.log('parentNodeId is the empty string ""');
      } else {
        setUserResponseParentNodeId(parentNodeId);
      }
    } else {
      console.log('nodeId is not a string', parentNodeId);
    }
  };

  let nodeFormParams: null | {
    editMode: boolean;
    hasChildren: boolean;
    nodeFormState: NodeFormState;
    deletedStoryNodeIds: string[];
  } = null;
  if (story && nodes && selectedNodeId !== null) {
    const numberOfNodes = Object.keys(nodes).length;
    const deletedStoryNodeIds = getDeletedStoryNodeIds(story.nodes);
    const newlyCreatedStory = numberOfNodes === 0;
    const initialNode: Partial<Node | LiveNode> = {
      type: (story.type === 'live') ? 'Live Image Overlay' : 'Live Action Multiple Choice Question',
      displayOrder: numberOfNodes + 1,
      isDeleted: false,
      isEndingNode: false,
    };
    if (newlyCreatedStory) {
      if (selectedNodeId === story.startingNodeId) {
        nodeFormParams = {
          editMode: false,
          hasChildren: false,
          deletedStoryNodeIds,
          nodeFormState: {
            id: selectedNodeId,
            nodeDefinition: {
              ...initialNode,
            },
            edgeConfiguration: null,
          },
        };
      } else {
        console.log('Not sure what happened');
      }
    } else if (selectedNodeId === '') {
      const newNodeId = uuidv4();
      nodeFormParams = {
        editMode: false,
        hasChildren: false,
        deletedStoryNodeIds,
        nodeFormState: {
          id: newNodeId,
          nodeDefinition: {
            type: (story.type === 'live') ? 'Live Image Overlay' : 'Video',
            displayOrder: numberOfNodes + 1,
            isEndingNode: false,
            isDeleted: false,
          },
          edgeConfiguration: null,
        },
      };
      if (edgeConfig !== null) {
        const { parentNodeId, edgId } = edgeConfig;
        const parentNode = nodes[parentNodeId];
        if (parentNode) {
          if (edgId === '') {
            console.log('in edgId empty string');
            const displayOrder = Object.keys(parentNode.edges).length + 1;
            nodeFormParams.nodeFormState.edgeConfiguration = {
              parentNodeId,
              parentNode,
              edgeId: uuidv4(),
              edge: {
                displayOrder,
                targetNodeId: newNodeId,
                // context?: {
                //   answerDefinitionIds?: string[];
                // };
              },
            };
            const options = getOptions(parentNode, deletedStoryNodeIds);
            if (options !== null) {
              nodeFormParams.nodeFormState.edgeConfiguration.edge.context = {
                optionIds: [],
              };
            }

          } else {
            console.log('editing node');
          }
        } else {
          console.log(`edgeConfig.parentNodeId ${edgeConfig.parentNodeId} does not correspond to a node for this story`);
        }
      }
    } else if (nodes[selectedNodeId]) {
      const node = nodes[selectedNodeId];
      const hasChildren = Object.values(node.edges).filter(({ targetNodeId }) => nodes && !nodes[targetNodeId].isDeleted).length > 0 || selectedNodeId === story.startingNodeId;
      nodeFormParams = {
        editMode: true,
        hasChildren,
        deletedStoryNodeIds,
        nodeFormState: {
          id: selectedNodeId,
          nodeDefinition: node,
          edgeConfiguration: null,
        }
      };
    } else {
      console.log('selected nodeId does not correspond to a node');
    }
  }
  return (
    <React.Fragment>
      <Row
        justify='center'
        style={{
          padding: '1em',
          margin: '1em',
          backgroundColor: 'white',
        }}
      >
        <Col>
          <Tabs
            defaultActiveKey='1'
            centered
            size='large'
          >
            <Tabs.TabPane
              tab='Story Graph'
              key='1'
            >
              <div
                ref={containerRef}
                style={{
                  width: 'auto',
                  height: '1000px',
                  borderWidth: '2px',
                  borderStyle: 'solid',
                  borderColor: '#40a9ff',
                }}
              >
                {(!fetchingStory && story && story.startingNodeId && nodes) && <StoryGraph
                  changesSincePublished={story.changesSincePublished}
                  errorNodeIds={errorNodeIds}
                  publishStory={handlePublishStory}
                  updateStoryStatus={handleStoryStatusChange}
                  nodes={nodes}
                  rootNodeId={story.startingNodeId}
                  status={story.status}
                  editNode={editNode}
                  addNewNode={addNewNode}
                  editUserResponseConfig={editUserResponseConfig}
                  selectedNodeId={selectedNodeId}
                  containerRect={containerRect}
                />}
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane tab='Story Details' key='2'>
              <Row justify='center'>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <StoryDetails
                    fetchingStory={fetchingStory}
                    selectedStory={selectedStory}
                    setEditStory={() => setEditStory(true)}
                  />
                </Col>
              </Row>
            </Tabs.TabPane>
          </Tabs>
        </Col>
      </Row>
      <Drawer
        width={600}
        placement={'right'}
        visible={nodeFormParams !== null}
        closable={false}
      >
        {(nodeFormParams !== null) && <NodeDefinitionFormContainer
          storyType={story?.type}
          {...nodeFormParams}
          onCancel={() => { selectNodeId(null); setEdgConfig(null); }}
        />}
      </Drawer>
      <Drawer
        width={600}
        placement={'right'}
        visible={editStory}
        closable={false}
      >
        {(!fetchingStory && selectedStory && editStory) && <StoryFormContainer
          editMode={true}
          storyFormState={{
            seasonId: selectedStory.seasonId,
            id: selectedStory.storyId,
            story: selectedStory.story,
          }}
          onCancel={() => setEditStory(false)}
        />}
      </Drawer>
      <Drawer
        width={600}
        placement={'right'}
        visible={userResponseParentNodeId !== null}
        closable={false}
      >
        {(userResponseParentNodeId !== null && nodes) && <UserResponseConfigurationForm
          parentNodeId={userResponseParentNodeId}
          storyNodes={nodes}
          onCancel={() => setUserResponseParentNodeId(null)}
        />}
      </Drawer>
    </React.Fragment>
  );
};

export default Stories;