import { createSlice } from '@reduxjs/toolkit';
import firebase from 'firebase';
import { generateFirestorePath } from 'src/helpers';
import * as yup from 'yup';
import 'firebase/firestore';
import { seasonSchema, seasonConverter } from './helpers';
import { fetchSeason } from './singleSeasonSlice';
/** type imports */
import type { AsyncAppThunk } from 'src/app/store';
import type {
  Season,
} from 'types';
import type { PayloadAction } from '@reduxjs/toolkit';

const { firestore } = firebase;

interface SuccessReturn {
  success: true;
  validationError: null;
}
interface FailureReturn {
  success: false;
  validationError: yup.ValidationError;
}

type ThunkReturn = SuccessReturn | FailureReturn;

interface SeasonsState {
  seasons: { [seasonId: string]: Season };
  fetchingSeasons: boolean;
}

const initialState: SeasonsState = {
  seasons: {},
  fetchingSeasons: true,
};

const seasonsSlice = createSlice({
  name: 'seasons',
  initialState,
  reducers: {
    setSeasons(state, action: PayloadAction<{ [seasonId: string]: Season }>): void {
      state.seasons = action.payload;
      state.fetchingSeasons = false;
    },
    setToLoading(state): void {
      state.fetchingSeasons = true;
    },
  },
});

const {
  setSeasons,
  // setToLoading,
} = seasonsSlice.actions;

// thunk creator
export function fetchSeasons(): AsyncAppThunk<void> {
  return async function thunk(dispatch, getState): Promise<void> {
    try {
      const user = getState().userState.user;
      if (!user) {
        throw new Error('Not authenticated');
      }
      const seasons: { [seasonId: string]: Season } = {};
      const seasonColSnapshot = await firestore()
        .collection(generateFirestorePath('seasons'))
        .withConverter(seasonConverter)
        .orderBy('createdAt', 'desc')
        .get();

      seasonColSnapshot.forEach((seasonSnapshot) => {
        if (seasonSnapshot.exists) {
          const id = seasonSnapshot.id;
          const season = seasonSnapshot.data();
          seasons[id] = season;
        }
      });

      dispatch(setSeasons(seasons));
    } catch (error) {
      console.log('Error in fetchSessions():', error);
    }
  };
}

export function addSeason(id: string, newSeason: Partial<Season>): AsyncAppThunk<ThunkReturn> {
  return async function thunk(dispatch, getState): Promise<ThunkReturn> {
    try {
      const user = getState().userState.user;
      if (!user) {
        throw new Error('Not authenticated');
      }
      newSeason.createdBy = user.uid;
      const season = await seasonSchema.validate(newSeason, {
        stripUnknown: true,
        context: {
          editMode: false,
        }
      });
      const newSeasonRef = firestore()
        .collection(generateFirestorePath('seasons'))
        .withConverter(seasonConverter)
        .doc(id);
      const seasonData = await newSeasonRef.get();
      if (seasonData.exists) {
        throw new yup.ValidationError('Question already exists', 'Already exists', '');
      }

      await newSeasonRef.set(season);

      await dispatch(fetchSeasons());
      return {
        success: true,
        validationError: null,
      };
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        return {
          success: false,
          validationError: error,
        };
      } else {
        console.log('Error in addSeason():', error);
        throw error;
      }
    }
  };
}

export function updateSeason(id: string, updatedSeason: Partial<Season>): AsyncAppThunk<ThunkReturn> {
  return async function thunk(dispatch, getState): Promise<ThunkReturn> {
    try {
      const user = getState().userState.user;
      if (!user) {
        throw new Error('Not authenticated');
      }
      const season = await seasonSchema.validate(updatedSeason, {
        stripUnknown: true,
        context: {
          editMode: true,
        }
      });
      const seasonRef = firestore()
        .collection(generateFirestorePath('seasons'))
        .withConverter(seasonConverter)
        .doc(id);
      const seasonData = await seasonRef.get();
      if (!seasonData.exists) {
        throw new yup.ValidationError('Season does not exist', 'Season does not exist', '');
      }

      await seasonRef.set(season);

      await dispatch(fetchSeason(id));
      await dispatch(fetchSeasons());
      return {
        success: true,
        validationError: null,
      };
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        return {
          success: false,
          validationError: error,
        };
      } else {
        console.log('Error in updateSeason():', error);
        throw error;
      }
    }
  };
}

export default seasonsSlice.reducer;