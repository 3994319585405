import moment from 'moment';
// import { snakeCase } from 'lodash';
import { generateValidationFunc, colorThemes, seasonStatus } from 'src/helpers';
import firebase from 'firebase';
import * as yup from 'yup';
import { SeasonSnapshot, SnapshotOptions, Season } from 'types';


const { firestore } = firebase;

export const seasonConverter: firebase.firestore.FirestoreDataConverter<Season> = {
  toFirestore(season: Season) {
    const snapshot = {
      ...season,
      createdBy: firestore().doc('adminUsers/' + season.createdBy),
      createdAt: firestore.Timestamp.fromMillis(season.createdAt),
      updatedAt: (season.updatedAt !== undefined) ? firestore.Timestamp.fromMillis(season.updatedAt) : firestore.Timestamp.now(),
    };
    return snapshot;
  },
  fromFirestore(snapshot: SeasonSnapshot, options: SnapshotOptions): Season {
    const data = snapshot.data(options);
    if (data.appTags === undefined) {
      data.appTags = [];
    }
    const season: Season = {
      ...data,
      createdBy: data.createdBy.id,
      createdAt: data.createdAt.toMillis(),
      updatedAt: (data.updatedAt) ? data.updatedAt.toMillis() : firestore.Timestamp.now().toMillis(),
    };
    if (season.appTags === undefined) {
      season.appTags = [];
    }
    return season;
  },
};


export const seasonSchema: yup.ObjectSchema<Season> = yup.object().required().shape({
  title: yup.string().required().label('Title'),
  subTitle: yup.string().required().label('Sub-Title'),
  longDescription: yup.string().required().label('User Facing Description'),
  primaryColor: yup.string().required().oneOf(colorThemes).label('Primary Color'),
  secondaryColor: yup.string().required().oneOf(colorThemes).label('Secondary Color'),
  comingSoon: yup.boolean().required().label('Is Deleted'),
  status: yup.string().required().oneOf(seasonStatus).label('Status'),
  coverImage: generateValidationFunc('image').required().label('Cover Image'),
  listImage: generateValidationFunc('image').required().label('List Image'),
  priority: yup.number().required().label('Priority'),
  tags: yup.array<string>().required().label('Tags'),
  appTags: yup.array<string>().required().label('App Tags'),
  isDeleted: yup.boolean().required(),
  createdBy: yup.string().required(),
  createdAt: yup.number().required().default(() => moment().valueOf()),
  updatedAt: yup.number().when('$editMode', (editMode: boolean | undefined, schema: yup.NumberSchema<number | undefined>) => {
    return schema
      .default(() => moment().valueOf())
      .transform(() => moment().valueOf());
  }),
});