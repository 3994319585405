import React from 'react';
import { Result } from 'antd';

const Unauthorized: React.FC = () => {
  return (
    <Result
      status='403'
      title='Unauthorized! 403'
      subTitle='You are not authorized to use the part of the app. Contact the admin to update your account.'
    // extra={<Button type="primary">Back Home</Button>}
    />
  );
};

export default Unauthorized;