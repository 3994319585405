import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NodeDefinitions, NodeDefinition } from 'types';
import { AsyncAppThunk } from 'src/app/store';
import firebase from 'firebase';
import moment from 'moment';
import * as yup from 'yup';
import 'firebase/firestore';
import 'firebase/auth';
import { spudMultiChoiceQuestionSchema } from './helpers';

const { auth, firestore } = firebase;
interface SuccessReturn {
  success: true;
  validationError: null;
}
interface FailureReturn {
  success: false;
  validationError: yup.ValidationError;
}

type ThunkReturn = SuccessReturn | FailureReturn;

interface NodeDefinitionsState {
  nodeDefinitions: NodeDefinitions;
  selectedNodeDefinition: NodeDefinition | null;
  fetchingSelectedDefinition: boolean;
  fetchingDefinitions: boolean;
}

const initialState: NodeDefinitionsState = {
  nodeDefinitions: {},
  selectedNodeDefinition: null,
  fetchingSelectedDefinition: false,
  fetchingDefinitions: true,
};

const nodeDefinitionsSlice = createSlice({
  name: 'nodeDefinitions',
  initialState,
  reducers: {
    setNodeDefinitions(state, action: PayloadAction<NodeDefinitions>): void {
      state.nodeDefinitions = action.payload;
      state.fetchingDefinitions = false;
    },
    setToLoading(state): void {
      state.fetchingDefinitions = true;
    },
  },
});

const {
  setNodeDefinitions,
  // setToLoading,
} = nodeDefinitionsSlice.actions;

// thunk creator
export function fetchNodeDefinitions(): AsyncAppThunk<void> {
  return async function thunk(dispatch): Promise<void> {
    try {
      const user = auth().currentUser;
      if (!user) {
        throw new Error('Not authenticated');
      }
      const nodeDefinitions: NodeDefinitions = {};
      const nodeDefColSnapshot = await firestore().collection('nodeDefinitions').orderBy('createdAt', 'desc').get();
      nodeDefColSnapshot.forEach((nodeDefinition) => {
        if (nodeDefinition.exists) {
          const id = nodeDefinition.id;
          const nodeDefinitionData = nodeDefinition.data() as NodeDefinition;

          if (nodeDefinitionData.type === 'Spud multi-choice question') {
            Object.values(nodeDefinitionData.answerDefinitions).forEach((answerDefinition, index) => {
              if (answerDefinition.displayOrder === undefined) {
                answerDefinition.displayOrder = index;
              }
            });
          }
          nodeDefinitions[id] = nodeDefinitionData;
        }
      });

      dispatch(setNodeDefinitions(nodeDefinitions));
    } catch (error) {
      console.log('Error in fetchNodeDefinitions():', error);
      dispatch(setNodeDefinitions({}));
    }
  };
}

export function addNodeDefinition(id: string, newNodeDef: Partial<NodeDefinition>): AsyncAppThunk<ThunkReturn> {
  return async function thunk(dispatch): Promise<ThunkReturn> {
    try {
      const user = auth().currentUser;
      if (!user) {
        throw new Error('Not authenticated');
      }
      // dispatch(setToLoading());
      let schema;
      switch (newNodeDef.type) {
        case 'Spud multi-choice question': {
          schema = spudMultiChoiceQuestionSchema;
          break;
        }
        default:
          throw new Error(`Unrecognized node definition type`);
      }
      const node = await schema?.validate(newNodeDef);
      const newNodeDefRef = firestore().collection('nodeDefinitions').doc(id);
      const nodeDef = await newNodeDefRef.get();
      if (nodeDef.exists) {
        throw new yup.ValidationError('Question already exists', 'Already exists', '');
      }

      // newNodeDef.createdAt = firestore.Timestamp.now();
      // extraValidationAndProcessing(newNodeDef);
      await newNodeDefRef.set(node);

      await dispatch(fetchNodeDefinitions());

      return {
        success: true,
        validationError: null,
      };
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        return {
          success: false,
          validationError: error,
        };
      } else {
        console.log('Error in addNodeDefinition():', error);
        throw error;
      }
    }
  };
}

export function updateNodeDefinition(id: string, newNodeDef: Partial<NodeDefinition>): AsyncAppThunk<ThunkReturn> {
  return async function thunk(dispatch): Promise<ThunkReturn> {
    try {
      const user = auth().currentUser;
      if (!user) {
        throw new Error('Not authenticated');
      }
      const newNodeDefRef = firestore().collection('nodeDefinitions').doc(id);
      const nodeDef = await newNodeDefRef.get();
      if (!nodeDef.exists) {
        throw new yup.ValidationError("Question Doesn't exist", "Question Doesn't exist", "");
      }

      let schema;
      switch (newNodeDef.type) {
        case 'Spud multi-choice question': {
          schema = spudMultiChoiceQuestionSchema;
          break;
        }
        default: {
          throw new Error(`Unrecognized node definition type`);
        }
      }

      const node = await schema?.validate(newNodeDef);

      await newNodeDefRef.set(node);

      await dispatch(fetchNodeDefinitions());

      return {
        success: true,
        validationError: null,
      };
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        return {
          success: false,
          validationError: error,
        };
      } else {
        console.log('Error in updateNodeDefinition():', error);
        throw error;
      }
    }
  };
}

export function deleteNodeDefinition(id: string): AsyncAppThunk<ThunkReturn> {
  return async function thunk(dispatch): Promise<ThunkReturn> {
    try {
      const user = auth().currentUser;
      if (!user) {
        throw new Error('Not authenticated');
      }

      const newNodeDefRef = firestore().collection('nodeDefinitions').doc(id);
      const nodeDef = await newNodeDefRef.get();
      if (!nodeDef.exists) {
        throw new yup.ValidationError("Question Doesn't exist", "Question Doesn't exist", "");
      }

      const nodeDefData = nodeDef.data() as NodeDefinition;
      let isDeleted = true;
      let status = 'Deleted';
      if (nodeDefData.isDeleted) {
        isDeleted = false;
        status = 'Ready';
      }

      await newNodeDefRef.set({
        isDeleted,
        status,
        updatedAt: moment().valueOf(),
      }, { merge: true });

      await dispatch(fetchNodeDefinitions());

      return {
        success: true,
        validationError: null,
      };
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        return {
          success: false,
          validationError: error,
        };
      } else {
        console.log('Error in deleteNodeDefinition():', error);
        throw error;
      }
    }
  };
}

export default nodeDefinitionsSlice.reducer;