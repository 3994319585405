import moment from 'moment';
import firebase from 'firebase';
import {
  colorThemes,
  difficultyLevels,
  generateValidationFunc,
  threeDModelIDs,
  particleEffects,
  defaultPositions,
} from 'src/helpers';
import * as yup from 'yup';
/** import types */
import type {
  Node,
  LiveNode,
  LiveNodeDefinitionBase,
  LiveImageOverlayNode,
  LivePollQuestionNode,
  LiveTrueOrFalseQuestionNode,
  AnswerDefinition,
  QuestionNodeDefinition,
  Timestamp,
  Edge,
  NodeDefinitionBase,
  VideoNode,
  BotMultipleQuestionNode,
  BotMultipleChoiceAnswer,
  XRayNode,
  PhoneNode,
  LAMCQuestionNode,
  LAMCAnswerDefinition,
  PulleyNode,
  SelfieNode,
  BadgeEarningNode,
  DrawingNode,
  ScreenCleaningNode,
  ScreenCleanerOption,
  IdBadgeNode,
  ThreeDModelNode,
  PointsDisplayNode,
  TravelNode,
  NodeTypes,
  LiveBuilderImageSelectorNode,
  LiveBuilderImageOption,
} from 'types';


const { firestore } = firebase;



export const edgeSchema: yup.ObjectSchema<Edge> = yup.object().required().shape<Edge>({
  targetNodeId: yup.string().required().label('Target Node ID'),
  displayOrder: yup.number().required().label('Display Order'),
  context: yup.object().when('$nodeType', (nodeType: NodeTypes | undefined, schema: yup.ObjectSchema) => {
    if (nodeType === 'Live Action Multiple Choice Question' || nodeType === 'Bot multi-choice question' || nodeType === 'Screen Cleaning') {
      return schema.shape({
        optionIds: yup.array<string>().required().min(1, 'Must select at least one user response').label('User Response Options'),
      }).required().label('Context');
    } else {
      return schema.strip(true);
    }
  }),
});

const nodeDefinitionBaseSchema: yup.ObjectSchema<NodeDefinitionBase> = yup.object().required().shape<NodeDefinitionBase>({
  displayName: yup.string().required().label('Display Name'),
  description: yup.string().required().label('Description'),
  displayOrder: yup.number().required(),
  isEndingNode: yup.boolean().required().label('Is Ending Node'),
  isDeleted: yup.boolean().required().label('Is Deleted'),
  createdBy: yup.string().required().label('Created By'),
  createdAt: yup.number().required().default(() => moment().valueOf()),
  updatedAt: yup.number().when('$editMode', (editMode: boolean | undefined, schema: yup.NumberSchema<number | undefined>) => {
    if (editMode) {
      return schema
        .default(() => moment().valueOf())
        .transform(() => moment().valueOf());
    } else {
      return schema.strip(true);
    }
  }),
  edges: yup.lazy<{ [edgeId: string]: Edge }>((edges = {}) => {
    /** create empty edges schema */
    let edgesSchema: yup.ObjectSchema<{ [edgeId: string]: Edge }> = yup.object<{ [edgeId: string]: Edge }>()
      .label('Edges')
      .defined()
      .default(() => ({}));
    /** loop over all the edges */
    Object.keys(edges).forEach((edgeId) => {
      /** add each edge schema to full edges schema */
      edgesSchema = edgesSchema.shape({
        [edgeId]: edgeSchema,
      });
    });
    /** return accumulated edges schema */
    return edgesSchema;
  }),
});

// { parentNodeId: string; edgeId: string; edge: Edge; }

export const edgeConfigurationSchema: yup.ObjectSchema<{ parentNodeId: string; edgeId: string; edge: Edge; }> = yup.object().required().shape<{ parentNodeId: string; edgeId: string; edge: Edge; }>({
  parentNodeId: yup.string().required().label('Parent Node Id'),
  edgeId: yup.string().required().label('Edge Id'),
  edge: edgeSchema,
});

export function generateEdgesSchema(node: Node | LiveNode): yup.ObjectSchema<{ [edgeId: string]: Edge }> {
  let edgesSchema: yup.ObjectSchema<{ [edgeId: string]: Edge }> = yup.object<{ [edgeId: string]: Edge }>()
    .label('Edges')
    .defined()
    .default(() => ({}));

  const edgeBuckets: { [edgeId: string]: string[] } = {};
  Object.entries(node.edges).forEach(([edgeId, { context }]) => {
    edgeBuckets[edgeId] = context?.optionIds || [];
  });

  // const options: { [id: string]: { label: string; value: string; edgeId: string | null } } = {};
  // switch (node.type) {
  //   case 'Live Action Multiple Choice Question':
  //     Object.entries(node.lamcAnswers).forEach(([id, option]) => {
  //       options[id] = {
  //         label: option.answer,
  //         value: id,
  //         edgeId: null,
  //       };
  //     });
  //     break;
  //   case 'Bot multi-choice question':
  //     Object.entries(node.answerDefinitions).forEach(([id, option]) => {
  //       options[id] = {
  //         label: option.answer,
  //         value: id,
  //         edgeId: null,
  //       };
  //     });
  //     break;
  //   case 'Screen Cleaning':
  //     Object.entries(node.screenCleanerOptions).forEach(([id, option]) => {
  //       options[id] = {
  //         label: option.displayName,
  //         value: id,
  //         edgeId: null,
  //       };
  //     });
  //     break;
  // }

  Object.entries(node.edges).forEach(([edgeId]) => {
    const excludedIds: string[] = [];
    Object.entries(edgeBuckets).forEach(([innerEdgeId, selectedOptionIds]) => {
      if (innerEdgeId !== edgeId) {
        excludedIds.push(...selectedOptionIds);
      }
    });
    edgesSchema = edgesSchema.shape({
      [edgeId]: yup.object().required().shape<Edge>({
        targetNodeId: yup.string().required().label('Target Node ID'),
        displayOrder: yup.number().required().label('Display Order'),
        context: yup.object().shape({
          optionIds: yup.array<string>()
            .required()
            .min(1, 'Must select at least one user response').label('User Response Options')
            .notOneOf(excludedIds, "You can't select an option that has already been selected for another edge"),
        }).required().label('Context'),
      })
    });
  });

  return edgesSchema;
}

export const idBadeNodeSchema: yup.ObjectSchema<IdBadgeNode> = nodeDefinitionBaseSchema.shape<Omit<IdBadgeNode, keyof NodeDefinitionBase>>({
  type: yup.string().required().oneOf(['ID Badge']).label('Type'),
  video: generateValidationFunc('video').required().label('Video File'),
  waitingVideo: generateValidationFunc('video').required().label('Waiting Video File'),
});

export const screenCleaningNodeSchema: yup.ObjectSchema<ScreenCleaningNode> = nodeDefinitionBaseSchema.shape<Omit<ScreenCleaningNode, keyof NodeDefinitionBase>>({
  type: yup.string().required().oneOf(['Screen Cleaning']).label('Type'),
  particleEffect: yup.string().oneOf(particleEffects).required().label('Partical Effect'),
  soundEffect: generateValidationFunc('audio').required().label('Sound Effect Audio File'),
  botAudio: generateValidationFunc('audio').label('Bot Audio File'),
  backgroundVideo: generateValidationFunc('video').required().label('Background Video File'),
  waitingLoopingVideo: generateValidationFunc('video').required().label('Waiting looping Video File'),
  screenCleanerOptions: yup.lazy<{ [scOptionId: string]: ScreenCleanerOption }>((scOptions = {}) => {

    let scOptionsSchema: yup.ObjectSchema<{ [scOptionId: string]: ScreenCleanerOption }> = yup.object<{ [scOptionId: string]: ScreenCleanerOption }>()
      .label('Option Definitions')
      .defined()
      .default(() => ({}));

    Object.keys(scOptions).forEach((scOptionId) => {

      const scOptionSchema: yup.ObjectSchema<ScreenCleanerOption> = yup.object<ScreenCleanerOption>({
        displayName: yup.string().required().label('Display Name'),
        image: generateValidationFunc('image').required().label('Image File'),
        isCorrect: yup.boolean().required().label('Is Correct'),
        soundEffect: generateValidationFunc('audio').required().label('Sound Effect Audio File'),
        botAudio: generateValidationFunc('audio').label('Bot Audio File'),
        particleEffect: yup.string().oneOf(particleEffects).required().label('Partical Effect'),
        responseVideo: generateValidationFunc('video').required().label('Response Video File'),
        responseBotAudio: generateValidationFunc('audio').label('Response Bot Audio'),
        points: yup.number().label('Points'),
        displayOrder: yup.number().required().label('Display Order'),
        isDeleted: yup.boolean().required().label('Is Deleted'),
        createdAt: yup.number().required().default(() => moment().valueOf()),
      }).required();

      scOptionsSchema = scOptionsSchema.shape({
        [scOptionId]: scOptionSchema,
      });

    });

    return scOptionsSchema
      .test('has-correct-answer', 'Must have at least one correct option', (scOptions: { [scOptionId: string]: ScreenCleanerOption }): boolean => {
        let hasAtLeastOneCorrectAnswer = false;
        Object.values(scOptions).forEach(({ isCorrect }) => {
          if (isCorrect) {
            hasAtLeastOneCorrectAnswer = true;
          }
        });
        return hasAtLeastOneCorrectAnswer;
      });
  }),
});

export const drawingNodeSchema: yup.ObjectSchema<DrawingNode> = nodeDefinitionBaseSchema.shape<Omit<DrawingNode, keyof NodeDefinitionBase>>({
  type: yup.string().required().oneOf(['Drawing']).label('Type'),
  botAudio: generateValidationFunc('audio').required().label('Bot Audio File'),
  backgroundImage: generateValidationFunc('image').required().label('Background Image File'),
  musicAudio: generateValidationFunc('audio').required().label('Music Audio File'),
  savedFileName: yup.string().required().label('Saved File Name'),
});

export const selfieNodeSchema: yup.ObjectSchema<SelfieNode> = nodeDefinitionBaseSchema.shape<Omit<SelfieNode, keyof NodeDefinitionBase>>({
  type: yup.string().required().oneOf(['Selfie']).label('Type'),
  botAudio: generateValidationFunc('audio').required().label('Bot Audio File'),
  overlayImage: generateValidationFunc('image').required().label('Overlay Image File'),
  musicAudio: generateValidationFunc('audio').required().label('Music Audio File'),
  savedFileName: yup.string().required().label('Saved File Name'),
});

export const badgeEarningNodeSchema: yup.ObjectSchema<BadgeEarningNode> = nodeDefinitionBaseSchema.shape<Omit<BadgeEarningNode, keyof NodeDefinitionBase>>({
  type: yup.string().required().oneOf(['Badge Earning']).label('Type'),
  badgeDescription: yup.string().required().label('User facing description'),
  botAudio: generateValidationFunc('audio').required().label('Bot Audio File'),
  backgroundImage: generateValidationFunc('image').required().label('Background JPG Image File'),
  image: generateValidationFunc('image').required().label('Badge PNG Image File'),
});

export const pulleyNodeSchema: yup.ObjectSchema<PulleyNode> = nodeDefinitionBaseSchema.shape<Omit<PulleyNode, keyof NodeDefinitionBase>>({
  type: yup.string().required().oneOf(['Pulley']).label('Type'),
  startingVideo: generateValidationFunc('video').required().label('Starting Video File'),
  botAudio: generateValidationFunc('audio').required().label('Bot Audio File'),
  waitingVideo: generateValidationFunc('video').required().label('Waiting Video File'),
  afterVideo: generateValidationFunc('video').label('After Video File'),
  particleEffect: yup.string().oneOf(particleEffects).label('Partical Effect'),
});

export const videoNodeSchema: yup.ObjectSchema<VideoNode> = nodeDefinitionBaseSchema.shape<Omit<VideoNode, keyof NodeDefinitionBase>>({
  type: yup.string().required().oneOf(['Video']).label('Type'),
  video: generateValidationFunc('video').required().label('Video File'),
  botAudio: generateValidationFunc('audio').label('Bot Audio File'),
  continueAfterBotAudioFinishes: yup.boolean().required().label('Continue After Bot Audio Finishes'),
}).test('has-bot-audio-clip', 'If you want to have the node continue after the audio clip you must select an audio clip!', (node: VideoNode): boolean => {
  if (node.continueAfterBotAudioFinishes === true && node.botAudio === undefined) {
    return false;
  }
  return true;
});

export const pointsDisplayNodeSchema: yup.ObjectSchema<PointsDisplayNode> = nodeDefinitionBaseSchema.shape<Omit<PointsDisplayNode, keyof NodeDefinitionBase>>({
  type: yup.string().required().oneOf(['Points Display']).label('Type'),
  backgroundImage: generateValidationFunc('image').required().label('Background Image File'),
});

export const travelNodeSchema: yup.ObjectSchema<TravelNode> = nodeDefinitionBaseSchema.shape<Omit<TravelNode, keyof NodeDefinitionBase>>({
  type: yup.string().required().oneOf(['Travel']).label('Type'),
});

export const xrayNodeSchema: yup.ObjectSchema<XRayNode> = nodeDefinitionBaseSchema.shape<Omit<XRayNode, keyof NodeDefinitionBase>>({
  type: yup.string().required().oneOf(['X-ray']).label('Type'),
  backgroundImage: generateValidationFunc('image').required().label('Background Image File'),
  xrayImage: generateValidationFunc('image').required().label('X-ray Image File'),
  botAudio: generateValidationFunc('audio').required().label('Bot Audio File'),
});

export const phoneNodeSchema: yup.ObjectSchema<PhoneNode> = nodeDefinitionBaseSchema.shape<Omit<PhoneNode, keyof NodeDefinitionBase>>({
  type: yup.string().required().oneOf(['Phone']).label('Type'),
  characterProfileId: yup.string().required().label('Character Profile'),
  backgroundImage: generateValidationFunc('image').required().label('Background Image File'),
  botAudio: generateValidationFunc('audio').label('Bot Audio File'),
});

export const threeDNodeSchema: yup.ObjectSchema<ThreeDModelNode> = nodeDefinitionBaseSchema.shape<Omit<ThreeDModelNode, keyof NodeDefinitionBase>>({
  type: yup.string().required().oneOf(['3D Model']).label('Type'),
  threeDModelAsset: yup.string().required().oneOf(threeDModelIDs).label('3D Model Asset'),
  botAudio: generateValidationFunc('audio').required().label('Bot Audio File'),
});

export const botMultipleQuestionNodeSchema: yup.ObjectSchema<BotMultipleQuestionNode> = nodeDefinitionBaseSchema.shape<Omit<BotMultipleQuestionNode, keyof NodeDefinitionBase>>({
  type: yup.string().required().oneOf(['Bot multi-choice question']).label('Type'),
  themeColor: yup.string().required().oneOf(colorThemes).label('Theme'),
  difficulty: yup.string().required().oneOf(difficultyLevels).label('Difficulty'),
  tags: yup.array<string>().required().label('Tags'),
  question: yup.string().required().label('Question Text'),
  questionAudio: generateValidationFunc('audio').required().label('Question Audio'),
  startingBackgroundVideo: generateValidationFunc('video').label('Starting Background Video File'),
  startingBackgroundImage: generateValidationFunc('image').label('Starting Background Image File'),
  answerDefinitions: yup.lazy<{ [answerDefId: string]: BotMultipleChoiceAnswer }>((answerDefinitions = {}) => {

    let answersSchema: yup.ObjectSchema<{ [answerDefId: string]: BotMultipleChoiceAnswer }> = yup.object<{ [answerDefId: string]: BotMultipleChoiceAnswer }>()
      .label('Answer Definitions')
      .defined()
      .default(() => ({}));

    /**
     * first entry is the ID to be replaced
     * second entry is the snakeCase id
     */
    Object.keys(answerDefinitions).forEach((answerId) => {

      const answerSchema: yup.ObjectSchema<BotMultipleChoiceAnswer> = yup.object<BotMultipleChoiceAnswer>({
        answer: yup.string().required().label('Answer Text'),
        answerAudio: generateValidationFunc('audio').required().label('Answer Audio File'),
        answerImage: generateValidationFunc('image').required().label('Answer Image File'),
        responseBotAudio: generateValidationFunc('audio').label('Response Bot Audio'),
        responseBackgroundImage: generateValidationFunc('image').label('Response Background Image File'),
        responseBackgroundVideo: generateValidationFunc('video').label('Response Background Video File'),
        displayOrder: yup.number().required().label('Display Order'),
        points: yup.number().label('Points'),
        isDeleted: yup.boolean().required().label('Is Deleted'),
        isCorrect: yup.boolean().required().label('Is Correct'),
        createdAt: yup.number().required().default(() => moment().valueOf()),
      }).required();

      answersSchema = answersSchema.shape({
        [answerId]: answerSchema,
      });

    });

    return answersSchema
      .test('has-correct-answer', 'Must have at least one correct answer', (answerDefinitions: { [answerDefId: string]: BotMultipleChoiceAnswer }): boolean => {
        let hasAtLeastOneCorrectAnswer = false;
        Object.values(answerDefinitions).forEach(({ isCorrect }) => {
          if (isCorrect) {
            hasAtLeastOneCorrectAnswer = true;
          }
        });
        return hasAtLeastOneCorrectAnswer;
      });
  }),
});

export const lamcQuestionNodeSchema: yup.ObjectSchema<LAMCQuestionNode> = nodeDefinitionBaseSchema.shape<Omit<LAMCQuestionNode, keyof NodeDefinitionBase>>({
  type: yup.string().required().oneOf(['Live Action Multiple Choice Question']).label('Type'),
  question: yup.string().required().label('Question Text'),
  difficulty: yup.string().required().oneOf(difficultyLevels).label('Difficulty'),
  questionVideo: generateValidationFunc('video').required().label('Question Video File'),
  transitionVideo: generateValidationFunc('video').label('Transition Video File'),
  questionAudio: generateValidationFunc('audio').label('Question Audio'),
  randomOrder: yup.boolean().required().label('Is Correct'),
  waitingLoopingVideo: generateValidationFunc('video').required().label('Waiting Looping Video File'),
  themeColor: yup.string().required().oneOf(colorThemes).label('Theme'),
  continueIfWrong: yup.boolean().required().label('Continue if wrong'),
  musicAudio: generateValidationFunc('audio').label('Music Audio File'),
  doAll: yup.boolean().required().label('Do All'),
  isButtonUI: yup.boolean().required().label('Is Button UI'),
  continueAfterQuestionAudio: yup.boolean().required().label('Continue after question audio'),
  botLeadsQuestion: yup.boolean().required().label('Bot Leads Question'),
  waitForBotResponseAudio: yup.boolean().required().label('Wait for bot response audio'),
  lamcAnswers: yup.lazy<{ [answerId: string]: LAMCAnswerDefinition }>((lamcAnswers = {}) => {

    let answersSchema: yup.ObjectSchema<{ [answerDefId: string]: LAMCAnswerDefinition }> = yup.object<{ [answerId: string]: LAMCAnswerDefinition }>()
      .label('Answer Definitions')
      .defined()
      .default(() => ({}));

    Object.keys(lamcAnswers).forEach((answerId) => {

      const answerSchema: yup.ObjectSchema<LAMCAnswerDefinition> = yup.object<LAMCAnswerDefinition>({
        answer: yup.string().required().label('Answer Text'),
        answerAudio: generateValidationFunc('audio').label('Answer Audio File'),
        answerImage: generateValidationFunc('image').required().label('Answer Image File'),
        responseBotAudio: generateValidationFunc('audio').label('Response Bot Audio'),
        responseBackgroundImage: generateValidationFunc('image').label('Response Background Image File'),
        responseBackgroundVideo: generateValidationFunc('video').label('Response Background Video File'),
        points: yup.number().label('Points'),
        displayOrder: yup.number().required().label('Display Order'),
        isDeleted: yup.boolean().required().label('Is Deleted'),
        isCorrect: yup.boolean().required().label('Is Correct'),
        createdAt: yup.number().required().default(() => moment().valueOf()),
      }).required();

      answersSchema = answersSchema.shape({
        [answerId]: answerSchema,
      });

    });

    return answersSchema
      .test('has-correct-answer', 'Must have at least one correct answer', (answerDefinitions: { [answerDefId: string]: BotMultipleChoiceAnswer }): boolean => {
        let hasAtLeastOneCorrectAnswer = false;
        Object.values(answerDefinitions).forEach(({ isCorrect }) => {
          if (isCorrect) {
            hasAtLeastOneCorrectAnswer = true;
          }
        });
        return hasAtLeastOneCorrectAnswer;
      });
  }),
});












export const spudMultiChoiceQuestionSchema = yup.object().required().shape<QuestionNodeDefinition>({
  displayName: yup.string().required().label('Display Name'),
  description: yup.string().required().label('Description'),
  isDeleted: yup.boolean().required().label('Is Deleted'),
  /** spud specific part */
  type: yup.string().required().oneOf(['Spud multi-choice question']).label('Type'),
  themeColor: yup.string().required().oneOf(colorThemes).label('Theme'),
  difficulty: yup.string().required().oneOf(difficultyLevels).label('Difficulty'),
  status: yup.string().oneOf(['staged', 'Deleted', 'Ready', 'published'] as const).required(),
  tags: yup.array<string>().required().label('Tags'),
  question: yup.string().required().label('Question Text'),
  questionAudio: generateValidationFunc('audio').required().label('Question Audio'),
  startingBackgroundVideo: generateValidationFunc('video').label('Starting Background Video File'),
  startingBackgroundImage: generateValidationFunc('image').label('Starting Background Image File'),
  createdAt: yup.object<Timestamp>().required().default(() => firestore.Timestamp.now()),
  answerDefinitions: yup.lazy<{ [answerDefId: string]: Partial<AnswerDefinition> }>((answerDefinitions) => {

    let answersSchema = yup.object()
      .label('Answer Definitions')
      .defined()
      .shape<{ [answerDefId: string]: AnswerDefinition }>({});

    /**
     * first entry is the ID to be replaced
     * second entry is the snakeCase id
     */
    Object.keys(answerDefinitions).forEach((answerId) => {

      const answerSchema = yup.object()
        .required()
        .shape<AnswerDefinition>({
          answer: yup.string().required().label('Answer Text'),
          answerAudio: generateValidationFunc('audio').required().label('Answer Audio File'),
          answerImage: generateValidationFunc('image').required().label('Answer Image File'),
          responseSpudAudio: generateValidationFunc('audio').label('Response Spud Audio'),
          responseBackgroundImage: generateValidationFunc('image').label('Response Background Image File'),
          responseBackgroundVideo: generateValidationFunc('video').label('Response Background Video File'),
          displayOrder: yup.number().required().label('Display Order'),
          isDeleted: yup.boolean().required().label('Is Deleted'),
          isCorrect: yup.boolean().required().label('Is Correct'),
          createdAt: yup.object<Timestamp>().required().default(() => firestore.Timestamp.now()),
        });

      answersSchema = answersSchema.shape({
        [answerId]: answerSchema,
      });

    });

    return answersSchema
      .test('has-correct-answer', 'Must have at least one correct answer', (answerDefinitions: { [answerDefId: string]: AnswerDefinition }): boolean => {
        let hasAtLeastOneCorrectAnswer = false;
        Object.values(answerDefinitions).forEach(({ isCorrect }) => {
          if (isCorrect) {
            hasAtLeastOneCorrectAnswer = true;
          }
        });
        return hasAtLeastOneCorrectAnswer;
      });
  }),
});

/**
 * Live Story Nodes
 */
const liveNodeDefinitionBaseSchema: yup.ObjectSchema<LiveNodeDefinitionBase> = nodeDefinitionBaseSchema.shape<Omit<LiveNodeDefinitionBase, keyof NodeDefinitionBase>>({
  imageOverlay: generateValidationFunc('image').label('Image Overlay File'),
  startTime: yup.number().required().label('Start Time (milliseconds)'),
  endTime: yup.number().required().label('End Time (milliseconds)'),
});

export const liveImageOverlayNodeSchema: yup.ObjectSchema<LiveImageOverlayNode> = nodeDefinitionBaseSchema.shape<Omit<LiveImageOverlayNode, keyof NodeDefinitionBase>>({
  type: yup.string().required().oneOf(['Live Image Overlay']).label('Type'),
  imageOverlay: generateValidationFunc('image').required().label('Image Overlay File'),
  startTime: yup.number().required().label('Start Time (milliseconds)'),
  endTime: yup.number().required().label('End Time (milliseconds)'),
});

export const livePollQuestionNodeSchema: yup.ObjectSchema<LivePollQuestionNode> = liveNodeDefinitionBaseSchema.shape<Omit<LivePollQuestionNode, keyof LiveNodeDefinitionBase>>({
  type: yup.string().required().oneOf(['Live Poll Question']).label('Type'),
  points: yup.number().required().label('Points'),
});

export const liveTrueOrFalseQuestionNode: yup.ObjectSchema<LiveTrueOrFalseQuestionNode> = liveNodeDefinitionBaseSchema.shape<Omit<LiveTrueOrFalseQuestionNode, keyof LiveNodeDefinitionBase>>({
  type: yup.string().required().oneOf(['Live True Or False Question']).label('Type'),
  points: yup.number().required().label('Points'),
  correctAnswer: yup.boolean().required().label('Correct Answer').default(false),
});

export const liveBuilderImageSelectorNode: yup.ObjectSchema<LiveBuilderImageSelectorNode> = liveNodeDefinitionBaseSchema.shape<Omit<LiveBuilderImageSelectorNode, keyof LiveNodeDefinitionBase>>({
  type: yup.string().required().oneOf(['Live Builder Image Selector']).label('Type'),
  includeInAssemblerNode: yup.boolean().required().label('Include In Builder Image Assembler Node'),
  options: yup.lazy<{ [answerId: string]: LiveBuilderImageOption }>((options = {}) => {

    let optionsSchema: yup.ObjectSchema<{ [optionId: string]: LiveBuilderImageOption }> = yup.object<{ [optionId: string]: LiveBuilderImageOption }>()
      .label('Options')
      .defined()
      .default(() => ({}));

    Object.keys(options).forEach((answerId) => {

      const optionSchema: yup.ObjectSchema<LiveBuilderImageOption> = yup.object<LiveBuilderImageOption>({
        image: generateValidationFunc('image').required().label('Response Background Image File'),
        points: yup.number().required().label('Points'),
        layer: yup.number().required().label('Layer'),
        defaultPosition: yup.string().required().oneOf(defaultPositions).label('Default Position'),
        displayOrder: yup.number().required().label('Display Order'),
        createdAt: yup.number().required().default(() => moment().valueOf()),
      }).required();

      optionsSchema = optionsSchema.shape({
        [answerId]: optionSchema,
      });

    });

    return optionsSchema;
  }),
});



export function generateNodeDefinitionSchema(node: Partial<Node | LiveNode>): yup.ObjectSchema<Node | LiveNode> {
  switch (node.type) {
    case 'Bot multi-choice question': {
      // 1
      return botMultipleQuestionNodeSchema;
    }
    case 'Video': {
      // 2
      return videoNodeSchema;
    }
    case 'X-ray': {
      // 3
      return xrayNodeSchema;
    }
    case 'Phone': {
      // 4
      return phoneNodeSchema;
    }
    case 'Live Action Multiple Choice Question': {
      // 5
      return lamcQuestionNodeSchema;
    }
    case '3D Model': {
      // 6
      return threeDNodeSchema;
    }
    case 'Pulley': {
      // 7
      return pulleyNodeSchema;
    }
    case 'Selfie': {
      // 8
      return selfieNodeSchema;
    }
    case 'Drawing': {
      // 9
      return drawingNodeSchema;
    }
    case 'Screen Cleaning': {
      // 10
      return screenCleaningNodeSchema;
    }
    case 'ID Badge': {
      // 11
      return idBadeNodeSchema;
    }
    case 'Points Display': {
      // 12
      return pointsDisplayNodeSchema;
    }
    case 'Travel': {
      // 13
      return travelNodeSchema;
    }
    case 'Live Image Overlay': {
      // 14
      return liveImageOverlayNodeSchema;
    }
    case 'Live Poll Question': {
      // 15
      return livePollQuestionNodeSchema;
    }
    case 'Live True Or False Question': {
      // 16
      return liveTrueOrFalseQuestionNode;
    }
    case 'Live Builder Image Selector': {
      // 17
      return liveBuilderImageSelectorNode;
    }
    default: {
      throw new Error(`Did not recognize type "${node.type}"`);
    }
  }
}