import React from 'react';
import { axiosInstance } from 'src/helpers';
import {
  useHistory,
  useLocation,
} from 'react-router-dom';
// import {
//   useSelector,
//   useDispatch,
// } from 'react-redux';
import './index.css';
/** type imports */
// import type { antdColorOptions, LiveEvent } from 'types';
// import type { RootState } from 'src/app/rootReducer';
// import type { AppDispatch } from 'src/app/store';



const YouTubeOAuth2Loader: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const code = query.get('code');
  React.useEffect(() => {
    if (code) {
      axiosInstance.post<{ success: boolean }>('/oauthcallback/youtube', { code }).then((result) => {
        history.replace('/live_events/upcoming');
      }).catch((error) => {
        console.log(error);
      });
    }
  }, [code, history]);
  return (
    <div>{(code) ? <div>Loading...</div> : <div>Oh no... it looks like something went wrong</div>}</div>
  );
};

export default YouTubeOAuth2Loader;
