import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Form,
  Select,
  Divider,
  Switch,
} from 'antd';
import {
  fetchFileReferences,
} from 'src/features/storage/storageSlice';
import { transformFiles } from 'src/helpers';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
/** type imports */
import type { AppDispatch } from 'src/app/store';
import type { RootState } from 'src/app/rootReducer';
import type { FormInstance } from 'antd/es/form';
import type { FormikErrors } from 'formik';
import type {
  VideoNode,
  FormItemProps,
} from 'types';


interface Props {
  nodeDefinition: Partial<VideoNode>;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => any;
  handleChange: (eventOrPath: string | React.ChangeEvent<any>) => void | ((eventOrTextValue: string | React.ChangeEvent<any>) => void);
  handleBlur: (eventOrString: any) => void | ((e: any) => void);
  form: FormInstance;
  isSubmitting: boolean;
  errors: FormikErrors<{ id: string; nodeDefinition: Partial<VideoNode>; }>;
  setErrors: (errors: FormikErrors<{ id: string; nodeDefinition: Partial<VideoNode>; }>) => void;
}

const VideoNodeFormBody: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  // const {
  //   tagDefinitions,
  //   fetchingTagDefinitions,
  // } = useSelector((state: RootState) => state.tagDefinitionsState);

  const {
    fileReferences: {
      // imageFiles,
      videoFiles,
      audioFiles,
    },
    fetchingFileReferences,
  } = useSelector((state: RootState) => state.storageState);

  React.useEffect(() => {
    dispatch(fetchFileReferences());
  }, [dispatch]);

  const {
    nodeDefinition,
    setFieldValue,
    // handleChange,
    // handleBlur,
    isSubmitting,
    errors,
    // form,
  } = props;

  const {
    video,
    botAudio,
    continueAfterBotAudioFinishes,
  } = nodeDefinition;
  /** this sets the defaults for boolean fields */
  React.useEffect(() => {
    if (continueAfterBotAudioFinishes === undefined) {
      console.log('this ran');
      setFieldValue('nodeDefinition.continueAfterBotAudioFinishes', false);
    }
    /** you can ignore the ts-lint issue here */
  }, [continueAfterBotAudioFinishes, setFieldValue]);



  type QuestionFormErrors = {
    [K in keyof VideoNode]?: string;
  }

  // interface AnswerFormErrors {
  //   [answerDefKey: string]: string;
  // }

  function generateFormItemProps(itemName: keyof VideoNode): FormItemProps {
    const hasError = itemName in errors;
    return {
      name: ['nodeDefinition', itemName],
      validateStatus: hasError ? 'error' : '',
      hasFeedback: hasError,
      help: hasError ? (errors as QuestionFormErrors)[itemName] : false,
    };
  }

  // const imageOptions = transformFiles(imageFiles);
  const videoOptions = transformFiles(videoFiles);
  const audioOptions = transformFiles(audioFiles);

  // const imagePlaceholder = 'image/example.jpeg';
  const videoPlaceholder = 'video/example.mp4';
  const audioPlaceholder = 'audio/example.mp3';

  if (Object.keys(errors).length) {
    console.log(errors);
  }

  return (
    <React.Fragment>
      <Divider>Video Configuration</Divider>
      <Form.Item
        {...generateFormItemProps('video')}
        label={'Video File'}
      >
        <Select
          allowClear
          showSearch
          options={videoOptions}
          disabled={isSubmitting}
          loading={fetchingFileReferences}
          value={video}
          placeholder={videoPlaceholder}
        />
      </Form.Item>
      <Form.Item
        {...generateFormItemProps('botAudio')}
        label={'Bot Audio File'}
      >
        <Select
          allowClear
          showSearch
          options={audioOptions}
          disabled={isSubmitting}
          loading={fetchingFileReferences}
          value={botAudio}
          placeholder={audioPlaceholder}
        />
      </Form.Item>
      <Form.Item
        {...generateFormItemProps('continueAfterBotAudioFinishes')}
        label={'Continue After Bot Audio Finishes'}
      >
        <Switch
          disabled={isSubmitting || (botAudio === undefined && continueAfterBotAudioFinishes === false)}
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          checked={continueAfterBotAudioFinishes}
        />
      </Form.Item>
    </React.Fragment>
  );
};

export default VideoNodeFormBody;