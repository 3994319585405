import { createSlice, createAsyncThunk, SerializedError } from '@reduxjs/toolkit';
// import {  auth } from 'firebase/app';
// import 'firebase/firestore';
// import 'firebase/auth';
// import * as yup from 'yup';
import { axiosInstance } from 'src/helpers';
import type { AxiosError } from 'axios';
/** type imports */
import type { RootState } from 'src/app/rootReducer';
// import type { LiveEvent } from 'types';
import type { youtube_v3 } from 'googleapis';



interface AuthError {
  error: string;
}

interface YouTubeLiveStreamsState {
  liveStreams: { [liveStreamId: string]: youtube_v3.Schema$LiveBroadcast } | null;
  oauth2Url: null | string;
  currentRequestId: undefined | string,
  loading: 'idle' | 'pending';
  error: null | SerializedError | AuthError;
}

const initialState: YouTubeLiveStreamsState = {
  liveStreams: null,
  oauth2Url: null,
  loading: 'idle',
  currentRequestId: undefined,
  error: null,
};

export const fetchYouTubeLiveStreams = createAsyncThunk<{ [liveEventId: string]: youtube_v3.Schema$LiveBroadcast } | null, void, { state: RootState; rejectValue: AuthError }>(
  'youtubeLiveStreams/fetchLiveStreams',
  async (_, { getState, requestId, rejectWithValue }) => {
    const { user } = getState().userState;
    if (!user) {
      throw new Error("Oops! You're not authenticated!");
    }
    const { currentRequestId, loading } = getState().youtubeLiveStreamsState;
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return null;
    }
    const liveStreams: { [liveEventId: string]: youtube_v3.Schema$LiveBroadcast } = {};
    try {
      const { data } = await axiosInstance.get<{ success: boolean, response: youtube_v3.Schema$LiveBroadcastListResponse }>('/youtube/livestreams');
      if (data) {
        data.response.items?.forEach((liveStream) => {
          if (liveStream.id) {
            liveStreams[liveStream.id] = liveStream;
          }
        });
      }
      return liveStreams;
    } catch (error) {
      console.log('error.isAxiosError', error.isAxiosError);
      if (error.isAxiosError) {
        return rejectWithValue(error?.response?.data);
      } else {
        throw error;
      }
    }
  }
);

export const fetchYouTubeOAuth2Url = createAsyncThunk<string | null, void, { state: RootState; rejectValue: AuthError }>(
  'youtubeLiveStreams/fetchYouTubeOAuth2Url',
  async (_, { getState, requestId, rejectWithValue }) => {
    const { user } = getState().userState;
    if (!user) {
      throw new Error("Oops! You're not authenticated!");
    }
    const { currentRequestId, loading } = getState().youtubeLiveStreamsState;
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return null;
    }
    try {
      const { data } = await axiosInstance.get<{ url: string }>('/oauth2_url/youtube');
      return data.url;
    } catch (error) {
      console.log('error.isAxiosError', error.isAxiosError);
      if (error.isAxiosError) {
        return rejectWithValue(error?.response?.data);
      } else {
        throw error;
      }
    }
  }
);

// export const addLiveEvent = createAsyncThunk<{ id: string; liveEvent: LiveEvent } | null, { id: string; liveEvent: Partial<LiveEvent> }, { state: RootState, rejectValue: yup.ValidationError }>(
//   'liveEvents/addLiveEvents',
//   async ({ id, liveEvent }, { getState, requestId, rejectWithValue }) => {
//     const { user } = getState().userState;
//     if (!user) {
//       throw new Error("Oops! You're not authenticated!");
//     }
//     const { currentRequestId, loading } = getState().liveEventsState;
//     if (loading !== 'pending' || requestId !== currentRequestId) {
//       return null;
//     }
//     try {
//       liveEvent.createdBy = user.uid;
//       const validated = await liveEventSchema.validate(liveEvent, {
//         stripUnknown: true,
//         context: {
//           editMode: false,
//         },
//       });

//       const liveEventRef = firestore()
//         .collection(generateFirestorePath('liveEvents'))
//         .withConverter(liveEventConverter)
//         .doc(id);

//       const liveEventData = await liveEventRef.get();
//       if (liveEventData.exists) {
//         throw new yup.ValidationError('Already exists', 'Already exists', '');
//       }

//       await liveEventRef.set(validated);

//       return { id, liveEvent: validated };
//     } catch (error) {
//       if (error instanceof yup.ValidationError) {
//         console.log('error instanceof yup.ValidationError', error);
//         return rejectWithValue(error);
//       } else {
//         throw error;
//       }

//     }
//   }
// );

// export const startLiveEvent = createAsyncThunk<{ id: string; liveEvent: LiveEvent } | null, { id: string; liveEvent: Partial<LiveEvent> }, { state: RootState, rejectValue: yup.ValidationError }>(
//   'liveEvents/addLiveEvents',
//   async ({ id, liveEvent }, { getState, requestId, rejectWithValue }) => {
//     const { user } = getState().userState;
//     if (!user) {
//       throw new Error("Oops! You're not authenticated!");
//     }
//     const { currentRequestId, loading } = getState().liveEventsState;
//     if (loading !== 'pending' || requestId !== currentRequestId) {
//       return null;
//     }
//     try {
//       liveEvent.createdBy = user.uid;
//       const validated = await liveEventSchema.validate(liveEvent, {
//         stripUnknown: true,
//         context: {
//           editMode: false,
//         },
//       });

//       const liveEventRef = firestore()
//         .collection(generateFirestorePath('liveEvents'))
//         .withConverter(liveEventConverter)
//         .doc(id);

//       const liveEventData = await liveEventRef.get();
//       if (liveEventData.exists) {
//         throw new yup.ValidationError('Already exists', 'Already exists', '');
//       }

//       await liveEventRef.set(validated);

//       return { id, liveEvent: validated };
//     } catch (error) {
//       if (error instanceof yup.ValidationError) {
//         console.log('error instanceof yup.ValidationError', error);
//         return rejectWithValue(error);
//       } else {
//         throw error;
//       }

//     }
//   }
// );

// export const updateLiveEvent = createAsyncThunk<{ id: string; liveEvent: LiveEvent } | null, { id: string; liveEvent: Partial<LiveEvent> }, { state: RootState, rejectValue: yup.ValidationError }>(
//   'liveEvents/updateLiveEvent',
//   async ({ id, liveEvent }, { getState, requestId, rejectWithValue }) => {
//     const { user } = getState().userState;
//     if (!user) {
//       throw new Error("Oops! You're not authenticated!");
//     }
//     const { currentRequestId, loading } = getState().liveEventsState;
//     if (loading !== 'pending' || requestId !== currentRequestId) {
//       return null;
//     }
//     try {
//       const validated = await liveEventSchema.validate(liveEvent, {
//         stripUnknown: true,
//         context: {
//           editMode: true,
//         },
//       });

//       const liveEventRef = firestore()
//         .collection(generateFirestorePath('liveEvents'))
//         .withConverter(liveEventConverter)
//         .doc(id);

//       const liveEventData = await liveEventRef.get();
//       if (!liveEventData.exists) {
//         throw new yup.ValidationError('Does not exists', 'Does not exists', '');
//       }

//       await liveEventRef.set(validated);

//       return { id, liveEvent: validated };
//     } catch (error) {
//       if (error instanceof yup.ValidationError) {
//         console.log('error instanceof yup.ValidationError', error);
//         return rejectWithValue(error);
//       } else {
//         throw error;
//       }

//     }
//   }
// );

const youtubeLiveStreamsSlice = createSlice({
  name: 'youtubeLiveStreams',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchYouTubeLiveStreams.pending, (state, action) => {
      const { meta: { requestId } } = action;
      console.log('made it to pending', requestId);
      if (state.loading === 'idle') {
        state.loading = 'pending';
        state.currentRequestId = requestId;
        state.error = null;
      }
    });
    builder.addCase(fetchYouTubeLiveStreams.fulfilled, (state, action) => {
      const { meta: { requestId }, payload } = action;
      if (payload !== null && requestId === state.currentRequestId) {
        state.liveStreams = payload;
        state.loading = 'idle';
        state.currentRequestId = undefined;
        state.error = null;
      }
    });
    builder.addCase(fetchYouTubeLiveStreams.rejected, (state, action) => {
      const { meta: { requestId }, payload } = action;
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle';
        state.error = payload || action.error;
        state.currentRequestId = undefined;
      }
    });
    builder.addCase(fetchYouTubeOAuth2Url.pending, (state, action) => {
      const { meta: { requestId } } = action;
      console.log('made it to pending', requestId);
      if (state.loading === 'idle') {
        state.loading = 'pending';
        state.currentRequestId = requestId;
        state.error = null;
      }
    });
    builder.addCase(fetchYouTubeOAuth2Url.fulfilled, (state, action) => {
      const { meta: { requestId }, payload } = action;
      if (payload !== null && requestId === state.currentRequestId) {
        state.oauth2Url = payload;
        state.loading = 'idle';
        state.currentRequestId = undefined;
        state.error = null;
      }
    });
    builder.addCase(fetchYouTubeOAuth2Url.rejected, (state, action) => {
      const { meta: { requestId }, payload } = action;
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle';
        state.error = payload || action.error;
        state.currentRequestId = undefined;
      }
    });
  },
});

export default youtubeLiveStreamsSlice.reducer;