import React from 'react';
import {
  Form,
  Input,
  Button,
  Select,
  Divider,
  PageHeader,
  Alert,
} from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import merge from 'deepmerge';
import isPlainObject from 'is-plain-object';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import {
  updateUserAuthorizations,
} from 'src/features/admin/usersManagerSlice';
import { authorizationDefinitions, overwriteMerge } from 'src/helpers';
/** type imports */
import type { UserAdminState, FirestoreUser, FormItemProps } from 'types';
import type { AppDispatch } from 'src/app/store';
import type { Store } from 'rc-field-form/es/interface';

interface Props {
  userAdminState: UserAdminState;
  onCancel: () => void;
}

const authorizationOptions = Object.entries(authorizationDefinitions)
  .map(([key, value]) => ({ value: key, label: value }));

const UserAdminFormContainer: React.FC<Props> = ({ userAdminState, onCancel }: Props) => {
  const dispatch = useDispatch<AppDispatch>();

  console.log(userAdminState);

  const [antdForm] = Form.useForm();

  let editMode = false;
  if (userAdminState.id !== "") {
    editMode = true;
  }

  const formik = useFormik<UserAdminState>({
    initialValues: userAdminState,
    enableReinitialize: true,
    onSubmit: async (values, formikHelpers): Promise<void> => {
      const { id, user: { authorizations } } = values;
      const { setSubmitting, resetForm, setErrors } = formikHelpers;
      try {
        const { success, validationError } = await dispatch(updateUserAuthorizations(id, authorizations));

        setSubmitting(false);
        if (success) {
          if (!editMode) {
            resetForm();
            antdForm.resetFields();
          }
        } else if (validationError) {
          console.log(validationError);
          const { path, message } = validationError;
          setErrors({
            [path]: message,
          });
        }
      } catch (error) {
        console.log('Non-validation error:', error);
      }
    }
  });



  const {
    values: formikValues,
    errors,
    // handleChange,
    // handleBlur,
    handleSubmit,
    isSubmitting,
    // setFieldValue,
    // setSubmitting,
    // setErrors,
    /* and other goodies */
    initialValues: formikInitialValues,
    setValues,
    dirty,
  } = formik;

  React.useEffect(() => {
    antdForm.resetFields();
  }, [antdForm, formikInitialValues]);


  function handleValuesChange(changedValues: Store /*, currentValues: Store */): void {
    const newValues = merge<UserAdminState, Store>(formikValues, changedValues, {
      arrayMerge: overwriteMerge,
      isMergeableObject: isPlainObject,
    });

    setValues(newValues);
  }

  type FormErrors = {
    [K in keyof FirestoreUser]?: string;
  }

  function generateFormItemProps(itemName: keyof FirestoreUser): FormItemProps {
    const hasError = itemName in errors;
    return {
      name: ['user', itemName],
      validateStatus: hasError ? 'error' : '',
      hasFeedback: hasError,
      help: hasError ? (errors as FormErrors)[itemName] : false,
    };
  }


  const { id, user } = formikValues;

  const { displayName, authorizations } = user;
  const BottomButtons: React.FC = () => {
    if (editMode) {
      // let deleteButtonContent = 'Archive Story';
      // const thing = status;
      // if (status === 'archived') {
      //   deleteButtonContent = 'Re-Publish Story';
      // }
      let updateButtonContent = 'Update User';
      if (!dirty) {
        updateButtonContent = 'Make a change to update';
      }
      return (
        <Form.Item>
          <Button
            block
            size={'large'}
            type={'primary'}
            htmlType={'submit'}
            loading={isSubmitting}
            disabled={isSubmitting || !dirty}
          >
            {updateButtonContent}
          </Button>
          {/* <Button
            block
            danger
            onClick={onDelete}
            size={'large'}
            type={'primary'}
            loading={isSubmitting}
            disabled={isSubmitting}
          >
            {deleteButtonContent}
          </Button> */}
        </Form.Item>
      );
    } else {
      return (
        <Form.Item>
          <Button
            block
            size={'large'}
            type={'primary'}
            htmlType={'submit'}
            loading={isSubmitting}
            disabled={isSubmitting}
          >
            Create new user
          </Button>
        </Form.Item>
      );
    }
  };
  return (
    <React.Fragment>
      <Form
        size={'middle'}
        layout={'vertical'}
        initialValues={formikInitialValues}
        form={antdForm}
        onValuesChange={handleValuesChange}
        onSubmitCapture={handleSubmit}
      >
        <PageHeader
          title={<Form.Item name={'id'}><span>ID: {id}</span></Form.Item>}
          extra={[
            <Button
              key={'close_sidebar'}
              danger
              icon={<CloseOutlined />}
              onClick={onCancel}
            />
          ]}
        >
          <Form.Item
            {...generateFormItemProps('displayName')}
            label={'Display Name'}
          >
            <Input
              placeholder={'John Doe'}
              disabled={true}
              value={displayName || 'Name Missing'}
            />
          </Form.Item>
          <Form.Item
            {...generateFormItemProps('authorizations')}
            label={'Authorizations'}
          >
            <Select
              allowClear
              mode={'multiple'}
              options={authorizationOptions}
              disabled={isSubmitting}
              value={authorizations}
              placeholder='Story Definitions, Tag Definitions...'
            />
          </Form.Item>
          <Divider />
          {Object.values(errors).map((error, index) => <Alert style={{ marginBottom: 10 }} showIcon type={'error'} key={index} message={error} />)}
          <BottomButtons />
        </PageHeader>
      </Form>
    </React.Fragment>
  );
};

export default UserAdminFormContainer;