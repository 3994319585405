import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchChipParts,
} from 'src/features/chipParts/chipPartsSlice';
import ChipPartFormContainer from './chipPartForm';
// import moment from 'moment';
import {
  Button,
  Table,
  Drawer,
  Row,
  Col,
} from 'antd';
// import { momentFormat } from 'src/helpers';
/** import types */
import type { RootState } from 'src/app/rootReducer';
import type { AppDispatch } from 'src/app/store';
import type { ColumnType } from 'antd/es/table';
import type {
  // milliseconds,
  ChipPart,
} from 'types';


interface TableDatum {
  id: string;
  title: string;
  image: string;
}

const ChipParts: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [selectedChipPartId, selectChipPartId] = React.useState<string | null>(null);
  const {
    chipParts,
    fetchingChipParts,
  } = useSelector((state: RootState) => state.chipPartsState);

  React.useEffect(() => {
    dispatch(fetchChipParts());
  }, [dispatch]);

  const tableData: TableDatum[] = Object.entries(chipParts).map(([id, { title, image }]) => {

    return {
      id,
      title,
      image
    };
  });


  const renderId: React.FC<string | undefined> = (id: string | undefined) => {
    return (
      <span
        style={{
          fontFamily: 'monospace',
          fontWeight: 'bold',
        }}>
        {id}
      </span>
    );
  };

  const tableColumns: ColumnType<TableDatum>[] = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 250,
      render: renderId,
    },
    {
      title: 'title',
      dataIndex: 'title',
      key: 'title',
      width: 250,
    },
    {
      title: 'image',
      dataIndex: 'image',
      key: 'image',
      width: 250,
    }
  ];

  let partFormParams: null | {
    editMode: boolean;
    chipFormState: {
      id: string;
      chipPart: Partial<ChipPart>;
    };
  } = null;
  if (selectedChipPartId !== null) {
    if (selectedChipPartId === '') {
      partFormParams = {
        editMode: false,
        chipFormState: {
          id: '',
          chipPart: {
            title: '',
          },
        },
      };
    } else if (chipParts[selectedChipPartId]) {
      partFormParams = {
        editMode: true,
        chipFormState: {
          id: selectedChipPartId,
          chipPart: chipParts[selectedChipPartId],
        }
      };
    } else {
      console.log('selected chip part id does not correspond to a part');
    }
  }
  return (
    <React.Fragment>
      <Row
        justify='center'
        gutter={[16, 16]}
        style={{
          padding: '1em',
          margin: '1em',
          backgroundColor: 'white',
        }}
      >
        <Col xs={23} sm={23} md={23} lg={22} xl={20}>
          <Table
            pagination={{
              total: tableData.length,
              defaultPageSize: 100,
              showTotal: (total) => `${total} Total Chip Parts`,
            }}
            bordered
            title={() => {
              return (<Button
                size='large'
                type='primary'
                onClick={() => selectChipPartId('')}
              >
                New Chip Part
              </Button>);
            }}
            loading={fetchingChipParts}
            dataSource={tableData}
            columns={tableColumns}
            rowKey={({ id }) => id}
            onRow={(record) => {
              return {
                onClick: () => selectChipPartId(record.id), // click row
                style: { cursor: 'pointer' },
              };
            }}
          />
        </Col>
      </Row>
      <Drawer
        width={600}
        placement={'right'}
        visible={partFormParams !== null}
        closable={false}
      >
        {(partFormParams !== null) && <ChipPartFormContainer
          {...partFormParams}
          onCancel={() => selectChipPartId(null)}
        />}
      </Drawer>
    </React.Fragment >
  );
};

export default ChipParts;