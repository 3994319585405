import React from 'react';
import {
  Form,
  Input,
  Button,
  Select,
  Divider,
  PageHeader,
  Alert,
  Switch,
  InputNumber,
} from 'antd';
import {
  CloseOutlined,
  CheckOutlined,
} from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchTagDefinitions,
} from 'src/features/tagDefinitions/tagDefinitionsSlice';
import {
  fetchAppTagDefinitions,
} from 'src/features/appTagDefinitions/appTagDefinitionsSlice';
import {
  fetchFileReferences,
} from 'src/features/storage/storageSlice';
import {
  fetchCharacterProfiles,
} from 'src/features/characterProfiles/characterProfilesSlice';
import {
  addStory,
  updateStory,
} from 'src/features/stories/storiesSlice';
import merge from 'deepmerge';
import isPlainObject from 'is-plain-object';
import { useFormik } from 'formik';
import { transformFiles, overwriteMerge, storyTypeOptions } from 'src/helpers';
import StoryRouter from './StoryRouter';
/** type imports */
import type { Store } from 'rc-field-form/es/interface';
import type { AppDispatch } from 'src/app/store';
import type { RootState } from 'src/app/rootReducer';
import type {
  Story,
  StoryFormState,
  // ColorScheme,
  FormItemProps,
} from 'types';


interface Props {
  storyFormState: StoryFormState;
  onCancel: () => void;
  editMode: boolean;
}

const StoryFormContainer: React.FC<Props> = ({ storyFormState, onCancel, editMode }: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    tagDefinitions,
    fetchingTagDefinitions,
  } = useSelector((state: RootState) => state.tagDefinitionsState);

  const {
    appTagDefinitions,
    fetchingAppTagDefinitions,
  } = useSelector((state: RootState) => state.appTagDefinitionsState);

  const {
    characterProfiles,
    fetchingCharacterProfiles,
  } = useSelector((state: RootState) => state.characterProfilesState);

  const characterProfilesOptions = Object.entries(characterProfiles)
    .map(([id, { displayName }]) => ({
      label: displayName,
      value: id,
    }));
  const appTagDefinitionOptions = Object.entries(appTagDefinitions)
    .map(([id, { displayName }]) => ({
      label: displayName,
      value: id,
    }));

  const {
    fileReferences: {
      imageFiles,
      // videoFiles,
      // audioFiles,
    },
    fetchingFileReferences,
  } = useSelector((state: RootState) => state.storageState);

  const [antdForm] = Form.useForm();

  const tagDefinitionOptions = Object.entries(tagDefinitions)
    .map(([id, { displayName }]) => ({
      label: displayName,
      value: id,
    }));

  const formik = useFormik<StoryFormState>({
    initialValues: storyFormState,
    enableReinitialize: true,
    onSubmit: async (values, formikHelpers): Promise<void> => {
      console.log(values);
      const { seasonId, id, story } = values;
      const { setSubmitting, resetForm, setErrors } = formikHelpers;
      let success, validationError;
      try {
        if (editMode) {
          ({ success, validationError } = await dispatch(updateStory(seasonId, id, story)));
        } else {
          ({ success, validationError } = await dispatch(addStory(seasonId, id, story)));
        }
        setSubmitting(false);
        if (success) {
          if (!editMode) {
            resetForm();
            antdForm.resetFields();
          }
        } else if (validationError) {
          console.log(validationError);
          const { path, message } = validationError;
          setErrors({
            [path]: message,
          });
        }
      } catch (error) {
        console.log('Non validation error:', error);
      }
    },
  });

  const {
    values: formikValues,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    // setSubmitting,
    setErrors,
    /* and other goodies */
    initialValues: formikInitialValues,
    setValues,
    dirty,
  } = formik;

  React.useEffect(() => {
    antdForm.resetFields();
  }, [antdForm, formikInitialValues]);

  React.useEffect(() => {
    dispatch(fetchTagDefinitions());
    dispatch(fetchFileReferences());
    dispatch(fetchCharacterProfiles());
    dispatch(fetchAppTagDefinitions());
  }, [dispatch]);


  const imageOptions = transformFiles(imageFiles);
  // const videoOptions = transformFiles(videoFiles);
  // const audioOptions = transformFiles(audioFiles);

  // const audioPlaceholder = 'audio/example.mp3';
  const imagePlaceholder = 'image/example.jpeg';
  // const videoPlaceholder = 'video/example.mp4';


  /**
   * not sure how to type this function
   * this is used to clean out the new state of the form
   * @param obj 
   */
  // function removeEmpty(obj: any): void {
  //   return Object.entries(obj).forEach(([key, val]) => {
  //     if (val && typeof val === 'object' && isPlainObject(val)) {
  //       removeEmpty(val);
  //     } else if (val == null) {
  //       delete obj[key];
  //     }
  //   });
  // }

  function handleValuesChange(changedValues: Store /*, currentValues: Store */): void {
    const newValues = merge<StoryFormState, Store>(formikValues, changedValues, {
      arrayMerge: overwriteMerge,
      isMergeableObject: isPlainObject,
    });

    // removeEmpty(newValues);

    setValues(newValues);
  }

  type FormErrors = {
    [K in keyof Story]?: string;
  }

  function generateFormItemProps(itemName: keyof Story): FormItemProps {
    const hasError = itemName in errors;
    return {
      name: ['story', itemName],
      validateStatus: hasError ? 'error' : '',
      hasFeedback: hasError,
      help: hasError ? (errors as FormErrors)[itemName] : false,
    };
  }

  const { id, story } = formikValues;
  const {
    title,
    type,
    description,
    searchKeywords,
    isFree,
    tags,
    appTags,
    characterProfileId,
    coverImage,
    order,
    minsToComplete,
    classKitTitle,
    discussionQuestions
  } = story;

  const questionDefStyle = {};
  const storyTypeOptionsWithLabels = Object.entries(storyTypeOptions).map(([key, label]) => ({ value: key, label }));

  const BottomButtons: React.FC = () => {
    if (editMode) {
      let updateButtonContent = 'Update Story';
      if (!dirty) {
        updateButtonContent = 'Make a change to update';
      }
      return (
        <Form.Item>
          <Button
            block
            size={'large'}
            type={'primary'}
            htmlType={'submit'}
            loading={isSubmitting}
            disabled={isSubmitting || !dirty}
          >
            {updateButtonContent}
          </Button>
        </Form.Item>
      );
    } else {
      return (
        <Form.Item>
          <Button
            block
            size={'large'}
            type={'primary'}
            htmlType={'submit'}
            loading={isSubmitting}
            disabled={isSubmitting}
          >
            Create New Story
          </Button>
        </Form.Item>
      );
    }
  };
  return (
    <React.Fragment>
      <Form
        size={'middle'}
        layout={'vertical'}
        initialValues={formikInitialValues}
        form={antdForm}
        onValuesChange={handleValuesChange}
        onSubmitCapture={handleSubmit}
      >
        <PageHeader
          title={<Form.Item name={'id'}><span style={questionDefStyle}>ID: {id}</span></Form.Item>}
          extra={[
            <Button
              key={'close_sidebar'}
              danger
              icon={<CloseOutlined />}
              onClick={onCancel}
            />
          ]}
        >
          <Form.Item
            {...generateFormItemProps('title')}
            label={'Title'}
          >
            <Input
              placeholder={'Volcano Experiment'}
              disabled={isSubmitting}
              value={title}
            />
          </Form.Item>
          <Form.Item
            {...generateFormItemProps('description')}
            label={'Description'}
          >
            <Input.TextArea
              disabled={isSubmitting}
              value={description}
            />
          </Form.Item>

          <Form.Item
            {...generateFormItemProps('searchKeywords')}
            label={'Searchable keywords'}
          >
            <Input.TextArea
              disabled={isSubmitting}
              value={searchKeywords}
            />
          </Form.Item>

          <Form.Item
            {...generateFormItemProps('isFree')}
            label={'Is Free'}
          >
            <Switch
              disabled={isSubmitting}
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              checked={isFree}
            />
          </Form.Item>
          <Form.Item
            {...generateFormItemProps('type')}
            label={'Type'}
          >
            <Select
              options={storyTypeOptionsWithLabels.map((option) => {
                let newOption: { label: string; value: string; disabled: boolean } | null = null;
                if (editMode) {
                  if (story.type === 'live') {
                    if (option.value !== 'live') {
                      newOption = {
                        ...option,
                        disabled: true,
                      };
                    }
                  } else {
                    if (option.value === 'live') {
                      newOption = {
                        ...option,
                        disabled: true,
                      };
                    }
                  }
                }
                if (newOption) {
                  return newOption;
                }
                return option;
              })}
              disabled={isSubmitting}
              value={type}
            />
          </Form.Item>
          <Form.Item
            {...generateFormItemProps('tags')}
            label={'Tags'}
          >
            <Select
              allowClear
              mode={'tags'}
              options={tagDefinitionOptions}
              disabled={isSubmitting}
              loading={fetchingTagDefinitions}
              value={tags}
              placeholder='Dinosaurs, Biology...'
            />
          </Form.Item>
          <Form.Item
            {...generateFormItemProps("appTags")}
            label={'App Tags'}
          >
            <Select
              allowClear
              mode={'multiple'}
              options={appTagDefinitionOptions}
              disabled={isSubmitting}
              loading={fetchingAppTagDefinitions}
              value={appTags}
              placeholder='Dinosaurs, Biology...'
            />
          </Form.Item>
          <Form.Item
            {...generateFormItemProps('characterProfileId')}
            label={'Character/Performer Profile'}
          >
            <Select
              allowClear
              // mode={'multiple'}
              options={characterProfilesOptions}
              disabled={isSubmitting}
              loading={fetchingCharacterProfiles}
              value={characterProfileId}
              placeholder='Haley'
            />
          </Form.Item>
          <Form.Item
            {...generateFormItemProps('coverImage')}
            label={'Cover Image File'}
          >
            <Select
              allowClear
              showSearch
              options={imageOptions}
              disabled={isSubmitting}
              loading={fetchingFileReferences}
              value={coverImage}
              placeholder={imagePlaceholder}
            />
          </Form.Item>
          <Form.Item
            {...generateFormItemProps('order')}
            label={'Order'}
          >
            <InputNumber
              disabled={true}
              value={order}
            />
          </Form.Item>
          <Form.Item
            {...generateFormItemProps('minsToComplete')}
            label={'Minutes To Complete'}
          >
            <InputNumber
              disabled={isSubmitting}
              value={minsToComplete}
            />
          </Form.Item>
          <Form.Item
            {...generateFormItemProps('classKitTitle')}
            label={'ClassKit Title'}
          >
            <Input
              disabled={isSubmitting}
              value={classKitTitle}
            />
          </Form.Item>
          <Form.Item
            {...generateFormItemProps('discussionQuestions')}
            label={'Discussion Questions'}
          >
            <Select
              allowClear
              mode={'tags'}
              disabled={isSubmitting}
              value={discussionQuestions}
              placeholder='Did dinosaurs exist during Pangea?'
            />
          </Form.Item>
          <StoryRouter
            story={story}
            setFieldValue={setFieldValue}
            handleChange={handleChange}
            handleBlur={handleBlur}
            errors={errors}
            isSubmitting={isSubmitting}
            setErrors={setErrors}
            form={antdForm}
          />
          <Divider />
          {Object.values(errors).map((error, index) => <Alert style={{ marginBottom: 10 }} showIcon type={'error'} key={index} message={error} />)}
          <BottomButtons />
        </PageHeader>
      </Form>
    </React.Fragment>
  );
};

export default StoryFormContainer;