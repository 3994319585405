import React, { useEffect, CSSProperties } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Form,
  Input,
  Button,
  Select,
  Divider,
  Card,
  Switch,
  InputNumber,
} from 'antd';
import {
  CloseOutlined,
  CheckOutlined,
} from '@ant-design/icons';
import {
  fetchTagDefinitions,
} from 'src/features/tagDefinitions/tagDefinitionsSlice';
import {
  fetchFileReferences,
} from 'src/features/storage/storageSlice';
import { v4 as uuidv4 } from 'uuid';
import {
  colorThemeOptions as themeOptions,
  difficultyLevelOptions,
  transformFiles,
} from 'src/helpers';
/** type imports */
import type { AppDispatch } from 'src/app/store';
import type { RootState } from 'src/app/rootReducer';
import type { FormInstance } from 'antd/es/form';
import type { FormikErrors } from 'formik';
import type {
  BotMultipleQuestionNode,
  BotMultipleChoiceAnswer,
  // NodeFormState,
  // AssetReference,
  // ColorScheme,
  // QuestionDifficulty,
  // AnswerDefinition,
  FormItemProps,
} from 'types';

const { TextArea } = Input;

interface Props {
  nodeDefinition: Partial<BotMultipleQuestionNode>;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => any;
  handleChange: (eventOrPath: string | React.ChangeEvent<any>) => void | ((eventOrTextValue: string | React.ChangeEvent<any>) => void);
  handleBlur: (eventOrString: any) => void | ((e: any) => void);
  form: FormInstance;
  isSubmitting: boolean;
  errors: FormikErrors<{ id: string; nodeDefinition: Partial<BotMultipleQuestionNode>; }>;
  setErrors: (errors: FormikErrors<{ id: string; nodeDefinition: Partial<BotMultipleQuestionNode>; }>) => void;
}
// "Post to password protected site" | "Spud multi-choice question"
const themeColorOptions = Object.entries(themeOptions)
  .map(([colorKey, colorDisplay]) => ({ label: colorDisplay, value: colorKey }));

const difficultyOptions = Object.entries(difficultyLevelOptions)
  .map(([difficultyKey, difficultyDisplay]) => ({ label: difficultyDisplay, value: difficultyKey }));

const QuestionNodeDefinitionForm: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    tagDefinitions,
    fetchingTagDefinitions,
  } = useSelector((state: RootState) => state.tagDefinitionsState);

  const {
    fileReferences: {
      imageFiles,
      videoFiles,
      audioFiles,
    },
    fetchingFileReferences,
  } = useSelector((state: RootState) => state.storageState);

  const imageOptions = transformFiles(imageFiles);
  const videoOptions = transformFiles(videoFiles);
  const audioOptions = transformFiles(audioFiles);

  const audioPlaceholder = 'audio/example.mp3';
  const imagePlaceholder = 'image/example.jpeg';
  const videoPlaceholder = 'video/example.mp4';

  useEffect(() => {
    dispatch(fetchTagDefinitions());
    dispatch(fetchFileReferences());
  }, [dispatch]);

  const tagDefinitionOptions = Object.entries(tagDefinitions)
    .map(([id, { displayName }]) => ({
      label: displayName,
      value: id,
    }));

  const {
    nodeDefinition,
    setFieldValue,
    // handleChange,
    // handleBlur,
    isSubmitting,
    errors,
    form,
  } = props;

  const {
    themeColor = "",
    difficulty = "",
    tags = [],
    question = "",
    questionAudio = "",
    startingBackgroundVideo = "",
    startingBackgroundImage = "",
    answerDefinitions = {},
  } = nodeDefinition;

  const answerDefinitionArray = Object.entries(answerDefinitions);

  function addBlankAnswerDef(/* event: React.MouseEvent<HTMLButtonElement, MouseEvent> */): void {
    // not clear if we need to do this
    // event.preventDefault();
    const newId = uuidv4();
    const blankAnswer = {
      displayOrder: answerDefinitionArray.length,
      isCorrect: false,
      isDeleted: false,
    };
    setFieldValue('nodeDefinition.answerDefinitions', {
      ...answerDefinitions,
      [newId]: blankAnswer,
    });
    /** 
     * without setting the fields manually using antd
     * the display order doesn't populate
     */
    form.setFieldsValue({
      nodeDefinition: {
        answerDefinitions: {
          [newId]: blankAnswer,
        },
      },
    });
  }


  type QuestionFormErrors = {
    [K in keyof BotMultipleQuestionNode]?: string;
  }

  interface AnswerFormErrors {
    [answerDefKey: string]: string;
  }

  function generateFormItemProps(itemName: keyof BotMultipleQuestionNode): FormItemProps {
    const hasError = itemName in errors;
    return {
      name: ['nodeDefinition', itemName],
      validateStatus: hasError ? 'error' : '',
      hasFeedback: hasError,
      help: hasError ? (errors as QuestionFormErrors)[itemName] : false,
    };
  }


  if (Object.keys(errors).length) {
    console.log(errors);
  }

  const usedOptionIds: string[] = Object.values(nodeDefinition.edges || {})
    .filter(({ context }) => context && context.optionIds !== undefined)
    .map(({ context }) => context?.optionIds ?? [])
    .flat();

  console.log(usedOptionIds);
  return (
    <React.Fragment>
      <Divider>Bot multi-choice question configuration</Divider>
      <Form.Item
        {...generateFormItemProps('themeColor')}
        label={'Theme Color'}
      >
        <Select
          allowClear
          options={themeColorOptions}
          disabled={isSubmitting}
          value={themeColor}
          placeholder={'Green'}
        />
      </Form.Item>
      <Form.Item
        {...generateFormItemProps('difficulty')}
        label={'Difficulty'}
      >
        <Select
          allowClear
          options={difficultyOptions}
          disabled={isSubmitting}
          value={difficulty}
          placeholder='Medium'
        />
      </Form.Item>
      <Form.Item
        {...generateFormItemProps('tags')}
        label={'Tags'}
      >
        <Select
          allowClear
          mode={'multiple'}
          options={tagDefinitionOptions}
          disabled={isSubmitting}
          loading={fetchingTagDefinitions}
          value={tags}
          placeholder='Dinosaurs, Biology...'
        />
      </Form.Item>
      <Form.Item
        {...generateFormItemProps('question')}
        label={'Question'}
      >
        <TextArea
          disabled={isSubmitting}
          value={question}
          placeholder={'How tall was the T-Rex?'}
        />
      </Form.Item>
      <Form.Item
        {...generateFormItemProps('questionAudio')}
        label={'Question Audio File'}
      >
        <Select
          allowClear
          showSearch
          options={audioOptions}
          disabled={isSubmitting}
          loading={fetchingFileReferences}
          value={questionAudio}
          placeholder={audioPlaceholder}
        />
      </Form.Item>
      <Form.Item
        {...generateFormItemProps('startingBackgroundVideo')}
        label={'Starting Background Video File'}
      >
        <Select
          allowClear
          showSearch
          options={videoOptions}
          disabled={isSubmitting}
          loading={fetchingFileReferences}
          value={startingBackgroundVideo}
          placeholder={videoPlaceholder}
        />
      </Form.Item>
      <Form.Item
        {...generateFormItemProps('startingBackgroundImage')}
        label={'Starting Background Image File'}
      >
        <Select
          allowClear
          showSearch
          options={imageOptions}
          disabled={isSubmitting}
          loading={fetchingFileReferences}
          value={startingBackgroundImage}
          placeholder={imagePlaceholder}
        />
      </Form.Item>
      <Card
        title={'Answer Definitions'}
        actions={[
          <Form.Item key={'add_new_answer_definition'}>
            <Button
              type={'primary'}
              onClick={addBlankAnswerDef}
            >
              Add Answer
            </Button>
          </Form.Item>
        ]}
      >
        {answerDefinitionArray
          .sort(([, { displayOrder: displayOrderA = 0 }], [, { displayOrder: displayOrderB = 1 }]) => displayOrderA - displayOrderB)
          .map(([answerId, answerDefinition], index) => {
            const answerPath = `nodeDefinition.answerDefinitions.${answerId}`;
            const errorPath = `answerDefinitions.${answerId}`;
            const {
              answer,
              answerAudio,
              answerImage,
              responseBotAudio,
              responseBackgroundImage,
              responseBackgroundVideo,
              points,
              isCorrect = false,
              displayOrder = index,
              isDeleted: answerIsDeleted, // this is to avoid clashing with 'isDeleted' on the NodeDefinition
              createdAt,
            } = answerDefinition;

            // let idDisplay = answerId;
            // if (!createdAt) {
            //   idDisplay = snakeCase(answer);
            // }

            let answerDefStyle: CSSProperties = {
              color: (isCorrect) ? 'green' : 'red',
            };
            if (answerIsDeleted) {
              answerDefStyle = {
                ...answerDefStyle,
                textDecorationLine: 'line-through',
                textDecorationStyle: 'solid',
              };
            }
            let fieldsDisabled = false;
            if (isSubmitting || answerIsDeleted) {
              fieldsDisabled = true;
            }

            function generateFormItemAnswerProps(itemName: keyof BotMultipleChoiceAnswer): FormItemProps {
              const errorKey = `${errorPath}.${itemName}`;
              const hasError = errorKey in errors;
              return {
                name: ['nodeDefinition', 'answerDefinitions', answerId, itemName],
                validateStatus: hasError ? 'error' : '',
                hasFeedback: hasError,
                help: hasError ? (errors as AnswerFormErrors)[errorKey] : false,
              };
            }

            function deleteAnswerDef(/* event: React.MouseEvent<HTMLElement, MouseEvent> */): void {
              // not clear that we need to do this
              // event.preventDefault();
              if (createdAt) {
                // only updated the 'isDeleted' field if the answer has already been saved
                setFieldValue(`${answerPath}.isDeleted`, !answerIsDeleted);
              } else {
                // remove the answer if it hasn't been saved
                setFieldValue(answerPath, undefined);
              }
            }

            const innerCardStyle: CSSProperties = {};

            if (index !== 0) {
              // this spaces out the answers
              innerCardStyle.marginTop = 16;
            }
            const optionCurrentlyUsed = usedOptionIds.includes(answerId);
            let deleteButtonContent = 'Delete';
            if (optionCurrentlyUsed) {
              deleteButtonContent = 'Answer currently being used';
            }

            if (answerIsDeleted) {
              deleteButtonContent = 'Un-Delete';
            }

            return (
              <Card
                key={answerId}
                headStyle={answerDefStyle}
                type='inner'
                style={innerCardStyle}
                title={<span>Answer ID: {answerId}</span>}
                size='small'
                actions={[
                  <Form.Item key={'delete_answer_definition'}>
                    <Button
                      danger
                      disabled={optionCurrentlyUsed}
                      size='small'
                      onClick={deleteAnswerDef}
                    >
                      {deleteButtonContent}
                    </Button>
                  </Form.Item>
                ]}
              >
                <Form.Item
                  {...generateFormItemAnswerProps('answer')}
                  label={'Answer'}
                >
                  <Input
                    placeholder='12 – 20 ft.'
                    disabled={fieldsDisabled}
                    value={answer}
                  />
                </Form.Item>
                <Form.Item
                  {...generateFormItemAnswerProps('answerAudio')}
                  label={'Answer Audio File'}
                >
                  <Select
                    allowClear
                    showSearch
                    options={audioOptions}
                    disabled={fieldsDisabled}
                    loading={fetchingFileReferences}
                    value={answerAudio}
                    placeholder={audioPlaceholder}
                  />
                </Form.Item>
                <Form.Item
                  {...generateFormItemAnswerProps('answerImage')}
                  label={'Answer Image File'}
                >
                  <Select
                    allowClear
                    showSearch
                    options={imageOptions}
                    disabled={fieldsDisabled}
                    loading={fetchingFileReferences}
                    value={answerImage}
                    placeholder={imagePlaceholder}
                  />
                </Form.Item>
                <Form.Item
                  {...generateFormItemAnswerProps('responseBotAudio')}
                  label={'Response Spud Audio File'}
                >
                  <Select
                    allowClear
                    showSearch
                    options={audioOptions}
                    disabled={fieldsDisabled}
                    loading={fetchingFileReferences}
                    value={responseBotAudio}
                    placeholder={audioPlaceholder}
                  />
                </Form.Item>
                <Form.Item
                  {...generateFormItemAnswerProps('responseBackgroundImage')}
                  label={'Response Background Image File'}
                >
                  <Select
                    allowClear
                    showSearch
                    options={imageOptions}
                    disabled={fieldsDisabled}
                    loading={fetchingFileReferences}
                    value={responseBackgroundImage}
                    placeholder={imagePlaceholder}
                  />
                </Form.Item>
                <Form.Item
                  {...generateFormItemAnswerProps('responseBackgroundVideo')}
                  label={'Response Background Video File'}
                >
                  <Select
                    allowClear
                    showSearch
                    options={videoOptions}
                    disabled={fieldsDisabled}
                    loading={fetchingFileReferences}
                    value={responseBackgroundVideo}
                    placeholder={videoPlaceholder}
                  />
                </Form.Item>
                <Form.Item
                  {...generateFormItemAnswerProps('isCorrect')}
                  label={'Is Correct'}
                >
                  <Switch
                    disabled={fieldsDisabled}
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    checked={isCorrect}
                  />
                </Form.Item>
                <Form.Item
                  {...generateFormItemAnswerProps('points')}
                  label={'Points'}
                >
                  <InputNumber
                    disabled={fieldsDisabled}
                    value={points}
                  />
                </Form.Item>
                <Form.Item
                  {...generateFormItemAnswerProps('displayOrder')}
                  label={'Display Order'}
                >
                  <InputNumber
                    disabled={true}
                    value={displayOrder}
                  />
                </Form.Item>
              </Card>
            );
          })}
      </Card>
    </React.Fragment>
  );
};

export default QuestionNodeDefinitionForm;