import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchLiveEvents,
} from 'src/features/liveEvents/liveEventsSlice';
import DayScheduleContainer from '../DayScheduleContainer';
import moment from 'moment-timezone';
import {
  Drawer,
  Row,
  Col,
  Calendar,
  Badge,
} from 'antd';
import './index.css';
/** import types */
import type { RootState } from 'src/app/rootReducer';
import type { AppDispatch } from 'src/app/store';
import type {
  LiveEvent,
} from 'types';
import type { Moment } from 'moment-timezone';

const LiveEventsSchedule: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [selectedDate, selectDate] = React.useState<Moment | null>(null);
  const {
    liveEvents,
  } = useSelector((state: RootState) => state.liveEventsState);


  React.useEffect(() => {
    if (liveEvents === null) {
      dispatch(fetchLiveEvents());
    }
  }, [dispatch, liveEvents]);

  const bucketedEvents = Object.entries(liveEvents || {}).reduce<{ [date: string]: { [liveEventId: string]: LiveEvent }; }>((acc, [id, event]) => {
    const dateIndex = moment(event.eventDatetime).format('L');
    if (!acc[dateIndex]) {
      acc[dateIndex] = {};
    }
    acc[dateIndex][id] = event;
    return acc;
  }, {});
  const dateCellRender: React.FC<Moment> = (date) => {
    const dateIndex = date.format('L');
    return (
      <ul className='date-live-events'>
        {Object.entries(bucketedEvents[dateIndex] || {}).sort()
          .sort(([, { eventDatetime: A }], [, { eventDatetime: B }]) => A - B)
          .map(([id, { title, status }]) => {
            let badgeStatus: "processing" | "success" | "error" | "default" | "warning" | undefined = 'processing';
            switch (status) {
              case 'completed': {
                badgeStatus = 'success';
                break;
              }
              case 'canceled': {
                badgeStatus = 'error';
                break;
              }
              case 'started': {
                badgeStatus = 'warning';
                break;
              }
            }
            return (
              <li key={id}>
                <Badge status={badgeStatus} text={title} />
              </li>
            );
          })}
      </ul>
    );
  };
  return (
    <React.Fragment>
      <Row
        justify='center'
        gutter={[16, 16]}
        style={{
          padding: '1em',
          margin: '1em',
          backgroundColor: 'white',
        }}
      >
        <Col xs={23} sm={23} md={23} lg={22} xl={20}>
          <Calendar
            mode='month'
            onSelect={selectDate}
            dateCellRender={dateCellRender}
          // dateCellRender={(data) => data}
          />
        </Col>
      </Row>
      <Drawer
        width={600}
        title={selectedDate?.format('dddd, LL')}
        placement={'right'}
        visible={!!selectedDate}
        closable={true}
        onClose={() => selectDate(null)}
      >
        {selectedDate && <DayScheduleContainer date={selectedDate} />}
      </Drawer>
    </React.Fragment>
  );
};

export default LiveEventsSchedule;