import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as yup from 'yup';
import {
  chipPartConverter,
  chipPartSchema,
} from './helpers';
import { generateFirestorePath } from 'src/helpers';
import firebase from 'firebase';
import 'firebase/firestore';
/** type imports */
import type { AsyncAppThunk } from 'src/app/store';
import type { ChipPart } from 'types';

const { firestore } = firebase;
interface SuccessReturn {
  success: true;
  validationError: null;
}
interface FailureReturn {
  success: false;
  validationError: yup.ValidationError;
}

type ThunkReturn = SuccessReturn | FailureReturn;

interface ChipParts { [chipPartId: string]: ChipPart }

interface ChipPartsState {
  chipParts: { [chipPartId: string]: ChipPart };
  fetchingChipParts: boolean;
}

const initialState: ChipPartsState = {
  chipParts: {},
  fetchingChipParts: true,
};

const chipPartsSlice = createSlice({
  name: 'chipParts',
  initialState,
  reducers: {
    setChipParts(state, action: PayloadAction<ChipParts>): void {
      state.chipParts = action.payload;
      state.fetchingChipParts = false;
    },
    setToLoading(state): void {
      state.fetchingChipParts = true;
    },
  },
});

export const {
  setChipParts,
  setToLoading,
} = chipPartsSlice.actions;

// thunk creator
export function fetchChipParts(): AsyncAppThunk<void> {
  return async function thunk(dispatch, getState): Promise<void> {
    try {
      const user = getState().userState.user;
      if (!user) {
        throw new Error('Not authenticated');
      }
      dispatch(setToLoading());
      const chipParts: ChipParts = {};

      const chipPartsSnapshot = await firestore()
        .collection(generateFirestorePath('chipParts'))
        .withConverter(chipPartConverter)
        .get();

      chipPartsSnapshot.forEach((ChipPart) => {
        if (ChipPart.exists) {
          chipParts[ChipPart.id] = ChipPart.data();
        }
      });

      dispatch(setChipParts(chipParts));
    } catch (error) {
      console.log('Error in fetchChipParts():', error);
    }
  };
}

export function addChipPart(newChipPart: Partial<ChipPart>): AsyncAppThunk<ThunkReturn> {
  return async function thunk(dispatch, getState): Promise<ThunkReturn> {
    try {
      const user = getState().userState.user;
      if (!user) {
        throw new Error('Not authenticated');
      }

      const newPart = await chipPartSchema.validate(newChipPart, {
        stripUnknown: true,
        context: {
          editMode: false,
        },
      });

      const partRef = firestore()
        .collection(generateFirestorePath('chipParts'))
        .withConverter(chipPartConverter)
        .doc();

      const newPartSnapshot = await partRef.get();

      if (newPartSnapshot.exists) {
        throw new yup.ValidationError('That Chip Part already exists', 'That Chip Part already exists', '');
      }

      await partRef.set(newPart);

      await dispatch(fetchChipParts());

      return {
        success: true,
        validationError: null,
      };
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        return {
          success: false,
          validationError: error,
        };
      } else {
        console.log('Error in addChipPart():', error);
        throw error;
      }
    }
  };
}

export function updateChipPart(id: string, updatedChipPart: Partial<ChipPart>): AsyncAppThunk<ThunkReturn> {
  return async function thunk(dispatch, getState): Promise<ThunkReturn> {
    try {
      const user = getState().userState.user;
      if (!user) {
        throw new Error('Not authenticated');
      }

      const updatedPart = await chipPartSchema.validate(updatedChipPart, {
        stripUnknown: true,
        context: {
          editMode: true,
        }
      });

      const partRef = firestore()
        .collection(generateFirestorePath('chipParts'))
        .withConverter(chipPartConverter)
        .doc(id);

      const updatedPartSnapshot = await partRef.get();

      if (!updatedPartSnapshot.exists) {
        throw new yup.ValidationError('That Chip Part does not exists', 'That Chip Part does not exists', '');
      }

      await partRef.set(updatedPart);

      await dispatch(fetchChipParts());

      return {
        success: true,
        validationError: null,
      };
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        return {
          success: false,
          validationError: error,
        };
      } else {
        console.log('Error in updateChipPart():', error);
        throw error;
      }
    }
  };
}

export default chipPartsSlice.reducer;