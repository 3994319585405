import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  Form,
  Input,
  Button,
  Select,
  Divider,
  PageHeader,
  Alert,
  TimePicker,
  Switch,
  Drawer,
  Card,
} from 'antd';
import * as yup from 'yup';
import NodeDefinitionFormContainer from 'src/components/stories/nodes/NodeDefinitionFormContainer';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchTagDefinitions,
} from 'src/features/tagDefinitions/tagDefinitionsSlice';
import {
  fetchSeasons,
} from 'src/features/seasons/seasonsSlice';
import {
  fetchFileReferences,
} from 'src/features/storage/storageSlice';
import {
  addLiveEvent,
  updateLiveEvent,
  deleteLiveEvent,
} from 'src/features/liveEvents/liveEventsSlice';
import {
  fetchCharacterProfiles,
} from 'src/features/characterProfiles/characterProfilesSlice';
import { liveBuilderImageSelectorNode } from 'src/features/nodes/helpers';
import merge from 'deepmerge';
import isPlainObject from 'is-plain-object';
import { useFormik } from 'formik';
import {
  transformFiles,
  overwriteMerge,
  nodeTypeIconOptions,
} from 'src/helpers';
import './nodeTemplateCard.css';
/** type imports */
import type { Store } from 'rc-field-form/es/interface';
import type { AppDispatch } from 'src/app/store';
import type { RootState } from 'src/app/rootReducer';
import type {
  LiveEventFormState,
  FormItemProps,
  LiveEvent,
  ValidationResult,
} from 'types';



interface Props {
  liveEventFormState: LiveEventFormState;
  onCancel: () => void;
  editMode: boolean;
}

const LiveEventFormContainer: React.FC<Props> = ({ liveEventFormState, onCancel, editMode }: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    seasons,
    fetchingSeasons,
  } = useSelector((state: RootState) => state.seasonsState);
  const { tagDefinitions } = useSelector((state: RootState) => state.tagDefinitionsState);
  const { fetchingFileReferences } = useSelector((state: RootState) => state.storageState);
  const { imageFiles } = useSelector((state: RootState) => state.storageState.fileReferences);
  const {
    characterProfiles,
    fetchingCharacterProfiles,
  } = useSelector((state: RootState) => state.characterProfilesState);

  const [addNewNodeTemplate, setAddNewNodeTemplate] = React.useState<boolean>(false);
  const [selectedNodeTemplateId, selectNodeTemplateId] = React.useState<string | null>(null);

  const [antdForm] = Form.useForm();



  const formik = useFormik<LiveEventFormState>({
    initialValues: liveEventFormState,
    enableReinitialize: true,
    onSubmit: async (values, formikHelpers): Promise<void> => {
      const { id, liveEvent: preLiveEvent } = values;
      const { setSubmitting, resetForm, setErrors } = formikHelpers;
      const liveEvent = {
        ...preLiveEvent,
        eventDatetime: preLiveEvent.eventDatetime?.valueOf()
      };
      let resultAction;
      try {
        if (editMode) {
          resultAction = await dispatch(updateLiveEvent({ id, liveEvent }));
        } else {
          resultAction = await dispatch(addLiveEvent({ id, liveEvent }));
        }
        setSubmitting(false);
        if (resultAction) {
          const payload = resultAction.payload;
          if (payload instanceof yup.ValidationError) {
            const { path, message } = payload;
            setErrors({
              [path]: message,
            });
          } else {
            if (!editMode) {
              console.log('for some reason I am hitting this');
              resetForm();
              antdForm.resetFields();
            }
          }
        }
      } catch (error) {
        console.log('Non validation error:', error);
      }
    },
  });

  const {
    values: formikValues,
    errors,
    // handleChange,
    // handleBlur,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    // setSubmitting,
    // setErrors,
    /* and other goodies */
    initialValues: formikInitialValues,
    setValues,
    dirty,
  } = formik;

  React.useEffect(() => {
    antdForm.resetFields();
  }, [antdForm, formikInitialValues]);

  React.useEffect(() => {
    if (Object.keys(tagDefinitions).length === 0) {
      dispatch(fetchTagDefinitions());
    }
    if (imageFiles.length === 0) {
      dispatch(fetchFileReferences());
    }
    if (Object.keys(seasons).length === 0) {
      dispatch(fetchSeasons());
    }
    if (Object.keys(characterProfiles).length === 0) {
      dispatch(fetchCharacterProfiles());
    }
  }, [dispatch, seasons, imageFiles, tagDefinitions, characterProfiles]);





  const imageOptions = transformFiles(imageFiles);
  // const videoOptions = transformFiles(videoFiles);
  // const audioOptions = transformFiles(audioFiles);

  // const audioPlaceholder = 'audio/example.mp3';
  const imagePlaceholder = 'image/example.jpeg';
  // const videoPlaceholder = 'video/example.mp4';

  function handleValuesChange(changedValues: Store /*, currentValues: Store */): void {
    const newValues = merge<LiveEventFormState, Store>(formikValues, changedValues, {
      arrayMerge: overwriteMerge,
      isMergeableObject: isPlainObject,
    });

    setValues(newValues);
  }


  type FormErrors = {
    [K in keyof LiveEvent]?: string;
  }

  function generateFormItemProps(itemName: keyof LiveEvent): FormItemProps {
    const hasError = itemName in errors;
    return {
      name: ['liveEvent', itemName],
      validateStatus: hasError ? 'error' : '',
      hasFeedback: hasError,
      help: hasError ? (errors as FormErrors)[itemName] : false,
    };
  }


  const characterProfilesOptions = Object.entries(characterProfiles)
    .map(([id, { displayName }]) => ({
      label: displayName,
      value: id,
    }));

  const { id, liveEvent } = formikValues;
  const {
    title,
    description,
    images,
    seasonId,
    characterProfileId,
    status,
    streamId,
    eventDatetime,
    hasBadge,
    assemblerBackgroundImage,
    selfieImage,
    nodeTemplates = {},
    storyId,
  } = liveEvent;

  /** this sets the defaults for boolean fields */
  React.useEffect(() => {
    if (hasBadge === undefined) {
      setFieldValue('liveEvent.hasBadge', false);
    }
    /** you can ignore the ts-lint issue here */
  }, []);

  const questionDefStyle = {};

  async function onDelete(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): Promise<void> {
    event.preventDefault();
    const { setSubmitting } = formik;
    try {
      setSubmitting(true);
      await dispatch(deleteLiveEvent({ id }));
      setSubmitting(false);
      onCancel();
    } catch (error) {
      console.log('Deletion error:', error);
      setSubmitting(false);
    }
  }
  const BottomButtons: React.FC = () => {
    const handleSubmitClick = async (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      try {
        event.preventDefault();
        antdForm.submit();
        await formik.submitForm();
      } catch (error) {
        //console.error(error.message);
      }
    };
    if (editMode) {
      let updateButtonContent = 'Update Live Event';
      if (!dirty) {
        updateButtonContent = 'Make a change to update';
      }
      return (
        <Form.Item>
          <Button
            block
            size={'large'}
            type={'primary'}
            onClick={handleSubmitClick}
            loading={isSubmitting}
            disabled={isSubmitting || !dirty}
          >
            {updateButtonContent}
          </Button>
          <Button
            block
            danger
            onClick={onDelete}
            size={'large'}
            type={'primary'}
            loading={isSubmitting}
            disabled={isSubmitting}
          >
            Delete Live Event
          </Button>
        </Form.Item>
      );
    } else {
      return (
        <Form.Item>
          <Button
            block
            size={'large'}
            type={'primary'}
            onClick={handleSubmitClick}
            loading={isSubmitting}
            disabled={isSubmitting}
          >
            Create New Live Event
          </Button>
        </Form.Item>
      );
    }
  };

  const day = liveEventFormState.liveEvent.eventDatetime?.format('dddd, LL');
  const notScheduled = status !== 'scheduled';
  const nodeTemplateArr = Object.entries(nodeTemplates).sort(([, { displayOrder: A }], [, { displayOrder: B }]) => A - B);

  return (
    <React.Fragment>
      <Form
        size={'middle'}
        layout={'vertical'}
        initialValues={formikInitialValues}
        form={antdForm}
        onValuesChange={handleValuesChange}
        onSubmitCapture={handleSubmit}
      >
        <PageHeader
          title={<Form.Item name={'id'}><span style={questionDefStyle}>ID: {id}</span></Form.Item>}
          extra={[
            <Button
              key={'close_sidebar'}
              danger
              icon={<CloseOutlined />}
              onClick={onCancel}
            />
          ]}
        >
          <Form.Item
            {...generateFormItemProps('title')}
            label={'Title'}
          >
            <Input
              placeholder={'Science Experiment'}
              disabled={isSubmitting || notScheduled}
              value={title}
            />
          </Form.Item>
          <Form.Item
            {...generateFormItemProps('description')}
            label={'Description'}
          >
            <Input.TextArea
              disabled={isSubmitting || notScheduled}
              value={description}
            />
          </Form.Item>
          <Form.Item
            {...generateFormItemProps('images')}
            label={'Images'}
          >
            <Select
              allowClear
              showSearch
              mode='multiple'
              options={imageOptions}
              disabled={isSubmitting || notScheduled}
              loading={fetchingFileReferences}
              value={images}
              placeholder={imagePlaceholder}
            />
          </Form.Item>
          <Form.Item
            {...generateFormItemProps('seasonId')}
            label={'Season'}
          >
            <Select
              allowClear
              showSearch
              options={Object.entries(seasons).map(([seasonId, { title }]) => ({ label: title, value: seasonId }))}
              disabled={isSubmitting || notScheduled}
              loading={fetchingSeasons}
              value={seasonId}
              placeholder={'Scientific Method'}
            />
          </Form.Item>
          <Form.Item
            {...generateFormItemProps('eventDatetime')}
            label={<div><div>Event Date and Time</div><div style={{ fontWeight: 'bold' }}>{day}</div></div>}
          >
            <TimePicker
              use12Hours
              showNow={false}
              format={'h:mm A'}
              disabled={isSubmitting || notScheduled}
              minuteStep={15}
              value={eventDatetime}
            />
          </Form.Item>

          <Form.Item
            {...generateFormItemProps('characterProfileId')}
            label={'Character/Performer Profile'}
          >
            <Select
              allowClear
              options={characterProfilesOptions}
              disabled={isSubmitting}
              loading={fetchingCharacterProfiles}
              value={characterProfileId}
              placeholder='Haley'
            />
          </Form.Item>
          <Form.Item
            {...generateFormItemProps('hasBadge')}
            label={'Does episode have badge?'}
          >
            <Switch
              disabled={isSubmitting}
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              checked={hasBadge}
            />
          </Form.Item>
          <Form.Item
            {...generateFormItemProps('selfieImage')}
            label={'Selfie or Badge Image'}
          >
            <Select
              allowClear
              showSearch
              options={imageOptions}
              disabled={isSubmitting}
              loading={fetchingFileReferences}
              value={selfieImage}
              placeholder={imagePlaceholder}
            />
          </Form.Item>
          <Form.Item
            {...generateFormItemProps('streamId')}
            label={'Stream ID'}
          >
            <Input
              disabled={true}
              value={streamId}
            />
          </Form.Item>
          <Form.Item
            {...generateFormItemProps('assemblerBackgroundImage')}
            label={'Assembler Background Image'}
          >
            <Select
              allowClear
              showSearch
              options={imageOptions}
              disabled={isSubmitting}
              loading={fetchingFileReferences}
              value={assemblerBackgroundImage}
              placeholder={imagePlaceholder}
            />
          </Form.Item>
          <Card
            title={'Node Templates'}
            actions={[
              <Form.Item key={'add_new_answer_definition'}>
                <Button
                  type={'primary'}
                  onClick={() => setAddNewNodeTemplate(true)}
                >
                  Add Node Template
                </Button>
              </Form.Item>
            ]}
          >
            {nodeTemplateArr.map(([id, node]) => {
              return (
                <div
                  key={id}
                  onClick={() => selectNodeTemplateId(id)}
                  className={'node-template-card'}
                >
                  <img className={'node-template-icon'} src={nodeTypeIconOptions[node.type]} alt={node.type} />
                  <div className={'node-template-summary'}>
                    <div className='node-template-display-name'>Display Name: {node.displayName}</div>
                    <div className='node-template-type'>Type: {node.type}</div>
                    <div className='node-template-description'>Description: {node.description}</div>
                    <div className='node-template-description'>Description: {node.displayOrder}</div>
                  </div>
                </div>
              );
            })}
          </Card>
          <Drawer
            width={600}
            placement={'right'}
            visible={addNewNodeTemplate}
            closable={false}
          >
            {(nodeTemplates && addNewNodeTemplate) && <NodeDefinitionFormContainer
              editMode={false}
              hasChildren={false}
              deletedStoryNodeIds={[]}
              storyType={'live'}
              nodeFormState={{
                id: uuidv4(),
                nodeDefinition: {
                  type: 'Live Builder Image Selector',
                  startTime: 0,
                  endTime: 0,
                  isDeleted: false,
                  isEndingNode: false,
                  displayOrder: nodeTemplateArr.length,
                  createdBy: 'placeholder',
                },
                edgeConfiguration: null,
              }}
              templateCB={async (node) => {
                let result: ValidationResult = {
                  success: true,
                  validationError: null,
                };
                try {
                  const nodeTemplate = await liveBuilderImageSelectorNode.validate(node);
                  const newId = uuidv4();
                  setFieldValue('liveEvent.nodeTemplates', {
                    ...nodeTemplates,
                    [newId]: nodeTemplate,
                  });
                  /** 
                   * without setting the fields manually using antd
                   * the display order doesn't populate
                   */
                  antdForm.setFieldsValue({
                    liveEvent: {
                      nodeTemplates: {
                        [newId]: nodeTemplate,
                      },
                    },
                  });

                  return result;
                } catch (error) {
                  if (error instanceof yup.ValidationError) {
                    console.error('whoa this is a validation error');
                    result = {
                      success: false,
                      validationError: error,
                    };
                    return result;
                  } else {
                    throw error;
                  }
                }
              }}
              onCancel={() => setAddNewNodeTemplate(false)}
            />}
          </Drawer>
          <Drawer
            width={600}
            placement={'right'}
            visible={!!selectedNodeTemplateId}
            closable={false}
          >
            {(nodeTemplates && selectedNodeTemplateId && nodeTemplates[selectedNodeTemplateId]) && <NodeDefinitionFormContainer
              editMode={true}
              hasChildren={false}
              deletedStoryNodeIds={[]}
              storyType={'live'}
              nodeFormState={{
                id: selectedNodeTemplateId,
                nodeDefinition: nodeTemplates[selectedNodeTemplateId],
                edgeConfiguration: null,
              }}
              templateCB={async (node) => {
                let result: ValidationResult = {
                  success: true,
                  validationError: null,
                };
                try {
                  const nodeTemplate = await liveBuilderImageSelectorNode.validate(node, {
                    context: {
                      editMode: true,
                    },
                  });
                  setFieldValue('liveEvent.nodeTemplates', {
                    ...nodeTemplates,
                    [selectedNodeTemplateId]: nodeTemplate,
                  });
                  /** 
                   * without setting the fields manually using antd
                   * the display order doesn't populate
                   */
                  antdForm.setFieldsValue({
                    liveEvent: {
                      nodeTemplates: {
                        [selectedNodeTemplateId]: nodeTemplate,
                      },
                    },
                  });

                  return result;
                } catch (error) {
                  if (error instanceof yup.ValidationError) {
                    console.error('whoa this is a validation error');
                    result = {
                      success: false,
                      validationError: error,
                    };
                    return result;
                  } else {
                    throw error;
                  }
                }
              }}
              onCancel={() => selectNodeTemplateId(null)}
            />}
          </Drawer>
          {(storyId && seasonId) ? <div style={{ marginTop: '1rem' }}>Season ID: {seasonId}</div> : ""}
          {storyId ? <div style={{ marginTop: '1rem' }}>Story ID: {storyId}</div> : ""}
          <Divider />
          {Object.values(errors).map((error, index) => <Alert style={{ marginBottom: 10 }} showIcon type={'error'} key={index} message={error} />)}
          <BottomButtons />
        </PageHeader>
      </Form>
    </React.Fragment>
  );
};

export default LiveEventFormContainer;