import firebase from 'firebase/app';
import 'firebase/auth';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
/** type imports */
import type { RootState } from 'src/app/rootReducer';


const provider = new firebase.auth.GoogleAuthProvider();

interface LocationState {
  from: {
    pathname: string;
    search: string;
    hash: string;
    state: undefined;
  };
}



type PrevURL = string;
type PrevState = LocationState
type Login = () => Promise<void>;
/**
 * useLogin hook
 */
export function useLogin(): [[PrevURL, PrevState | undefined] | null, Login] {
  const { user, fetchingUser } = useSelector((state: RootState) => state.userState);
  const location = useLocation<LocationState>();
  let prevUrl: PrevURL = '/';
  let prevState: LocationState | undefined;
  if (location.state) {
    console.log(location.state.from);
    console.log(location.state);
    prevUrl = location.state.from.pathname;
    prevState = { from: { ...location.state.from }, };
  }
  let prevInfo: [PrevURL, LocationState | undefined] | null = null;
  if (user !== null) {
    prevInfo = [prevUrl, prevState];
  }
  /**
   * commented out the logic below because it was causing a warning
   */
  // if (user) {
  //   /**
  //    * if the user is already logged in
  //    * then have them go back to the previous url
  //    * without polluting the location state
  //    */
  //   // history.replace(prevUrl);
  //   console.log(prevUrl, prevState);
  //   history.replace(prevUrl, prevState);
  // }
  async function login(): Promise<void> {
    try {
      if (!fetchingUser) {
        const result = await firebase.auth().signInWithPopup(provider);
        console.log('result from onClick', result);
      } else {
        console.log('already fetching');
      }
      /** commented out the logic below because it was causing a warning */
      // history.replace(prevUrl, prevState);
    } catch (error) {
      console.log('error in login', error);
    }
  }
  return [prevInfo, login];
}