import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Form,
  Select,
  Divider,
} from 'antd';
import {
  fetchFileReferences,
} from 'src/features/storage/storageSlice';
import { transformFiles } from 'src/helpers';
/** type imports */
import type { AppDispatch } from 'src/app/store';
import type { RootState } from 'src/app/rootReducer';
import type { FormInstance } from 'antd/es/form';
import type { FormikErrors } from 'formik';
import type {
  XRayNode,
  FormItemProps,
} from 'types';


interface Props {
  nodeDefinition: Partial<XRayNode>;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => any;
  handleChange: (eventOrPath: string | React.ChangeEvent<any>) => void | ((eventOrTextValue: string | React.ChangeEvent<any>) => void);
  handleBlur: (eventOrString: any) => void | ((e: any) => void);
  form: FormInstance;
  isSubmitting: boolean;
  errors: FormikErrors<{ id: string; nodeDefinition: Partial<XRayNode>; }>;
  setErrors: (errors: FormikErrors<{ id: string; nodeDefinition: Partial<XRayNode>; }>) => void;
}

const XRayNodeFormBody: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    fileReferences: {
      imageFiles,
      // videoFiles,
      audioFiles,
    },
    fetchingFileReferences,
  } = useSelector((state: RootState) => state.storageState);

  const imageOptions = transformFiles(imageFiles);
  // const videoOptions = transformFiles(videoFiles);
  const audioOptions = transformFiles(audioFiles);

  const audioPlaceholder = 'audio/example.mp3';
  // const videoPlaceholder = 'video/example.mp4';
  const imagePlaceholder = 'image/example.jpeg';

  React.useEffect(() => {
    dispatch(fetchFileReferences());
  }, [dispatch]);

  const {
    nodeDefinition,
    // setFieldValue,
    // handleChange,
    // handleBlur,
    isSubmitting,
    errors,
    // form,
  } = props;

  const {
    backgroundImage,
    xrayImage,
    botAudio,
  } = nodeDefinition;


  type QuestionFormErrors = {
    [K in keyof XRayNode]?: string;
  }

  // interface AnswerFormErrors {
  //   [answerDefKey: string]: string;
  // }

  function generateFormItemProps(itemName: keyof XRayNode): FormItemProps {
    const hasError = itemName in errors;
    return {
      name: ['nodeDefinition', itemName],
      validateStatus: hasError ? 'error' : '',
      hasFeedback: hasError,
      help: hasError ? (errors as QuestionFormErrors)[itemName] : false,
    };
  }


  if (Object.keys(errors).length) {
    console.log(errors);
  }

  return (
    <React.Fragment>
      <Divider>X-ray Configuration</Divider>
      <Form.Item
        {...generateFormItemProps('backgroundImage')}
        label={'Background Image File'}
      >
        <Select
          allowClear
          showSearch
          options={imageOptions}
          disabled={isSubmitting}
          loading={fetchingFileReferences}
          value={backgroundImage}
          placeholder={imagePlaceholder}
        />
      </Form.Item>
      <Form.Item
        {...generateFormItemProps('xrayImage')}
        label={'X-ray Image File'}
      >
        <Select
          allowClear
          showSearch
          options={imageOptions}
          disabled={isSubmitting}
          loading={fetchingFileReferences}
          value={xrayImage}
          placeholder={imagePlaceholder}
        />
      </Form.Item>
      <Form.Item
        {...generateFormItemProps('botAudio')}
        label={'Bot Audio File'}
      >
        <Select
          allowClear
          showSearch
          options={audioOptions}
          disabled={isSubmitting}
          loading={fetchingFileReferences}
          value={botAudio}
          placeholder={audioPlaceholder}
        />
      </Form.Item>
    </React.Fragment>
  );
};

export default XRayNodeFormBody;