import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import {
  fetchSeasons,
} from 'src/features/seasons/seasonsSlice';
import SeasonFormContainer from './SeasonFormContainer';
import moment from 'moment';
import {
  Button,
  Table,
  Drawer,
  Row,
  Col,
  Tag,
} from 'antd';
import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
} from '@ant-design/icons';
import { momentFormat, seasonStatusOptions } from 'src/helpers';
import {
  useHistory,
} from 'react-router-dom';
/** import types */
import type { RootState } from 'src/app/rootReducer';
import type { AppDispatch } from 'src/app/store';
import type { ColumnType } from 'antd/es/table';
import type {
  milliseconds,
  antdColorOptions,
  Season,
} from 'types';

interface TableDatum extends Season {
  id: string;
}

const Seasons: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();
  const [addNewSeason, setAddNewSeason] = React.useState<boolean>(false);
  const {
    seasons,
    fetchingSeasons,
  } = useSelector((state: RootState) => state.seasonsState);


  React.useEffect(() => {
    dispatch(fetchSeasons());
  }, [dispatch]);

  const tableData: TableDatum[] = Object.entries(seasons).map(([id, season]) => {
    return {
      id,
      ...season
    };
  });


  const renderStatus: React.FC<keyof typeof seasonStatusOptions> = (status: keyof typeof seasonStatusOptions) => {
    let renderValue: string = status;
    if (seasonStatusOptions[status]) {
      renderValue = seasonStatusOptions[status];
    }

    let color: antdColorOptions = 'success';
    switch (status) {
      case 'published': {
        color = 'success';
        break;
      }
      case 'staged': {
        color = 'processing';
        break;
      }
      case 'archived': {
        color = 'error';
        break;
      }
    }
    return <Tag color={color}>{renderValue}</Tag>;
  };

  const seasonStatusFilters = Object.entries(seasonStatusOptions)
    .map(([seasonStatusKey, seasonStatusLabel]) => ({ value: seasonStatusKey, text: seasonStatusLabel }));

  const renderComingSoon: React.FC<boolean> = (comingSoon: boolean) => {
    if (comingSoon) {
      return <CheckCircleTwoTone style={{ fontSize: '20px' }} twoToneColor='#52c41a' />;
    } else {
      return <CloseCircleTwoTone style={{ fontSize: '20px' }} twoToneColor='#a6a6a6' />;
    }
  };

  const comingSoonFilters = [
    { value: true, text: <CheckCircleTwoTone style={{ fontSize: '20px' }} twoToneColor='#52c41a' /> },
    { value: false, text: <CloseCircleTwoTone style={{ fontSize: '20px' }} twoToneColor='#a6a6a6' /> }
  ];

  const tableColumns: ColumnType<TableDatum>[] = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      width: 250,
    },
    {
      title: 'Sub-Title',
      dataIndex: 'subTitle',
      key: 'subTitle',
      width: 250,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 125,
      defaultFilteredValue: ['staged', 'published'],
      filters: seasonStatusFilters,
      render: renderStatus,
      onFilter: (value, record) => record.status === value,
    },
    {
      title: 'Coming Soon',
      dataIndex: 'comingSoon',
      key: 'comingSoon',
      width: 50,
      filters: comingSoonFilters,
      render: renderComingSoon,
      onFilter: (value, record) => record.comingSoon === value,
    },
    {
      title: 'Priority',
      dataIndex: 'priority',
      key: 'priority',
      width: 50,
      sorter: ({ priority: priorityA }, { priority: priorityB }) => priorityA - priorityB,
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 200,
      sorter: (a, b) => a.createdAt - b.createdAt,
      render: (createdAt: milliseconds) => moment(createdAt).format(momentFormat),
    },
    {
      title: 'Updated At',
      dataIndex: 'updatedAt',
      width: 200,
      key: 'updatedAt',
      sorter: ({ updatedAt: updatedAtA }, { updatedAt: updatedAtB }) => {
        if (updatedAtA !== undefined && updatedAtB !== undefined) {
          return updatedAtA - updatedAtB;
        } else if (updatedAtA !== undefined) {
          return 1;
        } else if (updatedAtB !== undefined) {
          return -1;
        }
        return 0;
      },
      render: (updatedAt: milliseconds | undefined) => (updatedAt) ? moment(updatedAt).format(momentFormat) : 'N/A',
    }
  ];

  return (
    <React.Fragment>
      <Row
        justify='center'
        gutter={[16, 16]}
        style={{
          padding: '1em',
          margin: '1em',
          backgroundColor: 'white',
        }}
      >
        <Col xs={23} sm={23} md={23} lg={22} xl={20}>
          <Table
            bordered
            title={() => {
              return <Button
                size='large'
                type='primary'
                onClick={() => setAddNewSeason(true)}
              >
                New Season
            </Button>;
            }}
            pagination={{
              total: tableData.length,
              defaultPageSize: 100,
              showTotal: (total) => `${total} Total Seasons`,
            }}
            loading={fetchingSeasons}
            dataSource={tableData}
            columns={tableColumns}
            rowKey={({ id }) => id}
            onRow={(record) => {
              return {
                onClick: () => history.push('/seasons/' + record.id), // click row
                style: { cursor: 'pointer' },
              };
            }}
          />
        </Col>
      </Row>
      <Drawer
        width={600}
        placement={'right'}
        visible={addNewSeason}
        closable={false}
      >
        {(!fetchingSeasons && addNewSeason) && <SeasonFormContainer
          editMode={false}
          seasonFormState={{
            id: uuidv4(),
            season: {
              title: '',
              subTitle: '',
              comingSoon: true,
              status: 'staged',
              priority: 0,
              isDeleted: false,
              tags: [],
            },
          }}
          onCancel={() => setAddNewSeason(false)}
        />}
      </Drawer>
    </React.Fragment>
  );
};

export default Seasons;