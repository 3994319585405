import moment from 'moment';
import firebase from 'firebase';
import { generateValidationFunc, colorThemes } from 'src/helpers';
import * as yup from 'yup';
import type {
  SnapshotOptions,
  AppTagDefinition,
} from 'types';


export const appTagDefinitionConverter: firebase.firestore.FirestoreDataConverter<AppTagDefinition> = {
  toFirestore(data: AppTagDefinition) {
    return data;
  },
  fromFirestore(snapshot: firebase.firestore.QueryDocumentSnapshot<AppTagDefinition>, options: SnapshotOptions): AppTagDefinition {
    const data = snapshot.data(options);

    return data;
  },
};

export const appTagDefinitionSchema: yup.ObjectSchema<AppTagDefinition> = yup.object().required().shape<AppTagDefinition>({
  displayName: yup.string().required().label('Display Name'),
  marketingTitle: yup.string().required().label('Marketing Title'),
  marketingDescription: yup.string().required().label('Marketing Description'),
  bannerImage: generateValidationFunc('image').required().label('Banner Image File'),
  iconImage: generateValidationFunc('image').required().label('App Icon Image File'),
  appStorePageLink: yup.string().required().label('App Store Page URL Link'),
  rank: yup.number().required().label('Rank order'),
  createdAt: yup.number().required().default(() => moment().valueOf()),
  updatedAt: yup.number().required().default(() => moment().valueOf()).transform(() => moment().valueOf()),
});