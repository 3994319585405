import React from 'react';
import { Result } from 'antd';

const NewlyCreated: React.FC = () => {
  return (
    <Result
      status='success'
      title='Thanks for creating an account!'
      subTitle='Please reach out to an admin to authorize your account.'
    // extra={<Button type="primary">Back Home</Button>}
    />
  );
};

export default NewlyCreated;