import React from 'react';
import {
  Form,
  Input,
  Button,
  Divider,
  PageHeader,
  Alert,
  Select,
  Switch,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  addCharacterProfile,
  updateCharacterProfile,
  fetchCharacterProfiles,
} from 'src/features/characterProfiles/characterProfilesSlice';
import {
  CloseOutlined,
  CheckOutlined,
} from '@ant-design/icons';
import {
  fetchFileReferences,
} from 'src/features/storage/storageSlice';
import merge from 'deepmerge';
import isPlainObject from 'is-plain-object';
import { useFormik } from 'formik';
import { overwriteMerge, transformFiles, colorThemeOptions, } from 'src/helpers';
import { snakeCase } from 'lodash';
/** type imports */
import type { Store } from 'rc-field-form/es/interface';
import type { AppDispatch } from 'src/app/store';
import type { RootState } from 'src/app/rootReducer';
import type {
  FormItemProps,
  CharacterProfile,
  CharacterFormState,
} from 'types';

const themeColorOptions = Object.entries(colorThemeOptions)
  .map(([colorKey, colorDisplay]) => ({ label: colorDisplay, value: colorKey }));

interface Props {
  characterFormState: CharacterFormState;
  onCancel: () => void;
  editMode: boolean;
}

const TagDefinitionFormContainer: React.FC<Props> = ({ characterFormState, onCancel, editMode }: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  
  const {
    fileReferences: {
      imageFiles,
      videoFiles,
      audioFiles,
    },
    fetchingFileReferences,
  } = useSelector((state: RootState) => state.storageState);
  const imageOptions = transformFiles(imageFiles);
  // const videoOptions = transformFiles(videoFiles);
  const audioOptions = transformFiles(audioFiles);

  const audioPlaceholder = 'audio/example.mp3';
  // const videoPlaceholder = 'video/example.mp4';
  const imagePlaceholder = 'image/example.jpeg';

  React.useEffect(() => {
    dispatch(fetchCharacterProfiles());
    dispatch(fetchFileReferences());
  }, [dispatch]);

  const [antdForm] = Form.useForm();

  const formik = useFormik<CharacterFormState>({
    initialValues: characterFormState,
    enableReinitialize: true,
    onSubmit: async (values, formikHelpers): Promise<void> => {
      const { id, characterProfile } = values;
      const { setSubmitting, resetForm, setErrors } = formikHelpers;
      let success, validationError;
      try {
        if (editMode) {
          ({ success, validationError } = await dispatch(updateCharacterProfile(id, characterProfile)));
        } else {
          ({ success, validationError } = await dispatch(addCharacterProfile(id, characterProfile)));
        }
        setSubmitting(false);
        if (success) {
          if (!editMode) {
            resetForm();
            antdForm.resetFields();
          }
        } else if (validationError) {
          console.log(validationError);
          const { path, message } = validationError;
          setErrors({
            [path]: message,
          });
        }
      } catch (error) {
        console.log('Non validation error:', error);
      }
    },
  });

  const videoOptions = transformFiles(videoFiles);
  const videoPlaceholder = 'video/example.mp4';
  

  const {
    values: formikValues,
    errors,
    // handleChange,
    // handleBlur,
    handleSubmit,
    isSubmitting,
    //setFieldValue,
    // setSubmitting,
    // setErrors,
    /* and other goodies */
    initialValues: formikInitialValues,
    setValues,
    dirty,
  } = formik;

  

  React.useEffect(() => {
    antdForm.resetFields();
  }, [antdForm, formikInitialValues]);

  function handleValuesChange(changedValues: Store /*, currentValues: Store */): void {
    const newValues = merge<CharacterFormState, Store>(formikValues, changedValues, {
      arrayMerge: overwriteMerge,
      isMergeableObject: isPlainObject,
    });

    if (!editMode) {
      const { characterProfile: { displayName } } = newValues;
      newValues.id = snakeCase(displayName);
    }
    setValues(newValues);
  }


  type FormErrors = {
    [K in keyof CharacterProfile]?: string;
  }

  function generateFormItemProps(itemName: keyof CharacterProfile): FormItemProps {
    const hasError = itemName in errors;
    return {
      name: ['characterProfile', itemName],
      validateStatus: hasError ? 'error' : '',
      hasFeedback: hasError,
      help: hasError ? (errors as FormErrors)[itemName] : false,
    };
  }

  const { id, characterProfile } = formikValues;
  const {
    displayName,
    backgroundImage,
    profileBackgroundImage,
    profileImage,
    coverImage,
    isGuest = false,
    introVideo,
    ringAudio,
    themeColor,
  } = characterProfile;

  const questionDefStyle = {};

 
  // if (isDeleted) {
  //   questionDefStyle = {
  //     textDecorationLine: 'line-through',
  //     textDecorationStyle: 'solid',
  //   };
  // }

  // async function onDelete(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): Promise<void> {
  //   event.preventDefault();
  //   try {
  //     setSubmitting(true);
  //     // await dispatch(deleteNodeDefinition(id));
  //     console.log('deleted');
  //   } catch (error) {
  //     console.log('error:', error);
  //   }
  //   setSubmitting(false);
  // }
  const BottomButtons: React.FC = () => {
    if (editMode) {
      // let deleteButtonContent = 'Archive Story';
      // if (status === 'archived') {
      //   deleteButtonContent = 'Re-Publish Story';
      // }
      let updateButtonContent = 'Update character profile';
      if (!dirty) {
        updateButtonContent = 'Make a change to update';
      }
      return (
        <Form.Item>
          <Button
            block
            size={'large'}
            type={'primary'}
            htmlType={'submit'}
            loading={isSubmitting}
            disabled={isSubmitting || !dirty}
          >
            {updateButtonContent}
          </Button>
          {/* <Button
            block
            danger
            onClick={onDelete}
            size={'large'}
            type={'primary'}
            loading={isSubmitting}
            disabled={isSubmitting}
          >
            {deleteButtonContent}
          </Button> */}
        </Form.Item>
      );
    } else {
      return (
        <Form.Item>
          <Button
            block
            size={'large'}
            type={'primary'}
            htmlType={'submit'}
            loading={isSubmitting}
            disabled={isSubmitting}
          >
            Create new character profile
          </Button>
        </Form.Item>
      );
    }
  };

  return (
    <React.Fragment>
      <Form
        size={'middle'}
        layout={'vertical'}
        initialValues={formikInitialValues}
        form={antdForm}
        onValuesChange={handleValuesChange}
        onSubmitCapture={handleSubmit}
      >
        <PageHeader
          title={<Form.Item name={'id'}><span style={questionDefStyle}>ID: {id}</span></Form.Item>}
          extra={[
            <Button
              key={'close_sidebar'}
              danger
              icon={<CloseOutlined />}
              onClick={onCancel}
            />
          ]}
        >
          <Form.Item
            {...generateFormItemProps('displayName')}
            label={'Display Name'}
          >
            <Input
              placeholder={'Hailey'}
              disabled={isSubmitting}
              value={displayName}
            />
          </Form.Item>
          <Form.Item
            {...generateFormItemProps('backgroundImage')}
            label={'Background Image File'}
          >
            <Select
              allowClear
              showSearch
              options={imageOptions}
              disabled={isSubmitting}
              loading={fetchingFileReferences}
              value={backgroundImage}
              placeholder={imagePlaceholder}
            />
          </Form.Item>
          <Form.Item
            {...generateFormItemProps('profileBackgroundImage')}
            label={'Profile Background Image File'}
          >
            <Select
              allowClear
              showSearch
              options={imageOptions}
              disabled={isSubmitting}
              loading={fetchingFileReferences}
              value={profileBackgroundImage}
              placeholder={imagePlaceholder}
            />
          </Form.Item>
          <Form.Item
            {...generateFormItemProps('profileImage')}
            label={'Profile Image File'}
          >
            <Select
              allowClear
              showSearch
              options={imageOptions}
              disabled={isSubmitting}
              loading={fetchingFileReferences}
              value={profileImage}
              placeholder={imagePlaceholder}
            />
          </Form.Item>
          
            <Form.Item
        {...generateFormItemProps('isGuest')}
        label={'Is Guest?'}
      >
        <Switch
          disabled={isSubmitting}
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          checked={isGuest}
        />
      </Form.Item>
      <Form.Item
            {...generateFormItemProps('introVideo')}
            label={'Intro Video File'}
          >
            <Select
              allowClear
              showSearch
              options={videoOptions}
              disabled={isSubmitting}
              loading={fetchingFileReferences}
              value={introVideo}
              placeholder={videoPlaceholder}
            />
          </Form.Item>
          <Form.Item
            {...generateFormItemProps('coverImage')}
            label={'Cover Image File'}
          >
            <Select
              allowClear
              showSearch
              options={imageOptions}
              disabled={isSubmitting}
              loading={fetchingFileReferences}
              value={coverImage}
              placeholder={imagePlaceholder}
            />
          </Form.Item>
          <Form.Item
            {...generateFormItemProps('ringAudio')}
            label={'Ring Audio File'}
          >
            <Select
              allowClear
              showSearch
              options={audioOptions}
              disabled={isSubmitting}
              loading={fetchingFileReferences}
              value={ringAudio}
              placeholder={audioPlaceholder}
            />
          </Form.Item>
          <Form.Item
            {...generateFormItemProps('themeColor')}
            label={'Theme Color'}
          >
            <Select
              allowClear
              options={themeColorOptions}
              disabled={isSubmitting}
              value={themeColor}
              placeholder={'Green'}
            />
          </Form.Item>
          <Divider />
          {Object.values(errors).map((error, index) => <Alert style={{ marginBottom: 10 }} showIcon type={'error'} key={index} message={error} />)}
          <BottomButtons />
        </PageHeader>
      </Form>
    </React.Fragment>
  );
};

export default TagDefinitionFormContainer;