import React from 'react';
import useInterval from '@use-it/interval';
import { v4 as uuidv4 } from 'uuid';
import firebase from 'firebase';
import 'firebase/firestore';
import {
  Modal,
} from 'antd';
import {
  generateFirestorePath,
  axiosInstance
} from 'src/helpers';
import moment from 'moment-timezone';
import {
  initializeLiveStory,
} from 'src/features/stories/storiesSlice';
import {
  startLiveEvent,
} from 'src/features/liveEvents/liveEventsSlice';
import { liveEventConverter } from 'src/features/liveEvents/helpers';

import {
  // useSelector,
  useDispatch,
} from 'react-redux';
import './index.css';
/** type imports */
import type { LiveStory, LiveEvent, AWSStreamResponse } from 'types';
// import type { RootState } from 'src/app/rootReducer';
import type { AppDispatch } from 'src/app/store';
import type { ModalProps } from 'antd/es/modal';


interface Props extends ModalProps {
  liveEventId: string;
  streamInputUrl?: string;
}

const AWSLiveEventStarter: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const { liveEventId, visible } = props;
  const [startStreamBtnLoading, setStartStreamBtnLoading] = React.useState<'loading' | 'idle'>('idle');
  const [currentLiveEvent, SetCurrentLiveEvent] = React.useState<LiveEvent | null>(null);
  const [awsStream, setAwsStream] = React.useState<null | AWSStreamResponse>(null);

  React.useEffect(() => {
    const liveEventRef = firebase.firestore()
      .collection(generateFirestorePath(`liveEvents`))
      .withConverter(liveEventConverter)
      .doc(liveEventId);
    console.log('added onSnapshot listener for LiveEvent');
    const liveEventRefUnsubscribe = liveEventRef.onSnapshot((storySnapshot) => {
      console.log('onSnapShot fired for LiveEvent');
      const liveEvent = storySnapshot.data();
      if (liveEvent) {
        SetCurrentLiveEvent(liveEvent);
      }
    }, (error) => {
      console.log(error);
    });
    return () => {
      if (liveEventRefUnsubscribe) {
        console.log('removing onSnapshot listener for LiveEvent');
        liveEventRefUnsubscribe();
      }
    };
  }, [liveEventId]);

  useInterval(async () => {
    try {
      if (visible && currentLiveEvent && currentLiveEvent.awsStreamChannelId && currentLiveEvent.awsStreamChannelId) {
        console.log('fetching aws stream info');
        const { data } = await axiosInstance.get<AWSStreamResponse>(`/awsStream/${currentLiveEvent.awsStreamChannelId}`);
        if (data.state !== awsStream?.state) {
          setAwsStream(data);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }, 1000);

  React.useEffect(() => {
    async function startPreShow() {
      try {
        if (currentLiveEvent && awsStream && awsStream.state === 'RUNNING') {
          const { title, description, seasonId } = currentLiveEvent;
          const liveStory: Pick<LiveStory, 'title' | 'description' | 'tags' | 'type' | 'status' | 'isDeleted' | 'isFree' | 'nodes' | 'actualStartTime' | 'startTime' | 'hlsStreamUrl'> = {
            title,
            description,
            tags: [],
            type: 'live',
            status: 'pre_show',
            hlsStreamUrl: awsStream.hlsStreamUrl,
            actualStartTime: moment().valueOf(),
            startTime: 0,
            isDeleted: false,
            isFree: false,
            nodes: {},
          };
          if (!currentLiveEvent.storyId) {
            const storyId = uuidv4();
            try {
              await dispatch(initializeLiveStory(seasonId, storyId, liveStory));
              await dispatch(startLiveEvent({ id: liveEventId, storyId, type: 'aws', hlsStreamUrl: awsStream.hlsStreamUrl }));
            } catch (error) {
              console.log('error attempting to initilize liveStory or start live event', error);
            }
          } else {
            console.log('story was already created', currentLiveEvent.storyId);
          }
        }
      } catch (error) {
        console.error('error in startPreShow function', error);
      }
    }
    startPreShow();
  }, [dispatch, liveEventId, awsStream, currentLiveEvent]);



  return (
    <Modal
      {...props}
    >
      {(!currentLiveEvent) ? <div>Loading...</div> :
        <div className={'aws-stream-container'}>
          {(!currentLiveEvent.awsStreamChannelId && !currentLiveEvent.awsStreamSessionId && awsStream === null) ?
            (
              (startStreamBtnLoading === 'idle') ? (
                <div
                  className={'start-aws-stream'}
                  onClick={async () => {
                    try {
                      setStartStreamBtnLoading('loading');
                      const { data } = await axiosInstance.post<AWSStreamResponse>('/awsStream', { liveEventId });
                      console.log('start stream success!', data);
                      setAwsStream(data);
                    } catch (error) {
                      console.log(error);
                    }
                    setStartStreamBtnLoading('idle');
                  }}
                >
                  Start Stream
                </div>
              ) : (
                <div className={'aws-stream-status'}>
                  Starting Stream...
                </div>
              )
            ) : (
              <div
                className={'aws-stream-status'}
              >
                {(!awsStream) ? 'Fetching Stream Status' : `Stream Status: ${awsStream.state}`}
              </div>
            )}
        </div>
      }
    </Modal>
  );
};

export default AWSLiveEventStarter;
