import React from 'react';
import {
  useSelector,
  useDispatch,
} from 'react-redux';
import {
  fetchTagDefinitions,
} from 'src/features/tagDefinitions/tagDefinitionsSlice';
import {
  fetchAppTagDefinitions,
} from 'src/features/appTagDefinitions/appTagDefinitionsSlice';
import moment from 'moment-timezone';
import {
  Button,
  Descriptions,
  Skeleton,
  Tag,
} from 'antd';
import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
} from '@ant-design/icons';
import {
  momentFormat,
  seasonStatusOptions,
  colorThemeOptions,
  // storyTypeOptions,
} from 'src/helpers';
/** type imports */
import type { RootState } from 'src/app/rootReducer';
import type { AppDispatch } from 'src/app/store';
import type {
  Season,
  antdColorOptions,
} from 'types';

interface Props {
  selectedSeason: null | {
    seasonId: string;
    season: Season;
  };
  fetchingSeason: boolean;
  setEditSeason: () => void;
}


const SeasonDetails: React.FC<Props> = ({ selectedSeason, fetchingSeason, setEditSeason }: Props) => {

  const dispatch = useDispatch<AppDispatch>();
  const {
    tagDefinitions,
    fetchingTagDefinitions,
  } = useSelector((state: RootState) => state.tagDefinitionsState);
  const {
    appTagDefinitions,
    fetchingAppTagDefinitions,
  } = useSelector((state: RootState) => state.appTagDefinitionsState);

  React.useEffect(() => {
    dispatch(fetchTagDefinitions());
    dispatch(fetchAppTagDefinitions());
  }, [dispatch]);

  if (selectedSeason === null || fetchingSeason || fetchingTagDefinitions || fetchingAppTagDefinitions) {
    return <Skeleton />;
  }

  const {
    seasonId,
    season: {
      title,
      subTitle,
      longDescription,
      status,
      primaryColor,
      secondaryColor,
      tags,
      appTags,
      coverImage,
      listImage,
      comingSoon,
      priority,
      createdAt,
      updatedAt,
    },
  } = selectedSeason;

  const monoStyle: React.CSSProperties = {
    fontFamily: 'monospace',
    fontWeight: 'bold',
  };


  let seasonStatusColor: antdColorOptions = 'green';
  switch (status) {
    case 'staged': {
      seasonStatusColor = 'processing';
      break;
    }
    case 'published': {
      seasonStatusColor = 'success';
      break;
    }
    case 'archived': {
      seasonStatusColor = 'error';
      break;
    }
  }

  return (
    <Descriptions
      bordered
      title='Season Details'
      extra={<Button type='primary' onClick={setEditSeason}>Edit Season</Button>}
      column={1}
    >
      <Descriptions.Item label='Season ID'><span style={monoStyle}>{seasonId}</span></Descriptions.Item>
      <Descriptions.Item label='Title'>{title}</Descriptions.Item>
      <Descriptions.Item label='Sub-Title'>{subTitle}</Descriptions.Item>
      <Descriptions.Item label='User Facing Description'>{longDescription}</Descriptions.Item>
      <Descriptions.Item label='Status'><Tag color={seasonStatusColor}>{seasonStatusOptions[status]}</Tag></Descriptions.Item>
      <Descriptions.Item label='Primary Color'><Tag color={primaryColor}>{colorThemeOptions[primaryColor]}</Tag></Descriptions.Item>
      <Descriptions.Item label='Secondary Color'><Tag color={secondaryColor}>{colorThemeOptions[secondaryColor]}</Tag></Descriptions.Item>
      <Descriptions.Item label='Tags'>{tags.map((tagId) => <Tag key={tagId}>{tagDefinitions[tagId]?.displayName}</Tag>)}</Descriptions.Item>
      <Descriptions.Item label='App Tags'>{appTags.map((tagId) => <Tag key={tagId}>{appTagDefinitions[tagId]?.displayName}</Tag>)}</Descriptions.Item>
      <Descriptions.Item label='Cover Image File'><span style={monoStyle}>{coverImage}</span></Descriptions.Item>
      <Descriptions.Item label='List Image File'><span style={monoStyle}>{listImage}</span></Descriptions.Item>
      <Descriptions.Item label='Coming Soon'>{(comingSoon)
        ? <CheckCircleTwoTone style={{ fontSize: '20px' }} twoToneColor='#52c41a' />
        : <CloseCircleTwoTone style={{ fontSize: '20px' }} twoToneColor='#a6a6a6' />}
      </Descriptions.Item>
      <Descriptions.Item label='Priority'>{priority}</Descriptions.Item>
      <Descriptions.Item label='Created At'>{moment(createdAt).format(momentFormat)}</Descriptions.Item>
      <Descriptions.Item label='Updated At'>{(updatedAt) ? moment(updatedAt).format(momentFormat) : 'N/A'}</Descriptions.Item>
    </Descriptions>
  );
};

export default SeasonDetails;