import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
// import { RootState } from 'src/app/rootReducer';
import type { AppDispatch } from 'src/app/store';
import { FirebaseAuthUser } from 'types';
import firebase from 'firebase/app';
import 'firebase/auth';
import {
  Route,
  Switch,
} from 'react-router-dom';
import type { StaticContext, RouteComponentProps } from 'react-router';
import { fetchUpsertUser } from 'src/features/authentication/userSlice';
import { LoginPage } from 'src/components/auth/Login';
import {
  Layout,
} from 'antd';
import { PrivateRoute } from 'src/components/auth/PrivateRoute';
import AccountDetails from 'src/components/account/AccountDetails';
import NodeDefinitions from 'src/components/nodes/NodeDefinitions';
import Users from 'src/components/admin/userManager/Users';
import TagDefinitions from 'src/components/tagDefinitions/TagDefinitions';
import TopBar from 'src/components/header/TopBar';
import BreadCrumbs from 'src/components/header/BreadCrumbs';
import NoMatch from 'src/components/auth/NoMatch';
import Dashboard from 'src/components/dashboard/Welcome';
import Seasons from 'src/components/seasons/Seasons';
import SeasonStories from 'src/components/stories/SeasonStories';
import StoryEditor from 'src/components/stories/StoryEditor';
import CharacterProfiles from 'src/components/characterProfiles/CharacterProfiles';
import ChipParts from 'src/components/chipParts/chipParts';
import LiveEventsSchedule from 'src/components/liveEvents/LiveEventsSchedule';
import UpcomingLiveEvents from 'src/components/liveEvents/UpcomingLiveEvents';
import LiveEventManager from 'src/components/liveEvents/LiveEventManager';
import YouTubeOAuth2Loader from 'src/components/liveEvents/LiveEventManager/YouTubeOAuth2Loader';
import UserIntegrations from 'src/components/userIntegrations';
import AppTagDefinitions from 'src/components/appTagDefinitions/AppTagDefinitions';



const firebaseConfig = {
  apiKey: "AIzaSyDTuzdkUh1GMA617HLyhxCs8OzrnudIM-Y",
  authDomain: "storytime-a93ed.firebaseapp.com",
  databaseURL: "https://storytime-a93ed.firebaseio.com",
  projectId: "storytime-a93ed",
  storageBucket: "storytime-a93ed.appspot.com",
  messagingSenderId: "641267299443",
  appId: "1:641267299443:web:45f24505a250347030a606",
  measurementId: "G-M46RLFEYGZ"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
// firebase.firestore().settings({host: 'localhost:8081', ssl: false}); // For connecting to firestore emulator


const { Header, Content } = Layout;

const App: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    firebase.auth().onAuthStateChanged(async (userRef) => {
      if (userRef) {
        // Need to cast because the 'toJSON()' function returns the type 'Object'
        const user = userRef.toJSON() as FirebaseAuthUser;
        console.log("user is signed in", user);
        dispatch(fetchUpsertUser(user));
      } else {
        // User is signed out.
        console.log("user is signed out");
        dispatch(fetchUpsertUser(null));
      }
    });
  }, [dispatch]);

  return (
    <React.Fragment>
      <Layout>
        <Header>
          <TopBar />
        </Header>
        <Content>
          <BreadCrumbs />
          <Switch>
            <Route path='/login'>
              <LoginPage />
            </Route>
            <Route exact path='/'>
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            </Route>
            <Route path='/settings' >
              <PrivateRoute>
                <AccountDetails />
              </PrivateRoute>
            </Route>
            <Route path='/user_manager' >
              <PrivateRoute authorizationRequired='users'>
                <Users />
              </PrivateRoute>
            </Route>
            <Route path='/seasons/:seasonId/stories/:storyId'>
              <PrivateRoute>
                <StoryEditor />
              </PrivateRoute>
            </Route>
            <Route path='/seasons/:seasonId'>
              <PrivateRoute>
                <SeasonStories />
              </PrivateRoute>
            </Route>
            <Route path={'/seasons'}>
              <PrivateRoute>
                <Seasons />
              </PrivateRoute>
            </Route>
            <Route path='/node_definitions'>
              <PrivateRoute authorizationRequired='node_definitions'>
                <NodeDefinitions />
              </PrivateRoute>
            </Route>
            <Route path='/tag_definitions'>
              <PrivateRoute authorizationRequired='tag_definitions'>
                <TagDefinitions />
              </PrivateRoute>
            </Route>
            <Route path='/app_tag_definitions'>
              <PrivateRoute authorizationRequired='tag_definitions'>
                <AppTagDefinitions />
              </PrivateRoute>
            </Route>
            <Route path='/character_profiles'>
              <PrivateRoute>
                <CharacterProfiles />
              </PrivateRoute>
            </Route>
            <Route path='/chip_parts'>
              <PrivateRoute>
                <ChipParts />
              </PrivateRoute>
            </Route>
            <Route path='/live_events/schedule'>
              <PrivateRoute>
                <LiveEventsSchedule />
              </PrivateRoute>
            </Route>
            <Route path='/live_events/upcoming'>
              <PrivateRoute>
                <UpcomingLiveEvents />
              </PrivateRoute>
            </Route>
            <Route path='/live_events/:liveEventId'
              render={(props: RouteComponentProps<{ liveEventId: string }, StaticContext, unknown>) => (
                <PrivateRoute>
                  <LiveEventManager liveEventId={props.match.params.liveEventId} />
                </PrivateRoute>
              )} />
            <Route path='/oauthcallback/youtube'>
              <PrivateRoute>
                <YouTubeOAuth2Loader />
              </PrivateRoute>
            </Route>
            <Route path='/user_integrations'>
              <PrivateRoute>
                <UserIntegrations />
              </PrivateRoute>
            </Route>
            <Route>
              <NoMatch />
            </Route>
          </Switch>
        </Content>
      </Layout>
    </React.Fragment>
  );
};

export default App;
