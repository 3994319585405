import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Form,
  Select,
  Divider,
  InputNumber,
} from 'antd';
// import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import {
  fetchFileReferences,
} from 'src/features/storage/storageSlice';
import {
  transformFiles,
  // particleEffectOptions,
} from 'src/helpers';
/** type imports */
import type { AppDispatch } from 'src/app/store';
import type { RootState } from 'src/app/rootReducer';
import type { FormInstance } from 'antd/es/form';
import type { FormikErrors } from 'formik';
import type {
  LiveImageOverlayNode,
  FormItemProps,
} from 'types';


interface Props {
  nodeDefinition: Partial<LiveImageOverlayNode>;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => any;
  handleChange: (eventOrPath: string | React.ChangeEvent<any>) => void | ((eventOrTextValue: string | React.ChangeEvent<any>) => void);
  handleBlur: (eventOrString: any) => void | ((e: any) => void);
  form: FormInstance;
  isSubmitting: boolean;
  errors: FormikErrors<{ id: string; nodeDefinition: Partial<LiveImageOverlayNode>; }>;
  setErrors: (errors: FormikErrors<{ id: string; nodeDefinition: Partial<LiveImageOverlayNode>; }>) => void;
}

const LiveImageOverlayNodeFormBody: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  // const {
  //   tagDefinitions,
  //   fetchingTagDefinitions,
  // } = useSelector((state: RootState) => state.tagDefinitionsState);

  const {
    fileReferences: {
      imageFiles,
      // videoFiles,
      // audioFiles,
    },
    fetchingFileReferences,
  } = useSelector((state: RootState) => state.storageState);

  React.useEffect(() => {
    dispatch(fetchFileReferences());
  }, [dispatch]);

  // const particleOptions = Object.entries(particleEffectOptions)
  //   .map(([particleKey, particleDisplay]) => ({ label: particleDisplay, value: particleKey }));

  const {
    nodeDefinition,
    // setFieldValue,
    // handleChange,
    // handleBlur,
    isSubmitting,
    errors,
    // form,
  } = props;

  const {
    imageOverlay,
    startTime,
    endTime,
  } = nodeDefinition;


  type QuestionFormErrors = {
    [K in keyof LiveImageOverlayNode]?: string;
  }

  // interface AnswerFormErrors {
  //   [answerDefKey: string]: string;
  // }

  function generateFormItemProps(itemName: keyof LiveImageOverlayNode): FormItemProps {
    const hasError = itemName in errors;
    return {
      name: ['nodeDefinition', itemName],
      validateStatus: hasError ? 'error' : '',
      hasFeedback: hasError,
      help: hasError ? (errors as QuestionFormErrors)[itemName] : false,
    };
  }

  // const audioOptions = transformFiles(audioFiles);
  const imageOptions = transformFiles(imageFiles);
  // const videoOptions = transformFiles(videoFiles);

  // const audioPlaceholder = 'audio/example.mp3';
  const imagePlaceholder = 'image/example.jpeg';
  // const videoPlaceholder = 'video/example.mp4';
  if (Object.keys(errors).length) {
    console.log(errors);
  }

  return (
    <React.Fragment>
      <Divider>Live Image Overlay Configuration</Divider>
      <Form.Item
        {...generateFormItemProps('imageOverlay')}
        label={'Image Overlay File'}
      >
        <Select
          allowClear
          showSearch
          options={imageOptions}
          disabled={isSubmitting}
          loading={fetchingFileReferences}
          value={imageOverlay}
          placeholder={imagePlaceholder}
        />
      </Form.Item>
      <Form.Item
        {...generateFormItemProps('startTime')}
        label={'Start Time (milliseconds)'}
      >
        <InputNumber
          disabled={isSubmitting}
          value={startTime}
        />
      </Form.Item>
      <Form.Item
        {...generateFormItemProps('endTime')}
        label={'End Time (milliseconds)'}
      >
        <InputNumber
          disabled={isSubmitting}
          value={endTime}
        />
      </Form.Item>
    </React.Fragment>
  );
};

export default LiveImageOverlayNodeFormBody;