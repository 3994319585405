import React from 'react';
import axios from 'axios';
import firebase from 'firebase';
import 'firebase/auth';

import type {
  AppCollection,
  LiveNode,
  AssetReference,
  NodeTypes,
  Node,
  LiveNodeTypes,
} from 'types';
import * as yup from 'yup';

export const greenEdgeColor: [number, number, number] = [82, 191, 111];
export const redEdgeColor: [number, number, number] = [191, 95, 82];
export const purpleEdgeColor: [number, number, number] = [106, 82, 191];
export const greyEdgeColor: [number, number, number] = [184, 184, 184];

export function generateFirestorePath(collectionName: AppCollection): string {
  const nodeEnv = process.env.NODE_ENV;
  if (nodeEnv === 'production') {
    return collectionName;
  }
  return 'testData/1/' + collectionName;
}

export function generateBackendUrl(): string {
  const nodeEnv = process.env.NODE_ENV;
  if (nodeEnv === 'production') {
    return 'https://storytime-a93ed.uc.r.appspot.com';
  }
  return 'http://localhost:8080';
}

export function overwriteMerge(destinationArray: [], sourceArray: [], options: any): [] {
  return sourceArray;
}

export function generateValidationFunc(assetType: 'audio' | 'video' | 'image'): yup.StringSchema<string | undefined> {
  return yup.string()
    .matches(new RegExp(`^${assetType}/`), {
      message: `Asset name must start with "${assetType}/"`,
      excludeEmptyString: true,
    });
}

export function transformFiles(assets: AssetReference[]): { text: string; value: string }[] {
  return assets.map(({ fullPath }) => ({ text: fullPath, value: fullPath }));
}


/**
 * Source of truth for what will populate forms
 * If you want to add more color themes add them here
 */
export const colorThemes = ['red', 'orange', 'yellow', 'green', 'blue', 'purple', 'brown'] as const;

export const colorThemeOptions: Record<typeof colorThemes[number], string> = {
  red: 'Red',
  orange: 'Orange',
  yellow: 'Yellow',
  green: 'Green',
  blue: 'Blue',
  purple: 'Purple',
  brown: 'Brown',
} as const;


export const authorizationOptions = [
  'users',
  'node_definitions',
  'story_definitions',
  'asset_manager',
  'tag_definitions',
  'seasons',
  'live_events',
  'character_profiles',
  'chip_parts',
  'configuration',
  'placeholder_asset_references',
] as const;

export const authorizationDefinitions: Record<typeof authorizationOptions[number], string> = {
  users: 'User Authorizations',
  node_definitions: 'Node Definitions',
  story_definitions: 'Story Definitions',
  asset_manager: 'Asset Manager',
  tag_definitions: 'Tag Definitions',
  seasons: 'Seasons',
  live_events: 'Live Events',
  character_profiles: 'Character Profiles',
  chip_parts: 'Chip Parts',
  configuration: 'Configuration',
  placeholder_asset_references: 'Placeholder Asset References',
} as const;

export const defaultPositions = [
  'top-left',
  'top-center',
  'top-right',
  'middle-left',
  'middle-center',
  'middle-right',
  'bottom-left',
  'bottom-center',
  'bottom-right',
] as const;

export const chipPartCategoryOptions = [
    'hat',
    'eyes',
    'shoes',
    'body',
    'shirt',
    'board',
    'overlay',
    'badge',
    'background',
    'pants',
    'unknown'
] as const;

export const nodeTypes: readonly NodeTypes[] = [
  'Video',
  'Live Action Multiple Choice Question',
  'Phone',
  'Bot multi-choice question',
  'Screen Cleaning',
  '3D Model',
  'Drawing',
  'ID Badge',
  'Pulley',
  'Selfie',
  'X-ray',
  'Points Display',
  'Travel',
] as const;

export const liveNodeTypes: readonly LiveNodeTypes[] = [
  'Live Poll Question',
  'Live True Or False Question',
  'Live Image Overlay',
  'Points Display',
] as const;




export const threeDModelIDs = ['planet', 'fishes', 'rolling_ball'] as const;

export const threeDModelOptions: Record<typeof threeDModelIDs[number], string> = {
  planet: 'Planet',
  fishes: 'Fishes',
  rolling_ball: 'Rolling Ball',
} as const;

/**
 * Source of truth for what will populate forms
 * if you want to add more levels, add them here
 */
export const difficultyLevels = ['easy', 'medium', 'hard', 'extrahard'] as const;

export const difficultyLevelOptions: Record<typeof difficultyLevels[number], string> = {
  easy: 'Easy',
  medium: 'Medium',
  hard: 'Hard',
  extrahard: 'Extra Hard',
} as const;


export const particleEffects = ['fire', 'foam', 'air', 'confetti'] as const;

export const particleEffectOptions: Record<typeof particleEffects[number], string> = {
  fire: 'Fire',
  foam: 'Foam',
  air: 'Air',
  confetti:  'Confetti',
} as const;


export const seasonStatus = ['staged', 'published', 'archived'] as const;

export const seasonStatusOptions: Record<typeof seasonStatus[number], string> = {
  staged: 'Staged',
  published: 'Published',
  archived: 'Archived',
} as const;

export const liveEventStatusKeys = ['scheduled', 'canceled', 'started', 'completed'] as const;

export const liveEventStatusOptions: Record<typeof liveEventStatusKeys[number], string> = {
  scheduled: 'Scheduled',
  canceled: 'Canceled',
  started: 'Started',
  completed: 'Completed',
} as const;

/** source of truth for story status options */
export const storyStatusKeys = ['staged', 'published', 'archived', 'preview', 'live', 'pre_show'] as const;

export const storyStatusOptions: Record<typeof storyStatusKeys[number], string> = {
  staged: 'Staged',
  published: 'Published',
  archived: 'Archived',
  preview: 'Preview',
  live: 'Live',
  pre_show: 'Pre-show',
} as const;


export const storyTypes = ['feature', 'mini', 'checkpoint', 'live'] as const;

export const storyTypeOptions: Record<typeof storyTypes[number], string> = {
  feature: 'Feature',
  mini: 'Mini',
  checkpoint: 'Checkpoint',
  live: 'Live',
} as const;


export const liveStoryTypes = ['activity', 'badge', 'build_a_blank', 'drawing', 'mission', 'narrative', 'trivia', 'sos', 'field_trip', 'guess_who', 'mini_mystery'] as const;
export const liveStoryTypeOptions: Record<typeof liveStoryTypes[number], string> = {
  activity: 'Activity',
  badge: 'Badge',
  build_a_blank: 'Build a blank',
  drawing: 'Drawing',
  mission: 'Mission',
  narrative: 'Narrative',
  trivia: 'Trivia',
  sos: 'SOS',
  field_trip: 'Field Trip',
  guess_who: 'Guess Who',
  mini_mystery: 'Mini Mystery',
} as const;


export const momentFormat = 'M/D/YY h:mm A';

export function formatMiliseconds(miliseconds: number):string {
  const total_seconds = Math.floor(miliseconds / 1000);
  const total_minutes = Math.floor(total_seconds / 60);
  const total_hours = Math.floor(total_minutes / 60);
  const seconds = total_seconds % 60;
  const minutes = total_minutes % 60;
  const hours = total_hours % 24;
  return hours + 'h' + minutes + 'm' + seconds+ 's';
}

const urlBld = (assetName: string): string => `https://firebasestorage.googleapis.com/v0/b/storytime-a93ed.appspot.com/o/builder-static-assets%2F${assetName}?alt=media`;

export const nodeTypeIconOptions: Record<NodeTypes | LiveNodeTypes, string> = {
  'Video': urlBld('VIDEO_NODE_4.svg'),
  'Bot multi-choice question': urlBld('BOT_MULTI_CHOICE_QUESTION_NODE.svg'),
  'Phone': urlBld('PHONE_NODE.svg'),
  'Live Action Multiple Choice Question': urlBld('LIVE_ACTION_MULTI_CHOICE_QUESTION_NODE.svg'),
  'Pulley': urlBld('PULLEY_NODE_3.svg'),
  'Selfie': urlBld('SELFIE_NODE.svg'),
  'Drawing': urlBld('DRAWING_NODE_4.svg'),
  'Screen Cleaning': urlBld('SCREEN_CLEANING_NODE_1.svg'),
  'ID Badge': urlBld('ID_BADGE_2.svg'),
  'Badge Earning': urlBld('ID_BADGE_2.svg'),
  '3D Model': urlBld('3D_MODEL_NODE.svg'),
  'X-ray': urlBld('X_RAY_2.svg'),
  'Points Display': urlBld('POINTS_DISPLAY_NODE.svg'),
  'Travel': urlBld('TRAVEL_NODE.svg'),
  'Builder Image Assembler': urlBld('BUILDER_IMAGE_ASSEMBLER_NODE.svg'),
  'Live Image Overlay': urlBld('LIVE_IMAGE_OVERLAY_NODE.svg'),
  'Live Poll Question': urlBld('LIVE_POLL_QUESTION_NODE.svg'),
  'Live True Or False Question': urlBld('LIVE_TRUE_OR_FALSE_QUESTION_NODE.svg'),
  'Live Builder Image Selector': urlBld('LIVE_BUILDER_IMAGE_SELECTOR_NODE.svg'),
} as const;


interface Dimensions {
  height: number;
  width: number;
}
/** react hook to use the dimensions of the window */
export function useWindowDimensions(): Dimensions {

  const [dimensions, setDimensions] = React.useState<Dimensions>({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  React.useEffect(() => {
    function handleResize(): void {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
    }

    window.addEventListener('resize', handleResize);
    return (): void => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return dimensions;
}


interface UserResponseOption {
  value: string;
  id: string;
  label: string;
  title: string;
  disabled: boolean;
  isCorrect?: boolean;
}

export function getDeletedStoryNodeIds(nodes: { [nodeId: string]: Node | LiveNode }): string[] {
  return Object.entries(nodes)
    .filter(([, { isDeleted }]) => isDeleted)
    .map(([id]) => id);
}
/**
 * 
 * @param node node to get options from
 * @param deletedStoryNodeIds array of ids from deleted nodes on story
 * @returns array of "UserResponseOption"s that have already filtered out deleted options
 * and indicates that the option is being used on the node via the "disabled" field
 */
export function getOptions(node: Partial<Node | LiveNode>, deletedStoryNodeIds: string[]): UserResponseOption[] | null {
  const selectedOptions = Object.values(node.edges || {})
    .filter(({ targetNodeId }) => !deletedStoryNodeIds.includes(targetNodeId))
    .map(({ context }) => { return context?.optionIds ?? []; }).flat();
  switch (node.type) {
    case 'Live Action Multiple Choice Question': {
      return Object.entries(node.lamcAnswers || {})
        .filter(([, { isDeleted }]) => !isDeleted)
        .map(([id, { answer, isCorrect }]) => ({
          value: id,
          id: id,
          label: answer,
          title: answer,
          disabled: selectedOptions.includes(id),
          isCorrect,
        }));
    }
    case 'Bot multi-choice question': {
      return Object.entries(node.answerDefinitions || {})
        .filter(([, { isDeleted }]) => !isDeleted)
        .map(([id, { answer, isCorrect }]) => ({
          value: id,
          id: id,
          label: answer,
          title: answer,
          disabled: selectedOptions.includes(id),
          isCorrect,
        }));
    }
    case 'Screen Cleaning': {
      return Object.entries(node.screenCleanerOptions || {})
        .filter(([, { isDeleted }]) => !isDeleted)
        .map(([id, { displayName, isCorrect }]) => ({
          value: id,
          id,
          label: displayName,
          title: displayName,
          disabled: selectedOptions.includes(id),
          isCorrect,
        }));
    }
    default:
      return null;
  }
}

export const firebaseIdTokenKey = 'firebase-id-token';
/** create axios instance for backend */
const axiosInstanceTemp = axios.create({
  baseURL: generateBackendUrl(),
});

axiosInstanceTemp.interceptors.request.use(async (config) => {
  const user = firebase.auth().currentUser;
  if (!user) {
    throw new Error('Unauthenticated');
  }
  const firebaseIdToken = await user.getIdToken();
  /** add firebase token to each request */
  config.headers[firebaseIdTokenKey] = firebaseIdToken;
  return config;
});

export const axiosInstance = axiosInstanceTemp;




