import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// import moment from 'moment';
import { FirebaseAuthUser, User, FirestoreUser, milliseconds } from 'types';
import { generateFirestorePath } from 'src/helpers';
import { fireStoreUserConverter } from './helpers';
import { AsyncAppThunk } from 'src/app/store';
import firebase from 'firebase';
import 'firebase/firestore';
import dayjs from 'dayjs';

const { firestore } = firebase;
interface AuthState {
  user: User | null;
  fetchingUser: boolean;
}

const initialState: AuthState = {
  user: null,
  fetchingUser: true,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<User | null>): void {
      state.fetchingUser = false;
      state.user = action.payload;
    },
  },
});

export const {
  setUser,
} = userSlice.actions;

function unixProcessor(strMilliSec: string): { seconds: number; nanoseconds: number } {
  const milliSec = parseInt(strMilliSec);
  const seconds = Math.floor(milliSec / 1000);
  const nanoseconds = (milliSec - (seconds * 1000)) * 1000000;
  return { seconds, nanoseconds } as const;
}

export function fetchUpsertUser(rawUser: FirebaseAuthUser | null): AsyncAppThunk<void> {
  return async function thunk(dispatch): Promise<void> {
    try {
      let user: User | null = null;
      if (rawUser) {
        const usersColRef = firestore()
          .collection(generateFirestorePath('adminUsers'))
          .withConverter(fireStoreUserConverter);
        const userRef = usersColRef.doc(rawUser.uid);
        const userSnapshot = await userRef.get();
        const currUserData = userSnapshot.data();
        if (userSnapshot.exists && currUserData !== undefined) {
          console.log('user already exists');
          let wasUpdated = false;

          // const createdAtObj = unixProcessor(rawUser.createdAt);
          // currUserData.createdAt = (new firestore.Timestamp(createdAtObj.seconds, createdAtObj.nanoseconds)).toMillis();
          const { seconds, nanoseconds } = unixProcessor(rawUser.lastLoginAt);
          const newLastLoginAt = (new firestore.Timestamp(seconds, nanoseconds)).toMillis();
          if (currUserData.lastLoginAt !== newLastLoginAt) {
            console.log('updating lastLoginAt');
            currUserData.lastLoginAt = newLastLoginAt;
          }

          if (rawUser.phoneNumber && rawUser.phoneNumber !== currUserData.phoneNumber) {
            console.log('updating phoneNumber');
            currUserData.phoneNumber = rawUser.phoneNumber;
            wasUpdated = true;
          }

          if (rawUser.photoURL && rawUser.photoURL !== currUserData.photoURL) {
            console.log('updating photoURL');
            currUserData.photoURL = rawUser.photoURL;
            wasUpdated = true;
          }

          if (wasUpdated) {
            currUserData.updatedAt = dayjs().valueOf();
          }

          await userRef.set(currUserData);

          user = {
            uid: rawUser.uid,
            ...currUserData,
          };

        } else {
          let newLastLoginAt: milliseconds = dayjs().valueOf();
          let newCreatedAt: milliseconds = dayjs().valueOf();
          if (rawUser.lastLoginAt) {
            console.log('parsing lastLoginAt for new user', rawUser.lastLoginAt);
            const { seconds, nanoseconds } = unixProcessor(rawUser.lastLoginAt);
            newLastLoginAt = (new firestore.Timestamp(seconds, nanoseconds)).toMillis();
          }

          if (rawUser.createdAt) {
            console.log('parsing createdAt for new user', rawUser.createdAt);
            const { seconds, nanoseconds } = unixProcessor(rawUser.lastLoginAt);
            newCreatedAt = (new firestore.Timestamp(seconds, nanoseconds)).toMillis();
          }

          const newUserData: FirestoreUser = {
            ...rawUser,
            createdAt: newCreatedAt,
            lastLoginAt: newLastLoginAt,
            authorizations: [],
            newlyCreated: true,
          };
          await userRef.set(newUserData);
          
          user = {
            ...rawUser,
            createdAt: newCreatedAt,
            lastLoginAt: newLastLoginAt,
            authorizations: [],
            newlyCreated: true,
          };
        }
      }

      dispatch(setUser(user));
    } catch (err) {
      console.log('Error in fetchUpsertUser():', err);
      dispatch(setUser(null));
    }
  };
}

export default userSlice.reducer;