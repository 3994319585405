import moment from 'moment';
import firebase from 'firebase';
import * as yup from 'yup';
import type {
  SnapshotOptions,
  TagDefinitionCore,
  TagDefinitionSnapshot,
  TagDefinition,
} from 'types';
import { generateValidationFunc } from 'src/helpers';

const { firestore } = firebase;
interface TagDefinitionData extends TagDefinitionCore {
  createdAt: firebase.firestore.Timestamp;
  updatedAt?: firebase.firestore.Timestamp;
}

export const tagDefinitionConverter: firebase.firestore.FirestoreDataConverter<TagDefinition> = {
  toFirestore(tagDefinition: TagDefinition): TagDefinitionData {
    const data: TagDefinitionData = {
      ...tagDefinition,
      createdAt: firestore.Timestamp.fromMillis(tagDefinition.createdAt),
      updatedAt: (tagDefinition.updatedAt !== undefined) ? firestore.Timestamp.fromMillis(tagDefinition.updatedAt) : firestore.Timestamp.now(),
    };
    return data;
  },
  fromFirestore(snapshot: TagDefinitionSnapshot, options: SnapshotOptions): TagDefinition {
    const data = snapshot.data(options);
    if (data.appTags === undefined) {
      data.appTags = [];
    }
    const tagDefintion: TagDefinition = {
      ...data,
      createdAt: data.createdAt.toMillis(),
      updatedAt: (data.updatedAt) ? data.updatedAt.toMillis() : firestore.Timestamp.now().toMillis(),
    };
    return tagDefintion;
  },
};

export const tagDefinitionSchema: yup.ObjectSchema<TagDefinition> = yup.object().required().shape<TagDefinition>({
  displayName: yup.string().required().label('Display Name'),
  emoji: yup.string().required().label('Emoji'),
  coverImage: generateValidationFunc('image').required().label('Cover Image'),
  appTags: yup.array<string>().required().label('App Tags'),
  isUserFacing: yup.boolean().required().label('Is not user facing'),
  createdAt: yup.number().required().default(() => moment().valueOf()),
  updatedAt: yup.number().when('$editMode', (editMode: boolean | undefined, schema: yup.NumberSchema<number | undefined>) => {
    if (editMode) {
      return schema
        .default(() => moment().valueOf())
        .transform(() => moment().valueOf());
    } else {
      return schema.transform(() => moment().valueOf());
    }
  }),
});