import React from 'react';
import moment from 'moment-timezone';
import {
  Tag,
} from 'antd';
import {
  fetchSeasons,
} from 'src/features/seasons/seasonsSlice';
import {
  fetchLiveEvents,
} from 'src/features/liveEvents/liveEventsSlice';
import {
  useSelector,
  useDispatch,
} from 'react-redux';
import {
  useHistory,
} from 'react-router-dom';
import './index.css';
import { liveEventStatusOptions } from 'src/helpers';
/** type imports */
import type { LiveEvent, milliseconds, antdColorOptions } from 'types';
import type { RootState } from 'src/app/rootReducer';
import type { AppDispatch } from 'src/app/store';

interface TableDatum extends LiveEvent {
  id: string;
}

const UpcomingLiveEvents: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();
  const {
    liveEvents,
  } = useSelector((state: RootState) => state.liveEventsState);
  const {
    seasons,
  } = useSelector((state: RootState) => state.seasonsState);
  React.useEffect(() => {
    if (liveEvents === null) {
      dispatch(fetchLiveEvents());
    }
    if (Object.keys(seasons).length === 0) {
      dispatch(fetchSeasons());
    }
  }, [dispatch, liveEvents, seasons]);

  const tableData: TableDatum[] = Object.entries(liveEvents || {}).map(([id, liveEvent]) => {
    const datum: TableDatum = {
      id,
      ...liveEvent
    };
    return datum;
  }).filter(({ status }) => status === 'scheduled' || status === 'started')
    .sort(({ eventDatetime: A }, { eventDatetime: B }) => A - B);

  const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const EventDatetimeDisplay: React.FC<{ eventDatetime: milliseconds }> = (props: { eventDatetime: milliseconds }) => {
    const { eventDatetime } = props;
    const date = moment(eventDatetime).tz(currentTimeZone);
    const now = moment();


    const style: React.CSSProperties = {};
    let dateText = date.format('L');
    const isToday = date.format('L') === now.format('L');
    if (isToday) {
      style.color = '#12c349';
      style.fontWeight = 'bold';
      dateText = 'Today, ' + date.format('h:mm A zz');
    } else {
      dateText = date.format('L, h:mm A zz');
    }

    if (now.isAfter(date)) {
      style.color = 'red';
    }
    const relativeTimeText = date.fromNow();
    return (
      <div
        className={'upcoming-live-event-datetime'}
        style={style}
      >
        <div>{dateText}</div>
        <div>({relativeTimeText})</div>
      </div>
    );
  };

  return (
    <div className={'upcoming-live-events-container'}>
      {tableData.map((datum) => {
        const {
          id,
          title,
          description,
          status,
          eventDatetime,
          seasonId,
        } = datum;
        let statusColor: antdColorOptions = 'green';
        switch (status) {
          case 'completed': {
            statusColor = 'success';
            break;
          }
          case 'scheduled': {
            statusColor = 'processing';
            break;
          }
          case 'canceled': {
            statusColor = 'error';
            break;
          }
          case 'started': {
            statusColor = 'orange';
            break;
          }
        }

        return (
          <div
            key={id}
            className={'upcoming-live-event'}
            onClick={() => history.push('/live_events/' + id)}
          >
            <div className={'live-event-header'}>
              <div className={'upcoming-live-event-title'}>
                {title}
              </div>
              <div><EventDatetimeDisplay eventDatetime={eventDatetime} /></div>
            </div>
            <div className='upcoming-live-event-status-season'>
              <Tag color={statusColor}>{liveEventStatusOptions[status]}</Tag>
              <Tag color={'geekblue'}>{seasons[seasonId]?.title}</Tag>
            </div>
            <div className='upcoming-live-event-description'>{description}</div>
          </div>
        );
      })}
    </div>
  );
};

export default UpcomingLiveEvents;
