import React from 'react';
import {
  useSelector,
  useDispatch,
} from 'react-redux';
import {
  fetchTagDefinitions,
} from 'src/features/tagDefinitions/tagDefinitionsSlice';
import dayjs from 'dayjs';
import {
  Button,
  Descriptions,
  Skeleton,
  Tag,
} from 'antd';
import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
} from '@ant-design/icons';
import {
  momentFormat,
  formatMiliseconds,
  storyTypeOptions,
} from 'src/helpers';
/** type imports */
import type { RootState } from 'src/app/rootReducer';
import type { AppDispatch } from 'src/app/store';
import type {
  Story,
  antdColorOptions,
} from 'types';
import { fetchAppTagDefinitions } from 'src/features/appTagDefinitions/appTagDefinitionsSlice';

interface Props {
  selectedStory: null | {
    seasonId: string;
    storyId: string;
    story: Story;
  };
  fetchingStory: boolean;
  setEditStory: () => void;
}


const SeasonDetails: React.FC<Props> = ({ selectedStory, fetchingStory, setEditStory }: Props) => {

  const dispatch = useDispatch<AppDispatch>();
  const {
    tagDefinitions,
    fetchingTagDefinitions,
  } = useSelector((state: RootState) => state.tagDefinitionsState);

  const {
    appTagDefinitions,
    fetchingAppTagDefinitions,
  } = useSelector((state: RootState) => state.appTagDefinitionsState);

  React.useEffect(() => {
    dispatch(fetchTagDefinitions());
    dispatch(fetchAppTagDefinitions());
  }, [dispatch]);

  if (selectedStory === null || fetchingStory || fetchingTagDefinitions || fetchingAppTagDefinitions) {
    return <Skeleton />;
  }

  const {
    storyId,
    story: {
      title,
      description,
      searchKeywords,
      isFree,
      type,
      tags,
      appTags,
      coverImage,
      createdAt,
      updatedAt,
      minsToComplete,
      classKitTitle,
      characterProfileId,
      discussionQuestions,
    },
  } = selectedStory;

  const monoStyle: React.CSSProperties = {
    fontFamily: 'monospace',
    fontWeight: 'bold',
  };

  let storyTypeColor: antdColorOptions = 'green';
  switch (type) {
    case 'feature': {
      storyTypeColor = 'geekblue';
      break;
    }
    case 'mini': {
      storyTypeColor = 'magenta';
      break;
    }
    case 'checkpoint': {
      storyTypeColor = 'purple';
      break;
    }
  }

  // const isFreeFilters = [
  //   { value: true, text: <CheckCircleTwoTone style={{ fontSize: '20px' }} twoToneColor='#52c41a' /> },
  //   { value: false, text: <CloseCircleTwoTone style={{ fontSize: '20px' }} twoToneColor='#a6a6a6' /> }
  // ];
  let isFreeComp = <CloseCircleTwoTone style={{ fontSize: '20px' }} twoToneColor='#a6a6a6' />;
  if (isFree) {
    isFreeComp = <CheckCircleTwoTone style={{ fontSize: '20px' }} twoToneColor='#52c41a' />;
  }
  let startTime = 0;
  if ('startTime' in selectedStory.story) {
    startTime = selectedStory.story.startTime;
  }

  return (
    <Descriptions
      style={{
        background: 'white',
        padding: 20,
      }}
      bordered
      title='Story Details'
      extra={<Button type='primary' onClick={setEditStory}>Edit Story</Button>}
      column={9}
      layout='vertical'
    >
      {/** first row */}
      <Descriptions.Item span={9} label='Story ID'><span style={monoStyle}>{storyId}</span></Descriptions.Item>
      {/** 2 row */}
      <Descriptions.Item span={6} label='Title'>{title}</Descriptions.Item>
      <Descriptions.Item span={1} label='Type'><Tag color={storyTypeColor}>{storyTypeOptions[type]}</Tag></Descriptions.Item>
      <Descriptions.Item span={2} label='Tags'>{tags.map((tagId) => <Tag key={tagId}>{tagDefinitions[tagId].displayName}</Tag>)}</Descriptions.Item>
      {/** 3 row */}
      <Descriptions.Item span={2} label='Is Free'>{isFreeComp}</Descriptions.Item>
      <Descriptions.Item span={7} label='Description'>{description}</Descriptions.Item>
      <Descriptions.Item span={7} label='Searchable Keywords'>{searchKeywords}</Descriptions.Item>
      {/** 4 row */}
      <Descriptions.Item span={4} label='Character/Performer Profile'>{characterProfileId}</Descriptions.Item>
      <Descriptions.Item span={5} label='Cover Image File'><span style={monoStyle}>{coverImage}</span></Descriptions.Item>
      {/** 5 row */}
      <Descriptions.Item span={2} label='Created At'>{dayjs(createdAt).format(momentFormat)}</Descriptions.Item>
      <Descriptions.Item span={2} label='Updated At'>{(updatedAt) ? dayjs(updatedAt).format(momentFormat) : 'N/A'}</Descriptions.Item>
      <Descriptions.Item span={2} label='Started After'>{(startTime) ? formatMiliseconds(startTime) : 'N/A'}</Descriptions.Item>
      <Descriptions.Item span={3} label='Minutes to Complete'>{(minsToComplete)}</Descriptions.Item>
      {/** 6 row */}
      <Descriptions.Item span={6} label='ClassKit Title'>{classKitTitle}</Descriptions.Item>
      <Descriptions.Item span={3} label='App Tags'>{appTags.map((tagId) => <Tag key={tagId}>{appTagDefinitions[tagId].displayName}</Tag>)}</Descriptions.Item>
      <Descriptions.Item span={9} label='Discussion Questions'>{(discussionQuestions) ? discussionQuestions.join(' | '): ''}</Descriptions.Item>
    </Descriptions>
  );
};

export default SeasonDetails;