import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchAppTagDefinitions
} from 'src/features/appTagDefinitions/appTagDefinitionsSlice';
import AppTagDefinitionFormContainer from './AppTagDefinitionForm';
import moment from 'moment';
import {
  Button,
  Table,
  Drawer,
  Row,
  Col,
} from 'antd';
import { momentFormat } from 'src/helpers';
/** import types */
import type { RootState } from 'src/app/rootReducer';
import type { AppDispatch } from 'src/app/store';
import type { ColumnType } from 'antd/es/table';
import type { milliseconds, AppTagDefinition } from 'types';

interface TableDatum {
  id: string;
  displayName: string;
  createdAt: milliseconds;
  updatedAt: milliseconds | undefined;
}

const AppTagDefinitions: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [selectedAppTagDefinitionId, selectAppTagDefinitionId] = React.useState<string | null>(null);
  const {
    appTagDefinitions,
    fetchingAppTagDefinitions,
  } = useSelector((state: RootState) => state.appTagDefinitionsState);


  React.useEffect(() => {
    dispatch(fetchAppTagDefinitions());
  }, [dispatch]);

  const tableData: TableDatum[] = Object.entries(appTagDefinitions).map(([id, { displayName, createdAt, updatedAt }]) => {

    return {
      id,
      displayName,
      createdAt,
      updatedAt,
    };
  });


  const renderId: React.FC<string | undefined> = (id: string | undefined) => {
    return (
      <span
        style={{
          fontFamily: 'monospace',
          fontWeight: 'bold',
        }}>
        {id}
      </span>
    );
  };

  const tableColumns: ColumnType<TableDatum>[] = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 250,
      render: renderId,
    },
    {
      title: 'Display Name',
      dataIndex: 'displayName',
      key: 'displayName',
      width: 250,
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 200,
      sorter: (a, b) => a.createdAt - b.createdAt,
      render: (createdAt: milliseconds) => moment(createdAt).format(momentFormat),
    },
    {
      title: 'Updated At',
      dataIndex: 'updatedAt',
      width: 200,
      key: 'updatedAt',
      sorter: ({ updatedAt: updatedAtA }, { updatedAt: updatedAtB }) => {
        if (updatedAtA !== undefined && updatedAtB !== undefined) {
          return updatedAtA - updatedAtB;
        } else if (updatedAtA !== undefined) {
          return 1;
        } else if (updatedAtB !== undefined) {
          return -1;
        }
        return 0;
      },
      render: (updatedAt: milliseconds | undefined) => (updatedAt) ? moment(updatedAt).format(momentFormat) : 'N/A',
    }
  ];

  let appTagFormParams: null | {
    editMode: boolean;
    appTagFormState: {
      id: string;
      appTagDefinition: Partial<AppTagDefinition>;
    };
  } = null;
  if (selectedAppTagDefinitionId !== null) {
    if (selectedAppTagDefinitionId === '') {
      appTagFormParams = {
        editMode: false,
        appTagFormState: {
          id: '',
          appTagDefinition: {
            displayName: '',
          },
        },
      };
    } else if (appTagDefinitions[selectedAppTagDefinitionId]) {
      appTagFormParams = {
        editMode: true,
        appTagFormState: {
          id: selectedAppTagDefinitionId,
          appTagDefinition: appTagDefinitions[selectedAppTagDefinitionId],
        }
      };
    } else {
      console.log('selected tag definition id does not correspond to a tag');
    }
  }
  return (
    <React.Fragment>
      <Row
        justify='center'
        gutter={[16, 16]}
        style={{
          padding: '1em',
          margin: '1em',
          backgroundColor: 'white',
        }}
      >
        <Col xs={23} sm={23} md={23} lg={22} xl={20}>
          <Table
            pagination={{
              total: tableData.length,
              defaultPageSize: 100,
              showTotal: (total) => `${total} Total App Tag Definitions`,
            }}
            bordered
            title={() => {
              return (<Button
                size='large'
                type='primary'
                onClick={() => selectAppTagDefinitionId('')}
              >
                New App Tag Definition
              </Button>);
            }}
            loading={fetchingAppTagDefinitions}
            dataSource={tableData}
            columns={tableColumns}
            rowKey={({ id }) => id}
            onRow={(record) => {
              return {
                onClick: () => selectAppTagDefinitionId(record.id), // click row
                style: { cursor: 'pointer' },
              };
            }}
          />
        </Col>
      </Row>
      <Drawer
        width={600}
        placement={'right'}
        visible={appTagFormParams !== null}
        closable={false}
      >
        {(appTagFormParams !== null) && <AppTagDefinitionFormContainer
          {...appTagFormParams}
          onCancel={() => selectAppTagDefinitionId(null)}
        />}
      </Drawer>
    </React.Fragment >
  );
};

export default AppTagDefinitions;