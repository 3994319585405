import { createSlice } from '@reduxjs/toolkit';
import firebase from 'firebase';
import 'firebase/firestore';
import { generateFirestorePath } from 'src/helpers';
import * as yup from 'yup';
import { storyConverter, publishStorySchema } from './helpers';
import {
  generateNodeDefinitionSchema,
  edgeConfigurationSchema,
  generateEdgesSchema,
} from '../nodes/helpers';

import type { AsyncAppThunk } from 'src/app/store';
import type {
  Story,
  LiveStory,
  Node,
  EdgeConfiguration,
  Edge,
  LiveNode,
} from 'types';
import type { PayloadAction } from '@reduxjs/toolkit';

const { firestore } = firebase;
interface SuccessReturn {
  success: true;
  validationError: null;
}
interface FailureReturn {
  success: false;
  validationError: yup.ValidationError;
}

type ThunkReturn = SuccessReturn | FailureReturn;

interface StoryState {
  selectedStory: { seasonId: string; storyId: string; story: Story | LiveStory } | null;
  fetchingStory: boolean;
}

const initialState: StoryState = {
  selectedStory: null,
  fetchingStory: true,
};

const storySlice = createSlice({
  name: 'story',
  initialState,
  reducers: {
    setStory(state, action: PayloadAction<{ seasonId: string; storyId: string; story: Story }>): void {
      state.selectedStory = action.payload;
      state.fetchingStory = false;
    },
    setToLoading(state): void {
      state.fetchingStory = true;
    },
  },
});

const {
  setStory,
  // setToLoading,
} = storySlice.actions;

// thunk creator
export function fetchStory(seasonId: string, storyId: string): AsyncAppThunk<void> {
  return async function thunk(dispatch, getState): Promise<void> {
    try {
      const user = getState().userState.user;
      if (!user) {
        throw new Error('Not authenticated');
      }
      // dispatch(setToLoading());
      const storySnapshot = await firestore()
        .collection(generateFirestorePath(`seasons/${seasonId}/stories`))
        .doc(storyId)
        .withConverter(storyConverter)
        .get();

      if (!storySnapshot.exists) {
        throw new Error('No story with that ID');
      }
      const storyData = storySnapshot.data();
      if (!storyData) {
        throw new Error('No story data with that ID');
      }


      dispatch(setStory({ seasonId, storyId, story: storyData }));
    } catch (error) {
      console.log('Error in fetchStory():', error);
    }
  };
}



export function addNodeToStory(newNodeId: string, newNode: Partial<Node | LiveNode>, edgeConfiguration: EdgeConfiguration | null): AsyncAppThunk<ThunkReturn> {
  /** 'getState' is used to get access to the current selected season and story */
  return async function thunk(dispatch, getState): Promise<ThunkReturn> {
    try {
      const user = getState().userState.user;
      if (!user) {
        throw new Error('Not authenticated');
      }
      const {
        storyState: { selectedStory },
      } = getState();
      if (!selectedStory) {
        throw new Error("Story hasn't been selected yet");
      }
      const {
        seasonId,
        storyId,
        story,
      } = selectedStory;

      if (newNodeId === '') {
        throw new yup.ValidationError('The node ID cannot be the empty string', 'The node ID cannot be the empty string', '');
      }

      if (newNodeId in story.nodes) {
        throw new yup.ValidationError('Node with that ID already exists', 'Node with that ID already exists', '');
      }

      let newEdgePath: firebase.firestore.FieldPath | null = null;
      let edge: Edge | null = null;
      if (edgeConfiguration) {

        const {
          parentNodeId,
          edge: validatedEdge,
          edgeId,
        } = await edgeConfigurationSchema.validate(edgeConfiguration, {
          // stripUnknown: true,
          context: {
            nodeType: edgeConfiguration.parentNode.type,
            editMode: false,
          },
        });


        if (edgeId === '') {
          throw new yup.ValidationError('The edge ID cannot be the empty string', 'The edge ID cannot be the empty string', '');
        }
        if (edgeId in story.nodes[parentNodeId].edges) {
          throw new yup.ValidationError('An edge with that ID already exists', 'An edge with that ID already exists', '');
        }

        newEdgePath = new firestore.FieldPath('nodes', parentNodeId, 'edges', edgeId);

        edge = validatedEdge;

      }


      const storyRef = firestore()
        .collection(generateFirestorePath(`seasons/${seasonId}/stories`))
        .withConverter(storyConverter)
        .doc(storyId);

      newNode.createdBy = user.uid;
      const nodeSchema = generateNodeDefinitionSchema(newNode);
      const node = await nodeSchema.validate(newNode, {
        stripUnknown: true,
        context: {
          editMode: false,
        },
      });

      const batch = firestore().batch();
      /**
       * Must use FieldPath to avoid erasing 
       * other nodes on the property 'nodes'
       */
      const newNodePath = new firestore.FieldPath('nodes', newNodeId);

      /**
       * batching updates to simplify logic
       */
      batch.update(storyRef, newNodePath, node);

      if (edge !== null && newEdgePath !== null) {
        batch.update(storyRef, newEdgePath, edge);
      }

      const alreadyPublished = story.status === 'published';
      if (alreadyPublished) {
        batch.update(storyRef, 'changesSincePublished', true);
      }

      batch.update(storyRef, 'updatedAt', firestore.Timestamp.now());

      await batch.commit();

      await dispatch(fetchStory(seasonId, storyId));
      return {
        success: true,
        validationError: null,
      };
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        return {
          success: false,
          validationError: error,
        };
      } else {
        console.log('Error in addStory():', error);
        throw error;
      }
    }
  };
}

export function updateStoryNode(nodeId: string, updatedNode: Partial<Node | LiveNode>): AsyncAppThunk<ThunkReturn> {
  /** 'getState' is used to get access to the current selected season and story */
  return async function thunk(dispatch, getState): Promise<ThunkReturn> {
    try {
      const user = getState().userState.user;
      if (!user) {
        throw new Error('Not authenticated');
      }
      const { storyState: { selectedStory } } = getState();

      if (!selectedStory) {
        throw new Error("Story hasn't been selected yet");
      }
      const {
        seasonId,
        storyId,
        story,
      } = selectedStory;

      if (!(nodeId in story.nodes)) {
        throw new yup.ValidationError('Node with that ID does not exist', 'Node with that ID does not exist', '');
      }

      const storyRef = firestore()
        .collection(generateFirestorePath(`seasons/${seasonId}/stories`))
        .withConverter(storyConverter)
        .doc(storyId);

      const nodeSchema = generateNodeDefinitionSchema(updatedNode);
      const node = await nodeSchema.validate(updatedNode, {
        stripUnknown: true,
        context: {
          editMode: true,
          nodeType: updatedNode.type,
        },
      });

      const nodePath = new firestore.FieldPath('nodes', nodeId);

      const alreadyPublished = story.status === 'published';

      if (alreadyPublished) {
        await storyRef.update(
          nodePath, node,
          'changesSincePublished', true,
          'updatedAt', firestore.Timestamp.now());
      } else {
        await storyRef.update(
          nodePath, node,
          'updatedAt', firestore.Timestamp.now());
      }

      await dispatch(fetchStory(seasonId, storyId));
      return {
        success: true,
        validationError: null,
      };
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        return {
          success: false,
          validationError: error,
        };
      } else {
        console.log('Error in updateStoryNode():', error);
        throw error;
      }
    }
  };
}

interface UpdatedEdges {
  [edgedId: string]: Partial<Edge>
}

export function updateStoryNodeEdges(nodeId: string, edges: UpdatedEdges): AsyncAppThunk<ThunkReturn> {
  /** 'getState' is used to get access to the current selected season and story */
  return async function thunk(dispatch, getState): Promise<ThunkReturn> {
    try {
      const user = getState().userState.user;
      if (!user) {
        throw new Error('Not authenticated');
      }
      const { storyState: { selectedStory } } = getState();

      if (!selectedStory) {
        throw new Error("Story hasn't been selected yet");
      }
      const {
        seasonId,
        storyId,
        story,
      } = selectedStory;

      if (!(nodeId in story.nodes)) {
        throw new yup.ValidationError('Node with that ID does not exist', 'Node with that ID does not exist', '');
      }
      const node = story.nodes[nodeId];

      if (node.type !== 'Screen Cleaning' && node.type !== 'Live Action Multiple Choice Question' && node.type === 'Bot multi-choice question') {
        throw new yup.ValidationError(`You can't configure edges for a node of type ${node.type}`, `You can't configure edges for a node of type ${node.type}`, '');
      }
      const edgesSchema = generateEdgesSchema(node);

      const updatedEdges = await edgesSchema.validate(edges);

      const storyRef = firestore()
        .collection(generateFirestorePath(`seasons/${seasonId}/stories`))
        .withConverter(storyConverter)
        .doc(storyId);

      const edgesPath = new firestore.FieldPath('nodes', nodeId, 'edges');

      const alreadyPublished = story.status === 'published';

      if (alreadyPublished) {
        await storyRef.update(
          edgesPath, updatedEdges,
          'changesSincePublished', true,
          'updatedAt', firestore.Timestamp.now());
      } else {
        await storyRef.update(
          edgesPath, updatedEdges,
          'updatedAt', firestore.Timestamp.now());
      }

      await dispatch(fetchStory(seasonId, storyId));
      return {
        success: true,
        validationError: null,
      };
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        return {
          success: false,
          validationError: error,
        };
      } else {
        console.log('Error in updateStoryNodeEdges():', error);
        throw error;
      }
    }
  };
}

export function deleteStoryNode(nodeId: string): AsyncAppThunk<ThunkReturn> {
  /** 'getState' is used to get access to the current selected season and story */
  return async function thunk(dispatch, getState): Promise<ThunkReturn> {
    try {
      const user = getState().userState.user;
      if (!user) {
        throw new Error('Not authenticated');
      }
      const { storyState: { selectedStory } } = getState();

      if (!selectedStory) {
        throw new Error("Story hasn't been selected yet");
      }
      const {
        seasonId,
        storyId,
        story,
      } = selectedStory;

      const node = story.nodes[nodeId];

      if (node === undefined) {
        throw new yup.ValidationError('Node with that ID does not exist', 'Node with that ID does not exist', '');
      }

      const storyRef = firestore()
        .collection(generateFirestorePath(`seasons/${seasonId}/stories`))
        .withConverter(storyConverter)
        .doc(storyId);

      const nodeDeletePath = new firestore.FieldPath('nodes', nodeId, 'isDeleted');

      const alreadyPublished = story.status === 'published';

      if (alreadyPublished) {
        await storyRef.update(
          nodeDeletePath, !node.isDeleted,
          'changesSincePublished', true,
          'updatedAt', firestore.Timestamp.now());
      } else {
        await storyRef.update(
          nodeDeletePath, !node.isDeleted,
          'updatedAt', firestore.Timestamp.now());
      }

      await dispatch(fetchStory(seasonId, storyId));
      return {
        success: true,
        validationError: null,
      };
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        return {
          success: false,
          validationError: error,
        };
      } else {
        console.log('Error in updateStoryNode():', error);
        throw error;
      }
    }
  };
}

export function publishStory(): AsyncAppThunk<ThunkReturn> {
  /** 'getState' is used to get access to the current selected season and story */
  return async function thunk(dispatch, getState): Promise<ThunkReturn> {
    try {
      const user = getState().userState.user;
      if (!user) {
        throw new Error('Not authenticated');
      }
      const { storyState: { selectedStory } } = getState();

      if (!selectedStory) {
        throw new Error("Story hasn't been selected yet");
      }
      const {
        seasonId,
        storyId,
        story,
      } = selectedStory;

      const storyRef = firestore()
        .collection(generateFirestorePath(`seasons/${seasonId}/stories`))
        .withConverter(storyConverter)
        .doc(storyId);

      await publishStorySchema.validate(story);

      await storyRef.update(
        'status', 'published',
        'changesSincePublished', false,
        'updatedAt', firestore.Timestamp.now());

      await dispatch(fetchStory(seasonId, storyId));

      return {
        success: true,
        validationError: null,
      };
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        return {
          success: false,
          validationError: error,
        };
      } else {
        console.log('Error in publishStory():', error);
        throw error;
      }
    }
  };
}

export function updateStoryStatus(status: 'staged' | 'archived' | 'preview'): AsyncAppThunk<ThunkReturn> {
  /** 'getState' is used to get access to the current selected season and story */
  return async function thunk(dispatch, getState): Promise<ThunkReturn> {
    try {
      const user = getState().userState.user;
      if (!user) {
        throw new Error('Not authenticated');
      }
      const { storyState: { selectedStory } } = getState();

      if (!selectedStory) {
        throw new Error("Story hasn't been selected yet");
      }
      const {
        seasonId,
        storyId,
        // story,
      } = selectedStory;

      const storyRef = firestore()
        .collection(generateFirestorePath(`seasons/${seasonId}/stories`))
        .withConverter(storyConverter)
        .doc(storyId);


      await storyRef.update(
        'status', status,
        'changesSincePublished', firestore.FieldValue.delete(),
        'updatedAt', firestore.Timestamp.now());

      await dispatch(fetchStory(seasonId, storyId));

      return {
        success: true,
        validationError: null,
      };
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        return {
          success: false,
          validationError: error,
        };
      } else {
        console.log('Error in updateStoryStatus():', error);
        throw error;
      }
    }
  };
}


export default storySlice.reducer;