import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AsyncAppThunk } from 'src/app/store';
import * as yup from 'yup';
import {
  tagDefinitionConverter,
  tagDefinitionSchema,
} from './helpers';
import firebase from 'firebase';
import { generateFirestorePath } from 'src/helpers';
import 'firebase/firestore';
import 'firebase/auth';
/** type imports */
import type { TagDefinition } from 'types';

const { firestore, auth } = firebase;
interface SuccessReturn {
  success: true;
  validationError: null;
}
interface FailureReturn {
  success: false;
  validationError: yup.ValidationError;
}

type ThunkReturn = SuccessReturn | FailureReturn;

interface TagDefinitions { [tagDefinitionId: string]: TagDefinition }

interface TagDefinitionsState {
  tagDefinitions: { [tagDefinitionId: string]: TagDefinition };
  fetchingTagDefinitions: boolean;
}

const initialState: TagDefinitionsState = {
  tagDefinitions: {},
  fetchingTagDefinitions: true,
};

const tagDefinitionsSlice = createSlice({
  name: 'tagDefinitions',
  initialState,
  reducers: {
    setTagDefinitions(state, action: PayloadAction<TagDefinitions>): void {
      state.tagDefinitions = action.payload;
      state.fetchingTagDefinitions = false;
    },
  },
});

export const {
  setTagDefinitions,
} = tagDefinitionsSlice.actions;

// thunk creator
export function fetchTagDefinitions(): AsyncAppThunk<void> {
  return async function thunk(dispatch): Promise<void> {
    try {
      const user = auth().currentUser;
      if (!user) {
        throw new Error('Not authenticated');
      }
      const tagDefinitions: TagDefinitions = {};

      const tagDefColSnapshot = await firestore()
        .collection(generateFirestorePath('tagDefinitions'))
        .withConverter(tagDefinitionConverter)
        .orderBy('createdAt', 'desc')
        .get();

      tagDefColSnapshot.forEach((tagDefinition) => {
        if (tagDefinition.exists) {
          tagDefinitions[tagDefinition.id] = tagDefinition.data();
        }
      });

      dispatch(setTagDefinitions(tagDefinitions));
    } catch (error) {
      console.log('Error in fetchTagDefinitions():', error);
    }
  };
}

export function addTagDefinition(id: string, newTagDef: Partial<TagDefinition>): AsyncAppThunk<ThunkReturn> {
  return async function thunk(dispatch): Promise<ThunkReturn> {
    try {
      const user = auth().currentUser;
      if (!user) {
        throw new Error('Not authenticated');
      }

      const newTag = await tagDefinitionSchema.validate(newTagDef, {
        stripUnknown: true,
        context: {
          editMode: false,
        },
      });

      const newTagDefRef = firestore()
        .collection(generateFirestorePath('tagDefinitions'))
        .withConverter(tagDefinitionConverter)
        .doc(id);

      const tagDef = await newTagDefRef.get();

      if (tagDef.exists) {
        throw new yup.ValidationError('Tag already exists', 'Tag Already exists', 'id');
      }

      await newTagDefRef.set(newTag);

      await dispatch(fetchTagDefinitions());

      return {
        success: true,
        validationError: null,
      };
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        return {
          success: false,
          validationError: error,
        };
      } else {
        console.log('Error in addTagDefinition():', error);
        throw error;
      }
    }
  };
}

export function updateTagDefinition(id: string, newTagDef: Partial<TagDefinition>): AsyncAppThunk<ThunkReturn> {
  return async function thunk(dispatch): Promise<ThunkReturn> {
    try {
      const user = auth().currentUser;
      if (!user) {
        throw new Error('Not authenticated');
      }

      const updatedTag = await tagDefinitionSchema.validate(newTagDef, {
        stripUnknown: true,
        context: {
          editMode: true,
        }
      });

      const tagDefRef = firestore()
        .collection(generateFirestorePath('tagDefinitions'))
        .withConverter(tagDefinitionConverter)
        .doc(id);

      const tagDef = await tagDefRef.get();

      if (!tagDef.exists) {
        throw new yup.ValidationError('Tag does not exists', 'Does not exists', '');
      }

      await tagDefRef.set(updatedTag);

      await dispatch(fetchTagDefinitions());

      return {
        success: true,
        validationError: null,
      };
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        return {
          success: false,
          validationError: error,
        };
      } else {
        console.log('Error in updateTagDefinition():', error);
        throw error;
      }
    }
  };
}

export default tagDefinitionsSlice.reducer;