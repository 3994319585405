import React from 'react';
import {
  nodeTypeIconOptions,
  greenEdgeColor,
  redEdgeColor,
  greyEdgeColor,
  purpleEdgeColor,
} from 'src/helpers';
import { G6Event as G6EventTypes } from '@antv/g6/lib/types';
import { v4 as uuidv4 } from 'uuid';
import Graphin from '@antv/graphin';
import {
  Button,
  Alert,
  Dropdown,
  Menu,
} from 'antd';
import { DownOutlined } from '@ant-design/icons';
/** type imports */
import type { Node, Edge, LiveNode } from 'types';
import type { RectResult } from './helpers';

import type {
  Data,
  Node as GraphinNode,
  Edge as GraphinEdge,
  G6Event,
} from '@antv/graphin';


interface UserInputConfig {
  nodeId: string;
  remainingOptionIds: string[];
  node: GraphinNode;
  edges: GraphinEdge[];
}

function generateStartingNode(nodeId: string): GraphinNode {
  return {
    id: uuidv4(),
    label: 'Add First Node',
    shape: 'circle',
    labelCfg: {
      style: {
        cursor: 'pointer',
      },
      position: 'bottom',
      offset: 10,
    },
    size: 50,
    style: {
      cursor: 'pointer',
      fill: '#92e092',
      stroke: '#92e092',
    },
    icon: {
      show: true,
      cursor: 'pointer',
      img: 'https://firebasestorage.googleapis.com/v0/b/storytime-a93ed.appspot.com/o/builder-static-assets%2Fplus-24.png?alt=media',
      width: 20,
      height: 20,
      primaryColor: '#57c955',
    },
    startingNode: true,
    newNode: false,
    placeholderNodeId: nodeId,
    data: {
      id: nodeId,
    },
  };
}

function generateEdge(edgeId: string, sourceNodeId: string, targetNodeId: string, context?: { label?: string, tappityEdge?: Edge, color: [number, number, number] }): GraphinEdge {
  const edge: GraphinEdge = {
    id: edgeId,
    source: sourceNodeId,
    target: targetNodeId,
    // tappityEdge: edge,
    style: {
      line: {
        width: 5,
        color: context?.color || greyEdgeColor,
      },
    },
    data: {
      id: edgeId,
    },
  };

  const tappityEdge = context?.tappityEdge;
  const label = context?.label;

  if (tappityEdge) {
    edge.tappityEdge = tappityEdge;
  }

  if (label) {
    edge.label = label;
  }

  return edge;
}

function generatePlusNode(nodeId: string, parentNodeId: string, parentNode: Node | LiveNode): GraphinNode {
  return {
    id: nodeId,
    label: 'Add Node',
    shape: 'circle',
    labelCfg: {
      style: {
        cursor: 'pointer',
      },
      position: 'bottom',
      offset: 10,
    },
    size: 50,
    style: {
      cursor: 'pointer',
      fill: '#92e092',
      stroke: '#92e092',
    },
    icon: {
      show: true,
      cursor: 'pointer',
      img: 'https://firebasestorage.googleapis.com/v0/b/storytime-a93ed.appspot.com/o/builder-static-assets%2Fplus-24.png?alt=media',
      width: 20,
      height: 20,
      primaryColor: '#57c955',
    },
    newNode: true,
    parentNodeId: parentNodeId,
    tappityNode: parentNode,
    displayOrder: parentNode.displayOrder,
    data: {
      id: nodeId,

    },
  };
}

function generateUserInputNode(parentNodeId: string, nodes: { [nodeId: string]: Node | LiveNode }): UserInputConfig {
  const inputNodeId = uuidv4();
  const parentNode = nodes[parentNodeId];
  let remainingOptionIds: string[] = [];
  const optionIsCorrectMappings: { [optionId: string]: { label: string; isCorrect: boolean | undefined } } = {};
  switch (parentNode.type) {
    case 'Live Action Multiple Choice Question': {
      Object.entries(parentNode.lamcAnswers)
        .filter(([, { isDeleted }]) => !isDeleted)
        .forEach(([optionId, { answer, isCorrect }]) => {
          remainingOptionIds.push(optionId);
          optionIsCorrectMappings[optionId] = { label: answer, isCorrect };
        });
      break;
    }
    case 'Bot multi-choice question': {
      Object.entries(parentNode.answerDefinitions)
        .filter(([, { isDeleted }]) => !isDeleted)
        .forEach(([optionId, { answer, isCorrect }]) => {
          remainingOptionIds.push(optionId);
          optionIsCorrectMappings[optionId] = { label: answer, isCorrect };
        });
      break;
    }
    case 'Screen Cleaning': {
      Object.entries(parentNode.screenCleanerOptions)
        .filter(([, { isDeleted }]) => !isDeleted)
        .forEach(([optionId, { displayName, isCorrect }]) => {
          remainingOptionIds.push(optionId);
          optionIsCorrectMappings[optionId] = { label: displayName, isCorrect };
        });
      break;
    }
    default: {
      throw new Error('this node does not user input options');
    }
  }
  const edges: GraphinEdge[] = [];
  Object.entries(parentNode.edges)
    .filter(([edgeId, edge]) => !nodes[edge.targetNodeId]?.isDeleted)
    .forEach(([edgeId, edge]) => {
      const { targetNodeId, context } = edge;
      if (context) {
        const { optionIds } = context;
        if (optionIds) {
          remainingOptionIds = remainingOptionIds.filter((id) => !optionIds.includes(id));
          let color = greyEdgeColor;
          let label;
          let consistentIsCorrectValue = true;
          optionIds.forEach((optionId, index, array) => {
            if (index > 0) {
              const prevOptionId = array[index - 1];
              label = '** multiple choices **';
              if (optionIsCorrectMappings[optionId].isCorrect !== optionIsCorrectMappings[prevOptionId].isCorrect) {
                consistentIsCorrectValue = false;

              }
            } else {
              const { label: innerLabel, isCorrect } = optionIsCorrectMappings[optionId];
              label = innerLabel;
              if (isCorrect !== undefined) {
                if (isCorrect) {
                  color = greenEdgeColor;
                } else {
                  color = redEdgeColor;
                }
              }
            }
          });

          if (!consistentIsCorrectValue) {
            color = purpleEdgeColor;
          }

          edges.push(generateEdge(edgeId, inputNodeId, targetNodeId, { color, label }));

        } else {
          edges.push(generateEdge(edgeId, inputNodeId, targetNodeId));
        }
      } else {
        edges.push(generateEdge(edgeId, inputNodeId, targetNodeId));
      }
    });



  const label = 'User Response';
  const userResponseNode: GraphinNode = {
    id: inputNodeId,
    shape: 'diamond',
    label,
    // description: rootNode.description,
    // descriptionCfg: {
    //   style: {
    //     cursor: 'pointer',
    //     fontSize: 14,
    //     fill: '#757575',
    //   },
    // },
    size: [110, 110],
    style: {
      cursor: 'pointer',
      fill: '#d7f7fc',
      // stroke: '#d7f7fc',
    },
    labelCfg: {
      style: {
        fontSize: 12,
        cursor: 'pointer',
        fill: '#404040',
      },
    },
    // stateIcon: {
    //   // whether to show the icon
    //   show: false,
    //   cursor: 'pointer',
    //   x: 0,
    //   y: 0,
    //   // img: nodeIcon,
    //   width: 40,
    //   height: 40,
    //   // adjust hte offset along x-axis for the icon
    //   offset: -20,
    // },
    // tappityNode: rootNode,
    editNode: false,
    editUserResponse: true,
    tappityNode: parentNode,
    parentNodeId: parentNodeId,
    displayOrder: -1,
    data: {
      id: inputNodeId,
      label,
    },
  };
  return {
    nodeId: inputNodeId,
    remainingOptionIds,
    node: userResponseNode,
    edges,
  };
}

function convertGraphData(nodes: { [nodeId: string]: Node | LiveNode }, rootId: string, selectedNodeId: string | null, errorNodeIds: string[]): Data {
  const graphData: Data = {
    nodes: [],
    edges: [],
  };

  if (Object.keys(nodes).length === 0) {
    graphData.nodes.push(generateStartingNode(rootId));
    return graphData;
  }

  const rootNode = nodes[rootId];
  const nodeIcon = nodeTypeIconOptions[rootNode.type];
  let nodeFillColor = 'white';
  if (rootNode.isEndingNode) {
    nodeFillColor = '#d9ffe3';
  }
  if (rootId === selectedNodeId) {
    nodeFillColor = '#fff8e3';
  }

  if (errorNodeIds.includes(rootId)) {
    nodeFillColor = '#ffe3e3';
  }
  const node: GraphinNode = {
    id: rootId,
    label: rootNode.displayName + " " + rootNode.displayOrder,
    description: rootNode.description,
    descriptionCfg: {
      style: {
        cursor: 'pointer',
        fontSize: 14,
        fill: '#757575',
      },
    },
    preRect: {
      fill: '#40a9ff',
    },
    style: {
      cursor: 'pointer',
      fill: nodeFillColor,
    },
    labelCfg: {
      style: {
        fontSize: 14,
        cursor: 'pointer',
        fill: '#404040',
      },
    },
    stateIcon: {
      // whether to show the icon
      show: true,
      cursor: 'pointer',
      x: 0,
      y: 0,
      img: nodeIcon,
      width: 40,
      height: 40,
      // adjust hte offset along x-axis for the icon
      offset: -20,
    },
    displayOrder: rootNode.displayOrder,
    tappityNode: rootNode,
    editNode: true,
    data: {
      id: rootId,
      label: rootNode.displayName,
    },
  };

  graphData.nodes.push(node);

  if (rootNode.isEndingNode) {
    return graphData;
  }

  /** this is the critical filtering and sorting step */
  const tappityRootEdgesEntries = Object.entries(rootNode.edges).filter(([edgeId, edge]) => {
    const childNode = nodes[edge.targetNodeId];
    if (!childNode) {
      return false;
    }
    return !childNode.isDeleted;
  }).sort(([edgeAId, edgeA], [edgeBId, edgeB]) => {
    return edgeA.displayOrder - edgeB.displayOrder;
  });

  if (rootNode.type === 'Live Action Multiple Choice Question' || rootNode.type === 'Bot multi-choice question' || rootNode.type === 'Screen Cleaning') {
    const {
      nodeId: userResponseNodeId,
      node: userResponseNode,
      remainingOptionIds,
      edges: outUserResponseEdges,
    } = generateUserInputNode(rootId, nodes);
    const inUserResponseEdge = generateEdge(uuidv4(), rootId, userResponseNodeId);
    let branchingNodes = [userResponseNode];
    const branchingEdges = [inUserResponseEdge];
    let plusNode: GraphinNode | null = null;
    if (remainingOptionIds.length > 0) {
      const plusNodeId = uuidv4();
      const plusEdge = generateEdge(uuidv4(), userResponseNodeId, plusNodeId);
      plusNode = generatePlusNode(plusNodeId, rootId, rootNode);
      branchingEdges.push(plusEdge);
    }

    branchingEdges.push(...outUserResponseEdges);
    const childrenData: Data[] = tappityRootEdgesEntries.map(([edgeId, edge]) => convertGraphData(nodes, edge.targetNodeId, selectedNodeId, errorNodeIds));

    childrenData.forEach(({ nodes, edges }) => {
      branchingNodes.push(...nodes);
      branchingEdges.push(...edges);
    });

    if (plusNode) {
      const length = branchingNodes.length;
      const index = Math.floor(length / 2);
      /** this is used to center the plus node */
      branchingNodes = [...branchingNodes.slice(0, index), plusNode, ...branchingNodes.slice(index)];
    }
    /** populated the return value */
    graphData.nodes.push(...branchingNodes);
    graphData.edges.push(...branchingEdges);
    return graphData;
  } else {
    /** this is a branch to add the final plus node and return */
    if (tappityRootEdgesEntries.length === 0) {
      const extraNodeId = uuidv4();
      const extraEdgeId = uuidv4();
      const extraNode = generatePlusNode(extraNodeId, rootId, rootNode);
      const extraEdge = generateEdge(extraEdgeId, rootId, extraNodeId);
      graphData.nodes.push(extraNode);
      graphData.edges.push(extraEdge);
      return graphData;
    }
    /** generate root eges */
    const rootEdges: GraphinEdge[] = tappityRootEdgesEntries.map(([edgeId, edge]) => generateEdge(edgeId, rootId, edge.targetNodeId));
    graphData.edges.push(...rootEdges);

    /** generate children data */
    const childrenData: Data[] = tappityRootEdgesEntries.map(([edgeId, edge]) => convertGraphData(nodes, edge.targetNodeId, selectedNodeId, errorNodeIds));

    childrenData.forEach(({ nodes, edges }) => {
      graphData.nodes.push(...nodes);
      graphData.edges.push(...edges);
    });
    return graphData;
  }
}

// function calculateGraphDimensions(nodes: { [nodeId: string]: Node }, rootId: string): { rowCount: number; columnCount: number } {
//   const rootNode = nodes[rootId];
//   const dimensions = { rowCount: 0, columnCount: 0 };
//   if (rootNode) {
//     dimensions.rowCount = 1;
//     dimensions.columnCount = Object.keys(rootNode.edges).length || 1;
//     const edgesArray = Object.values(rootNode.edges);
//     let maxChildrenRows = 0;
//     let maxChildrenColumns = edgesArray.length;
//     edgesArray.forEach((edge) => {
//       const childDimensions = calculateGraphDimensions(nodes, edge.targetNodeId);
//       if (maxChildrenRows < childDimensions.rowCount) {
//         maxChildrenRows = childDimensions.rowCount;
//       }

//       if (maxChildrenColumns < childDimensions.columnCount) {
//         maxChildrenColumns = childDimensions.columnCount;
//       }

//     });
//     dimensions.rowCount = dimensions.rowCount + maxChildrenRows;
//     dimensions.columnCount = maxChildrenColumns;
//   }
//   return dimensions;
// }

interface Props {
  nodes: { [nodeId: string]: Node | LiveNode };
  rootNodeId: string;
  status: string;
  selectedNodeId: string | null;
  changesSincePublished: boolean | undefined;
  publishStory: () => Promise<void>;
  editNode: (nodeId: string) => void;
  addNewNode: (sourceNodeId: string) => void;
  editUserResponseConfig: (parentNodeId: string) => void;
  updateStoryStatus: (newStatus: 'archived' | 'staged' | 'preview') => Promise<void>;
  errorNodeIds: string[];
  containerRect: RectResult;
}

const StoryGraph: React.FC<Props> = ({ status, changesSincePublished, nodes, rootNodeId, editNode, addNewNode, selectedNodeId, editUserResponseConfig, errorNodeIds, publishStory, updateStoryStatus, containerRect }: Props) => {
  const graphRef = React.useRef<Graphin>(null);


  const handleNodeClick = React.useCallback(
    (event: G6Event) => {
      const graph = graphRef.current?.graph;
      if (graph) {
        const target = event.item;
        const model = target._cfg?.model;
        if (model) {
          const editNodeFlag = model.editNode as boolean | undefined;
          const newNodeFlag = model.newNode as boolean | undefined;
          const editUserResponseFlag = model.editUserResponse as boolean | undefined;
          const startingNodeFlag = model.startingNode as boolean | undefined;

          if (startingNodeFlag) {
            const placeholderNodeId = model.placeholderNodeId as string | undefined;
            if (placeholderNodeId) {
              addNewNode(placeholderNodeId);
            }
          } else if (editNodeFlag) {
            const nodeId = target.getID();
            editNode(nodeId);
          } else if (newNodeFlag) {
            const sourceNodeId = model.parentNodeId as string | undefined;
            if (sourceNodeId) {
              addNewNode(sourceNodeId);
            } else {
              console.log('sourceNodeId not set');
            }
          } else if (editUserResponseFlag) {
            const parentNodeId = model.parentNodeId as string | undefined;
            if (parentNodeId) {
              editUserResponseConfig(parentNodeId);
            } else {
              console.log('parentNodeId not set');
            }

          }
        }
      }
    },
    [addNewNode, editNode, editUserResponseConfig],
  );

  React.useEffect(() => {

    const graph = graphRef.current?.graph;

    // const handleEdgeClick = (event: G6Event) => {
    //   if (graph) {
    //     const target = event.item;
    //     const model = target._cfg?.model;
    //     console.log(target);
    //     console.log(model);
    //     // if (model) {
    //     //   const nodeId = target.getID();
    //     //   const editNodeFlag = model.editNode as boolean | undefined;
    //     //   const newNodeFlag = model.newNode as boolean | undefined;
    //     //   if (editNodeFlag) {
    //     //     editNode(nodeId);
    //     //   } else if (newNodeFlag) {
    //     //     const sourceNodeId = model.parentNodeId as string | undefined;
    //     //     if (sourceNodeId) {
    //     //       addNewNode(sourceNodeId);
    //     //     } else {
    //     //       console.log('sourceNodeId not set');
    //     //     }
    //     //   }
    //     // }
    //   }
    // };

    if (graph) {
      graph.on(G6EventTypes.NODE_CLICK, handleNodeClick);
      // graph.on(G6EventTypes.EDGE_CLICK, handleEdgeClick);
    }
    return () => {
      if (graph) {
        graph.off(G6EventTypes.NODE_CLICK, handleNodeClick);
        // graph.off(G6EventTypes.EDGE_CLICK, handleEdgeClick);
      }
    };
  }, [handleNodeClick]);




  const data: Data = convertGraphData(nodes, rootNodeId, selectedNodeId, errorNodeIds);

  // const { rowCount, columnCount } = calculateGraphDimensions(nodes, rootNodeId);
  // console.log(rowCount, columnCount);

  const graphLayout = {
    name: 'dagre',
    options: {
      align: 'DL',
      rankdir: 'TB', // The center of the graph by default
      nodesep: 100, // make this half the width. It's the only way to center the graph!
      ranksep: 30,
    },
  };
  const nodeWidth = 300;
  const nodeHeight = 90;
  const width = 1500;
  const height = 1000;
  const Alerts: React.FC = () => {
    const alertStyle: React.CSSProperties = {
      position: 'absolute',
      width: 400,
      bottom: '10px',
      marginLeft: 'auto',
      marginRight: 'auto',
      left: 0,
      right: 0,
      textAlign: 'center',
    };
    return (
      <React.Fragment>
        <div
          style={alertStyle}
        >
          {(errorNodeIds.map((errorNodeId, index) => {
            return (
              <React.Fragment key={index}>
                {index !== 0 ? <br /> : undefined}
                <Alert
                  showIcon
                  type="error"
                  message={`The node "${nodes[errorNodeId].displayName}" is dangling`}
                />
              </React.Fragment>
            );
          }))}
        </div>
      </React.Fragment>
    );
  };
  const buttonWidth = 150;
  let publishButtonStyle: React.CSSProperties = {
    display: 'flex',
    position: 'absolute',
    top: '20px',
    left: '20px',
    width: buttonWidth,
  };
  const stageButtonStyle: React.CSSProperties = {
    display: 'flex',
    position: 'absolute',
    top: '70px',
    left: '20px',
    width: buttonWidth,
  };
  const archiveButtonStyle: React.CSSProperties = {
    display: 'flex',
    position: 'absolute',
    top: '120px',
    left: '20px',
    width: buttonWidth,
    alignContent: 'center',
  };
  switch (status) {
    case 'published': {
      if (changesSincePublished) {
        publishButtonStyle = {
          ...publishButtonStyle,
          background: '#52c41a',
          borderColor: '#52c41a',
        };
      }
      break;
    }
    case 'staged': {
      publishButtonStyle = {
        ...publishButtonStyle,
        background: '#52c41a',
        borderColor: '#52c41a',
      };
      break;
    }
    case 'archived': {
      publishButtonStyle = {
        ...publishButtonStyle,
        background: '#52c41a',
        borderColor: '#52c41a',
      };
      break;
    }
  }
  return (
    <Graphin
      ref={graphRef}
      data={data}
      options={{
        disableDrag: true,
        fitCenter: true,
        height: height,
        width: width,
        modes: {
          default: [
            {
              type: 'click-select',
              multiple: false,
            }
          ],
        },
        zoom: 1,
        pan: {
          x: (width / 2) - (nodeWidth / 2),
          y: 40,
        },
        defaultNode: {
          labelCfg: {
            style: {
              cursor: 'pointer',
            }
          },
          style: {
            cursor: 'pointer',
          },
          shape: 'modelRect',
          logoIcon: {
            show: false,
          },
          size: [nodeWidth, nodeHeight],
          anchorPoints: [
            [0.5, 0],
            [0.5, 1],
          ],
        },
      }}
      layout={graphLayout}
    >
      <Button
        size='large'
        type='primary'
        disabled={changesSincePublished === false}
        onClick={publishStory}
        style={publishButtonStyle}
      >
        {(changesSincePublished) ? 'Re-Publish' : 'Publish'}
      </Button>
      <Dropdown overlay={
        <Menu selectedKeys={[status]} onClick={(info) => {
          const newStatus = info.key.toString() as 'staged' | 'preview';
          console.log(newStatus);
          updateStoryStatus(newStatus);
        }}>
          <Menu.Item disabled={status === 'staged'} key={'staged'}>Staged</Menu.Item>
          <Menu.Item isSelected={status === 'preview'} disabled={status === 'preview'} key={'preview'}>Preview</Menu.Item>
        </Menu>}>
        <Button
          size='large'
          style={stageButtonStyle}
        >
          Update Status <DownOutlined />
        </Button>
      </Dropdown>
      <Button
        size='large'
        type='primary'
        danger
        disabled={status === 'archived'}
        onClick={() => updateStoryStatus('archived')}
        style={archiveButtonStyle}
      >
        Archive
      </Button>
      <Alerts />
    </Graphin>
  );

};

export default StoryGraph;