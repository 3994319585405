import React, { useRef } from 'react';
import { useDrag, useDrop, DropTargetMonitor } from 'react-dnd';
import { ItemTypes } from './ItemTypes';
import { XYCoord } from 'dnd-core';
import { Descriptions, Tag } from 'antd';
import { storyTypeOptions, storyStatusOptions } from 'src/helpers';
/** type imports */
import type { Story, antdColorOptions } from 'types';

const style = {
  border: '1px dashed gray',
  padding: '0.5rem 1rem',
  marginBottom: '.5rem',
  backgroundColor: 'white',
  cursor: 'move',
};

export interface CardProps {
  id: string;
  story: Story,
  index: number
  moveCard: (dragIndex: number, hoverIndex: number) => void
}

interface DragItem {
  index: number
  id: string
  type: string
}
export const Card: React.FC<CardProps> = ({ id, index, moveCard, story }: CardProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [, drop] = useDrop({
    accept: ItemTypes.CARD,
    hover(item: DragItem, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    item: { type: ItemTypes.CARD, id, index },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
    type: 'Card',
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  const { title, order, status, type } = story;
  let storyTypeColor: antdColorOptions = 'green';
  switch (type) {
    case 'feature': {
      storyTypeColor = 'geekblue';
      break;
    }
    case 'mini': {
      storyTypeColor = 'magenta';
      break;
    }
    case 'checkpoint': {
      storyTypeColor = 'purple';
      break;
    }
  }

  let storyStatusColor: antdColorOptions = 'green';
  switch (status) {
    case 'staged': {
      storyStatusColor = 'processing';
      break;
    }
    case 'published': {
      storyStatusColor = 'success';
      break;
    }
    case 'archived': {
      storyStatusColor = 'error';
      break;
    }
  }

  const storyOrderStyle: React.CSSProperties = {
    fontFamily: 'monospace',
    fontWeight: 'bold',
    fontSize: 16,
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 3,
    backgroundColor: '#fff7c9',
    paddingLeft: 2,
    paddingRight: 2,
  };
  return (
    <div ref={ref} style={{ ...style, opacity }}>
      <Descriptions
        size={'small'}
        title={title}
        column={2}
      >
        <Descriptions.Item span={2} label={'Original Order'}><span style={storyOrderStyle}>{order}</span></Descriptions.Item>
        <Descriptions.Item span={1} label={'Type'}><Tag color={storyTypeColor}>{storyTypeOptions[type]}</Tag></Descriptions.Item>
        <Descriptions.Item span={1} label={'Status'}><Tag color={storyStatusColor}>{storyStatusOptions[status]}</Tag></Descriptions.Item>
      </Descriptions>
    </div>
  );
};
