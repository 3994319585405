import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Menu,
  Avatar,
} from 'antd';
import {
  IdcardOutlined,
  BuildOutlined,
  TagsOutlined,
  LogoutOutlined,
  HomeOutlined,
  TeamOutlined,
  InfoCircleOutlined,
  AppstoreAddOutlined,
  PartitionOutlined,
  // ScheduleOutlined,
  VideoCameraOutlined,
  CalendarOutlined,
  ClockCircleOutlined,
  AppleFilled,
} from '@ant-design/icons';
import firebase from 'firebase/app';
import 'firebase/auth';

import type { RootState } from 'src/app/rootReducer';



const TopBar: React.FC = () => {
  const { SubMenu } = Menu;
  const history = useHistory();
  const { pathname } = useLocation();

  const handleClick = (event: any) => {
    const { key } = event;
    switch (key) {
      case 'sign-out': {
        firebase.auth().signOut();
        break;
      }
      case 'home': {
        history.push('/');
        break;
      }
      default: {
        history.push('/' + key);
        break;
      }
    }
  };

  const { user } = useSelector((state: RootState) => state.userState);
  if (user) {
    const { photoURL, authorizations } = user;

    let selectedKey = pathname.substring(1);
    if (pathname === '' || pathname === '/') {
      selectedKey = 'home';
    }
    const rightCSS: React.CSSProperties = {
      float: 'right',
    };
    return (
      <React.Fragment>
        <Avatar src='logo.png' />
        <Menu
          key={'top_menu'}
          theme='dark'
          onClick={handleClick}
          mode='horizontal'
          style={rightCSS}
          selectedKeys={[selectedKey]}
        >
          <Menu.Item
            key='home'
            icon={<HomeOutlined />}
          >
            Home
          </Menu.Item>
          <SubMenu
            key={'live_events'}
            title={' Live Events'}
            icon={<VideoCameraOutlined />}
          >
            <Menu.Item
              key='live_events/upcoming'
              active
              // disabled={!authorizations.includes('users')}
              icon={<ClockCircleOutlined />}
            >
              Upcoming
            </Menu.Item>
            <Menu.Item
              key='live_events/schedule'
              active
              // disabled={!authorizations.includes('users')}
              icon={<CalendarOutlined />}
            >
              Scheduler
            </Menu.Item>
          </SubMenu>
          <SubMenu
            key={'builder'}
            title='Builder'
            icon={<BuildOutlined />}
          >
            <Menu.Item
              key='seasons'
              icon={<PartitionOutlined />}
            >
              Seasons
            </Menu.Item>
            <Menu.Item
              key='node_definitions'
              disabled={!authorizations.includes('node_definitions')}
              icon={<AppstoreAddOutlined />}
            >
              Node Definitions
            </Menu.Item>
            {/* <Menu.Item
              key='asset_manager'
              disabled={!authorizations.includes('asset_manager')}
              icon={<FileOutlined />}
            >
              Asset Manager
            </Menu.Item> */}
            <Menu.Item
              key='character_profiles'
              // disabled={!authorizations.includes('tag_definitions')}
              icon={<IdcardOutlined />}
            >
              Character Profiles
            </Menu.Item>
            <Menu.Item
              key='chip_parts'
              // disabled={!authorizations.includes('tag_definitions')}
              icon={<IdcardOutlined />}
            >
              Chip Parts
            </Menu.Item>
            <Menu.Item
              key='tag_definitions'
              disabled={!authorizations.includes('tag_definitions')}
              icon={<TagsOutlined />}
            >
              Tag Definitions
            </Menu.Item>
            <Menu.Item
              key='app_tag_definitions'
              disabled={!authorizations.includes('tag_definitions')}
              icon={<AppleFilled />}
            >
              App Tag Definitions
            </Menu.Item>
          </SubMenu>
          <SubMenu
            key={'settings'}
            title={' Settings'}
            icon={<Avatar src={photoURL || undefined} />}
          >
            <Menu.Item
              key='user_integrations'
              active
              // icon={<TeamOutlined />}
            >
              User Integrations
            </Menu.Item>
            <Menu.Item
              key='user_manager'
              active
              disabled={!authorizations.includes('users')}
              icon={<TeamOutlined />}
            >
              User Manager
            </Menu.Item>
            <Menu.Item
              key='settings'
              icon={<InfoCircleOutlined />}
            >
              Account Details
            </Menu.Item>
            <Menu.Item
              key='sign-out'
              icon={<LogoutOutlined />}
            >
              Sign Out
            </Menu.Item>
          </SubMenu>
        </Menu >
      </React.Fragment >
    );
  }
  return (<span></span>);
};

export default TopBar;