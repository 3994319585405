import React from 'react';
import { useLocation } from 'react-router-dom';
import { Result } from 'antd';

const NoMatch: React.FC = () => {
  const { pathname } = useLocation();
  console.log('Did not match a route');

  return (
    <Result
      status='404'
      title='Oops! 404'
      subTitle={<span>No match for <code>{pathname}</code></span>}
    // extra={<Button type="primary">Back Home</Button>}
    />
  );
};

export default NoMatch;