import { AnyAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AssetReference, PlaceholderAssetReference } from 'types';
import { AsyncAppThunk } from 'src/app/store';
import firebase from 'firebase';
import * as yup from 'yup';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/firestore';

const { firestore, auth, storage } = firebase;

interface SuccessReturn {
  success: true;
  validationError: null;
}
interface FailureReturn {
  success: false;
  validationError: yup.ValidationError;
}

type ThunkReturn = SuccessReturn | FailureReturn;

interface StorageState {
  fileReferences: {
    imageFiles: AssetReference[];
    videoFiles: AssetReference[];
    audioFiles: AssetReference[];
  };
  fetchingFileReferences: boolean;
}

const initialState: StorageState = {
  fileReferences: {
    imageFiles: [],
    videoFiles: [],
    audioFiles: [],
  },
  fetchingFileReferences: true,
};

const storageSlice = createSlice({
  name: 'storage',
  initialState,
  reducers: {
    setImageReferences(state, action: PayloadAction<AssetReference[]>): void {
      state.fileReferences.imageFiles = action.payload;
    },
    setVideoReferences(state, action: PayloadAction<AssetReference[]>): void {
      state.fileReferences.videoFiles = action.payload;
    },
    setAudioReferences(state, action: PayloadAction<AssetReference[]>): void {
      state.fileReferences.audioFiles = action.payload;
    },
    setPlaceholderReference(state, action: PayloadAction<Omit<PlaceholderAssetReference, 'createdAt' | 'updatedAt'>>): void {
      const { name, fullPath, assetType } = action.payload;
      switch (assetType) {
        case 'video':
          state.fileReferences.videoFiles.push({ name, fullPath });
          break;
        case 'audio':
          state.fileReferences.audioFiles.push({ name, fullPath });
          break;
        case 'image':
          state.fileReferences.imageFiles.push({ name, fullPath });
          break;
      }
    },
    finishInitialFetch(state): void {
      state.fetchingFileReferences = false;
    },
  },
});

export const {
  setImageReferences,
  setVideoReferences,
  setAudioReferences,
  setPlaceholderReference,
  finishInitialFetch,
} = storageSlice.actions;

type AssetType = 'audio' | 'video' | 'image';

async function fetchNestedReferences(ref: firebase.storage.Reference): Promise<firebase.storage.Reference[]> {
  let refs: firebase.storage.Reference[] = [];
  const { items, prefixes } = await ref.listAll();
  items.forEach((itemRef) => refs.push(itemRef));
  const childrenPromises: Promise<firebase.storage.Reference[]>[] = [];
  prefixes.forEach((folderRef) => childrenPromises.push(fetchNestedReferences(folderRef)));
  const childrenItems = await Promise.all(childrenPromises);
  childrenItems.forEach((childItems) => {
    refs = [...refs, ...childItems];
  });
  return refs;
}

// thunk creator
export function fetchFileReferences(includedAssetTypes: ('audio' | 'video' | 'image')[] = ['audio', 'image', 'video']): AsyncAppThunk<void> {

  return async function thunk(dispatch, getState): Promise<void> {
    try {
      const user = getState().userState.user;
      if (!user) {
        throw new Error('Not authenticated');
      }
      const { fileReferences } = getState().storageState;


      const imageFiles: AssetReference[] = [];
      const videoFiles: AssetReference[] = [];
      const audioFiles: AssetReference[] = [];
      const storageRef = storage().ref();


      const getReferences: Promise<firebase.storage.Reference[]>[] = [];
      const assetOrder: { [index: number]: AssetType } = {};

      includedAssetTypes.forEach((assetType) => {
        switch (assetType) {
          case 'image': {
            if (fileReferences.imageFiles.length === 0) {
              assetOrder[getReferences.length] = assetType;
              getReferences.push(fetchNestedReferences(storageRef.child(assetType)));
            }
            break;
          }
          case 'video': {
            if (fileReferences.videoFiles.length === 0) {
              assetOrder[getReferences.length] = assetType;
              getReferences.push(fetchNestedReferences(storageRef.child(assetType)));
            }
            break;
          }
          case 'audio': {
            if (fileReferences.audioFiles.length === 0) {
              assetOrder[getReferences.length] = assetType;
              getReferences.push(fetchNestedReferences(storageRef.child(assetType)));
            }
            break;
          }
        }
      });

      if (getReferences.length === 0) {
        dispatch(finishInitialFetch());
        return;
      }

      const assetCols = await Promise.all(getReferences);

      assetCols.forEach((assetFileCol, index) => {
        switch (assetOrder[index]) {
          case 'image': {
            assetFileCol.forEach(({ name, fullPath }) => {
              imageFiles.push({
                name,
                fullPath,
              });
            });
            dispatch(setImageReferences(imageFiles));
            break;
          }
          case 'video': {
            assetFileCol.forEach(({ name, fullPath }) => {
              videoFiles.push({
                name,
                fullPath,
              });
            });
            dispatch(setVideoReferences(videoFiles));
            break;
          }
          case 'audio': {
            assetFileCol.forEach(({ name, fullPath }) => {
              audioFiles.push({
                name,
                fullPath,
              });
            });
            dispatch(setAudioReferences(audioFiles));
            break;
          }
        }
      });

      dispatch(finishInitialFetch());
    } catch (error) {
      console.log('Error in fetchFileReferences():', error);
    }
  };
}

export function addPlaceholderAssetReference(newPlaceholderAsset: Omit<PlaceholderAssetReference, 'createdAt' | 'updatedAt'>): AsyncAppThunk<ThunkReturn> {
  return async function thunk(dispatch): Promise<ThunkReturn> {
    try {
      const user = auth().currentUser;
      if (!user) {
        throw new Error('Not authenticated');
      }
      const placeholderAssetColRef = firestore().collection('placeholderAssetReferences');

      await placeholderAssetColRef.add({
        ...newPlaceholderAsset,
        createdAt: firestore.Timestamp.now(),
      });

      dispatch(setPlaceholderReference(newPlaceholderAsset));

      return {
        success: true,
        validationError: null,
      };
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        return {
          success: false,
          validationError: error,
        };
      } else {
        console.log('Error in addPlaceholderAssetReference():', error);
        throw error;
      }
    }
  };
}

export default storageSlice.reducer;