import React from 'react';
import {
  Form,
  Input,
  Button,
  Select,
  Divider,
  PageHeader,
  Alert,
  Radio,
} from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import {
  addNodeDefinition,
  updateNodeDefinition,
  deleteNodeDefinition,
} from 'src/features/nodes/nodeDefinitionSlice';
// import { snakeCase } from 'lodash';
import merge from 'deepmerge';
import isPlainObject from 'is-plain-object';
import { useFormik } from 'formik';
import NodeRouter from './NodeRouter';
import type { Store } from 'rc-field-form/es/interface';
import type { CheckboxOptionType } from 'antd/es/checkbox/Group';
import type { AppDispatch } from 'src/app/store';
import type { NodeFormState, NodeDefinition } from 'types';

const { TextArea } = Input;

interface Props {
  nodeFormState: NodeFormState;
  onCancel: () => void;
  editMode: boolean;
}

interface NodeDefOption {
  label: 'Spud multi-choice question';
  value: 'Spud multi-choice question';
}

// "Post to password protected site" | "Spud multi-choice question"
const nodeDefTypeOptions: NodeDefOption[] = [
  // {
  //   text: "Post to password protected site",
  //   value: "Post to password protected site",
  // },
  {
    label: "Spud multi-choice question",
    value: "Spud multi-choice question",
  },
];

const statusOptions: CheckboxOptionType[] = [
  {
    label: 'Ready', value: 'Ready',
  },
  {
    label: 'Deleted', value: 'Deleted', disabled: true,
  },
  {
    label: 'Staged', value: 'staged', disabled: true,
  },
  {
    label: 'Published', value: 'published', disabled: true,
  }
];

function overwriteMerge(destinationArray: [], sourceArray: [], options: any): [] {
  return sourceArray;
}


const NodeDefinitionFormContainer: React.FC<Props> = ({ nodeFormState, onCancel, editMode }: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const [antdForm] = Form.useForm();


  // let editMode = true;
  // const initialValues: NodeFormState = {
  //   ...nodeFormState,
  // };//nodeFormState.id = uuidv4()
  // if (nodeFormState.id === '') {
  //   editMode = false;
  // }

  const formik = useFormik<NodeFormState>({
    initialValues: nodeFormState,
    enableReinitialize: true,
    onSubmit: async (values, formikHelpers): Promise<void> => {
      console.log(values);
      const { id, nodeDefinition } = values;
      const { setSubmitting, resetForm, setErrors } = formikHelpers;
      let success, validationError;
      try {
        if (editMode) {
          ({ success, validationError } = await dispatch(updateNodeDefinition(id, nodeDefinition)));
        } else {
          ({ success, validationError } = await dispatch(addNodeDefinition(id, nodeDefinition)));
        }
        setSubmitting(false);
        if (success) {
          if (!editMode) {
            resetForm();
            antdForm.resetFields();
          }
        } else if (validationError) {
          console.log(validationError);
          const { path, message } = validationError;
          setErrors({
            [path]: message,
          });
        }
      } catch (error) {
        console.log('Non validation error:', error);
      }
    },
  });

  const {
    values: formikValues,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    setSubmitting,
    setErrors,
    /* and other goodies */
    initialValues: formikInitialValues,
    setValues,
    dirty,
  } = formik;

  React.useEffect(() => {
    antdForm.resetFields();
  }, [antdForm, formikInitialValues]);


  // function displayNameOnChange(event: React.ChangeEvent<HTMLInputElement>): void {
  //   const { target: { value } } = event;
  //   if (!editMode) {
  //     setFieldValue('id', snakeCase(value));
  //   }
  // }

  /**
   * not sure how to type this function
   * this is used to clean out the new state of the form
   * @param obj 
   */
  function removeEmpty(obj: any): void {
    return Object.entries(obj).forEach(([key, val]) => {
      if (val && typeof val === 'object' && isPlainObject(val)) {
        removeEmpty(val);
      } else if (val == null) {
        delete obj[key];
      }
    });
  }

  function handleValuesChange(changedValues: Store /*, currentValues: Store */): void {
    const newValues = merge<NodeFormState, Store>(formikValues, changedValues, {
      arrayMerge: overwriteMerge,
      isMergeableObject: isPlainObject,
    });

    removeEmpty(newValues);

    // if (!editMode) {
    //   const { nodeDefinition: { displayName } } = newValues;
    //   newValues.id = snakeCase(displayName);
    // }
    setValues(newValues);
  }

  interface FormItemProps {
    name: string | number | (string | number)[] | undefined;
    validateStatus: "" | 'error' | 'success' | 'warning' | 'validating' | undefined;
    hasFeedback: boolean;
    help: React.ReactNode | undefined;
  }

  type FormErrors = {
    [K in keyof NodeDefinition]?: string;
  }

  function generateFormItemProps(itemName: keyof NodeDefinition): FormItemProps {
    const hasError = itemName in errors;
    return {
      name: ['nodeDefinition', itemName],
      validateStatus: hasError ? 'error' : '',
      hasFeedback: hasError,
      help: hasError ? (errors as FormErrors)[itemName] : false,
    };
  }

  const { id, nodeDefinition } = formikValues;
  const { displayName, type, description, isDeleted, status } = nodeDefinition;

  let questionDefStyle = {};
  if (isDeleted) {
    questionDefStyle = {
      textDecorationLine: 'line-through',
      textDecorationStyle: 'solid',
    };
  }

  async function onDelete(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): Promise<void> {
    event.preventDefault();
    try {
      setSubmitting(true);
      await dispatch(deleteNodeDefinition(id));
    } catch (error) {
      console.log('error:', error);
    }
    setSubmitting(false);
  }
  const BottomButtons: React.FC = () => {
    if (editMode) {
      let deleteButtonContent = 'Delete Node Definition';
      if (isDeleted) {
        deleteButtonContent = 'Un-Delete Node Definition';
      }
      let updateButtonContent = 'Update Node Definition';
      if (!dirty) {
        updateButtonContent = 'Make a change to update';
      }
      return (
        <Form.Item>
          <Button
            block
            size={'large'}
            type={'primary'}
            htmlType={'submit'}
            loading={isSubmitting}
            disabled={isSubmitting || !dirty}
          >
            {updateButtonContent}
          </Button>
          <Button
            block
            danger
            onClick={onDelete}
            size={'large'}
            type={'primary'}
            loading={isSubmitting}
            disabled={isSubmitting}
          >
            {deleteButtonContent}
          </Button>
        </Form.Item>
      );
    } else {
      return (
        <Form.Item>
          <Button
            block
            size={'large'}
            type={'primary'}
            htmlType={'submit'}
            loading={isSubmitting}
            disabled={isSubmitting}
          >
            Create New Node Definition
          </Button>
        </Form.Item>
      );
    }
  };
  return (
    <React.Fragment>
      <Form
        size={'middle'}
        layout={'vertical'}
        initialValues={formikInitialValues}
        form={antdForm}
        onValuesChange={handleValuesChange}
        onSubmitCapture={handleSubmit}
      >
        <PageHeader
          title={<Form.Item name={'id'}><span style={questionDefStyle}>ID: {id}</span></Form.Item>}
          extra={[
            <Button
              key={'close_sidebar'}
              danger
              icon={<CloseOutlined />}
              onClick={onCancel}
            />
          ]}
        >
          <Form.Item
            {...generateFormItemProps('displayName')}
            label={'Display Name'}
          >
            <Input
              placeholder={'T-Rex height'}
              disabled={isSubmitting}
              value={displayName}
            // onChange={displayNameOnChange}
            />
          </Form.Item>
          <Form.Item
            {...generateFormItemProps('type')}
            label={'Type'}
          >
            <Select
              options={nodeDefTypeOptions}
              disabled={isSubmitting || editMode}
              value={type}
            />
          </Form.Item>
          <Form.Item
            {...generateFormItemProps('description')}
            label={'Description'}
          >
            <TextArea
              disabled={isSubmitting}
              value={description}
            />
          </Form.Item>
          <Form.Item
            {...generateFormItemProps('status')}
            label={'Status'}
          >
            <Radio.Group
              options={statusOptions}
              disabled={isSubmitting || status === 'Deleted'}
              value={status}
              optionType={'button'}
              buttonStyle={'solid'}
            />
          </Form.Item>
          <NodeRouter
            nodeDefinition={nodeDefinition}
            setFieldValue={setFieldValue}
            handleChange={handleChange}
            handleBlur={handleBlur}
            errors={errors}
            isSubmitting={isSubmitting}
            setErrors={setErrors}
            form={antdForm}
          />
          <Divider />
          {Object.values(errors).map((error, index) => <Alert style={{ marginBottom: 10 }} showIcon type={'error'} key={index} message={error} />)}
          <BottomButtons />
        </PageHeader>
      </Form>
    </React.Fragment>
  );
};

export default NodeDefinitionFormContainer;