import React from 'react';

const PlaceholderComp: React.FC = () => {
  const containerStyle: React.CSSProperties = {
    // width: '800px',
    // margin: '1em auto',
    // fontSize: '5em',
    // textAlign: 'center',
  };
  return (
    <div style={containerStyle}>
      Welcome!
    </div>
  );
};

export default PlaceholderComp;