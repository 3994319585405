import React from 'react';
import { Redirect } from 'react-router-dom';
import { Button, Result } from 'antd';
import { GoogleOutlined } from '@ant-design/icons';
import { useLogin } from './helpers';


export const LoginPage: React.FC = () => {
  const [prevInfo, login] = useLogin();
  if (prevInfo === null) {
    return (
      <Result
        icon={<img alt='profile' src='./logo.png' style={{ width: '100px', }} />}
        title={'Log-in to your account'}
        extra={
          <Button
            icon={<GoogleOutlined />}
            onClick={login}
            size='large'
            type='primary'
          >
            Login with Google
          </Button>
        }
      />
    );
  } else {
    const [prevUrl, prevState] = prevInfo;
    console.log('prevState?.from.search', prevState?.from.search);
    return <Redirect
      to={{
        pathname: prevUrl,
        search: prevState?.from.search,
        state: prevState
      }}
    />;
  }
};