import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import {
  BrowserRouter as Router,
} from 'react-router-dom';
import store from './app/store';
// import 'semantic-ui-less/semantic.less';
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import '@antv/graphin/dist/index.css'; // Don't forget to import css
import 'src/index.css';
import * as serviceWorker from './serviceWorker';

const render = (): void => {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const App = require('./app/App').default;
  ReactDOM.render(
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>,
    document.getElementById('root')
  );
};
render();


if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./app/App', render);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
