import React from 'react';
import { useSelector } from 'react-redux';
import {
  Descriptions,
  Row,
  Col,
} from 'antd';
import moment from 'moment-timezone';
import type { RootState } from 'src/app/rootReducer';
// import type { milliseconds } from 'types';
import type { CSSProperties } from 'react';



const AccountDetails: React.FC = () => {
  const { user } = useSelector((state: RootState) => state.userState);
  const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const containerStyle: CSSProperties = {
    padding: '1em',
    margin: '1em auto',
    backgroundColor: 'white',
  };

  if (user) {
    const {
      displayName,
      email,
      lastLoginAt,
      createdAt,
      updatedAt
    } = user;
    return (
      <Row justify='center'>
        <Col xs={23} sm={22} md={22} lg={20} xl={16}>
          <Descriptions style={containerStyle} bordered title='Account Info'>
            <Descriptions.Item label='Email'>{email}</Descriptions.Item>
            <Descriptions.Item label='Display Name'>{displayName}</Descriptions.Item>
            <Descriptions.Item label='Last Login'>{moment(lastLoginAt).tz(currentTimeZone).fromNow()}</Descriptions.Item>
            <Descriptions.Item label='Created At'>{moment(createdAt).tz(currentTimeZone).fromNow()}</Descriptions.Item>
            <Descriptions.Item label='Updated At'>{(updatedAt) ? moment(updatedAt).tz(currentTimeZone).fromNow() : 'N/A'}</Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
    );
  }
  return <div>Not logged in</div>;
};

export default AccountDetails;