import React from 'react';
import moment from 'moment-timezone';
import firebase from 'firebase';
import 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';
import {
  Tag,
  Statistic,
  Radio,
  Button,
} from 'antd';
import {
  generateFirestorePath,
  axiosInstance,
} from 'src/helpers';
import {
  fetchLiveEvents,
  completeLiveEvent,
} from 'src/features/liveEvents/liveEventsSlice';
import Stopwatch from './Stopwatch';
import {
  useSelector,
  useDispatch,
} from 'react-redux';
import { storyConverter, nodeResultConverter } from 'src/features/stories/helpers';
import LiveEventStarter from './AWSLiveEventStarter';
import { WaitingIcon, VisibilityOffIcon, LiveStreamingIcon } from './Icons';
import './index.css';
/** type imports */
import type {
  Story,
  LiveStory,
  LiveNode,
  PointsDisplayNode,
  LivePollQuestionNode,
  NodeResult,
  LiveTrueOrFalseQuestionNode,
  LiveImageOverlayNode,
  BuilderImageAssemblerNode,
  Edge,
  SelfieNode,
  BadgeEarningNode,
  AWSStopStreamResponse,
  LiveBuilderImageSelectorNode,
} from 'types';
import type { RootState } from 'src/app/rootReducer';
import type { AppDispatch } from 'src/app/store';

const { Countdown } = Statistic;
const { firestore } = firebase;
interface Props {
  liveEventId: string;
}

const LiveEventManager: React.FC<Props> = ({ liveEventId }: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    liveEvents,
  } = useSelector((state: RootState) => state.liveEventsState);
  const {
    user,
  } = useSelector((state: RootState) => state.userState);

  const [shouldStart, setShouldStart] = React.useState<boolean>(false);
  const storyReactRef = React.useRef<firebase.firestore.DocumentReference<Story> | null>(null);
  const nodeResultsReactRef = React.useRef<firebase.firestore.CollectionReference<NodeResult> | null>(null);
  const [story, setStory] = React.useState<LiveStory | null>(null);
  const [[leadingNodeId, leadingNode], setLeadingNodeTuple] = React.useState<[string, LiveNode] | [null, null]>([null, null]);
  const [usedTemplates, setUsedTemplates] = React.useState<{ [templateId: string]: boolean }>({});
  const [nodeResults, setNodeResults] = React.useState<{ [nodeId: string]: NodeResult }>({});
  const [points, setPoints] = React.useState<number>(10);
  const cachedImageOverlay = React.useRef<string | null>(null);

  const streamOffset = 5500;
  const nodeOffset = 2000;
  const liveEvent = liveEvents?.[liveEventId];
  const storyId = liveEvent?.storyId;
  const seasonId = liveEvent?.seasonId;


  React.useEffect(() => {
    if (liveEvents === null) {
      dispatch(fetchLiveEvents());
    }
  }, [dispatch, liveEvents]);

  React.useEffect(() => {
    if (liveEvent) {
      const { eventDatetime } = liveEvent;
      if (moment().add(5, 'minutes').isAfter(moment(eventDatetime))) {
        setShouldStart(true);
      }
    }
  }, [liveEvent]);


  React.useEffect(() => {

    if (seasonId && storyId) {
      storyReactRef.current = firestore()
        .collection(generateFirestorePath(`seasons/${seasonId}/stories`))
        .withConverter(storyConverter)
        .doc(storyId);
    }

    const storyRefUnsubscribe = storyReactRef.current?.onSnapshot((storySnapshot) => {
      console.log('onSnapShot for storyRef fired');
      const storyData = storySnapshot.data();
      if (storyData && storyData.type === 'live') {
        setStory(storyData);
      }
    }, (error) => {
      console.log(error);
    });
    if (storyRefUnsubscribe) {
      console.log('added onSnapshot for storyRef');
    }
    return () => {
      if (storyRefUnsubscribe) {
        console.log('unsubscribing for storyRef');
        storyRefUnsubscribe();
      } else {
        console.log('unmounting for storyRef but not unsubscribing');
      }
    };
  }, [seasonId, storyId]);

  React.useEffect(() => {
    if (seasonId && storyId) {
      nodeResultsReactRef.current = firestore()
        .collection(generateFirestorePath(`seasons/${seasonId}/stories/${storyId}/nodeResults`))
        .withConverter(nodeResultConverter);
    }
    const nodeResultsRefUnsubscribe = nodeResultsReactRef.current?.onSnapshot((nodeResultsCol) => {
      console.log('onSnapShot for nodeResultsRef fired');
      const resultsData: { [nodeId: string]: NodeResult } = {};
      nodeResultsCol.forEach((nodeResultSnapshot) => {
        if (nodeResultSnapshot.exists) {
          const id = nodeResultSnapshot.id;
          const data = nodeResultSnapshot.data();
          resultsData[id] = data;
        }
      });
      setNodeResults(resultsData);
    }, (error) => {
      console.log(error);
    });
    if (nodeResultsRefUnsubscribe) {
      console.log('added onSnapshot for nodeResultsRef');
    }
    return () => {
      if (nodeResultsRefUnsubscribe) {
        console.log('unsubscribing for nodeResults');
        nodeResultsRefUnsubscribe();
      } else {
        console.log('unmounting for nodeResults but not unsubscribing');
      }
    };
  }, [seasonId, storyId]);

  React.useEffect(() => {
    const usedTemps: { [templateId: string]: boolean } = {};
    function getLeadingNodeId(nodes: { [nodeId: string]: LiveNode }, startingNodeId: string): string {
      const node = nodes[startingNodeId];
      if (node.type === 'Live Builder Image Selector' && node.templateId) {
        usedTemps[node.templateId] = true;
      }
      const edges = Object.values(node.edges);
      if (edges.length === 0) {
        return startingNodeId;
      } else {
        return getLeadingNodeId(nodes, edges[0].targetNodeId);
      }
    }
    if (story && story.startingNodeId && Object.keys(story.nodes).length > 0) {
      const nodes = story.nodes;
      const startingNodeId = story.startingNodeId;
      const nodeId = getLeadingNodeId(nodes, startingNodeId);
      const node = nodes[nodeId];
      setLeadingNodeTuple([nodeId, node]);
      setUsedTemplates(usedTemps);
    }
  }, [story]);

  if (!liveEvent) {
    return <div>Loading Live Event...</div>;
  }
  if (!user) {
    return <div>Loading User...</div>;
  }

  const storyRef = storyReactRef.current;
  const nodeResultsRef = nodeResultsReactRef.current;

  const {
    status,
    eventDatetime,
    nodeTemplates = {},
  } = liveEvent;

  let format = 'mm:ss';
  if (moment().add(1, 'hour').isBefore(moment(eventDatetime))) {
    format = 'HH:mm:ss';
  }
  const fontSize = '2rem';

  function getAssemblerNodeIds(nodes: { [nodeId: string]: LiveNode }, startingNodeId: string): string[] {
    const node = nodes[startingNodeId];
    let nodeIds: string[] = [];
    if (node.type === 'Live Builder Image Selector' && node.includeInAssemblerNode) {
      nodeIds.push(startingNodeId);
    }
    const edges = Object.values(node.edges);
    if (edges.length === 0) {
      return nodeIds;
    } else {
      nodeIds = [...getAssemblerNodeIds(nodes, edges[0].targetNodeId), ...nodeIds];
      return nodeIds;
    }
  }

  async function handleCreateNode(config: { type: 'Live Poll Question' } | { type: 'Live True Or False Question'; correctAnswer: boolean } | { type: 'Live Image Overlay'; imagePath: string; } | Omit<LiveBuilderImageSelectorNode & { templateId: string; }, 'endTime'>) {
    try {
      if (story && storyRef) {
        const nodeId = uuidv4();
        let node: Omit<LivePollQuestionNode, 'endTime'>
          | Omit<LiveTrueOrFalseQuestionNode, 'endTime'>
          | Omit<LiveImageOverlayNode, 'endTime'>
          | Omit<LiveBuilderImageSelectorNode, 'endTime'>;
        const createdAt = moment().valueOf();
        let now: number = moment().diff(moment(story.actualStartTime + story.startTime)) - nodeOffset;
        if (now < 100) {
          now = 10;
        }
        switch (config.type) {
          case 'Live Poll Question': {
            node = {
              displayName: 'Live Poll Question',
              description: 'Live Poll Question',
              edges: {},
              isEndingNode: false,
              displayOrder: 0,
              isDeleted: false,
              createdBy: user?.uid || '',
              createdAt,
              updatedAt: createdAt,
              type: 'Live Poll Question',
              points,
              startTime: now,
            };
            if (cachedImageOverlay.current) {
              node.imageOverlay = cachedImageOverlay.current;
            }
            break;
          }
          case 'Live True Or False Question': {

            node = {
              displayName: 'Live True Or False Question',
              description: 'Live True Or False Question',
              edges: {},
              isEndingNode: false,
              displayOrder: 0,
              isDeleted: false,
              createdBy: user?.uid || '',
              createdAt,
              updatedAt: createdAt,
              type: 'Live True Or False Question',
              correctAnswer: config.correctAnswer,
              points,
              startTime: now,
            };
            if (cachedImageOverlay.current) {
              node.imageOverlay = cachedImageOverlay.current;
            }
            break;
          }
          case 'Live Image Overlay': {
            node = {
              displayName: 'Live Image Overlay',
              description: config.imagePath,
              edges: {},
              isEndingNode: false,
              displayOrder: 0,
              isDeleted: false,
              createdBy: user?.uid || '',
              createdAt,
              updatedAt: createdAt,
              type: 'Live Image Overlay',
              imageOverlay: config.imagePath,
              startTime: now,
            };
            cachedImageOverlay.current = config.imagePath;
            break;
          }
          case 'Live Builder Image Selector': {
            node = {
              ...config,
              description: config.imageOverlay || 'missing image',
              createdBy: user?.uid || config.createdBy,
              imageOverlay: liveEvent?.assemblerBackgroundImage,
              createdAt,
              updatedAt: createdAt,
              startTime: now,
              isEndingNode: false,
            };
          }
        }

        if (leadingNode) {
          // update display order on new node to follow leading node
          node.displayOrder = leadingNode.displayOrder + 1;
        }



        const batch = firestore().batch();

        if (!story.startingNodeId) {
          console.log('adding first node');
          batch.update(storyRef, {
            [`nodes.${nodeId}`]: node,
            startingNodeId: nodeId,
          });
        } else if (leadingNodeId) {
          const edgeId = uuidv4();
          const edge: Edge = {
            displayOrder: 1,
            targetNodeId: nodeId,
          };
          console.log(`adding new node ${node.type}`);
          batch.update(storyRef, {
            [`nodes.${leadingNodeId}.edges.${edgeId}`]: edge,
            [`nodes.${leadingNodeId}.updatedAt`]: createdAt,
            [`nodes.${leadingNodeId}.endTime`]: now,
            [`nodes.${nodeId}`]: node,
          });
        }

        /** starts vote counter */
        if (node.type === "Live Poll Question" || node.type === "Live True Or False Question") {
          if (nodeResultsRef) {
            const nodeResults = nodeResultsRef.doc(nodeId);
            batch.set(nodeResults, {
              numShards: 20,
              option1Votes: 0,
              option2Votes: 0,
            });
          } else {
            console.log('nodeResultsRef is missing');
          }
        }

        await batch.commit();

      } else {
        if (!story) {
          console.error('story is missing');
        }
        if (!storyRef) {
          console.error('storyRef is missing');
        }
      }
    } catch (error) {
      console.log(error);
    }
  }
  const configDisabled = leadingNode?.type !== 'Live Image Overlay'
    || leadingNode.type === 'Live Image Overlay' && leadingNode.endTime !== undefined;

  const TemplateConfig: React.FC = () => {
    return (
      <div className={`live-node-template-config ${(configDisabled) ? 'config-disabled' : ''}`}>
        {Object.entries(nodeTemplates).map(([templateId, nodeTemplate]) => {
          const template: Omit<LiveBuilderImageSelectorNode, 'endTime'>
            & { templateId: string; endTime?: number } = {
            ...nodeTemplate,
            templateId,
          };
          if ('endTime' in template) {
            delete template.endTime;
          }
          let templateUsed = '';
          if (usedTemplates[templateId]) {
            templateUsed = ' template-used';
          }
          return (
            <div
              key={templateId}
              onClick={async () => {
                try {
                  if (!configDisabled) {
                    await handleCreateNode(template);
                  } else {
                    console.log('disabled cannot do this');
                  }
                } catch (error) {
                  console.error(error.message);
                }

              }}
              className={'template-config-card' + templateUsed}
            >
              <div className={'template-summary'}>
                <div className='template-display-name'>Display Name: {nodeTemplate.displayName}</div>
                <div className='template-type'>Type: {nodeTemplate.type}</div>
                <div className='template-description'>Description: {nodeTemplate.description}</div>
              </div>
              <div className={'template-images-container'}>
                {Object.values(nodeTemplate.options)
                  .sort(({ displayOrder: A }, { displayOrder: B }) => A - B)
                  .map((option, index) => {
                    return (
                      <img
                        key={index}
                        className={'template-image'}
                        width={200}
                        height={200}
                        src={`https://assetcdn.tappityapp.com/${option.image}`}
                        alt={'holding image'}
                      />
                    );
                  })}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const TemplateProgress: React.FC = () => {
    return (
      <div className='leading-node-data'>
        {(story && storyRef && leadingNodeId && leadingNode && leadingNode.type === 'Live Builder Image Selector') &&
          <React.Fragment>
            <div className='leading-node-data-row'>{leadingNode.type}</div>
            <div className='leading-node-data-row'>
              Time visible: <Stopwatch
                style={{
                  display: 'inline-block',
                  color: 'red',
                }}
                unixMilliseconds={story.actualStartTime + story.startTime + leadingNode.startTime}
              />
            </div>
            <Button
              type={'primary'}
              danger
              onClick={async () => {
                try {
                  if (leadingNodeId) {
                    if (cachedImageOverlay.current) {
                      /** this ends the node and creates the next one */
                      await handleCreateNode({ type: 'Live Image Overlay', imagePath: cachedImageOverlay.current });
                    } else {
                      /** this just ends the node */
                      let endTime = moment().diff(moment(story.actualStartTime + story.startTime)) - streamOffset;
                      if (endTime < 0) {
                        endTime = 10;
                      }
                      const updatedAt = moment().valueOf();
                      await storyRef.update({
                        [`nodes.${leadingNodeId}.endTime`]: endTime,
                        [`nodes.${leadingNodeId}.updatedAt`]: updatedAt,
                      });
                    }
                  } else {
                    console.log('missing leadingNodeId');
                  }
                } catch (error) {
                  console.log(error);
                }
              }}>
              End
            </Button>
          </React.Fragment>
        }
      </div>
    );
  };

  const QuestionConfig: React.FC = () => {

    return (
      <div className={`live-question-config ${configDisabled ? 'config-disabled' : ''}`}>
        <Radio.Group
          onChange={(e) => setPoints(e.target.value)}
          className={'points-selector'}
          value={points}
          buttonStyle="solid"
        >
          <Radio.Button value={10}>10 Points</Radio.Button>
          <Radio.Button value={20}>20 Points</Radio.Button>
          <Radio.Button value={30}>30 Points</Radio.Button>
        </Radio.Group>
        <Button
          onClick={async () => {
            try {
              if (!configDisabled) {
                await handleCreateNode({ type: 'Live Poll Question' });
              } else {
                console.log('disabled cannot do this');
              }
            } catch (error) {
              console.error(error.message);
            }
          }}
          className={'question-type-btn'}
          disabled={configDisabled}
          type='primary'
        >
          Show Poll Question
        </Button>
        <Button
          className={'question-type-btn'}
          type='primary'
          disabled={configDisabled}
          onClick={async () => {
            try {
              if (!configDisabled) {
                await handleCreateNode({ type: 'Live True Or False Question', correctAnswer: true });
              } else {
                console.log('disabled cannot do this');
              }
            } catch (error) {
              console.error(error.message);
            }
          }}
        >
          Show True/False Question: Correct Answer is <Tag color='green'>TRUE</Tag>
        </Button>
        <Button
          className={'question-type-btn'}
          disabled={configDisabled}
          type='primary'
          onClick={async () => {
            try {
              if (!configDisabled) {
                await handleCreateNode({ type: 'Live True Or False Question', correctAnswer: false });
              } else {
                console.log('disabled cannot do this');
              }
            } catch (error) {
              console.error(error.message);
            }
          }}
        >
          Show True/False Question: Correct Answer is <Tag color='red'>FALSE</Tag>
        </Button>
      </div>
    );
  };
  const QuestionProgress: React.FC = () => {
    let option1Label = (<div>Option 1</div>);
    let option2Label = (<div>Option 2</div>);
    let option1ResultsClassname = '';
    let option2ResultsClassname = '';
    if (leadingNode?.type === 'Live Poll Question') {
      option1Label = <Tag color='gold'>Yellow Option</Tag>;
      option2Label = <Tag color='blue'>Blue Option</Tag>;
      option1ResultsClassname = 'yellow-votes';
      option2ResultsClassname = 'blue-votes';
    } else if (leadingNode?.type === 'Live True Or False Question') {
      option1ResultsClassname = 'true-votes';
      option2ResultsClassname = 'false-votes';
      option1Label = <Tag color='green'>True</Tag>;
      option2Label = <Tag color='red'>False</Tag>;
    }
    return (
      <div className='leading-node-data'>
        {(story && storyRef && nodeResultsRef && leadingNodeId && leadingNode && (leadingNode.type === 'Live Poll Question' || leadingNode.type === 'Live True Or False Question')) && (
          <React.Fragment>
            <div className='leading-node-data-row'>{leadingNode.type}: {((leadingNode.type === 'Live True Or False Question') ? (leadingNode.correctAnswer ? (<Tag color='green'>True</Tag>) : (<Tag color='red'>False</Tag>)) : '')}</div>
            <div className='leading-node-data-row'>
              Time visible: <Stopwatch
                style={{
                  display: 'inline-block',
                  color: 'red',
                }}
                unixMilliseconds={story.actualStartTime + story.startTime + leadingNode.startTime}
              />
            </div>
            <div className='leading-node-data-row'>{option1Label}votes: <span className={option1ResultsClassname}>{nodeResults[leadingNodeId]?.option1Votes}</span></div>
            <div className='leading-node-data-row'>{option2Label}votes: <span className={option2ResultsClassname}>{nodeResults[leadingNodeId]?.option2Votes}</span></div>
            <Button
              type={'primary'}
              danger
              disabled={leadingNode.endTime !== undefined}
              onClick={async () => {
                try {
                  if (leadingNodeId && leadingNode) {
                    let endTime = moment().diff(moment(story.actualStartTime + story.startTime)) - streamOffset;
                    if (endTime < 0) {
                      endTime = 10;
                    }
                    const updatedAt = moment().valueOf();
                    await nodeResultsRef
                      .doc(leadingNodeId)
                      .update({ endTime });
                    if (cachedImageOverlay.current) {
                      /** this ends the node as well as creates the new one */
                      await handleCreateNode({ type: 'Live Image Overlay', imagePath: cachedImageOverlay.current });
                    } else {
                      /** this just ends the node */
                      await storyRef.update({
                        [`nodes.${leadingNodeId}.endTime`]: endTime,
                        [`nodes.${leadingNodeId}.updatedAt`]: updatedAt,
                      });
                    }
                  } else {
                    console.log('missing leadingNodeId or leadingNode');
                  }
                } catch (error) {
                  console.log(error);
                }
              }}>
              End
            </Button>
          </React.Fragment>
        )}
      </div>
    );
  };
  return (
    <div className='live-event-manager'>
      <Countdown
        title={'Countdown'}
        style={{
          display: status !== 'scheduled' ? 'none' : undefined,
        }}
        format={format}
        onFinish={() => setShouldStart(true)}
        value={eventDatetime}
      />

      {story && (
        <div className='live-story-details'>
          {story.status === 'pre_show' && <Stopwatch unixMilliseconds={story.actualStartTime} />}
          {story.status === 'live' && (
            <div>
              <Stopwatch unixMilliseconds={story.actualStartTime + story.startTime} />
            </div>
          )}
          <div className='live-story-status-bar'>
            <span className={'live-story-status ' + ((story.status === 'pre_show') ? 'active-status is-pre-show' : '')}>
              <div>
                <WaitingIcon style={{ fontSize }} />
              </div>
              <div>
                Pre-show
              </div>
            </span>
            <span className={'live-story-status ' + ((story.status === 'live') ? 'active-status is-live' : '')}>
              <div>
                <LiveStreamingIcon style={{ fontSize }} />
              </div>
              <div>
                Live
              </div>
            </span>
            <span className={'live-story-status ' + ((story.status === 'staged') ? 'is-complete' : '')}>
              <div>
                <VisibilityOffIcon style={{ fontSize }} />
              </div>
              <div>
                Completed
              </div>
            </span>
          </div>
          {story.status === 'pre_show' && (
            <div className='go-live'>
              <Button
                size='large'
                type='primary'
                danger
                onClick={async () => {
                  try {
                    if (storyRef) {
                      await storyRef.update({
                        status: 'live',
                        startTime: moment().diff(moment(story.actualStartTime)),
                        actualGoLiveTime: moment().valueOf() - streamOffset,
                      });
                    } else {
                      console.error('cannot start story, storyRef is missing');
                    }
                  } catch (error) {
                    console.error(error.message);
                  }
                }}
              >
                Go Live!
              </Button>
            </div>
          )}
          {(story.status === 'live') && (
            <div className={''}>
              <div className='live-event-panel'>
                <div className={'live-event-panel-header'}>
                  <span className='live-event-panel-title'>
                    Node Templates Config
                  </span>
                </div>
                <div className='live-event-question-config-container'>
                  {(leadingNodeId && leadingNode && leadingNode.type === 'Live Builder Image Selector' && !leadingNode.endTime) ? (
                    <TemplateProgress />
                  ) : (
                    <TemplateConfig />
                  )}
                </div>
              </div>
              <div className='live-event-panel'>
                <div className={'live-event-panel-header'}>
                  <span className='live-event-panel-title'>
                    Question Config
                  </span>
                </div>
                <div className='live-event-question-config-container'>
                  {(leadingNodeId && (leadingNode?.type === 'Live Poll Question' || leadingNode?.type === 'Live True Or False Question') && !leadingNode?.endTime) ? (
                    <QuestionProgress />
                  ) : (
                    <QuestionConfig />
                  )}
                </div>
              </div>
              <div className='live-event-panel'>
                <div className={'live-event-panel-header'}>
                  <span className='live-event-panel-title'>
                    Images
                  </span>
                  <Button
                    disabled={!(leadingNode?.type === 'Live Image Overlay' && leadingNode?.imageOverlay !== undefined && leadingNode?.endTime === undefined)}
                    type='primary'
                    onClick={async () => {
                      try {
                        console.log('clear images');
                        const endTime = moment().diff(moment(story.actualStartTime + story.startTime)) - streamOffset;
                        if (leadingNodeId && storyRef) {
                          await storyRef.update({
                            [`nodes.${leadingNodeId}.endTime`]: endTime,
                          });
                        }
                      } catch (error) {
                        console.log(error);
                      }
                    }}
                  >
                    Clear
                  </Button>
                </div>
                <div className='live-event-images-container'>
                  {liveEvent.images.map((imagePath, index) => {
                    let selected = '';
                    let disabled = '';
                    if (leadingNode !== null
                      && (leadingNode.type === 'Live Image Overlay'
                        || leadingNode.type === 'Live True Or False Question'
                        || leadingNode.type === 'Live Poll Question'
                        || leadingNode.type === 'Live Builder Image Selector')) {

                      if (!leadingNode.endTime && leadingNode.imageOverlay === imagePath) {
                        selected = ' selected-live-event-image';
                      }
                      if (leadingNode.type !== 'Live Image Overlay' && !leadingNode.endTime) {
                        disabled = ' live-event-image-disabled';
                      }
                    }
                    return (
                      <img
                        key={index}
                        className={'live-event-image' + selected + disabled}
                        onClick={async () => {
                          try {
                            if (story.status === 'live' && disabled === '' && selected === '') {
                              await handleCreateNode({ type: 'Live Image Overlay', imagePath });
                            } else {
                              console.log('cannot select this image, disabled');
                            }
                          } catch (error) {
                            console.error(error.message);
                          }
                        }}
                        width={200}
                        height={200}
                        src={`https://assetcdn.tappityapp.com/${imagePath}`}
                      />
                    );
                  })}
                </div>
              </div>
              <div>
                <Button
                  className='end-story-btn'
                  danger
                  size='large'
                  type='primary'
                  disabled={leadingNode === null}
                  onClick={async () => {
                    try {
                      if (leadingNode && leadingNodeId && storyRef) {
                        const finalNodes: [string, (PointsDisplayNode | BadgeEarningNode | SelfieNode | BuilderImageAssemblerNode)][] = [];

                        const createdAtNow = moment().valueOf();
                        const pointsNodeId = uuidv4();
                        const pointsNode: PointsDisplayNode = {
                          displayName: 'Points Display',
                          description: 'Points Display',
                          edges: {},
                          isEndingNode: true,
                          displayOrder: 0,
                          isDeleted: false,
                          createdBy: user.uid,
                          createdAt: createdAtNow,
                          updatedAt: createdAtNow,
                          type: 'Points Display',
                          backgroundImage: 'image/temp-lab-bg-blur.jpg',
                        };

                        finalNodes.unshift([pointsNodeId, pointsNode]);

                        const assemblerNodeIds = getAssemblerNodeIds(story.nodes, story.startingNodeId);

                        if (assemblerNodeIds.length > 0 && liveEvent.assemblerBackgroundImage) {
                          const imageAssemblerNodeId = uuidv4();
                          const imageAssemblerNode: BuilderImageAssemblerNode = {
                            displayName: 'Builder Image Assembler',
                            description: 'Builder Image Assembler',
                            type: 'Builder Image Assembler',
                            edges: {},
                            isEndingNode: false,
                            displayOrder: 0,
                            isDeleted: false,
                            createdBy: user.uid,
                            createdAt: createdAtNow,
                            updatedAt: createdAtNow,
                            nodeIds: assemblerNodeIds,
                            backgroundImage: liveEvent.assemblerBackgroundImage,
                          };

                          finalNodes.unshift([imageAssemblerNodeId, imageAssemblerNode]);
                        }

                        // IF THIS EPISODE HAS A BADGE AT THE END
                        if (liveEvent.selfieImage && liveEvent.hasBadge) {
                          const badgeEarningNodeId = uuidv4();
                          const badgeEarningNode: BadgeEarningNode = {
                            displayName: 'Badge Earning',
                            description: 'badge',
                            badgeDescription: 'Earned badge.',
                            edges: {},
                            isEndingNode: false,
                            displayOrder: 0,
                            isDeleted: false,
                            createdBy: user.uid,
                            createdAt: createdAtNow,
                            updatedAt: createdAtNow,
                            type: 'Badge Earning',
                            botAudio: "audio/chip-live-reaction-1.mp3",
                            image: liveEvent.selfieImage,
                            backgroundImage: "image/character-president-blur-bg.jpg",
                          };

                          finalNodes.unshift([badgeEarningNodeId, badgeEarningNode]);

                          //ELSE IF STORY HAS A SELFIE IMAGE
                        } else if (liveEvent.selfieImage) {
                          const selfieNodeId = uuidv4();
                          const selfieNode: SelfieNode = {
                            displayName: 'Selfie',
                            description: 'Selfie',
                            edges: {},
                            isEndingNode: false,
                            displayOrder: 0,
                            isDeleted: false,
                            createdBy: user.uid,
                            createdAt: createdAtNow,
                            updatedAt: createdAtNow,
                            type: 'Selfie',
                            botAudio: "audio/250-milliseconds-of-silence.mp3",
                            savedFileName: selfieNodeId + "-image",
                            overlayImage: liveEvent.selfieImage,
                            musicAudio: "audio/250-milliseconds-of-silence.mp3",
                          };
                          finalNodes.unshift([selfieNodeId, selfieNode]);
                        }


                        let endTime = moment().diff(moment(story.actualStartTime + story.startTime)) - streamOffset;
                        if (endTime < 0) {
                          endTime = 10;
                        }
                        const batch = firestore().batch();

                        const displayOrderBase = leadingNode.displayOrder + 1;
                        for (let i = 0; i < finalNodes.length; i++) {
                          const edgeId = uuidv4();
                          const [currNodeId, currNode] = finalNodes[i];
                          /** updates display orders before committing */
                          currNode.displayOrder = displayOrderBase + i;
                          const edge: Edge = {
                            displayOrder: 1,
                            targetNodeId: currNodeId,
                          };
                          if (i === 0) {
                            /** ends story and the last leadingNode */
                            batch.update(storyRef, {
                              actualEndTime: moment().valueOf(),
                              status: 'staged',
                              [`nodes.${leadingNodeId}.edges.${edgeId}`]: edge,
                              [`nodes.${leadingNodeId}.endTime`]: endTime,
                              [`nodes.${leadingNodeId}.updatedAt`]: createdAtNow,
                            });
                          } else {
                            /** updates edges for other nodes */
                            const prevNode = finalNodes[i - 1][1];
                            prevNode.edges[edgeId] = edge;
                          }
                        }

                        finalNodes.forEach(([nodeId, node]) => {
                          /** updates story with new nodes */
                          batch.update(storyRef, {
                            [`nodes.${nodeId}`]: node,
                          });
                        });

                        /** commits all changes */
                        await batch.commit();

                        await dispatch(completeLiveEvent({ liveEventId }));
                        if (liveEvent.awsStreamChannelId) {
                          const { data } = await axiosInstance.post<AWSStopStreamResponse>(`/awsStream/${liveEvent.awsStreamChannelId}/stop`, { liveEventId });
                          console.log('success in stopping stream', data);
                        } else {
                          console.log('something went wrong. awsStreamChannelId was missing on liveEvent');
                        }
                      }
                    } catch (error) {
                      console.log(error);
                    }
                  }}
                >End Story</Button>
              </div>
            </div>
          )}
          <div className={'output-story-info'}>
            <div>SeasonId: {seasonId}</div>
            <div>StoryId: {storyId}</div>
            <div>Stream input url: {liveEvent.streamInputUrl}</div>
          </div>
        </div>
      )}

      <LiveEventStarter
        liveEventId={liveEventId}
        streamInputUrl={liveEvent.streamInputUrl}
        title={'Start Live Event'}
        visible={shouldStart && status === 'scheduled'}
        closable={false}
        footer={null}
      />
    </div>
  );
};

export default LiveEventManager;
