import React from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Unauthorized from './Unauthorized';
import NewlyCreated from './NewlyCreated';
/** type imports */
import type { RootState } from 'src/app/rootReducer';
import type { AuthorizationType } from 'types';

interface PrivateProps {
  children: React.ReactNode;
  authorizationRequired?: AuthorizationType;
}

export const PrivateRoute: React.FC<PrivateProps> = (props: PrivateProps) => {
  const location = useLocation();
  const {
    children,
    authorizationRequired,
  } = props;
  const { user } = useSelector((state: RootState) => state.userState);
  if (user) {
    const { authorizations } = user;
    if (authorizationRequired === undefined || authorizations.includes(authorizationRequired)) {
      return (
        <React.Fragment>
          {children}
        </React.Fragment>
      );
    } else if (user.newlyCreated) {
      return <NewlyCreated />;
    } else {
      return <Unauthorized />;
    }
  } else {
    return (
      <Redirect to={{
        pathname: "/login",
        state: { from: location }
      }}
      />
    );
  }
};
