import React from 'react';
// import moment from 'moment-timezone';
// import { UserOutlined, SolutionOutlined, LoadingOutlined, SmileOutlined } from '@ant-design/icons';
import firebase from 'firebase';
import 'firebase/firestore';
// import 'firebase/storage';
// import { v4 as uuidv4 } from 'uuid';
import {
  // Descriptions,
  // Tag,
  // Statistic,
  // Radio,
  // Modal,
  Button,
} from 'antd';
import {
  // liveEventStatusOptions,
  // momentFormat,
  generateFirestorePath,
} from 'src/helpers';

import {
  useSelector,
  // useDispatch,
} from 'react-redux';
import './index.css';
/** type imports */
import type { RootState } from 'src/app/rootReducer';
// import type { AppDispatch } from 'src/app/store';

const { firestore } = firebase;

const UserIntegrations: React.FC = () => {
  const userIntegrationsRef = React.useRef<firebase.firestore.CollectionReference | null>(null);
  const [integrations, setIntegrations] = React.useState<string[]>([]);
  const {
    user,
  } = useSelector((state: RootState) => state.userState);
  React.useEffect(() => {
    let userIntegrationsRefUnsubscribe: () => void | undefined;
    if (user) {

      userIntegrationsRef.current = firestore().collection(generateFirestorePath(`adminUsers/${user.uid}/userIntegrations`));

      userIntegrationsRefUnsubscribe = userIntegrationsRef.current.onSnapshot((integrationsSnapshot) => {
        console.log('onSnapShot fired');
        const currentIntegrations: string[] = [];
        integrationsSnapshot.forEach((integration) => {
          currentIntegrations.push(integration.id);
        });
        setIntegrations(currentIntegrations);
      }, (error) => {
        console.log(error);
      });
    }
    return () => {
      if (userIntegrationsRefUnsubscribe) {
        userIntegrationsRefUnsubscribe();
      }
    };
  }, [user]);

  return (
    <div className='user-integrations-manager'>
      <div className='user-integrations-manager-header'>User Integrations</div>
      {integrations.map((integration) => {
        async function handleDelete() {
          try {
            const integrationRef = userIntegrationsRef.current?.doc(integration);
            await integrationRef?.delete();
          } catch (error) {
            console.error(error);
          }
        }
        return (
          <div className='user-integration-details' key={integration}>
            <div className='integration-name'>{integration}</div>
            <Button
              onClick={handleDelete}
              danger
              type={'primary'}
            >
              Delete
            </Button>
          </div>
        );
      })}
    </div>
  );
};

export default UserIntegrations;
