
import React from 'react';
import { Container } from './Container';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
/** type imports */
import type { Story } from 'types';

export interface ContainerProps {
  seasonId: string;
  seasonTitle: string;
  stories: { [storyId: string]: Story };
  onCancel: () => void;
}
const DrDp: React.FC<ContainerProps> = (props: ContainerProps) => {
  return (
    <DndProvider backend={HTML5Backend} >
      <Container {...props} />
    </DndProvider>
  );
};

export default DrDp;

