import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Form,
  Button,
  Select,
  Divider,
  Card,
  InputNumber,
  Switch,
} from 'antd';
import {
  CloseOutlined,
  CheckOutlined,
} from '@ant-design/icons';
// import {
//   fetchTagDefinitions,
// } from 'src/features/tagDefinitions/tagDefinitionsSlice';
import {
  fetchFileReferences,
  // addPlaceholderAssetReference,
} from 'src/features/storage/storageSlice';
import { v4 as uuidv4 } from 'uuid';
import {
  // colorThemeOptions,
  // difficultyLevelOptions,
  transformFiles
} from 'src/helpers';
/** type imports */
import type { AppDispatch } from 'src/app/store';
import type { RootState } from 'src/app/rootReducer';
import type { FormInstance } from 'antd/es/form';
import type { FormikErrors } from 'formik';
import type {
  LiveBuilderImageSelectorNode,
  LiveBuilderImageOption,
  FormItemProps,
} from 'types';


interface Props {
  nodeDefinition: Partial<LiveBuilderImageSelectorNode>;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => any;
  handleChange: (eventOrPath: string | React.ChangeEvent<any>) => void | ((eventOrTextValue: string | React.ChangeEvent<any>) => void);
  handleBlur: (eventOrString: any) => void | ((e: any) => void);
  form: FormInstance;
  isSubmitting: boolean;
  errors: FormikErrors<{ id: string; nodeDefinition: Partial<LiveBuilderImageSelectorNode>; }>;
  setErrors: (errors: FormikErrors<{ id: string; nodeDefinition: Partial<LiveBuilderImageSelectorNode>; }>) => void;
}
const LiveBuilderImageSelectorFormBody: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const { fetchingFileReferences } = useSelector((state: RootState) => state.storageState);
  const { imageFiles } = useSelector((state: RootState) => state.storageState.fileReferences);

  React.useEffect(() => {
    if (imageFiles.length === 0) {
      dispatch(fetchFileReferences());
    }
  }, [dispatch, imageFiles]);

  const {
    nodeDefinition,
    setFieldValue,
    // handleChange,
    // handleBlur,
    isSubmitting,
    errors,
    form,
  } = props;

  const {
    imageOverlay,
    startTime,
    endTime,
    includeInAssemblerNode, 
    options = {},
  } = nodeDefinition;

  /** this sets the defaults for boolean fields */
  React.useEffect(() => {

    if (includeInAssemblerNode === undefined) {
      setFieldValue('nodeDefinition.includeInAssemblerNode', true);
    }

    /** you can ignore the ts-lint issue here */
  }, []);


  const optionsArray = Object.entries(options);

  function addBlankAnswerDef(/* event: React.MouseEvent<HTMLButtonElement, MouseEvent> */): void {
    // not clear if we need to do this
    // event.preventDefault();
    const newId = uuidv4();
    const blankAnswer = {
      displayOrder: optionsArray.length,
      points: 10,
      defaultPosition: positionOptions[4].value,
    };
    setFieldValue('nodeDefinition.options', {
      ...options,
      [newId]: blankAnswer,
    });
    /** 
     * without setting the fields manually using antd
     * the display order doesn't populate
     */
    form.setFieldsValue({
      nodeDefinition: {
        options: {
          [newId]: blankAnswer,
        },
      },
    });
  }


  type QuestionFormErrors = {
    [K in keyof LiveBuilderImageSelectorNode]?: string;
  }

  interface AnswerFormErrors {
    [answerDefKey: string]: string;
  }

  function generateFormItemProps(itemName: keyof LiveBuilderImageSelectorNode): FormItemProps {
    const hasError = itemName in errors;
    return {
      name: ['nodeDefinition', itemName],
      validateStatus: hasError ? 'error' : '',
      hasFeedback: hasError,
      help: hasError ? (errors as QuestionFormErrors)[itemName] : false,
    };
  }

  const imageOptions = transformFiles(imageFiles);
  // const videoOptions = transformFiles(videoFiles);
  // const audioOptions = transformFiles(audioFiles);
  const positionOptions: { text: LiveBuilderImageOption['defaultPosition']; value: LiveBuilderImageOption['defaultPosition']; }[] = [
    { text: 'top-left', value: 'top-left' },
    { text: 'top-center', value: 'top-center' },
    { text: 'top-right', value: 'top-right' },
    { text: 'middle-left', value: 'middle-left' },
    { text: 'middle-center', value: 'middle-center' },
    { text: 'middle-right', value: 'middle-right' },
    { text: 'bottom-left', value: 'bottom-left' },
    { text: 'bottom-center', value: 'bottom-center' },
    { text: 'bottom-right', value: 'bottom-right' },
  ];

  // const audioPlaceholder = 'audio/example.mp3';
  const imagePlaceholder = 'image/example.jpeg';
  // const videoPlaceholder = 'video/example.mp4';
  if (Object.keys(errors).length) {
    console.log(errors);
  }

  const usedOptionIds: string[] = Object.values(nodeDefinition.edges || {})
    .filter(({ context }) => context && context.optionIds !== undefined)
    .map(({ context }) => context?.optionIds ?? [])
    .flat();
  return (
    <React.Fragment>
      <Divider>Live Builder Image Selector</Divider>
      <Form.Item
        {...generateFormItemProps('includeInAssemblerNode')}
        label={'Include In Builder Image Assembler Node'}
      >
        <Switch
          disabled={isSubmitting}
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          checked={includeInAssemblerNode}
        />
      </Form.Item>
      <Form.Item
        {...generateFormItemProps('imageOverlay')}
        label={'Image Overlay File'}
      >
        <Select
          allowClear
          showSearch
          options={imageOptions}
          disabled={isSubmitting}
          loading={fetchingFileReferences}
          value={imageOverlay}
          placeholder={imagePlaceholder}
        />
      </Form.Item>
      <Form.Item
        {...generateFormItemProps('startTime')}
        label={'Start Time (milliseconds)'}
      >
        <InputNumber
          disabled={isSubmitting}
          value={startTime}
        />
      </Form.Item>
      <Form.Item
        {...generateFormItemProps('endTime')}
        label={'End Time (milliseconds)'}
      >
        <InputNumber
          disabled={isSubmitting}
          value={endTime}
        />
      </Form.Item>
      <Card
        title={'Options'}
        actions={[
          <Form.Item key={'add_new_answer_definition'}>
            <Button
              type={'primary'}
              onClick={addBlankAnswerDef}
            >
              Add Answer
            </Button>
          </Form.Item>
        ]}
      >
        {optionsArray
          .sort(([, { displayOrder: displayOrderA = 0 }], [, { displayOrder: displayOrderB = 1 }]) => displayOrderA - displayOrderB)
          .map(([answerId, answerDefinition], index) => {
            const answerPath = `nodeDefinition.options.${answerId}`;
            const errorPath = `options.${answerId}`;
            const {
              image,
              points,
              layer,
              defaultPosition,
              displayOrder = index,
              // createdAt,
            } = answerDefinition;

            // let idDisplay = answerId;
            // if (!createdAt) {
            //   idDisplay = snakeCase(answer);
            // }

            const answerDefStyle: React.CSSProperties = {};

            let fieldsDisabled = false;
            if (isSubmitting) {
              fieldsDisabled = true;
            }

            function generateFormItemAnswerProps(itemName: keyof LiveBuilderImageOption): FormItemProps {
              const errorKey = `${errorPath}.${itemName}`;
              const hasError = errorKey in errors;
              return {
                name: ['nodeDefinition', 'options', answerId, itemName],
                validateStatus: hasError ? 'error' : '',
                hasFeedback: hasError,
                help: hasError ? (errors as AnswerFormErrors)[errorKey] : false,
              };
            }

            function deleteAnswerDef(/* event: React.MouseEvent<HTMLElement, MouseEvent> */): void {
              // not clear that we need to do this
              // event.preventDefault();
              setFieldValue(answerPath, undefined);

            }

            const innerCardStyle: React.CSSProperties = {};

            if (index !== 0) {
              // this spaces out the answers
              innerCardStyle.marginTop = 16;
            }
            const optionCurrentlyUsed = usedOptionIds.includes(answerId);
            let deleteButtonContent = 'Delete';
            if (optionCurrentlyUsed) {
              deleteButtonContent = 'Answer currently being used';
            }

            return (
              <Card
                key={answerId}
                headStyle={answerDefStyle}
                type='inner'
                style={innerCardStyle}
                title={<span>Answer ID: {answerId}</span>}
                size='small'
                actions={[
                  <Form.Item key={'delete_answer_definition'}>
                    <Button
                      danger
                      disabled={optionCurrentlyUsed}
                      size='small'
                      onClick={deleteAnswerDef}
                    >
                      {deleteButtonContent}
                    </Button>
                  </Form.Item>
                ]}
              >
                <Form.Item
                  {...generateFormItemAnswerProps('image')}
                  label={'Image File'}
                >
                  <Select
                    allowClear
                    showSearch
                    options={imageOptions}
                    disabled={fieldsDisabled}
                    loading={fetchingFileReferences}
                    value={image}
                    placeholder={imagePlaceholder}
                  />
                </Form.Item>
                <Form.Item
                  {...generateFormItemAnswerProps('points')}
                  label={'Points'}
                >
                  <InputNumber
                    disabled={fieldsDisabled}
                    value={points}
                  />
                </Form.Item>
                <Form.Item
                  {...generateFormItemAnswerProps('layer')}
                  label={'Layer'}
                >
                  <InputNumber
                    disabled={fieldsDisabled}
                    value={layer}
                  />
                </Form.Item>
                <Form.Item
                  {...generateFormItemAnswerProps('defaultPosition')}
                  label={'Default Position'}
                >
                  <Select
                    allowClear
                    options={positionOptions}
                    disabled={fieldsDisabled}
                    value={defaultPosition}
                  />
                </Form.Item>
                <Form.Item
                  {...generateFormItemAnswerProps('displayOrder')}
                  label={'Display Order'}
                >
                  <InputNumber
                    disabled={true}
                    value={displayOrder}
                  />
                </Form.Item>
              </Card>
            );
          })}
      </Card>
    </React.Fragment>
  );
};

export default LiveBuilderImageSelectorFormBody;