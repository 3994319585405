import * as yup from 'yup';
import { generateValidationFunc, chipPartCategoryOptions } from 'src/helpers';
/** import types */
import type firebase from 'firebase';
import type {
  ChipPart,
  SnapshotOptions,
} from 'types';

type ChipPartSnapshot = firebase.firestore.QueryDocumentSnapshot<ChipPart>;

export const chipPartConverter: firebase.firestore.FirestoreDataConverter<ChipPart> = {
  toFirestore(chipPart: ChipPart): ChipPart {
    return chipPart;
  },
  fromFirestore(snapshot: ChipPartSnapshot, options: SnapshotOptions): ChipPart {
    const data = snapshot.data(options);
    return data;
  },
};

export const chipPartSchema: yup.ObjectSchema<ChipPart> = yup.object().required().shape({
  appTag: yup.string().required().label('appTag'),
  category:  yup.string().required().oneOf(chipPartCategoryOptions).label('category'),
  description: yup.string().required().label('description'),
  image: generateValidationFunc('image').required().label('Part Image File'),
  thumbnailImage: generateValidationFunc('image').required().label('Thumbnail Image File'),
  title:  yup.string().required().label('description'),
});