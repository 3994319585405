import React from 'react';
import FeatureStoryFormBody from './FeatureStoryFormBody';
import LiveStoryFormBody from './LiveStoryFormBody';
/** type imports */
import type { Story, StoryFormState } from 'types';
import type { FormikErrors } from 'formik';
import type { FormInstance } from 'antd/lib/form';


interface Props {
  story: Partial<Story>;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => any;
  handleChange: (eventOrPath: string | React.ChangeEvent<any>) => void | ((eventOrTextValue: string | React.ChangeEvent<any>) => void);
  handleBlur: (eventOrString: any) => void | ((e: any) => void);
  isSubmitting: boolean;
  form: FormInstance;
  errors: FormikErrors<StoryFormState>;
  setErrors: (errors: FormikErrors<StoryFormState>) => void;
}

const StoryRouter: React.FC<Props> = (props: Props) => {

  const {
    story,
    setFieldValue,
    handleChange,
    handleBlur,
    isSubmitting,
    errors,
    form,
    setErrors,
  } = props;

  switch (story.type) {
    case 'live': {
      return (
        <LiveStoryFormBody
          story={story}
          setFieldValue={setFieldValue}
          handleChange={handleChange}
          handleBlur={handleBlur}
          errors={errors}
          isSubmitting={isSubmitting}
          setErrors={setErrors}
          form={form}
        />
      );
    }
    case 'feature': {
      return (
        <FeatureStoryFormBody
          story={story}
          setFieldValue={setFieldValue}
          handleChange={handleChange}
          handleBlur={handleBlur}
          errors={errors}
          isSubmitting={isSubmitting}
          setErrors={setErrors}
          form={form}
        />
      );
    }
    case 'checkpoint': {
      return (
        <FeatureStoryFormBody
          story={story}
          setFieldValue={setFieldValue}
          handleChange={handleChange}
          handleBlur={handleBlur}
          errors={errors}
          isSubmitting={isSubmitting}
          setErrors={setErrors}
          form={form}
        />
      );
    }
    case 'mini': {
      return (
        <FeatureStoryFormBody
          story={story}
          setFieldValue={setFieldValue}
          handleChange={handleChange}
          handleBlur={handleBlur}
          errors={errors}
          isSubmitting={isSubmitting}
          setErrors={setErrors}
          form={form}
        />
      );
    }
    default: {
      return (
        <div>nothing to see here</div>
      );
    }
  }
};

export default StoryRouter;
