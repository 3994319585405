import React from "react";
import {
  Form,
  Input,
  Button,
  Divider,
  PageHeader,
  Alert,
  Select,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  addChipPart,
  updateChipPart,
  fetchChipParts,
} from "src/features/chipParts/chipPartsSlice";
import { CloseOutlined } from "@ant-design/icons";
import { fetchFileReferences } from "src/features/storage/storageSlice";
import { fetchAppTagDefinitions } from 'src/features/appTagDefinitions/appTagDefinitionsSlice';
import merge from "deepmerge";
import isPlainObject from "is-plain-object";
import { useFormik } from "formik";
import {
  overwriteMerge,
  transformFiles,
  chipPartCategoryOptions,
} from "src/helpers";
/** type imports */
import type { Store } from "rc-field-form/es/interface";
import type { AppDispatch } from "src/app/store";
import type { RootState } from "src/app/rootReducer";
import type { FormItemProps, ChipPart, ChipFormState } from "types";

const chipPartCategories = chipPartCategoryOptions.map((category) => ({
  label: category,
  value: category,
}));

interface Props {
  chipFormState: ChipFormState;
  onCancel: () => void;
  editMode: boolean;
}

const ChipPartFormContainer: React.FC<Props> = ({
  chipFormState,
  onCancel,
  editMode,
}: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    fileReferences: { imageFiles },
    fetchingFileReferences,
  } = useSelector((state: RootState) => state.storageState);
  const imageOptions = transformFiles(imageFiles);

  const {
    appTagDefinitions,
    fetchingAppTagDefinitions,
  } = useSelector((state: RootState) => state.appTagDefinitionsState);

  React.useEffect(() => {
    dispatch(fetchChipParts());
    dispatch(fetchFileReferences());
    dispatch(fetchAppTagDefinitions());
  }, [dispatch]);

  const [antdForm] = Form.useForm();

  const formik = useFormik<ChipFormState>({
    initialValues: chipFormState,
    enableReinitialize: true,
    onSubmit: async (values, formikHelpers): Promise<void> => {
      const { id, chipPart } = values;
      const { setSubmitting, resetForm, setErrors } = formikHelpers;
      let success, validationError;
      try {
        if (editMode) {
          ({ success, validationError } = await dispatch(
            updateChipPart(id, chipPart)
          ));
        } else {
          ({ success, validationError } = await dispatch(
            addChipPart(chipPart)
          ));
        }
        setSubmitting(false);
        if (success) {
          if (!editMode) {
            resetForm();
            antdForm.resetFields();
          }
        } else if (validationError) {
          console.log(validationError);
          const { path, message } = validationError;
          setErrors({
            [path]: message,
          });
        }
      } catch (error) {
        console.log("Non validation error:", error);
      }
    },
  });
  const appTagDefinitionOptions = Object.entries(appTagDefinitions)
    .map(([id, { displayName }]) => ({
      label: displayName,
      value: id,
    }));
  const imagePlaceholder = "image/example.jpeg";

  const {
    values: formikValues,
    errors,
    // handleChange,
    // handleBlur,
    handleSubmit,
    isSubmitting,
    //setFieldValue,
    // setSubmitting,
    // setErrors,
    /* and other goodies */
    initialValues: formikInitialValues,
    setValues,
    dirty,
  } = formik;

  React.useEffect(() => {
    antdForm.resetFields();
  }, [antdForm, formikInitialValues]);

  function handleValuesChange(
    changedValues: Store /*, currentValues: Store */
  ): void {
    const newValues = merge<ChipFormState, Store>(formikValues, changedValues, {
      arrayMerge: overwriteMerge,
      isMergeableObject: isPlainObject,
    });
    setValues(newValues);
  }

  type FormErrors = {
    [K in keyof ChipPart]?: string;
  };

  function generateFormItemProps(itemName: keyof ChipPart): FormItemProps {
    const hasError = itemName in errors;
    return {
      name: ["chipPart", itemName],
      validateStatus: hasError ? "error" : "",
      hasFeedback: hasError,
      help: hasError ? (errors as FormErrors)[itemName] : false,
    };
  }

  const { id, chipPart } = formikValues;
  const { appTag, category, description, image, thumbnailImage, title } =
    chipPart;

  const questionDefStyle = {};


  const BottomButtons: React.FC = () => {
    if (editMode) {

      let updateButtonContent = "Update chip part";
      if (!dirty) {
        updateButtonContent = "Make a change to update";
      }
      return (
        <Form.Item>
          <Button
            block
            size={"large"}
            type={"primary"}
            htmlType={"submit"}
            loading={isSubmitting}
            disabled={isSubmitting || !dirty}
          >
            {updateButtonContent}
          </Button>

        </Form.Item>
      );
    } else {
      return (
        <Form.Item>
          <Button
            block
            size={"large"}
            type={"primary"}
            htmlType={"submit"}
            loading={isSubmitting}
            disabled={isSubmitting}
          >
            Create new chip part
          </Button>
        </Form.Item>
      );
    }
  };

  return (
    <React.Fragment>
      <Form
        size={"middle"}
        layout={"vertical"}
        initialValues={formikInitialValues}
        form={antdForm}
        onValuesChange={handleValuesChange}
        onSubmitCapture={handleSubmit}
      >
        <PageHeader
          title={
            <Form.Item name={"id"}>
              <span style={questionDefStyle}>ID: {id}</span>
            </Form.Item>
          }
          extra={[
            <Button
              key={"close_sidebar"}
              danger
              icon={<CloseOutlined />}
              onClick={onCancel}
            />,
          ]}
        >
          <Form.Item
            {...generateFormItemProps("appTag")}
            label={'App Tags'}
          >
            <Select
              allowClear
              mode={'multiple'}
              options={appTagDefinitionOptions}
              disabled={isSubmitting}
              loading={fetchingAppTagDefinitions}
              value={appTag}
              placeholder='Dinosaurs, Biology...'
            />
          </Form.Item>

          <Form.Item {...generateFormItemProps("category")} label={"Category"}>
            <Select
              allowClear
              options={chipPartCategories}
              disabled={isSubmitting}
              value={category}
              placeholder={"hat"}
            />
          </Form.Item>
          <Form.Item {...generateFormItemProps("description")} label={"Description"}>
            <Input
              placeholder={"description"}
              disabled={isSubmitting}
              value={description}
            />
          </Form.Item>
          <Form.Item {...generateFormItemProps("image")} label={"Image File"}>
            <Select
              allowClear
              showSearch
              options={imageOptions}
              disabled={isSubmitting}
              loading={fetchingFileReferences}
              value={image}
              placeholder={imagePlaceholder}
            />
          </Form.Item>
          <Form.Item {...generateFormItemProps("thumbnailImage")} label={"Thumbnail Image File"}>
            <Select
              allowClear
              showSearch
              options={imageOptions}
              disabled={isSubmitting}
              loading={fetchingFileReferences}
              value={thumbnailImage}
              placeholder={imagePlaceholder}
            />
          </Form.Item>
          <Form.Item {...generateFormItemProps("title")} label={"Title"}>
            <Input
              placeholder={"title"}
              disabled={isSubmitting}
              value={title}
            />
          </Form.Item>


          <Divider />
          {Object.values(errors).map((error, index) => (
            <Alert
              style={{ marginBottom: 10 }}
              showIcon
              type={"error"}
              key={index}
              message={error}
            />
          ))}
          <BottomButtons />
        </PageHeader>
      </Form>
    </React.Fragment>
  );
};

export default ChipPartFormContainer;
