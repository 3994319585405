import React from 'react';
import { Card } from './Card';
import { PageHeader, Button, Divider } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import update from 'immutability-helper';
import { useDispatch } from 'react-redux';
import {
  updateStoriesOrder,
} from 'src/features/seasons/singleSeasonSlice';
/** type imports */
import type { Story } from 'types';
import type { ContainerProps } from './index';
import type { AppDispatch } from 'src/app/store';
// import type { RootState } from 'src/app/rootReducer';

const style = {
  // width: 400,
};

export interface Item {
  id: number
  text: string
}
export interface ContainerState {
  cards: Item[]
}

type CardDatum = Story & { id: string };

function convertStories(stories: { [storyId: string]: Story }): CardDatum[] {
  return Object.entries(stories)
    .map(([id, story]) => ({ id, ...story }))
    .sort(({ order: orderA }, { order: orderB }) => orderA - orderB);
}

export const Container: React.FC<ContainerProps> = ({ seasonTitle, seasonId, stories, onCancel }: ContainerProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const initialCards = convertStories(stories);
  const [cards, setCards] = React.useState(initialCards);
  const [isSubmitting, setSubmitting] = React.useState<boolean>(false);
  React.useEffect(() => {
    setCards(convertStories(stories));
  }, [stories]);

  async function handleSubmit() {
    try {
      setSubmitting(true);
      const storiesOrder: { [storyId: string]: number } = {};
      cards.forEach(({ id }, index) => storiesOrder[id] = index + 1);
      await dispatch(updateStoriesOrder(seasonId, storiesOrder));
      setSubmitting(false);
    } catch (error) {
      console.log(error);
    }
  }

  const moveCard = React.useCallback((dragIndex: number, hoverIndex: number) => {
    const dragCard = cards[dragIndex];
    setCards(
      update(cards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragCard],
        ],
      }),
    );
  },
    [cards],
  );

  let dirty = false;
  cards.forEach(({ order }, index) => {
    if (order !== index + 1) {
      dirty = true;
    }
  });

  const renderCard = (card: CardDatum, index: number) => {
    return (
      <Card
        key={card.id}
        index={index}
        id={card.id}
        story={card}
        moveCard={moveCard}
      />
    );
  };

  let updateButtonContent = 'Make a change to update';
  if (dirty) {
    updateButtonContent = 'Update stories order';
  }

  return (
    <React.Fragment>
      <PageHeader
        title={<span style={{ fontWeight: 'lighter' }}>Season: <span style={{ fontWeight: 'bold' }}>{seasonTitle}</span></span>}
        extra={[
          <Button
            key={'close_sidebar'}
            danger
            icon={<CloseOutlined />}
            onClick={onCancel}
          />
        ]}
      >
        <div style={style}>{cards.map((card, i) => renderCard(card, i))}</div>
        <Divider />
        <Button
          block
          size={'large'}
          type={'primary'}
          onClick={handleSubmit}
          loading={isSubmitting}
          disabled={isSubmitting || !dirty}
        >
          {updateButtonContent}
        </Button>
      </PageHeader>
    </React.Fragment>
  );

};
