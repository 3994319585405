import React from 'react';
import QuestionNodeDefinitionForm from './QuestionDetailFormBody';
import type { NodeDefinition, NodeFormState } from 'types';
import type { FormikErrors } from 'formik';
import type { FormInstance } from 'antd/lib/form';

interface Props {
  nodeDefinition: Partial<NodeDefinition>;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => any;
  handleChange: (eventOrPath: string | React.ChangeEvent<any>) => void | ((eventOrTextValue: string | React.ChangeEvent<any>) => void);
  handleBlur: (eventOrString: any) => void | ((e: any) => void);
  isSubmitting: boolean;
  form: FormInstance;
  errors: FormikErrors<NodeFormState>;
  setErrors: (errors: FormikErrors<NodeFormState>) => void;
}

const NodeRouter: React.FC<Props> = (props: Props) => {

  const {
    nodeDefinition,
    setFieldValue,
    handleChange,
    handleBlur,
    isSubmitting,
    errors,
    form,
    setErrors,
  } = props;

  switch (nodeDefinition.type) {
    case 'Spud multi-choice question': {
      return (
        <QuestionNodeDefinitionForm
          nodeDefinition={nodeDefinition}
          setFieldValue={setFieldValue}
          handleChange={handleChange}
          handleBlur={handleBlur}
          errors={errors}
          isSubmitting={isSubmitting}
          setErrors={setErrors}
          form={form}
        />
      );
    }
    default: {
      return (<div>Nothing to see here</div>);
    }
  }
};

export default NodeRouter;
