import { createSlice } from '@reduxjs/toolkit';
import firebase from 'firebase';
import { generateFirestorePath } from 'src/helpers';
import { fetchStory } from './singleStorySlice';
import * as yup from 'yup';
import moment from 'moment';
import 'firebase/firestore';
import 'firebase/auth';
import { storyConverter, generateCreateNewStorySchema, generateStoryDetailsSchema } from './helpers';
import { seasonConverter } from 'src/features/seasons/helpers';

import type { AsyncAppThunk } from 'src/app/store';
import type {
  Story,
  LiveStory,
} from 'types';
import type { PayloadAction } from '@reduxjs/toolkit';

interface SuccessReturn {
  success: true;
  validationError: null;
}
interface FailureReturn {
  success: false;
  validationError: yup.ValidationError;
}

type ThunkReturn = SuccessReturn | FailureReturn;

const { firestore } = firebase;
interface StoriesState {
  stories: { [seasonId: string]: Story };
  fetchingStories: boolean;
}

const initialState: StoriesState = {
  stories: {},
  fetchingStories: true,
};

const storiesSlice = createSlice({
  name: 'stories',
  initialState,
  reducers: {
    setStories(state, action: PayloadAction<{ [storyId: string]: Story }>): void {
      state.stories = action.payload;
      state.fetchingStories = false;
    },
    setToLoading(state): void {
      state.fetchingStories = true;
    },
  },
});

const {
  setStories,
  setToLoading,
} = storiesSlice.actions;

// thunk creator
export function fetchStories(seasonId: string): AsyncAppThunk<void> {
  return async function thunk(dispatch, getState): Promise<void> {
    try {
      const user = getState().userState.user;
      if (!user) {
        throw new Error('Not authenticated');
      }
      dispatch(setToLoading());
      const stories: { [storyId: string]: Story } = {};
      const storyColSnapshot = await firestore()
        .collection(generateFirestorePath(`seasons/${seasonId}/stories`))
        .withConverter(storyConverter)
        .orderBy('createdAt', 'desc')
        .get();

      storyColSnapshot.forEach((storySnapshot) => {
        if (storySnapshot.exists) {
          const id = storySnapshot.id;
          const story = storySnapshot.data();
          stories[id] = story;
        }
      });

      dispatch(setStories(stories));
    } catch (error) {
      console.log('Error in fetchStories():', error);
    }
  };
}

export function addStory(seasonId: string, newStoryId: string, newStory: Partial<Story>): AsyncAppThunk<ThunkReturn> {
  return async function thunk(dispatch, getState): Promise<ThunkReturn> {
    try {
      const user = getState().userState.user;
      if (!user) {
        throw new Error('Not authenticated');
      }
      newStory.createdBy = user.uid;
      const createNewStorySchema = generateCreateNewStorySchema(newStory);
      const story = await createNewStorySchema.validate(newStory);
      const newStoryRef = firestore()
        .collection(generateFirestorePath(`seasons/${seasonId}/stories`))
        .withConverter(storyConverter)
        .doc(newStoryId);
  
      const storyData = await newStoryRef.get();
      if (storyData.exists) {
        throw new yup.ValidationError('Story already exists', 'Already exists', '');
      }

      await newStoryRef.set(story);

      await dispatch(fetchStories(seasonId));
      return {
        success: true,
        validationError: null,
      };
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        return {
          success: false,
          validationError: error,
        };
      } else {
        console.log('Error in addStory():', error);
        throw error;
      }
    }
  };
}

export function initializeLiveStory(seasonId: string, newStoryId: string, newStory: (Partial<LiveStory>)): AsyncAppThunk<ThunkReturn> {
  return async function thunk(dispatch, getState): Promise<ThunkReturn> {
    try {
      const user = getState().userState.user;
      if (!user) {
        throw new Error('Not authenticated');
      }
      newStory.createdBy = user.uid;
      newStory.createdAt = moment().valueOf();
      const seasonRef = firestore()
        .collection(generateFirestorePath('seasons'))
        .withConverter(seasonConverter)
        .doc(seasonId);

      const storyColRef = firestore()
        .collection(generateFirestorePath(`seasons/${seasonId}/stories`))
        .withConverter(storyConverter);

      const storyColSnapshot = await storyColRef.orderBy('createdAt', 'desc').get();

      let order = 1;
      storyColSnapshot.forEach((storySnapshot) => {
        if (storySnapshot.exists) {
          order++;
        }
      });
      newStory.order = order;

      const newStoryRef = storyColRef.doc(newStoryId);

      const storyData = await newStoryRef.get();
      if (storyData.exists) {
        throw new yup.ValidationError('Story already exists', 'Already exists', '');
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      await newStoryRef.set(newStory);
      await seasonRef.update({
        currentLiveStoryId: newStoryId,
      });

      await dispatch(fetchStories(seasonId));
      return {
        success: true,
        validationError: null,
      };
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        return {
          success: false,
          validationError: error,
        };
      } else {
        console.log('Error in addStory():', error);
        throw error;
      }
    }
  };
}


export function updateStory(seasonId: string, storyId: string, updatedStory: Partial<Story>): AsyncAppThunk<ThunkReturn> {
  return async function thunk(dispatch, getState): Promise<ThunkReturn> {
    try {
      const user = getState().userState.user;
      if (!user) {
        throw new Error('Not authenticated');
      }

      const schema = generateStoryDetailsSchema(updatedStory);
      const validated = await schema.validate(updatedStory);

      const storyRef = firestore()
        .collection(generateFirestorePath(`seasons/${seasonId}/stories`))
        .withConverter(storyConverter)
        .doc(storyId);

      const storyData = await storyRef.get();
      if (!storyData.exists) {
        throw new yup.ValidationError('Story does not exist', 'Does not exist', '');
      }
      let mergeFields = ["updatedAt", "title", "description", "searchKeywords", "tags", "appTags", "characterProfileId", "coverImage", "type", "isFree", "minsToComplete", "classKitTitle", "discussionQuestions"];
      if (validated.type === 'live') {
        mergeFields = ["updatedAt", "title", "description", "searchKeywords", "tags", "appTags", "characterProfileId", "coverImage", "type", "subType", "isFree", "minsToComplete", "classKitTitle", "discussionQuestions", "video", "startTime"];
      }

      await storyRef.set(validated, { mergeFields });

      await dispatch(fetchStory(seasonId, storyId));
      await dispatch(fetchStories(seasonId));
      return {
        success: true,
        validationError: null,
      };
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        return {
          success: false,
          validationError: error,
        };
      } else {
        console.log('Error in updateStory():', error);
        throw error;
      }
    }
  };
}


export default storiesSlice.reducer;