import React from 'react';
import {
  Form,
  Input,
  Button,
  Divider,
  Select,
  PageHeader,
  Switch,
  Alert,
} from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import {
  addTagDefinition,
  updateTagDefinition,
} from 'src/features/tagDefinitions/tagDefinitionsSlice';
import merge from 'deepmerge';
import isPlainObject from 'is-plain-object';
import { useFormik } from 'formik';
import { transformFiles, overwriteMerge } from 'src/helpers';
import { snakeCase } from 'lodash';
/** type imports */
import type { Store } from 'rc-field-form/es/interface';
import type { AppDispatch } from 'src/app/store';
import {
  fetchFileReferences,
} from 'src/features/storage/storageSlice';
import type { RootState } from 'src/app/rootReducer';
import type {
  FormItemProps,
  TagDefinition,
  TagFormState,
} from 'types';
import { fetchAppTagDefinitions } from 'src/features/appTagDefinitions/appTagDefinitionsSlice';

interface Props {
  tagFormState: TagFormState;
  onCancel: () => void;
  editMode: boolean;
}

const TagDefinitionFormContainer: React.FC<Props> = ({ tagFormState, onCancel, editMode }: Props) => {
  const dispatch = useDispatch<AppDispatch>();

  const [antdForm] = Form.useForm();

  const {
    fileReferences: {
      imageFiles,
      // videoFiles,
      // audioFiles,
    },
    fetchingFileReferences,
  } = useSelector((state: RootState) => state.storageState);
  const {
    appTagDefinitions,
    fetchingAppTagDefinitions,
  } = useSelector((state: RootState) => state.appTagDefinitionsState);

  const formik = useFormik<TagFormState>({
    initialValues: tagFormState,
    enableReinitialize: true,
    onSubmit: async (values, formikHelpers): Promise<void> => {
      const { id, tagDefinition } = values;
      const { setSubmitting, resetForm, setErrors } = formikHelpers;
      let success, validationError;
      try {
        if (editMode) {
          ({ success, validationError } = await dispatch(updateTagDefinition(id, tagDefinition)));
        } else {
          ({ success, validationError } = await dispatch(addTagDefinition(id, tagDefinition)));
        }
        setSubmitting(false);
        if (success) {
          if (!editMode) {
            resetForm();
            antdForm.resetFields();
          }
        } else if (validationError) {
          console.log(validationError);
          const { path, message } = validationError;
          setErrors({
            [path]: message,
          });
        }
      } catch (error) {
        console.log('Non validation error:', error);
      }
    },
  });

  const {
    values: formikValues,
    errors,
    // handleChange,
    // handleBlur,
    handleSubmit,
    isSubmitting,
    // setFieldValue,
    // setSubmitting,
    // setErrors,
    /* and other goodies */
    initialValues: formikInitialValues,
    setValues,
    dirty,
  } = formik;

  React.useEffect(() => {
    antdForm.resetFields();
  }, [antdForm, formikInitialValues]);

  React.useEffect(() => {
    dispatch(fetchFileReferences());
    dispatch(fetchAppTagDefinitions());
  }, [dispatch]);

  const imageOptions = transformFiles(imageFiles);
  const imagePlaceholder = 'image/example.jpeg';

  function handleValuesChange(changedValues: Store /*, currentValues: Store */): void {
    const newValues = merge<TagFormState, Store>(formikValues, changedValues, {
      arrayMerge: overwriteMerge,
      isMergeableObject: isPlainObject,
    });

    if (!editMode) {
      const { tagDefinition: { displayName } } = newValues;
      newValues.id = snakeCase(displayName);
    }
    setValues(newValues);
  }


  type FormErrors = {
    [K in keyof TagDefinition]?: string;
  }

  function generateFormItemProps(itemName: keyof TagDefinition): FormItemProps {
    const hasError = itemName in errors;
    return {
      name: ['tagDefinition', itemName],
      validateStatus: hasError ? 'error' : '',
      hasFeedback: hasError,
      help: hasError ? (errors as FormErrors)[itemName] : false,
    };
  }

  const { id, tagDefinition } = formikValues;
  const {
    displayName,
    emoji,
    coverImage,
    isUserFacing,
    appTags,
  } = tagDefinition;

  const questionDefStyle = {};
  // if (isDeleted) {
  //   questionDefStyle = {
  //     textDecorationLine: 'line-through',
  //     textDecorationStyle: 'solid',
  //   };
  // }

  // async function onDelete(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): Promise<void> {
  //   event.preventDefault();
  //   try {
  //     setSubmitting(true);
  //     // await dispatch(deleteNodeDefinition(id));
  //     console.log('deleted');
  //   } catch (error) {
  //     console.log('error:', error);
  //   }
  //   setSubmitting(false);
  // }

  const appTagDefinitionOptions = Object.entries(appTagDefinitions)
    .map(([id, { displayName }]) => ({
      label: displayName,
      value: id,
    }));

  const BottomButtons: React.FC = () => {
    if (editMode) {
      // let deleteButtonContent = 'Archive Story';
      // if (status === 'archived') {
      //   deleteButtonContent = 'Re-Publish Story';
      // }
      let updateButtonContent = 'Update tag definition';
      if (!dirty) {
        updateButtonContent = 'Make a change to update';
      }
      return (
        <Form.Item>
          <Button
            block
            size={'large'}
            type={'primary'}
            htmlType={'submit'}
            loading={isSubmitting}
            disabled={isSubmitting || !dirty}
          >
            {updateButtonContent}
          </Button>
          {/* <Button
            block
            danger
            onClick={onDelete}
            size={'large'}
            type={'primary'}
            loading={isSubmitting}
            disabled={isSubmitting}
          >
            {deleteButtonContent}
          </Button> */}
        </Form.Item>
      );
    } else {
      return (
        <Form.Item>
          <Button
            block
            size={'large'}
            type={'primary'}
            htmlType={'submit'}
            loading={isSubmitting}
            disabled={isSubmitting}
          >
            Create new tag definition
          </Button>
        </Form.Item>
      );
    }
  };
  
  return (
    <React.Fragment>
      <Form
        size={'middle'}
        layout={'vertical'}
        initialValues={formikInitialValues}
        form={antdForm}
        onValuesChange={handleValuesChange}
        onSubmitCapture={handleSubmit}
      >
        <PageHeader
          title={<Form.Item name={'id'}><span style={questionDefStyle}>ID: {id}</span></Form.Item>}
          extra={[
            <Button
              key={'close_sidebar'}
              danger
              icon={<CloseOutlined />}
              onClick={onCancel}
            />
          ]}
        >
          <Form.Item
            {...generateFormItemProps('displayName')}
            label={'Display Name'}
          >
            <Input
              placeholder={'Biology'}
              disabled={isSubmitting}
              value={displayName}
            />
          </Form.Item>
          <Form.Item
            {...generateFormItemProps('emoji')}
            label={'Emoji'}
          >
            <Input
              placeholder={'🚀'}
              disabled={isSubmitting}
              value={emoji}
            />
          </Form.Item>

          <Form.Item
            {...generateFormItemProps('coverImage')}
            label={'Cover Image File'}
          >
            <Select
              allowClear
              showSearch
              options={imageOptions}
              disabled={isSubmitting}
              loading={fetchingFileReferences}
              value={coverImage}
              placeholder={imagePlaceholder}
            />
          </Form.Item>

          <Form.Item
            {...generateFormItemProps('isUserFacing')}
            label={'Is user facing'}
          >
            <Switch
              disabled={isSubmitting}
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              checked={isUserFacing}
            />
          </Form.Item>
          <Form.Item
            {...generateFormItemProps("appTags")}
            label={'App Tags'}
          >
            <Select
              allowClear
              mode={'multiple'}
              options={appTagDefinitionOptions}
              disabled={isSubmitting}
              loading={fetchingAppTagDefinitions}
              value={appTags}
              placeholder='Dinosaurs, Biology...'
            />
          </Form.Item>

          <Divider />
          {Object.values(errors).map((error, index) => <Alert style={{ marginBottom: 10 }} showIcon type={'error'} key={index} message={error} />)}
          <BottomButtons />
        </PageHeader>
      </Form>
    </React.Fragment>
  );
};

export default TagDefinitionFormContainer;