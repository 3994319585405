import dayjs from 'dayjs';
import * as yup from 'yup';
import {
  liveEventStatusKeys,
  generateValidationFunc,
} from 'src/helpers';
import { liveBuilderImageSelectorNode } from 'src/features/nodes/helpers';
/** type imports */
import type {
  LiveEvent,
  SnapshotOptions,
  LiveBuilderImageSelectorNode,
} from 'types';
import type firebase from 'firebase';

export const liveEventConverter: firebase.firestore.FirestoreDataConverter<LiveEvent> = {
  toFirestore(liveEvent: LiveEvent): LiveEvent {
    if ('updatedAt' in liveEvent && liveEvent.updatedAt === undefined) {
      liveEvent.updatedAt = dayjs().valueOf();
    }
    return liveEvent;
  },
  fromFirestore(snapshot: firebase.firestore.QueryDocumentSnapshot<LiveEvent>, options: SnapshotOptions): LiveEvent {
    const data = snapshot.data(options);
    return data;
  },
};


export const liveEventSchema: yup.ObjectSchema<LiveEvent> = yup.object().required().shape<LiveEvent>({
  title: yup.string().required().label('Title'),
  description: yup.string().required().label('Description'),
  seasonId: yup.string().defined().label('Season'),
  status: yup.string().defined().oneOf(liveEventStatusKeys).label('Status'),
  images: yup.array().of(generateValidationFunc('image').defined()).defined().label('Images'),
  characterProfileId: yup.string().required().label('Character/Performer Profile'),
  eventDatetime: yup.number().required().label('Event Datetime'),
  createdBy: yup.string().required().label('Created By'),
  hasBadge: yup.boolean().required().label('Does this event have a badge?'),
  selfieImage: generateValidationFunc('image').label('Selfie Image'),
  assemblerBackgroundImage: generateValidationFunc('image').label('Assembler Background Image'),
  nodeTemplates: yup.lazy<{ [nodeId: string]: LiveBuilderImageSelectorNode }>((nodes = {}) => {

    let nodesSchema: yup.ObjectSchema<{ [nodeId: string]: LiveBuilderImageSelectorNode }> = yup.object<{ [nodeId: string]: LiveBuilderImageSelectorNode }>()
      .label('Node Templates')
      .defined()
      .default(() => ({}));

    Object.keys(nodes).forEach((answerId) => {

      const nodeSchema: yup.ObjectSchema<LiveBuilderImageSelectorNode> = liveBuilderImageSelectorNode.required();

      nodesSchema = nodesSchema.shape({
        [answerId]: nodeSchema,
      });

    });

    return nodesSchema;
  }),
  createdAt: yup.number().required().default(() => dayjs().valueOf()),
  updatedAt: yup.number().required().default(() => dayjs().valueOf()).transform(() => dayjs().valueOf()),
  isDeleted: yup.boolean().required(),
}).test('assembler-background-image', 'You must select a background image for the assembler node', (liveEvent: LiveEvent): boolean | yup.ValidationError => {
  const nodes = liveEvent.nodeTemplates;
  let mustIncludeBackgroundImage = false;
  Object.entries(nodes).forEach(([, node]) => {
    if (node.type === 'Live Builder Image Selector' && node.includeInAssemblerNode) {
      mustIncludeBackgroundImage = true;
    }
  });
  if (mustIncludeBackgroundImage && !liveEvent.assemblerBackgroundImage) {
    throw new yup.ValidationError('This event is missing the assembler background image', 'An error occured', 'assemblerBackgroundImage');
  } else {
    return true;
  }
}).test('badge-image-selection', 'You must select a badge image', (liveEvent: LiveEvent): boolean | yup.ValidationError => {
  if (liveEvent.hasBadge && !liveEvent.selfieImage) {
    throw new yup.ValidationError('This event is missing the badge image', 'An error occured', 'selfieImage');
  } else {
    return true;
  }
});



