import React from 'react';
import {
  Form,
  Button,
  Select,
  PageHeader,
  Alert,
  Descriptions,
  Card,
  Tag,
} from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import {
  updateStoryNodeEdges
} from 'src/features/stories/singleStorySlice';
import merge from 'deepmerge';
import isPlainObject from 'is-plain-object';
import { useFormik } from 'formik';
import { overwriteMerge } from 'src/helpers';
/** type imports */
import type { Store } from 'rc-field-form/es/interface';
import type { CustomTagProps } from 'rc-select/es/interface/generator';
import type { AppDispatch } from 'src/app/store';
import type {
  Node,
  LiveNode,
  FormItemProps,
  Edge,
  antdColorOptions,
} from 'types';

export interface UserResponseFormState {
  edges: { [edgeId: string]: Edge };
}

interface Props {
  parentNodeId: string;
  storyNodes: { [nodeId: string]: Node | LiveNode };
  onCancel: () => void;
}


const UserResponseConfigurationForm: React.FC<Props> = ({ parentNodeId, storyNodes, onCancel }: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const [antdForm] = Form.useForm();
  const initialEdges = storyNodes[parentNodeId].edges;

  const formik = useFormik<UserResponseFormState>({
    initialValues: { edges: initialEdges },
    enableReinitialize: true,
    onSubmit: async (values, formikHelpers): Promise<void> => {
      console.log(values);
      const { edges } = values;
      const { setSubmitting, resetForm, setErrors } = formikHelpers;
      try {
        const { success, validationError } = await dispatch(updateStoryNodeEdges(parentNodeId, edges));
        setSubmitting(false);
        if (success) {
          resetForm();
          antdForm.resetFields();
        } else if (validationError) {
          console.log(validationError);
          const { path, message } = validationError;
          setErrors({
            [path]: message,
          });
        }
      } catch (error) {
        console.log('Non validation error:', error);
      }
    },
  });

  const {
    values: formikValues,
    errors,
    // handleChange,
    // handleBlur,
    handleSubmit,
    isSubmitting,
    // setFieldValue,
    // setSubmitting,
    // setErrors,
    /* and other goodies */
    initialValues: formikInitialValues,
    setValues,
    dirty,
  } = formik;

  React.useEffect(() => {
    antdForm.resetFields();
  }, [antdForm, formikInitialValues]);


  function handleValuesChange(changedValues: Store /*, currentValues: Store */): void {
    const newValues = merge<UserResponseFormState, Store>(formikValues, changedValues, {
      arrayMerge: overwriteMerge,
      isMergeableObject: isPlainObject,
    });

    setValues(newValues);
  }

  const parentNode = storyNodes[parentNodeId];

  const { edges } = formikValues;
  const targetNodeConfigs: [string, { edge: Edge; targetNode: Node | LiveNode; }][] = Object.entries(edges)
    .filter(([, { targetNodeId }]) => !storyNodes[targetNodeId].isDeleted)
    .sort(([, { displayOrder: displayOrderA }], [, { displayOrder: displayOrderB }]) => displayOrderA - displayOrderB)
    .map(([edgeId, edge]) => ([edgeId, { edge, targetNode: storyNodes[edge.targetNodeId] }]));

  const questionDefStyle: React.CSSProperties = {
    fontSize: 13,
    color: 'black',

  };

  const BottomButtons: React.FC = () => {
    let updateButtonContent = 'Update Edge Configurations';
    if (!dirty) {
      updateButtonContent = 'Make a change to update';
    }
    return (
      <Form.Item>
        <Button
          block
          size={'large'}
          type={'primary'}
          htmlType={'submit'}
          loading={isSubmitting}
          disabled={isSubmitting || !dirty}
        >
          {updateButtonContent}
        </Button>
      </Form.Item>
    );
  };

  const globalOptions: { label: string; value: string; disabled: boolean; isCorrect?: boolean }[] = [];
  switch (parentNode.type) {
    case 'Live Action Multiple Choice Question': {
      globalOptions.push(...Object.entries(parentNode.lamcAnswers)
        .filter(([, { isDeleted }]) => !isDeleted)
        .map(([id, { answer, isCorrect }]) => ({ label: answer, value: id, disabled: false, isCorrect })));
      break;
    }
    case 'Bot multi-choice question': {
      globalOptions.push(...Object.entries(parentNode.answerDefinitions)
        .filter(([, { isDeleted }]) => !isDeleted)
        .map(([id, { answer, isCorrect }]) => ({ label: answer, value: id, disabled: false, isCorrect })));
      break;
    }
    case 'Screen Cleaning': {
      globalOptions.push(...Object.entries(parentNode.screenCleanerOptions)
        .filter(([, { isDeleted }]) => !isDeleted)
        .map(([id, { displayName, isCorrect }]) => ({ label: displayName, value: id, disabled: false, isCorrect })));
      break;
    }
  }

  const selectedOptionIds: { [optionId: string]: string } = {};
  Object.entries(edges)
    .filter(([, { targetNodeId }]) => !storyNodes[targetNodeId].isDeleted)
    .forEach(([edgeId, edge]) => {
      const optionIds = edge.context?.optionIds || [];
      optionIds.forEach((optionId) => {
        selectedOptionIds[optionId] = edgeId;
      });
    });

  return (
    <React.Fragment>
      <Form
        size={'middle'}
        layout={'vertical'}
        initialValues={formikInitialValues}
        form={antdForm}
        onValuesChange={handleValuesChange}
        onSubmitCapture={handleSubmit}
      >
        <PageHeader
          title={<span style={questionDefStyle}>Parent Node ID: {parentNodeId}</span>}
          extra={[
            <Button
              key={'close_sidebar'}
              danger
              icon={<CloseOutlined />}
              onClick={onCancel}
            />
          ]}
        >
          <Descriptions style={{ marginBottom: 10, }} size='small' bordered layout='vertical' column={2} title='Parent Node Details'>
            <Descriptions.Item label='Display Name'>{parentNode.displayName}</Descriptions.Item>
            <Descriptions.Item label='Type'><Tag color={'cyan'}>{parentNode.type}</Tag></Descriptions.Item>
            <Descriptions.Item span={2} label='Description'>{parentNode.description}</Descriptions.Item>
          </Descriptions>
          <Card title={'User Response Configuration'} style={{ marginBottom: 10, }}>
            {targetNodeConfigs
              .map(([edgeId, targetNodeConfig], index) => {
                const {
                  targetNode,
                  edge: { context },
                } = targetNodeConfig;
                const localOptions = globalOptions.map((option) => {
                  const optionIsSelected = option.value in selectedOptionIds;
                  return {
                    ...option,
                    disabled: optionIsSelected && selectedOptionIds[option.value] !== edgeId,
                    color: (option.isCorrect) ? 'green' : 'red',
                  };
                });

                // let answerDefStyle: React.CSSProperties = {
                //   color: (isCorrect) ? 'green' : 'red',
                // };
                // if (answerIsDeleted) {
                //   answerDefStyle = {
                //     ...answerDefStyle,
                //     textDecorationLine: 'line-through',
                //     textDecorationStyle: 'solid',
                //   };
                // }
                // let fieldsDisabled = false;
                // if (isSubmitting || targetNode.isDeleted) {
                //   fieldsDisabled = true;
                // }

                function generateFormItemEdgeProps(): FormItemProps {
                  const errorKey = [edgeId, 'context', 'optionIds'].join('.');
                  const hasError = errorKey in errors;
                  return {
                    name: ['edges', edgeId, 'context', 'optionIds'],
                    validateStatus: hasError ? 'error' : '',
                    hasFeedback: hasError,
                    help: hasError ? (errors as any)[errorKey] : false,
                  };
                }

                // function deleteAnswerDef(/* event: React.MouseEvent<HTMLElement, MouseEvent> */): void {
                //   // not clear that we need to do this
                //   // event.preventDefault();
                //   if (createdAt) {
                //     // only updated the 'isDeleted' field if the answer has already been saved
                //     setFieldValue(`${answerPath}.isDeleted`, !answerIsDeleted);
                //   } else {
                //     // remove the answer if it hasn't been saved
                //     setFieldValue(answerPath, undefined);
                //   }
                // }
                function tagRender(props: CustomTagProps) {
                  const { label, value, closable, onClose } = props;
                  let color: antdColorOptions | undefined;
                  globalOptions.forEach(({ value: innerValue, isCorrect }) => {
                    if (innerValue === value) {
                      if (isCorrect !== undefined) {
                        if (isCorrect) {
                          color = 'green';
                        } else {
                          color = 'red';
                        }
                      }
                    }
                  });

                  return (
                    <Tag
                      color={color}
                      closable={closable}
                      onClose={onClose}
                      style={{ marginRight: 3 }}
                    >
                      {label}
                    </Tag>
                  );
                }

                const value = context?.optionIds || [];
                const innerCardStyle: React.CSSProperties = {};

                if (index !== 0) {
                  // this spaces out the answers
                  innerCardStyle.marginTop = 16;
                }

                return (
                  <Card
                    key={edgeId}
                    // headStyle={answerDefStyle}
                    type='inner'
                    style={innerCardStyle}
                    title={<span>Edge ID: {edgeId}</span>}
                    size='small'
                  >
                    <Descriptions style={{ marginBottom: 10, }} size='small' bordered layout='vertical' column={2} title='Target Node Details'>
                      <Descriptions.Item label='Display Name'>{targetNode.displayName}</Descriptions.Item>
                      <Descriptions.Item label='Type'><Tag color={'cyan'}>{targetNode.type}</Tag></Descriptions.Item>
                      <Descriptions.Item span={2} label='Description'>{targetNode.description}</Descriptions.Item>
                    </Descriptions>
                    <Form.Item
                      {...generateFormItemEdgeProps()}
                      label={'User Response Options'}
                    >
                      <Select
                        allowClear
                        mode={'multiple'}
                        tagRender={tagRender}
                        options={localOptions}
                        disabled={isSubmitting}
                        value={value}
                      />
                    </Form.Item>
                  </Card>
                );
              })}
          </Card>
          {Object.values(errors).map((error, index) => <Alert style={{ marginBottom: 10 }} showIcon type={'error'} key={index} message={error} />)}
          <BottomButtons />
        </PageHeader>
      </Form>
    </React.Fragment>
  );
};

export default UserResponseConfigurationForm;