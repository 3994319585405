import moment from 'moment';
import * as yup from 'yup';
import { generateValidationFunc, colorThemes } from 'src/helpers';
/** import types */
import type firebase from 'firebase';
import type {
  CharacterProfile,
  SnapshotOptions,
} from 'types';

type CharacterProfileSnapshot = firebase.firestore.QueryDocumentSnapshot<CharacterProfile>;

export const characterProfileConverter: firebase.firestore.FirestoreDataConverter<CharacterProfile> = {
  toFirestore(characterProfile: CharacterProfile): CharacterProfile {
    return characterProfile;
  },
  fromFirestore(snapshot: CharacterProfileSnapshot, options: SnapshotOptions): CharacterProfile {
    const data = snapshot.data(options);
    return data;
  },
};

export const characterProfileSchema: yup.ObjectSchema<CharacterProfile> = yup.object().required().shape({
  displayName: yup.string().required().label('Display Name'),
  backgroundImage: generateValidationFunc('image').required().label('Background Image File'),
  profileBackgroundImage: generateValidationFunc('image').required().label('Profile Background Image File'),
  profileImage: generateValidationFunc('image').required().label('Profile Image File'),
  coverImage: generateValidationFunc('image').required().label('Cover Image File'),
  introVideo: generateValidationFunc('video').label('Intro Video File'),
  ringAudio: generateValidationFunc('audio').label('Ring Audio File'),
  isGuest: yup.boolean().label('Is Guest?'),
  createdBy: yup.string().required().label('Created By'),
  themeColor: yup.string().required().oneOf(colorThemes).label('Theme'),
  createdAt: yup.number().required().default(() => moment().valueOf()),
  updatedAt: yup.number().when('$editMode', (editMode: boolean | undefined, schema: yup.NumberSchema<number | undefined>) => {
    if (editMode) {
      return schema
        .default(() => moment().valueOf())
        .transform(() => moment().valueOf());
    } else {
      return schema.default(() => moment().valueOf())
        .transform(() => moment().valueOf());
    }
  }),
});