import React from 'react';
import {
  Form,
  Input,
  Button,
  Select,
  Divider,
  PageHeader,
  Alert,
  Descriptions,
  Switch,
  Tag,
} from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import {
  addNodeToStory,
  updateStoryNode,
  deleteStoryNode,
} from 'src/features/stories/singleStorySlice';
import merge from 'deepmerge';
import isPlainObject from 'is-plain-object';
import { useFormik } from 'formik';
import NodeRouter from './NodeRouter';
import { overwriteMerge, nodeTypes, getOptions, liveNodeTypes } from 'src/helpers';
/** type imports */
import type { Store } from 'rc-field-form/es/interface';
import type { CustomTagProps } from 'rc-select/es/interface/generator';
import type { AppDispatch } from 'src/app/store';
import type { storyTypes } from 'src/helpers';
import type {
  Node,
  LiveNode,
  FormItemProps,
  EdgeConfiguration,
  antdColorOptions,
  LiveBuilderImageSelectorNode,
  ValidationResult,
} from 'types';

const { TextArea } = Input;

interface NodeFormState {
  id: string;
  nodeDefinition: Partial<Node | LiveNode>;
  edgeConfiguration: null | EdgeConfiguration;
}

interface Props {
  nodeFormState: NodeFormState;
  onCancel: () => void;
  editMode: boolean;
  hasChildren: boolean;
  deletedStoryNodeIds: string[];
  storyType?: typeof storyTypes[number];
  templateCB?: (node: Partial<LiveBuilderImageSelectorNode>) => Promise<ValidationResult>;
}

/**
 * not sure how to type this function
 * this is used to clean out the new state of the form
 * @param obj 
 */
function removeEmpty(obj: any): void {
  return Object.entries(obj).forEach(([key, val]) => {
    if (val && typeof val === 'object' && isPlainObject(val)) {
      removeEmpty(val);
    } else if (val == null) {
      delete obj[key];
    }
  });
}


const nodeDefTypeOptions = nodeTypes.map((nodeType) => ({ label: nodeType, value: nodeType }));
const liveNodeDefTypeOptions = liveNodeTypes.map((nodeType) => ({ label: nodeType, value: nodeType }));

const NodeDefinitionFormContainer: React.FC<Props> = ({ nodeFormState, onCancel, editMode, hasChildren, deletedStoryNodeIds, storyType, templateCB }: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const [antdForm] = Form.useForm();

  const formik = useFormik<NodeFormState>({
    initialValues: nodeFormState,
    enableReinitialize: true,
    onSubmit: async (values, formikHelpers): Promise<void> => {
      console.log("shouldn't I see this");
      const { id, nodeDefinition, edgeConfiguration } = values;
      removeEmpty(nodeDefinition);
      console.log(values);
      const { setSubmitting, resetForm, setErrors } = formikHelpers;
      let success, validationError;
      try {
        if (editMode) {
          if (!templateCB) {
            ({ success, validationError } = await dispatch(updateStoryNode(id, nodeDefinition)));
          } else {
            if (nodeDefinition.type !== 'Live Builder Image Selector') {
              throw new Error('Only Supports "Live Builder Image Selector" type nodes');
            }
            ({ success, validationError } = await templateCB(nodeDefinition));
          }
        } else {
          if (!templateCB) {
            ({ success, validationError } = await dispatch(addNodeToStory(id, nodeDefinition, edgeConfiguration)));
          } else {
            if (nodeDefinition.type !== 'Live Builder Image Selector') {
              throw new Error('Only Supports "Live Builder Image Selector" type nodes');
            }
            ({ success, validationError } = await templateCB(nodeDefinition));
          }
        }
        setSubmitting(false);
        if (success) {
          if (!editMode) {
            onCancel();
            resetForm();
            antdForm.resetFields();
          }
        } else if (validationError) {
          console.log(validationError);
          const { path, message } = validationError;
          setErrors({
            [path]: message,
          });
        }
      } catch (error) {
        console.log('Non validation error:', error);
      }
    },
  });

  const {
    values: formikValues,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    setSubmitting,
    setErrors,
    /* and other goodies */
    initialValues: formikInitialValues,
    setValues,
    dirty,
  } = formik;

  React.useEffect(() => {
    antdForm.resetFields();
  }, [antdForm, formikInitialValues]);


  function handleValuesChange(changedValues: Store /*, currentValues: Store */): void {
    const newValues = merge<NodeFormState, Store>(formikValues, changedValues, {
      arrayMerge: overwriteMerge,
      isMergeableObject: isPlainObject,
    });

    setValues(newValues);
  }

  type FormErrors = {
    [K in keyof Node]?: string;
  }

  type EdgeConfigErrors = {
    [K in keyof EdgeConfiguration]?: string;
  }

  function generateFormItemProps(itemName: keyof Node): FormItemProps {
    const hasError = itemName in errors;
    return {
      name: ['nodeDefinition', itemName],
      validateStatus: hasError ? 'error' : '',
      hasFeedback: hasError,
      help: hasError ? (errors as FormErrors)[itemName] : false,
    };
  }

  const { id, nodeDefinition, edgeConfiguration } = formikValues;
  const { displayName, type, description, isDeleted, isEndingNode, edges = {} } = nodeDefinition;

  let questionDefStyle: React.CSSProperties = {
    fontSize: 13,
    color: 'black',

  };
  if (isDeleted) {
    questionDefStyle = {
      textDecorationLine: 'line-through',
      textDecorationStyle: 'solid',
    };
  }

  async function onDelete(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): Promise<void> {
    event.preventDefault();
    try {
      setSubmitting(true);
      await dispatch(deleteStoryNode(id));
    } catch (error) {
      console.log('error:', error);
    }
    setSubmitting(false);
  }
  const BottomButtons: React.FC = () => {
    const handleSubmitClick = async (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      try {
        event.preventDefault();
        antdForm.submit();
        await formik.submitForm();
        console.log("past submit");
      } catch (error) {
        console.error(error.message);
      }
    };
    if (editMode) {
      let deleteButtonContent = 'Delete Node Definition';
      const deleteButtonDisabled = isSubmitting || hasChildren;
      if (isDeleted) {
        deleteButtonContent = 'Un-Delete Node Definition';
      }

      if (hasChildren) {
        deleteButtonContent = 'Node has children';
      }

      let updateButtonContent = 'Update Node Definition';
      if (!dirty) {
        updateButtonContent = 'Make a change to update';
      }
      return (
        <Form.Item>
          <Button
            block
            size={'large'}
            type={'primary'}
            style={{ marginBottom: 10, }}
            onClick={handleSubmitClick}
            loading={isSubmitting}
            disabled={isSubmitting || !dirty}
          >
            {updateButtonContent}
          </Button>
          <Button
            block
            danger
            onClick={onDelete}
            size={'large'}
            type={'primary'}
            loading={isSubmitting}
            disabled={deleteButtonDisabled}
          >
            {deleteButtonContent}
          </Button>
        </Form.Item>
      );
    } else {
      return (
        <Form.Item>
          <Button
            block
            size={'large'}
            type={'primary'}
            onClick={handleSubmitClick}
            loading={isSubmitting}
            disabled={isSubmitting}
          >
            Create New Node Definition
          </Button>
        </Form.Item>
      );
    }
  };
  let nodeLabel = 'Node ID';
  if (!editMode) {
    nodeLabel = 'New Node ID';
  }

  let edgeConfig = null;
  if (edgeConfiguration && !editMode) {
    const {
      parentNode,
      edge: { context },
    } = edgeConfiguration;
    const edgeDescription = (
      <React.Fragment>
        <Divider>Edge Configuration</Divider>
        <Descriptions style={{ marginBottom: 10, }} size='small' bordered layout='vertical' column={2} title='Parent Node Details'>
          <Descriptions.Item label='Display Name'>{edgeConfiguration.parentNode.displayName}</Descriptions.Item>
          <Descriptions.Item label='Type'><Tag color={'cyan'}>{edgeConfiguration.parentNode.type}</Tag></Descriptions.Item>
          <Descriptions.Item span={2} label='Description'>{edgeConfiguration.parentNode.description}</Descriptions.Item>
        </Descriptions>
      </React.Fragment>
    );
    const options = getOptions(parentNode, deletedStoryNodeIds);


    if (options && context) {
      const { optionIds } = context;
      if (optionIds !== undefined) {
        const hasError = 'edge.context.optionIds' in errors;
        const name = ['edgeConfiguration', 'edge', 'context', 'optionIds'];
        const validateStatus = hasError ? 'error' : '';
        const hasFeedback = hasError;
        const help: any = hasError ? (errors as any)['edge.context.optionIds'] : false;

        edgeConfig = (
          <React.Fragment>
            {edgeDescription}
            <Form.Item
              name={name}
              validateStatus={validateStatus}
              hasFeedback={hasFeedback}
              help={help}
              label={'User Response Options'}
            >
              <Select
                allowClear
                mode={'multiple'}
                options={options}
                disabled={isSubmitting || editMode}
                value={optionIds}
                tagRender={(props: CustomTagProps) => {
                  const { label, value, closable, onClose } = props;
                  let color: antdColorOptions | undefined;
                  options.forEach(({ value: innerValue, isCorrect }) => {
                    if (innerValue === value) {
                      if (isCorrect !== undefined) {
                        if (isCorrect) {
                          color = 'green';
                        } else {
                          color = 'red';
                        }
                      }
                    }
                  });

                  return (
                    <Tag
                      color={color}
                      closable={closable}
                      onClose={onClose}
                      style={{ marginRight: 3 }}
                    >
                      {label}
                    </Tag>
                  );
                }}
              />
            </Form.Item>
          </React.Fragment>
        );
      }

    } else {
      edgeConfig = edgeDescription;
    }

  }


  let isEndingNodeDisabled = false;
  if (isSubmitting) {
    isEndingNodeDisabled = true;
  }
  if (editMode && isEndingNode === false) {
    if (Object.values(edges).filter(({ targetNodeId }) => !deletedStoryNodeIds.includes(targetNodeId)).length > 0) {
      isEndingNodeDisabled = true;
    }
  }

  let nodeTypeOptions: { value: string; label: string; }[] = nodeDefTypeOptions;
  if (storyType === 'live') {
    nodeTypeOptions = liveNodeDefTypeOptions;
    if (templateCB) {
      nodeTypeOptions = liveNodeDefTypeOptions.filter(({ value }) => value === 'Live Builder Image Selector');
    }
  }

  return (
    <React.Fragment>
      <Form
        size={'middle'}
        layout={'vertical'}
        initialValues={formikInitialValues}
        form={antdForm}
        onValuesChange={handleValuesChange}
        onSubmitCapture={handleSubmit}
      >
        <PageHeader
          title={<Form.Item name={'id'}><span style={questionDefStyle}>{nodeLabel}: {id}</span></Form.Item>}
          extra={[
            <Button
              key={'close_sidebar'}
              danger
              icon={<CloseOutlined />}
              onClick={onCancel}
            />
          ]}
        >
          {(edgeConfig !== null) && edgeConfig}
          <Divider>Base Node Configuration</Divider>
          <Form.Item
            {...generateFormItemProps('displayName')}
            label={'Display Name'}
          >
            <Input
              placeholder={'T-Rex height'}
              disabled={isSubmitting}
              value={displayName}
            />
          </Form.Item>
          <Form.Item
            {...generateFormItemProps('type')}
            label={'Type'}
          >
            <Select
              options={nodeTypeOptions}
              disabled={isSubmitting || editMode}
              value={type}
            />
          </Form.Item>
          <Form.Item
            {...generateFormItemProps('description')}
            label={'Description'}
          >
            <TextArea
              disabled={isSubmitting}
              value={description}
            />
          </Form.Item>
          <Form.Item
            {...generateFormItemProps('isEndingNode')}
            label={'Is Ending Node'}
          >
            <Switch
              disabled={isEndingNodeDisabled}
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              checked={isEndingNode}
            />
          </Form.Item>
          <NodeRouter
            nodeDefinition={nodeDefinition}
            setFieldValue={setFieldValue}
            handleChange={handleChange}
            handleBlur={handleBlur}
            errors={errors}
            isSubmitting={isSubmitting}
            setErrors={setErrors}
            form={antdForm}
          />
          <Divider />
          {Object.values(errors).map((error, index) => <Alert style={{ marginBottom: 10 }} showIcon type={'error'} key={index} message={error} />)}
          <BottomButtons />
        </PageHeader>
      </Form>
    </React.Fragment>
  );
};

export default NodeDefinitionFormContainer;