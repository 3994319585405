import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchNodeDefinitions,
} from 'src/features/nodes/nodeDefinitionSlice';
import NodeDefinitionFormContainer from './NodeDefinitionFormContainer';
import moment from 'moment-timezone';
import {
  Button,
  Table,
  Drawer,
  Row,
  Col,
  Tag,
} from 'antd';
import { v4 as uuidv4 } from 'uuid';
/** type imports */
import type { RootState } from 'src/app/rootReducer';
import type { AppDispatch } from 'src/app/store';
import type { ColumnType } from 'antd/es/table';
import type {
  // NodeDefinition,
  NodeDefinitionStatus,
  antdColorOptions,
  Timestamp,
} from 'types';
import type { CSSProperties } from 'react';

interface TableDatum {
  id: string;
  displayName: string;
  description: string;
  type: 'Spud multi-choice question';
  status: NodeDefinitionStatus;
  createdAt: Timestamp;
  updatedAt: Timestamp | undefined;
}

const NodeDefinitions: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const [selectedNodeId, selectNodeId] = useState<string | null>(null);

  const {
    nodeDefinitions,
    fetchingDefinitions,
  } = useSelector((state: RootState) => state.nodeDefinitionsState);

  useEffect(() => {
    dispatch(fetchNodeDefinitions());
  }, [dispatch]);

  function handleCreateNew(): void {
    selectNodeId('');
  }

  const momentFormat = 'M/D/YY h:mm A';

  const tableData: TableDatum[] = Object.entries(nodeDefinitions).map(([id, nodeDefintion]) => {
    const {
      displayName,
      description,
      type,
      status,
      createdAt,
      updatedAt,
    } = nodeDefintion;

    return {
      id,
      displayName,
      description,
      type,
      status,
      createdAt,
      updatedAt,
    };
  });

  const renderDisplayName: React.FC<string | undefined> = (displayName) => {
    return (
      <span
        style={{
          fontFamily: 'monospace',
          fontWeight: 'bold',
        }}>
        {displayName}
      </span>
    );
  };

  const renderType: React.FC<string> = (type) => {
    const renderValue: string = type;
    const color: antdColorOptions = 'cyan';
    switch (type) {
      case 'Spud multi-choice question': {
        break;
      }
    }
    return <Tag color={color}>{renderValue}</Tag>;
  };

  const renderStatus: React.FC<NodeDefinitionStatus> = (status) => {
    let renderValue: string = status;
    let color: antdColorOptions = 'success';
    switch (status) {
      case 'Ready': {
        break;
      }
      case 'Deleted': {
        color = 'error';
        break;
      }
      case 'staged': {
        color = 'warning';
        renderValue = 'Staged';
        break;
      }
      case 'published': {
        renderValue = 'Published';
        break;
      }
    }
    return <Tag color={color}>{renderValue}</Tag>;
  };

  const tableColumns: ColumnType<TableDatum>[] = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 250,
      render: renderDisplayName,
    },
    {
      title: 'Display Name',
      dataIndex: 'displayName',
      key: 'displayName',
      width: 250,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      width: 250,
      filters: [
        { text: 'Spud multi-choice question', value: 'Spud multi-choice question' },
      ],
      onFilter: (value, record) => record.status === value,
      render: renderType,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 125,
      filters: [
        { text: 'Ready', value: 'Ready' },
        { text: 'Deleted', value: 'Deleted' },
        { text: 'Staged', value: 'staged' },
        { text: 'Published', value: 'published' },
      ],
      render: renderStatus,
      onFilter: (value, record) => record.status === value,
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 200,
      sorter: (a, b) => a.createdAt.toMillis() - b.createdAt.toMillis(),
      render: (createdAt: Timestamp) => moment(createdAt.toMillis()).format(momentFormat),
    },
    {
      title: 'Updated At',
      dataIndex: 'updatedAt',
      width: 200,
      key: 'updatedAt',
      sorter: ({ updatedAt: updatedAtA }, { updatedAt: updatedAtB }) => {
        if (updatedAtA && updatedAtB) {
          return updatedAtA.toMillis() - updatedAtB.toMillis();
        } else if (updatedAtA) {
          return 1;
        } else if (updatedAtB) {
          return -1;
        }
        return 0;
      },
      render: (updatedAt: Timestamp | undefined) => (updatedAt) ? moment(updatedAt.toMillis()).format(momentFormat) : 'N/A',
    }
  ];

  return (
    <React.Fragment>
      <Row gutter={[16, 16]}>
        <Col>
          <Button
            size='large'
            type='primary'
            onClick={handleCreateNew}
          >
            New Node Definition
          </Button>
        </Col>
      </Row>
      <Row gutter={16} >
        <Col span={24} >
          <Table
            pagination={{
              total: tableData.length,
              defaultPageSize: 100,
              showTotal: (total) => `${total} Total Node Definitions`,
            }}
            loading={fetchingDefinitions}
            dataSource={tableData}
            columns={tableColumns}
            rowKey={({ id }) => id}
            onRow={(record) => {
              const currentlySelected = record.id === selectedNodeId;
              function handleRowClick(): void {
                if (!currentlySelected) {
                  selectNodeId(record.id);
                } else {
                  console.log('already selected');
                }
              }
              const rowCss: CSSProperties = {
                cursor: (currentlySelected) ? 'auto' : 'pointer',
              };
              return {
                onClick: handleRowClick, // click row
                style: rowCss,
                className: (currentlySelected) ? 'ant-table-row-selected' : '',
              };
            }}
          />
        </Col>
      </Row>
      <Drawer
        width={600}
        placement={'right'}
        visible={selectedNodeId !== null}
        closable={false}
      >
        {selectedNodeId !== null && <NodeDefinitionFormContainer
          editMode={selectedNodeId !== ''}
          nodeFormState={{
            id: (selectedNodeId === '') ? uuidv4() : selectedNodeId,
            nodeDefinition: (selectedNodeId === '') ? {
              displayName: '',
              status: 'Ready',
              description: '',
              type: 'Spud multi-choice question',
              isDeleted: false,
            } : nodeDefinitions[selectedNodeId],
          }}
          onCancel={() => selectNodeId(null)}
        />}
      </Drawer>
    </React.Fragment>
  );
};

export default NodeDefinitions;