import { createSlice, createAsyncThunk, SerializedError } from '@reduxjs/toolkit';
import firebase from 'firebase';
import { generateFirestorePath } from 'src/helpers';
import 'firebase/firestore';
import * as yup from 'yup';
import { seasonConverter } from 'src/features/seasons/helpers';
import { liveEventConverter, liveEventSchema } from './helpers';
/** type imports */
import type { RootState } from 'src/app/rootReducer';
import type { LiveEvent } from 'types';
import dayjs from 'dayjs';

const { firestore } = firebase;

interface LiveEventsState {
  liveEvents: { [liveEventId: string]: LiveEvent } | null;
  currentRequestId: undefined | string,
  loading: 'idle' | 'pending';
  error: null | SerializedError;
}

const initialState: LiveEventsState = {
  liveEvents: null,
  loading: 'idle',
  currentRequestId: undefined,
  error: null,
};

export const fetchLiveEvents = createAsyncThunk<{ [liveEventId: string]: LiveEvent } | null, void, { state: RootState }>(
  'liveEvents/fetchLiveEvents',
  async (_, { getState, requestId }) => {
    const { user } = getState().userState;
    if (!user) {
      throw new Error("Oops! You're not authenticated!");
    }
    const { currentRequestId, loading } = getState().liveEventsState;
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return null;
    }
    const liveEvents: { [liveEventId: string]: LiveEvent } = {};
    const liveEventsColSnapshot = await firestore()
      .collection(generateFirestorePath('liveEvents'))
      .withConverter(liveEventConverter)
      .where("isDeleted", "==", false)
      .get();

    liveEventsColSnapshot.forEach((liveEventSnapshot) => {
      if (liveEventSnapshot.exists) {
        const id = liveEventSnapshot.id;
        const liveEvent = liveEventSnapshot.data();
        liveEvents[id] = liveEvent;
      }
    });
    return liveEvents;
  }
);

export const addLiveEvent = createAsyncThunk<{ id: string; liveEvent: LiveEvent } | null, { id: string; liveEvent: Partial<LiveEvent> }, { state: RootState, rejectValue: yup.ValidationError }>(
  'liveEvents/addLiveEvents',
  async ({ id, liveEvent }, { getState, requestId, rejectWithValue }) => {
    const { user } = getState().userState;
    if (!user) {
      throw new Error("Oops! You're not authenticated!");
    }
    const { currentRequestId, loading } = getState().liveEventsState;
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return null;
    }
    try {
      liveEvent.createdBy = user.uid;
      const validated = await liveEventSchema.validate(liveEvent, {
        stripUnknown: true,
        context: {
          editMode: false,
        },
      });

      const liveEventRef = firestore()
        .collection(generateFirestorePath('liveEvents'))
        .withConverter(liveEventConverter)
        .doc(id);

      const liveEventData = await liveEventRef.get();
      if (liveEventData.exists) {
        throw new yup.ValidationError('Already exists', 'Already exists', '');
      }

      await liveEventRef.set(validated);

      return { id, liveEvent: validated };
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        console.log('error instanceof yup.ValidationError', error);
        return rejectWithValue(error);
      } else {
        throw error;
      }
    }
  }
);

interface YouTubeStreamEvent { id: string; storyId: string; type: 'youtube'; streamId: string; }
interface AWSStreamEvent { id: string; storyId: string; type: 'aws'; hlsStreamUrl: string; }

export const startLiveEvent = createAsyncThunk<{ id: string; liveEvent: LiveEvent } | null, YouTubeStreamEvent | AWSStreamEvent, { state: RootState, rejectValue: yup.ValidationError }>(
  'liveEvents/startLiveEvent',
  async (payload, { getState, requestId, rejectWithValue }) => {
    const { id, storyId } = payload;
    const { user } = getState().userState;
    if (!user) {
      throw new Error("Oops! You're not authenticated!");
    }
    const { currentRequestId, loading } = getState().liveEventsState;
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return null;
    }
    try {
      const liveEventRef = firestore()
        .collection(generateFirestorePath('liveEvents'))
        .withConverter(liveEventConverter)
        .doc(id);

      const liveEventData = await liveEventRef.get();
      if (!liveEventData.exists) {
        throw new yup.ValidationError('Already does not exist', 'Already does not exist', '');
      }
      if (payload.type === 'youtube') {
        await liveEventRef.update({
          status: 'started',
          storyId,
          streamId: payload.streamId,
          updatedAt: dayjs().valueOf(),
        });
      } else if (payload.type === 'aws') {
        await liveEventRef.update({
          status: 'started',
          storyId,
          hlsStreamUrl: payload.hlsStreamUrl,
          updatedAt: dayjs().valueOf(),
        });
      }


      const liveEventDoc = await liveEventRef.get();
      const liveEvent = liveEventDoc.data();
      if (!liveEventDoc.exists || !liveEvent) {
        throw new Error('should not happen');
      }

      return { id, liveEvent };
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        console.log('error instanceof yup.ValidationError', error);
        return rejectWithValue(error);
      } else {
        throw error;
      }

    }
  }
);

export const updateLiveEvent = createAsyncThunk<{ id: string; liveEvent: LiveEvent } | null, { id: string; liveEvent: Partial<LiveEvent> }, { state: RootState, rejectValue: yup.ValidationError }>(
  'liveEvents/updateLiveEvent',
  async ({ id, liveEvent }, { getState, requestId, rejectWithValue }) => {
    const { user } = getState().userState;
    if (!user) {
      throw new Error("Oops! You're not authenticated!");
    }
    const { currentRequestId, loading } = getState().liveEventsState;
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return null;
    }
    try {
      const validated = await liveEventSchema.validate(liveEvent, {
        stripUnknown: true,
        context: {
          editMode: true,
        },
      });

      const liveEventRef = firestore()
        .collection(generateFirestorePath('liveEvents'))
        .withConverter(liveEventConverter)
        .doc(id);

      const liveEventData = await liveEventRef.get();
      if (!liveEventData.exists) {
        throw new yup.ValidationError('Does not exists', 'Does not exists', '');
      }

      await liveEventRef.set(validated);

      return { id, liveEvent: validated };
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        console.log('error instanceof yup.ValidationError', error);
        return rejectWithValue(error);
      } else {
        throw error;
      }

    }
  }
);

export const deleteLiveEvent = createAsyncThunk<{ id: string; } | null, { id: string; }, { state: RootState, rejectValue: yup.ValidationError }>(
  'liveEvents/deleteLiveEvent',
  async ({ id }, { getState, requestId, rejectWithValue }) => {
    const { user } = getState().userState;
    if (!user) {
      throw new Error("Oops! You're not authenticated!");
    }
    const { currentRequestId, loading } = getState().liveEventsState;
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return null;
    }
    try { 
      const liveEventRef = firestore()
        .collection(generateFirestorePath('liveEvents'))
        .withConverter(liveEventConverter)
        .doc(id);
      const liveEventData = await liveEventRef.get();
      if (!liveEventData.exists) {
        throw new yup.ValidationError('Does not exists', 'Does not exists', '');
      }
      await liveEventRef.update({
        isDeleted: true,
        updatedAt: dayjs().valueOf(),
      });
      return { id };
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        console.log('error instanceof yup.ValidationError', error);
        return rejectWithValue(error);
      } else {
        throw error;
      }
    }
  }
);

export const completeLiveEvent = createAsyncThunk<{ id: string; liveEvent: LiveEvent } | null, { liveEventId: string; }, { state: RootState, rejectValue: yup.ValidationError }>(
  'liveEvents/completeLiveEvent',
  async ({ liveEventId }, { getState, requestId, rejectWithValue }) => {
    const { user } = getState().userState;
    const id = liveEventId;
    if (!user) {
      throw new Error("Oops! You're not authenticated!");
    }
    const { currentRequestId, loading } = getState().liveEventsState;
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return null;
    }
    try {

      const liveEventRef = firestore()
        .collection(generateFirestorePath('liveEvents'))
        .withConverter(liveEventConverter)
        .doc(id);

      await liveEventRef.update({
        status: 'completed',
        updatedAt: dayjs().valueOf(),
      });

      const liveEventSnapshot = await liveEventRef.get();

      const liveEvent = liveEventSnapshot.data();

      if (!liveEvent) {
        throw new yup.ValidationError('Does not exists', 'Does not exists', '');
      }

      const seasonRef = firestore()
        .collection(generateFirestorePath('seasons'))
        .withConverter(seasonConverter)
        .doc(liveEvent.seasonId);

      await seasonRef.update({
        currentLiveStoryId: firestore.FieldValue.delete(),
      });


      return { id, liveEvent };

    } catch (error) {
      if (error instanceof yup.ValidationError) {
        console.log('error instanceof yup.ValidationError', error);
        return rejectWithValue(error);
      } else {
        throw error;
      }

    }
  }
);


const liveEventsSlice = createSlice({
  name: 'liveEvents',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchLiveEvents.pending, (state, action) => {
      const { meta: { requestId } } = action;
      if (state.loading === 'idle') {
        state.loading = 'pending';
        state.currentRequestId = requestId;
      }
    });
    builder.addCase(fetchLiveEvents.fulfilled, (state, action) => {
      const { meta: { requestId }, payload } = action;
      if (payload !== null && requestId === state.currentRequestId) {
        state.liveEvents = payload;
        state.loading = 'idle';
        state.currentRequestId = undefined;
        state.error = null;
      }
    });
    builder.addCase(fetchLiveEvents.rejected, (state, action) => {
      const { meta: { requestId } } = action;
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle';
        state.error = action.error;
        state.currentRequestId = undefined;
      }
    });
    builder.addCase(addLiveEvent.pending, (state, action) => {
      const { meta: { requestId } } = action;
      if (state.loading === 'idle') {
        state.loading = 'pending';
        state.currentRequestId = requestId;
      }
    });
    builder.addCase(addLiveEvent.fulfilled, (state, action) => {
      const { meta: { requestId }, payload } = action;
      if (payload !== null && state.liveEvents && requestId === state.currentRequestId) {
        const { id, liveEvent } = payload;
        state.liveEvents[id] = liveEvent;
        state.loading = 'idle';
        state.currentRequestId = undefined;
        state.error = null;
      }
    });
    builder.addCase(addLiveEvent.rejected, (state, action) => {
      const { meta: { requestId } } = action;
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle';
        state.currentRequestId = undefined;
        state.error = null;
      }
    });
    builder.addCase(updateLiveEvent.pending, (state, action) => {
      const { meta: { requestId } } = action;
      if (state.loading === 'idle') {
        state.loading = 'pending';
        state.currentRequestId = requestId;
      }
    });
    builder.addCase(updateLiveEvent.fulfilled, (state, action) => {
      const { meta: { requestId }, payload } = action;
      if (payload !== null && state.liveEvents && requestId === state.currentRequestId) {
        const { id, liveEvent } = payload;
        state.liveEvents[id] = liveEvent;
        state.loading = 'idle';
        state.currentRequestId = undefined;
        state.error = null;
      }
    });
    builder.addCase(updateLiveEvent.rejected, (state, action) => {
      const { meta: { requestId } } = action;
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle';
        state.error = null;
        state.currentRequestId = undefined;
      }
    });

    builder.addCase(deleteLiveEvent.pending, (state, action) => {
      const { meta: { requestId } } = action;
      if (state.loading === 'idle') {
        state.loading = 'pending';
        state.currentRequestId = requestId;
      }
    });
    builder.addCase(deleteLiveEvent.fulfilled, (state, action) => {
      const { meta: { requestId }, payload } = action;
      if (payload !== null && state.liveEvents && requestId === state.currentRequestId) {
        const { id } = payload;
        delete state.liveEvents[id];
        state.loading = 'idle';
        state.currentRequestId = undefined;
        state.error = null;
      }
    });
    builder.addCase(deleteLiveEvent.rejected, (state, action) => {
      const { meta: { requestId } } = action;
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle';
        state.error = null;
        state.currentRequestId = undefined;
      }
    });

    builder.addCase(startLiveEvent.pending, (state, action) => {
      const { meta: { requestId } } = action;
      if (state.loading === 'idle') {
        state.loading = 'pending';
        state.currentRequestId = requestId;
      }
    });
    builder.addCase(startLiveEvent.fulfilled, (state, action) => {
      const { meta: { requestId }, payload } = action;
      if (payload !== null && state.liveEvents && requestId === state.currentRequestId) {
        const { id, liveEvent } = payload;
        state.liveEvents[id] = liveEvent;
        state.loading = 'idle';
        state.currentRequestId = undefined;
        state.error = null;
      }
    });
    builder.addCase(startLiveEvent.rejected, (state, action) => {
      const { meta: { requestId }, payload } = action;
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle';
        state.error = payload || action.error;
        state.currentRequestId = undefined;
      }
    });
    builder.addCase(completeLiveEvent.pending, (state, action) => {
      const { meta: { requestId } } = action;
      if (state.loading === 'idle') {
        state.loading = 'pending';
        state.currentRequestId = requestId;
      }
    });
    builder.addCase(completeLiveEvent.fulfilled, (state, action) => {
      const { meta: { requestId }, payload } = action;
      if (payload !== null && state.liveEvents && requestId === state.currentRequestId) {
        const { id, liveEvent } = payload;
        state.liveEvents[id] = liveEvent;
        state.loading = 'idle';
        state.currentRequestId = undefined;
        state.error = null;
      }
    });
    builder.addCase(completeLiveEvent.rejected, (state, action) => {
      const { meta: { requestId }, payload } = action;
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle';
        state.error = payload || action.error;
        state.currentRequestId = undefined;
      }
    });

  },
});

export default liveEventsSlice.reducer;