import React from 'react';
import moment from 'moment-timezone';
import {
  // Descriptions,
  // Tag,
  Drawer,
  Button
} from 'antd';
import {
  fetchSeasons,
} from 'src/features/seasons/seasonsSlice';
import { v4 as uuidv4 } from 'uuid';
import {
  useSelector,
  useDispatch,
} from 'react-redux';
// import {
//   liveEventStatusOptions,
// } from 'src/helpers';
import LiveEventDescription from './LiveEventDescription';
import LiveEventFormContainer from './LiveEventFormContainer';
/** type imports */
// import type { antdColorOptions, LiveEvent } from 'types';
import type { RootState } from 'src/app/rootReducer';
import type { AppDispatch } from 'src/app/store';

interface Props {
  date: moment.Moment,
}
const DayScheduleContainer: React.FC<Props> = ({ date }: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    liveEvents,
  } = useSelector((state: RootState) => state.liveEventsState);
  const {
    seasons,
  } = useSelector((state: RootState) => state.seasonsState);
  React.useEffect(() => {
    if (Object.keys(seasons).length === 0) {
      dispatch(fetchSeasons());
    }
  }, [dispatch, seasons]);
  const [newEventId, setNewEventId] = React.useState<string | null>(null);
  const [selectedLiveEventId, selectLiveEventId] = React.useState<string | null>(null);
  React.useEffect(() => { 
    if (liveEvents && newEventId && newEventId in liveEvents) {
      setNewEventId(uuidv4());
    }
  }, [liveEvents, newEventId]);


  const events = Object.entries(liveEvents || {})
    .filter(([, { eventDatetime }]) => moment(eventDatetime).isSame(date, 'date'))
    .sort(([, { eventDatetime: A }], [, { eventDatetime: B }]) => A - B);

  const defaultNewEvent = {
    eventDatetime: date.hours(14).minutes(0).seconds(0).milliseconds(0),
    status: 'scheduled' as const,
    isDeleted: false,
  };
  return (
    <React.Fragment>
      {events.map(([id, event]) => <LiveEventDescription key={id} id={id} liveEvent={event} onClick={() => selectLiveEventId(id)} />)}
      <Button
        block
        size={'large'}
        type={'primary'}
        onClick={() => {
          setNewEventId(uuidv4());
        }}
      >
        Add Live Event
      </Button>
      <Drawer
        width={600}
        placement={'right'}
        visible={!!newEventId}
        closable={false}
      >
        {(liveEvents && !!newEventId) && <LiveEventFormContainer
          editMode={false}
          liveEventFormState={{
            id: newEventId,
            liveEvent: defaultNewEvent,
          }}
          onCancel={() => {
            setNewEventId(null);
          }}
        />}
      </Drawer>
      <Drawer
        width={600}
        placement={'right'}
        visible={!!selectedLiveEventId}
        closable={false}
      >
        {(liveEvents && selectedLiveEventId && liveEvents[selectedLiveEventId]) && <LiveEventFormContainer
          editMode={true}
          liveEventFormState={{
            id: selectedLiveEventId,
            liveEvent: {
              ...liveEvents[selectedLiveEventId],
              eventDatetime: moment(liveEvents[selectedLiveEventId].eventDatetime),
            },
          }}
          onCancel={() => selectLiveEventId(null)}
        />}
      </Drawer>

    </React.Fragment>
  );
};

export default DayScheduleContainer;
