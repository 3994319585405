import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AsyncAppThunk } from 'src/app/store';
import * as yup from 'yup';
import {
  appTagDefinitionConverter,
  appTagDefinitionSchema,
} from './helpers';
import firebase from 'firebase';
import { generateFirestorePath } from 'src/helpers';
import 'firebase/firestore';
import 'firebase/auth';
/** type imports */
import type { AppTagDefinition } from 'types';

const { firestore, auth } = firebase;
interface SuccessReturn {
  success: true;
  validationError: null;
}
interface FailureReturn {
  success: false;
  validationError: yup.ValidationError;
}

type ThunkReturn = SuccessReturn | FailureReturn;

interface AppTagDefinitions { [appTagDefinitionId: string]: AppTagDefinition }

interface AppTagDefinitionsState {
  appTagDefinitions: { [appTagDefinitionId: string]: AppTagDefinition };
  fetchingAppTagDefinitions: boolean;
}

const initialState: AppTagDefinitionsState = {
  appTagDefinitions: {},
  fetchingAppTagDefinitions: true,
};

const appTagDefinitionsSlice = createSlice({
  name: 'appTagDefinitions',
  initialState,
  reducers: {
    setAppTagDefinitions(state, action: PayloadAction<AppTagDefinitions>): void {
      state.appTagDefinitions = action.payload;
      state.fetchingAppTagDefinitions = false;
    },
  },
});

export const {
  setAppTagDefinitions,
} = appTagDefinitionsSlice.actions;

// thunk creator
export function fetchAppTagDefinitions(): AsyncAppThunk<void> {
  return async function thunk(dispatch): Promise<void> {
    try {
      const user = auth().currentUser;
      if (!user) {
        throw new Error('Not authenticated');
      }
      const AppTagDefinitions: AppTagDefinitions = {};

      const tagDefColSnapshot = await firestore()
        .collection(generateFirestorePath('appTagDefinitions'))
        .withConverter(appTagDefinitionConverter)
        .orderBy('createdAt', 'desc')
        .get();

      tagDefColSnapshot.forEach((appTagDefinition) => {
        if (appTagDefinition.exists) {
          AppTagDefinitions[appTagDefinition.id] = appTagDefinition.data();
        }
      });

      dispatch(setAppTagDefinitions(AppTagDefinitions));
    } catch (error) {
      console.log('Error in fetchAppTagDefinitions():', error);
    }
  };
}

export function addAppTagDefinition(id: string, newTagDef: Partial<AppTagDefinition>): AsyncAppThunk<ThunkReturn> {
  return async function thunk(dispatch): Promise<ThunkReturn> {
    try {
      const user = auth().currentUser;
      if (!user) {
        throw new Error('Not authenticated');
      }

      const newTag = await appTagDefinitionSchema.validate(newTagDef, {
        stripUnknown: true,
        context: {
          editMode: false,
        },
      });

      const newTagDefRef = firestore()
        .collection(generateFirestorePath('appTagDefinitions'))
        .withConverter(appTagDefinitionConverter)
        .doc(id);

      const tagDef = await newTagDefRef.get();

      if (tagDef.exists) {
        throw new yup.ValidationError('App Tag already exists', 'Tag Already exists', 'id');
      }

      await newTagDefRef.set(newTag);

      await dispatch(fetchAppTagDefinitions());

      return {
        success: true,
        validationError: null,
      };
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        return {
          success: false,
          validationError: error,
        };
      } else {
        console.log('Error in addAppTagDefinition():', error);
        throw error;
      }
    }
  };
}

export function updateAppTagDefinition(id: string, newTagDef: Partial<AppTagDefinition>): AsyncAppThunk<ThunkReturn> {
  return async function thunk(dispatch): Promise<ThunkReturn> {
    try {
      const user = auth().currentUser;
      if (!user) {
        throw new Error('Not authenticated');
      }

      const updatedTag = await appTagDefinitionSchema.validate(newTagDef, {
        stripUnknown: true,
        context: {
          editMode: true,
        }
      });

      const tagDefRef = firestore()
        .collection(generateFirestorePath('appTagDefinitions'))
        .withConverter(appTagDefinitionConverter)
        .doc(id);

      const tagDef = await tagDefRef.get();

      if (!tagDef.exists) {
        throw new yup.ValidationError('App Tag does not exists', 'Does not exists', '');
      }

      await tagDefRef.set(updatedTag);

      await dispatch(fetchAppTagDefinitions());

      return {
        success: true,
        validationError: null,
      };
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        return {
          success: false,
          validationError: error,
        };
      } else {
        console.log('Error in updateAppTagDefinition():', error);
        throw error;
      }
    }
  };
}

export default appTagDefinitionsSlice.reducer;