import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Form,
  Input,
  Button,
  Select,
  Divider,
  Card,
  Switch,
  InputNumber,
} from 'antd';
import {
  CloseOutlined,
  CheckOutlined,
} from '@ant-design/icons';
// import {
//   fetchTagDefinitions,
// } from 'src/features/tagDefinitions/tagDefinitionsSlice';
import {
  fetchFileReferences,
  // addPlaceholderAssetReference,
} from 'src/features/storage/storageSlice';
import { v4 as uuidv4 } from 'uuid';
import { transformFiles, particleEffectOptions } from 'src/helpers';
/** type imports */
import type { AppDispatch } from 'src/app/store';
import type { RootState } from 'src/app/rootReducer';
import type { FormInstance } from 'antd/es/form';
import type { FormikErrors } from 'formik';
import type {
  ScreenCleaningNode,
  ScreenCleanerOption,
  FormItemProps,
} from 'types';

// const { TextArea } = Input;

interface Props {
  nodeDefinition: Partial<ScreenCleaningNode>;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => any;
  handleChange: (eventOrPath: string | React.ChangeEvent<any>) => void | ((eventOrTextValue: string | React.ChangeEvent<any>) => void);
  handleBlur: (eventOrString: any) => void | ((e: any) => void);
  form: FormInstance;
  isSubmitting: boolean;
  errors: FormikErrors<{ id: string; nodeDefinition: Partial<ScreenCleaningNode>; }>;
  setErrors: (errors: FormikErrors<{ id: string; nodeDefinition: Partial<ScreenCleaningNode>; }>) => void;
}
// "Post to password protected site" | "Spud multi-choice question"
// const themeColorOptions = Object.entries(colorThemeOptions)
//   .map(([colorKey, colorDisplay]) => ({ label: colorDisplay, value: colorKey }));

// const difficultyOptions = Object.entries(difficultyLevelOptions)
//   .map(([difficultyKey, difficultyDisplay]) => ({ label: difficultyDisplay, value: difficultyKey }));

const ScreenCleaningNodeFormBody: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch<AppDispatch>();

  // const {
  //   tagDefinitions,
  //   fetchingTagDefinitions,
  // } = useSelector((state: RootState) => state.tagDefinitionsState);

  const {
    fileReferences: {
      imageFiles,
      videoFiles,
      audioFiles,
    },
    fetchingFileReferences,
  } = useSelector((state: RootState) => state.storageState);

  React.useEffect(() => {
    // dispatch(fetchTagDefinitions());
    dispatch(fetchFileReferences());
  }, [dispatch]);

  const particleOptions = Object.entries(particleEffectOptions)
    .map(([particleKey, particleDisplay]) => ({ label: particleDisplay, value: particleKey }));

  const {
    nodeDefinition,
    setFieldValue,
    // handleChange,
    // handleBlur,
    isSubmitting,
    errors,
    form,
  } = props;

  const {
    particleEffect,
    soundEffect,
    backgroundVideo,
    waitingLoopingVideo,
    botAudio,
    screenCleanerOptions = {},
  } = nodeDefinition;

  // /** this sets the defaults for boolean fields */
  // React.useEffect(() => {

  //   if (randomOrder === undefined) {
  //     setFieldValue('nodeDefinition.randomOrder', false);
  //   }

  //   if (continueIfWrong === undefined) {
  //     setFieldValue('nodeDefinition.continueIfWrong', false);
  //   }

  //   if (doAll === undefined) {
  //     setFieldValue('nodeDefinition.doAll', true);
  //   }

  //   if (isButtonUI === undefined) {
  //     setFieldValue('nodeDefinition.isButtonUI', false);
  //   }
  //   /** you can ignore the ts-lint issue here */
  // }, []);

  const scOptionArray = Object.entries(screenCleanerOptions);

  function addBlankAnswerDef(/* event: React.MouseEvent<HTMLButtonElement, MouseEvent> */): void {
    // not clear if we need to do this
    // event.preventDefault();
    const newId = uuidv4();
    const blankAnswer = {
      displayOrder: scOptionArray.length,
      isCorrect: false,
      isDeleted: false,
    };
    setFieldValue('nodeDefinition.screenCleanerOptions', {
      ...screenCleanerOptions,
      [newId]: blankAnswer,
    });
    /** 
     * without setting the fields manually using antd
     * the display order doesn't populate
     */
    form.setFieldsValue({
      nodeDefinition: {
        screenCleanerOptions: {
          [newId]: blankAnswer,
        },
      },
    });
  }


  type QuestionFormErrors = {
    [K in keyof ScreenCleaningNode]?: string;
  }

  interface AnswerFormErrors {
    [answerDefKey: string]: string;
  }

  function generateFormItemProps(itemName: keyof ScreenCleaningNode): FormItemProps {
    const hasError = itemName in errors;
    return {
      name: ['nodeDefinition', itemName],
      validateStatus: hasError ? 'error' : '',
      hasFeedback: hasError,
      help: hasError ? (errors as QuestionFormErrors)[itemName] : false,
    };
  }

  const imageOptions = transformFiles(imageFiles);
  const videoOptions = transformFiles(videoFiles);
  const audioOptions = transformFiles(audioFiles);

  const audioPlaceholder = 'audio/example.mp3';
  const imagePlaceholder = 'image/example.jpeg';
  const videoPlaceholder = 'video/example.mp4';
  if (Object.keys(errors).length) {
    console.log(errors);
  }

  const usedOptionIds: string[] = Object.values(nodeDefinition.edges || {})
    .filter(({ context }) => context && context.optionIds !== undefined)
    .map(({ context }) => context?.optionIds ?? [])
    .flat();

  return (
    <React.Fragment>
      <Divider>Screen cleaning configuration</Divider>
      <Form.Item
        {...generateFormItemProps('particleEffect')}
        label={'Particle Effect'}
      >
        <Select
          allowClear
          options={particleOptions}
          disabled={isSubmitting}
          value={particleEffect}
          placeholder='Fire'
        />
      </Form.Item>
      <Form.Item
        {...generateFormItemProps('soundEffect')}
        label={'Sound Effect Audio File'}
      >
        <Select
          allowClear
          showSearch
          options={audioOptions}
          disabled={isSubmitting}
          loading={fetchingFileReferences}
          value={soundEffect}
          placeholder={audioPlaceholder}
        />
      </Form.Item>
      <Form.Item
        {...generateFormItemProps('botAudio')}
        label={'Bot Audio File'}
      >
        <Select
          allowClear
          showSearch
          options={audioOptions}
          disabled={isSubmitting}
          loading={fetchingFileReferences}
          value={botAudio}
          placeholder={audioPlaceholder}
        />
      </Form.Item>
      <Form.Item
        {...generateFormItemProps('backgroundVideo')}
        label={'Background Video File'}
      >
        <Select
          allowClear
          showSearch
          options={videoOptions}
          disabled={isSubmitting}
          loading={fetchingFileReferences}
          value={backgroundVideo}
          placeholder={videoPlaceholder}
        />
      </Form.Item>
      <Form.Item
        {...generateFormItemProps('waitingLoopingVideo')}
        label={'Waiting Looping Video File'}
      >
        <Select
          allowClear
          showSearch
          options={videoOptions}
          disabled={isSubmitting}
          loading={fetchingFileReferences}
          value={waitingLoopingVideo}
          placeholder={videoPlaceholder}
        />
      </Form.Item>
      <Card
        title={'Screen Cleaner Options'}
        actions={[
          <Form.Item key={'add_new_answer_definition'}>
            <Button
              type={'primary'}
              onClick={addBlankAnswerDef}
            >
              Add Option
            </Button>
          </Form.Item>
        ]}
      >
        {scOptionArray
          .sort(([, { displayOrder: displayOrderA = 0 }], [, { displayOrder: displayOrderB = 1 }]) => displayOrderA - displayOrderB)
          .map(([answerId, answerDefinition], index) => {
            const answerPath = `nodeDefinition.screenCleanerOptions.${answerId}`;
            const errorPath = `screenCleanerOptions.${answerId}`;
            const {
              displayName,
              image,
              isCorrect = false,
              soundEffect,
              particleEffect,
              responseVideo,
              responseBotAudio,
              botAudio,
              points,
              displayOrder = index,
              isDeleted: answerIsDeleted, // this is to avoid clashing with 'isDeleted' on the NodeDefinition
              createdAt,
            } = answerDefinition;

            // let idDisplay = answerId;
            // if (!createdAt) {
            //   idDisplay = snakeCase(answer);
            // }

            let answerDefStyle: React.CSSProperties = {
              color: (isCorrect) ? 'green' : 'red',
            };
            if (answerIsDeleted) {
              answerDefStyle = {
                ...answerDefStyle,
                textDecorationLine: 'line-through',
                textDecorationStyle: 'solid',
              };
            }
            let fieldsDisabled = false;
            if (isSubmitting || answerIsDeleted) {
              fieldsDisabled = true;
            }

            function generateFormItemAnswerProps(itemName: keyof ScreenCleanerOption): FormItemProps {
              const errorKey = `${errorPath}.${itemName}`;
              const hasError = errorKey in errors;
              return {
                name: ['nodeDefinition', 'screenCleanerOptions', answerId, itemName],
                validateStatus: hasError ? 'error' : '',
                hasFeedback: hasError,
                help: hasError ? (errors as AnswerFormErrors)[errorKey] : false,
              };
            }

            function deleteAnswerDef(/* event: React.MouseEvent<HTMLElement, MouseEvent> */): void {
              // not clear that we need to do this
              // event.preventDefault();
              if (createdAt) {
                // only updated the 'isDeleted' field if the answer has already been saved
                setFieldValue(`${answerPath}.isDeleted`, !answerIsDeleted);
              } else {
                // remove the answer if it hasn't been saved
                setFieldValue(answerPath, undefined);
              }
            }

            const innerCardStyle: React.CSSProperties = {};

            if (index !== 0) {
              // this spaces out the answers
              innerCardStyle.marginTop = 16;
            }

            const optionCurrentlyUsed = usedOptionIds.includes(answerId);
            let deleteButtonContent = 'Delete';
            if (optionCurrentlyUsed) {
              deleteButtonContent = 'Option currently being used';
            }

            if (answerIsDeleted) {
              deleteButtonContent = 'Un-Delete';
            }


            return (
              <Card
                key={answerId}
                headStyle={answerDefStyle}
                type='inner'
                style={innerCardStyle}
                title={<span>Answer ID: {answerId}</span>}
                size='small'
                actions={[
                  <Form.Item key={'delete_answer_definition'}>
                    <Button
                      danger
                      disabled={optionCurrentlyUsed}
                      size='small'
                      onClick={deleteAnswerDef}
                    >
                      {deleteButtonContent}
                    </Button>
                  </Form.Item>
                ]}
              >
                <Form.Item
                  {...generateFormItemAnswerProps('displayName')}
                  label={'Display Name'}
                >
                  <Input
                    placeholder='Leaf Blower'
                    disabled={fieldsDisabled}
                    value={displayName}
                  />
                </Form.Item>
                <Form.Item
                  {...generateFormItemAnswerProps('image')}
                  label={'Image File'}
                >
                  <Select
                    allowClear
                    showSearch
                    options={imageOptions}
                    disabled={fieldsDisabled}
                    loading={fetchingFileReferences}
                    value={image}
                    placeholder={imagePlaceholder}
                  />
                </Form.Item>
                <Form.Item
                  {...generateFormItemAnswerProps('soundEffect')}
                  label={'Sound Effect Audio File'}
                >
                  <Select
                    allowClear
                    showSearch
                    options={audioOptions}
                    disabled={fieldsDisabled}
                    loading={fetchingFileReferences}
                    value={soundEffect}
                    placeholder={audioPlaceholder}
                  />
                </Form.Item>
                <Form.Item
                  {...generateFormItemAnswerProps('botAudio')}
                  label={'Bot Audio File'}
                >
                  <Select
                    allowClear
                    showSearch
                    options={audioOptions}
                    disabled={fieldsDisabled}
                    loading={fetchingFileReferences}
                    value={botAudio}
                    placeholder={audioPlaceholder}
                  />
                </Form.Item>
                <Form.Item
                  {...generateFormItemAnswerProps('particleEffect')}
                  label={'Particle Effect'}
                >
                  <Select
                    allowClear
                    options={particleOptions}
                    disabled={isSubmitting}
                    value={particleEffect}
                    placeholder='Fire'
                  />
                </Form.Item>
                <Form.Item
                  {...generateFormItemAnswerProps('responseVideo')}
                  label={'Response Video File'}
                >
                  <Select
                    allowClear
                    showSearch
                    options={videoOptions}
                    disabled={fieldsDisabled}
                    loading={fetchingFileReferences}
                    value={responseVideo}
                    placeholder={videoPlaceholder}
                  />
                </Form.Item>
                <Form.Item
                  {...generateFormItemAnswerProps('responseBotAudio')}
                  label={'Response Bot Audio File'}
                >
                  <Select
                    allowClear
                    showSearch
                    options={audioOptions}
                    disabled={fieldsDisabled}
                    loading={fetchingFileReferences}
                    value={responseBotAudio}
                    placeholder={audioPlaceholder}
                  />
                </Form.Item>
                <Form.Item
                  {...generateFormItemAnswerProps('points')}
                  label={'Points'}
                >
                  <InputNumber
                    disabled={fieldsDisabled}
                    value={points}
                  />
                </Form.Item>
                <Form.Item
                  {...generateFormItemAnswerProps('isCorrect')}
                  label={'Is Correct'}
                >
                  <Switch
                    disabled={fieldsDisabled}
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    checked={isCorrect}
                  />
                </Form.Item>

                <Form.Item
                  {...generateFormItemAnswerProps('displayOrder')}
                  label={'Display Order'}
                >
                  <InputNumber
                    disabled={true}
                    value={displayOrder}
                  />
                </Form.Item>
              </Card>
            );
          })}
      </Card>
    </React.Fragment>
  );
};

export default ScreenCleaningNodeFormBody;