import React from 'react';
import moment from 'moment-timezone';
import { Descriptions, Tag } from 'antd';
import {
  liveEventStatusOptions,
  momentFormat,
} from 'src/helpers';
import {
  useSelector,
  // useDispatch,
} from 'react-redux';
import './index.css';
/** type imports */
import type { antdColorOptions, LiveEvent } from 'types';
import type { RootState } from 'src/app/rootReducer';


interface Props {
  id: string;
  liveEvent: LiveEvent;
  onClick?: ((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void) | undefined;
}
const LiveEventDescription: React.FC<Props> = ({ liveEvent, onClick }: Props) => {
  const {
    seasons,
  } = useSelector((state: RootState) => state.seasonsState);
  const {
    title,
    description,
    status,
    eventDatetime,
    seasonId,
    createdAt,
    updatedAt,
  } = liveEvent;

  let statusColor: antdColorOptions = 'green';
  switch (status) {
    case 'completed': {
      statusColor = 'success';
      break;
    }
    case 'scheduled': {
      statusColor = 'processing';
      break;
    }
    case 'canceled': {
      statusColor = 'error';
      break;
    }
    case 'started': {
      statusColor = 'orange';
      break;
    }
  }

  const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const EventTitle: React.FC = () => {
    const parsedEventDatetime = moment(eventDatetime);
    return (
      <div>
        <span className='live-event-datetime'>{parsedEventDatetime.tz(currentTimeZone).format('h:mm A z')}</span>
        <div className='live-event-title'>Title: {title}</div>
      </div>
    );
  };
  return (
    <div className='live-event-description' onClick={onClick}>
      <Descriptions
        size={'small'}
        title={<EventTitle />}
        column={2}
      >
        <Descriptions.Item span={2} label={'Description'}>{description}</Descriptions.Item>
        <Descriptions.Item span={1} label={'Status'}><Tag color={statusColor}>{liveEventStatusOptions[status]}</Tag></Descriptions.Item>
        <Descriptions.Item span={1} label={'Season'}><Tag>{seasons[seasonId]?.title}</Tag></Descriptions.Item>
        <Descriptions.Item span={1} label={'Created At'}>{moment(createdAt).tz(currentTimeZone).format(momentFormat)}</Descriptions.Item>
        <Descriptions.Item span={1} label={'Updated At'}>{(updatedAt) ? moment(updatedAt).tz(currentTimeZone).format(momentFormat) : 'N/A'}</Descriptions.Item>
      </Descriptions>
    </div>
  );
};

export default LiveEventDescription;
