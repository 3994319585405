import React, { useState, useEffect, CSSProperties } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'src/app/rootReducer';
import { AppDispatch } from 'src/app/store';
import {
  fetchUsers,
} from 'src/features/admin/usersManagerSlice';
import {
  // Button,
  Table,
  Drawer,
  Row,
  Col,
  Tag,
} from 'antd';
import UserAdminForm from './UserAdminForm';
import { momentFormat, authorizationDefinitions } from 'src/helpers';
import moment from 'moment';
import type { AuthorizationType, milliseconds } from 'types';
import type { ColumnType } from 'antd/es/table';

interface TableDatum {
  id: string;
  displayName: string;
  authorizations: AuthorizationType[];
  newlyCreated: boolean;
  lastLoginAt: milliseconds;
  createdAt: milliseconds;
  updatedAt: milliseconds | undefined;
}

const Users: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const [selectedUserId, selectUserId] = useState<string | null>(null);

  const {
    users,
    fetchingUsers,
  } = useSelector((state: RootState) => state.usersManagerState);

  useEffect(() => {
    dispatch(fetchUsers());
  }, [dispatch]);

  // function handleCreateNew(): void {
  //   selectStoryId('');
  // }
  // let currentMaxOrder = 0;
  const tableData: TableDatum[] = Object.entries(users)
    .map(([id, { displayName, authorizations, newlyCreated, lastLoginAt, createdAt, updatedAt }]) => {
      return ({
        id,
        displayName: displayName || "Missing Name",
        authorizations,
        newlyCreated,
        lastLoginAt,
        createdAt,
        updatedAt,
      });
    });

  const renderAuthorizations: React.FC<AuthorizationType[]> = (authorizations, { newlyCreated }: TableDatum) => {
    if (newlyCreated) {
      return <span>Please give this user some authorizations</span>;
    }
    // need to use slice because sort mutates the array
    const sorted = authorizations.slice().sort((a, b) => {
      if (a > b) {
        return -1;
      }
      if (b > a) {
        return 1;
      }
      return 0;
    });
    return (
      <ul>
        {sorted.map((authorization, index) => <li key={index}><Tag>{authorizationDefinitions[authorization]}</Tag></li>)}
      </ul>
    );
  };

  const tableColumns: ColumnType<TableDatum>[] = [
    {
      title: 'Display Name',
      dataIndex: 'displayName',
      key: 'displayName',
      width: 250,
    },
    {
      title: 'Authorizations',
      dataIndex: 'authorizations',
      key: 'authorizations',
      width: 250,
      render: renderAuthorizations,
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 200,
      sorter: (a, b) => a.createdAt - b.createdAt,
      render: (createdAt: milliseconds) => moment(createdAt).format(momentFormat),
    },
    {
      title: 'Updated At',
      dataIndex: 'updatedAt',
      width: 200,
      key: 'updatedAt',
      sorter: ({ updatedAt: updatedAtA }, { updatedAt: updatedAtB }) => {
        if (updatedAtA !== undefined && updatedAtB !== undefined) {
          return updatedAtA - updatedAtB;
        } else if (updatedAtA !== undefined) {
          return 1;
        } else if (updatedAtB !== undefined) {
          return -1;
        }
        return 0;
      },
      render: (updatedAt: milliseconds | undefined) => (updatedAt) ? moment(updatedAt).format(momentFormat) : 'N/A',
    },
    {
      title: 'Last Login At',
      dataIndex: 'lastLoginAt',
      width: 200,
      key: 'lastLoginAt',
      sorter: ({ lastLoginAt: lastLoginAtA }, { lastLoginAt: lastLoginAtB }) => {
        if (lastLoginAtA !== undefined && lastLoginAtB !== undefined) {
          return lastLoginAtA - lastLoginAtB;
        } else if (lastLoginAtA !== undefined) {
          return 1;
        } else if (lastLoginAtB !== undefined) {
          return -1;
        }
        return 0;
      },
      render: (lastLoginAtB: milliseconds | undefined) => (lastLoginAtB) ? moment(lastLoginAtB).format(momentFormat) : 'N/A',
    }
  ];

  const tableCSS: React.CSSProperties = {
    padding: '1em',
    margin: '1em auto',
  };
  return (
    <React.Fragment>
      <Row justify='center'>
        <Col xs={23} sm={22} md={22} lg={20} xl={16}>
          <Table
            title={() => (<h2>Users</h2>)}
            style={tableCSS}
            pagination={{
              total: tableData.length,
              defaultPageSize: 100,
              showTotal: (total) => `${total} Total Users`,
            }}
            loading={fetchingUsers}
            dataSource={tableData}
            columns={tableColumns}
            rowKey={({ id }) => id}
            onRow={(record) => {
              const currentlySelected = record.id === selectedUserId;
              function handleRowClick(): void {
                if (!currentlySelected) {
                  selectUserId(record.id);
                } else {
                  console.log('already selected');
                }
              }
              const rowCss: CSSProperties = {
                cursor: (currentlySelected) ? 'auto' : 'pointer',
              };
              return {
                onClick: handleRowClick, // click row
                style: rowCss,
                className: (currentlySelected) ? 'ant-table-row-selected' : '',
              };
            }}
          />
        </Col>
      </Row>
      <Drawer
        width={600}
        placement={'right'}
        visible={selectedUserId !== null}
        closable={false}
      >
        {(!fetchingUsers && selectedUserId !== null) && <UserAdminForm
          userAdminState={{
            id: selectedUserId,
            user: (selectedUserId === '') ? {
              displayName: '',
              authorizations: [],
            } : users[selectedUserId],
          }}
          onCancel={() => { selectUserId(null); }}
        />}
      </Drawer>
    </React.Fragment>
  );
};

export default Users;

