import { FirestoreUser, FirestoreUserSnapshot, SnapshotOptions, FirestoreUserCore } from 'types';
// import moment from 'moment';
import firebase from 'firebase';
import 'firebase/firestore';
// import { colorThemes, difficultyLevels, generateValidationFunc } from 'src/helpers';
// import { snakeCase } from 'lodash';
// import * as yup from 'yup';

const { firestore } = firebase;
interface ToFireStoreData extends FirestoreUserCore {
  lastLoginAt: firebase.firestore.Timestamp,
  createdAt: firebase.firestore.Timestamp,
  updatedAt?: firebase.firestore.Timestamp,
}

export const fireStoreUserConverter: firebase.firestore.FirestoreDataConverter<FirestoreUser> = {
  toFirestore(fireStoreUser: FirestoreUser): ToFireStoreData {
    const user = {
      ...fireStoreUser,
      lastLoginAt: firestore.Timestamp.fromMillis(fireStoreUser.lastLoginAt),
      createdAt: firestore.Timestamp.fromMillis(fireStoreUser.createdAt),
      updatedAt: (fireStoreUser.updatedAt !== undefined) ? firestore.Timestamp.fromMillis(fireStoreUser.updatedAt) : undefined,
    };

    if (user.updatedAt === undefined) {
      delete user.updatedAt;
    }
    
    return user;
  },
  fromFirestore(snapshot: FirestoreUserSnapshot, options: SnapshotOptions): FirestoreUser {
    const data = snapshot.data(options);
    const user: FirestoreUser = {
      ...data,
      lastLoginAt: data.lastLoginAt.toMillis(),
      createdAt: data.createdAt.toMillis(),
      updatedAt: data.updatedAt?.toMillis(),
    };
    return user;
  },
};