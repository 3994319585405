import { createSlice } from '@reduxjs/toolkit';
import firebase from 'firebase';
import { generateFirestorePath } from 'src/helpers';
import * as yup from 'yup';
import 'firebase/firestore';
import { seasonConverter } from './helpers';
import { storyConverter } from '../stories/helpers';
import { fetchStories } from '../stories/storiesSlice';
// import { generateNodeDefinitionSchema } from '../nodes/helpers';

import type { AsyncAppThunk } from 'src/app/store';
import type {
  Season,
} from 'types';
import type { PayloadAction } from '@reduxjs/toolkit';

const { firestore } = firebase;
interface SuccessReturn {
  success: true;
  validationError: null;
}
interface FailureReturn {
  success: false;
  validationError: yup.ValidationError;
}

type ThunkReturn = SuccessReturn | FailureReturn;

interface SeasonState {
  selectedSeason: { seasonId: string; season: Season } | null;
  fetchingSeason: boolean;
}

const initialState: SeasonState = {
  selectedSeason: null,
  fetchingSeason: true,
};

const seasonSlice = createSlice({
  name: 'season',
  initialState,
  reducers: {
    setSeason(state, action: PayloadAction<{ seasonId: string; season: Season }>): void {
      state.selectedSeason = action.payload;
      state.fetchingSeason = false;
    },
    setToLoading(state): void {
      state.fetchingSeason = true;
    },
  },
});

const {
  setSeason,
  // setToLoading,
} = seasonSlice.actions;

// thunk creator
export function fetchSeason(seasonId: string): AsyncAppThunk<void> {
  return async function thunk(dispatch, getState): Promise<void> {
    try {
      const user = getState().userState.user;
      if (!user) {
        throw new Error('Not authenticated');
      }
      const seasonSnapshot = await firestore()
        .collection(generateFirestorePath('seasons'))
        .doc(seasonId)
        .withConverter(seasonConverter)
        .get();

      if (!seasonSnapshot.exists) {
        throw new Error('No story with that ID');
      }
      const seasonData = seasonSnapshot.data();
      if (!seasonData) {
        throw new Error('No story data with that ID');
      }

      dispatch(setSeason({ seasonId, season: seasonData }));
    } catch (error) {
      console.log('Error in fetchSeason():', error);
    }
  };
}

interface StoriesOrder {
  [storyId: string]: number;
}
// thunk creator
export function updateStoriesOrder(seasonId: string, storiesOrder: StoriesOrder): AsyncAppThunk<ThunkReturn> {
  return async function thunk(dispatch, getState): Promise<ThunkReturn> {
    try {
      const user = getState().userState.user;
      if (!user) {
        throw new Error('Not authenticated');
      }

      const storiesCol = firestore()
        .collection(generateFirestorePath(`seasons/${seasonId}/stories`))
        .withConverter(storyConverter);

      const batch = firestore().batch();
      Object.entries(storiesOrder).forEach(([storyId, newOrder]) => {
        const storyRef = storiesCol.doc(storyId);
        batch.update(storyRef, 'order', newOrder);
      });

      const seasonRef = firestore()
        .collection(generateFirestorePath('seasons'))
        .withConverter(seasonConverter).doc(seasonId);

      batch.update(seasonRef, 'updatedAt', firestore.Timestamp.now());
      await batch.commit();

      dispatch(fetchSeason(seasonId));
      dispatch(fetchStories(seasonId));
      return {
        success: true,
        validationError: null,
      };
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        return {
          success: false,
          validationError: error,
        };
      } else {
        console.log('Error in updateStoriesOrder():', error);
        throw error;
      }
    }
  };
}

export default seasonSlice.reducer;