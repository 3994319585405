import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';
import firebase from 'firebase';
import { fireStoreUserConverter } from '../authentication/helpers';
// import { snakeCase } from 'lodash';
import * as yup from 'yup';
import { authorizationOptions, generateFirestorePath } from 'src/helpers';
import 'firebase/firestore';
import 'firebase/auth';
import type { FirestoreUser, AuthorizationType } from 'types';
import type { AsyncAppThunk } from 'src/app/store';

const { firestore, auth } = firebase;

interface SuccessReturn {
  success: true;
  validationError: null;
}
interface FailureReturn {
  success: false;
  validationError: yup.ValidationError;
}

type ThunkReturn = SuccessReturn | FailureReturn;

interface FirestoreUsers {
  [userId: string]: FirestoreUser;
}

interface AdminUsersState {
  users: FirestoreUsers;
  fetchingUsers: boolean;
}

const initialState: AdminUsersState = {
  users: {},
  fetchingUsers: true,
};

const usersManagerSlice = createSlice({
  name: 'usersManager',
  initialState,
  reducers: {
    setUsers(state, action: PayloadAction<FirestoreUsers>): void {
      state.users = action.payload;
      state.fetchingUsers = false;
    },
    setUserAutorizations(state, action: PayloadAction<{ id: string; authorizations: AuthorizationType[] }>): void {
      const { id, authorizations } = action.payload;
      state.users[id].authorizations = authorizations;
    }
  },
});

export const {
  setUsers,
} = usersManagerSlice.actions;

// thunk creator
export function fetchUsers(): AsyncAppThunk<void> {
  return async function thunk(dispatch): Promise<void> {
    try {
      const user = auth().currentUser;
      if (!user) {
        throw new Error('Not authenticated');
      }
      const users: FirestoreUsers = {};
      const userColSnapshot = await firestore()
        .collection(generateFirestorePath('adminUsers'))
        .withConverter(fireStoreUserConverter)
        .orderBy('displayName', 'desc').get();
      userColSnapshot.forEach((user) => {
        if (user.exists) {
          users[user.id] = user.data();
        }
      });

      dispatch(setUsers(users));
    } catch (error) {
      console.log('Error in fetchUsers():', error);
      throw error;
    }
  };
}

export function updateUserAuthorizations(id: string, authorizations?: AuthorizationType[]): AsyncAppThunk<ThunkReturn> {
  return async function thunk(dispatch): Promise<ThunkReturn> {
    try {
      const user = auth().currentUser;
      if (!user) {
        throw new Error('Not authenticated');
      }

      const userRef = firestore()
        .collection(generateFirestorePath('adminUsers'))
        .withConverter(fireStoreUserConverter)
        .doc(id);
      const userSnapshot = await userRef.get();
      const userData = userSnapshot.data();
      if (!userSnapshot.exists || userData === undefined) {
        throw new yup.ValidationError("User Doesn't exist", "User Doesn't exist", "");
      }

      const schema = yup.object().shape<{ authorizations: AuthorizationType[] }>({
        authorizations: yup.array().of(yup.string().defined().oneOf(authorizationOptions)).required().label('Authorizations'),
      });

      const updatedAuths = await schema.validate({ authorizations });

      await userRef.set({
        ...userData,
        ...updatedAuths,
        newlyCreated: false,
        updatedAt: moment().valueOf(),
      }, { merge: true });

      await dispatch(fetchUsers());

      return {
        success: true,
        validationError: null,
      };
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        return {
          success: false,
          validationError: error,
        };
      } else {
        console.log('Error in updateUserAuthorizations():', error);
        throw error;
      }
    }
  };
}


export default usersManagerSlice.reducer;