import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';
import {
  Breadcrumb,
} from 'antd';
/** type imports */
import type { RootState } from 'src/app/rootReducer';


const BreadCrumbs: React.FC = () => {
  const { pathname } = useLocation();
  const breadcrumbItems = [];
  const segments = pathname.split('/').filter(seg => seg !== '');
  const {
    storyState: {
      selectedStory,
      fetchingStory,
    },
    seasonState: {
      selectedSeason,
      fetchingSeason,
    },
  } = useSelector((state: RootState) => state);
  const firstSegment = segments[0];
  if (firstSegment === 'seasons') {
    breadcrumbItems.push(
      <Breadcrumb.Item key='seasons'>
        <Link to='/seasons'>Seasons</Link>
      </Breadcrumb.Item>
    );

    if (segments.length >= 2) {
      let seasonBreadCrumbTitle = 'Season Details';
      if (!fetchingSeason && selectedSeason) {
        seasonBreadCrumbTitle = selectedSeason.season.title;
      }
      breadcrumbItems.push(
          <Breadcrumb.Item key='season_details'>
            <Link to={`/seasons/${segments[1]}`}>{seasonBreadCrumbTitle}</Link>
          </Breadcrumb.Item>
      );
      if (segments.length === 4 && segments[2] === 'stories') {
        let breadCrumbTitle = 'Story Details';
        if (!fetchingStory && selectedStory) {
          breadCrumbTitle = selectedStory.story.title;
        }
        breadcrumbItems.push(
            <Breadcrumb.Item key='story_details'>
              <Link to={`/seasons/${segments[1]}/stories/${segments[3]}`}>{breadCrumbTitle}</Link>
            </Breadcrumb.Item>
        );
      }
    }
  } else if (firstSegment === 'tag_definitions') {
    breadcrumbItems.push(
      <Breadcrumb.Item key='tag_definitions'>
        <Link to='/tag_definitions'>Tag Definitions</Link>
      </Breadcrumb.Item>
    );
  } else if (firstSegment === 'character_profiles') {
    breadcrumbItems.push(
      <Breadcrumb.Item key='character_profiles'>
        <Link to='/character_profiles'>Character Profiles</Link>
      </Breadcrumb.Item>
    );
  }
  const style: React.CSSProperties = {
    padding: '1em',
  };
  return <Breadcrumb style={style} >{breadcrumbItems.flat()}</Breadcrumb>;
};

export default BreadCrumbs;