import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PlaceholderAssetReference } from 'types';
import { AsyncAppThunk } from 'src/app/store';
import firebase from 'firebase';
import { fetchFileReferences } from './storageSlice';
import * as yup from 'yup';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/firestore';


const { firestore, auth, storage } = firebase;
interface SuccessReturn {
  success: true;
  validationError: null;
}
interface FailureReturn {
  success: false;
  validationError: yup.ValidationError;
}

type ThunkReturn = SuccessReturn | FailureReturn;

interface PlaceholderAssetReferences {
  [AssetId: string]: PlaceholderAssetReference;
}

interface PlaceholderAssetState {
  placholderAssetReferences: PlaceholderAssetReferences;
  fetchingPlaceholderAssetReferences: boolean;
}

const initialState: PlaceholderAssetState = {
  placholderAssetReferences: {},
  fetchingPlaceholderAssetReferences: true,
};

const placeholderAssetSlice = createSlice({
  name: 'placeholderAssets',
  initialState,
  reducers: {
    setPlaceholderAssetReferences(state, action: PayloadAction<PlaceholderAssetReferences>): void {
      state.placholderAssetReferences = action.payload;
      state.fetchingPlaceholderAssetReferences = false;
    },
  },
});

export const {
  setPlaceholderAssetReferences,
} = placeholderAssetSlice.actions;

// thunk creator
export function fetchPlaceholderAssetReferences(): AsyncAppThunk<void> {
  return async function thunk(dispatch): Promise<void> {
    try {
      const user = auth().currentUser;
      if (!user) {
        throw new Error('Not authenticated');
      }

      const placeholderAssetColSnapshot = await firestore().collection('placeholderAssetReferences').get();

      const placeholderAssets: PlaceholderAssetReferences = {};

      placeholderAssetColSnapshot.forEach((placeholderAssetSnapshot) => {
        const asset = placeholderAssetSnapshot.data() as PlaceholderAssetReference;
        placeholderAssets[placeholderAssetSnapshot.id] = asset;
      });

      dispatch(setPlaceholderAssetReferences(placeholderAssets));

    } catch (error) {
      console.log('Error in fetchPlaceholderAssetReferences():', error);
    }
  };
}


export function uploadRealAsset(placeholderAssetId: string, file: File | null, setUploadProgress: (newProgress: number | null) => void): AsyncAppThunk<ThunkReturn> {
  return async function thunk(dispatch): Promise<ThunkReturn> {
    try {
      const user = auth().currentUser;
      if (!user) {
        throw new Error('Not authenticated');
      }
      const placeholderAssetRef = firestore().collection('placeholderAssetReferences').doc(placeholderAssetId);
      const placeholderAssetSnapshot = await placeholderAssetRef.get();
      if (!placeholderAssetSnapshot.exists) {
        throw new yup.ValidationError("Placeholder Asset missing", "Placeholder Asset missing", "");
      }

      if (!file) {
        throw new yup.ValidationError("Must select a file to upload", "Must select a file to upload", "");
      }

      const { fullPath } = placeholderAssetSnapshot.data() as PlaceholderAssetReference;

      const ref = storage().ref().child(fullPath);

      const uploadTask = ref.put(file);

      await new Promise((resolve, reject) => {
        uploadTask.on(storage.TaskEvent.STATE_CHANGED,
          (snapshot) => {
            const newProgress = Math.ceil((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            setUploadProgress(newProgress);
          }, (error) => {
            const errorMessage = error.message;
            reject(new yup.ValidationError(errorMessage, errorMessage, ""));
          }, () => {
            resolve(undefined);
          });
      });

      await placeholderAssetRef.set({
        isReplaced: true,
        updatedAt: firestore.Timestamp.now(),
      }, { merge: true });

      await dispatch(fetchPlaceholderAssetReferences());

      await dispatch(fetchFileReferences());

      return {
        success: true,
        validationError: null,
      };
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        return {
          success: false,
          validationError: error,
        };
      } else {
        console.log('Error in uploadRealAsset():', error);
        throw error;
      }
    }
  };
}

export function deletePlaceholderAssetReference(placeholderAssetId: string): AsyncAppThunk<ThunkReturn> {
  return async function thunk(dispatch): Promise<ThunkReturn> {
    try {
      const user = auth().currentUser;
      if (!user) {
        throw new Error('Not authenticated');
      }
      const placeholderAssetRef = firestore().collection('placeholderAssetReferences').doc(placeholderAssetId);
      const placeholderAssetSnapshot = await placeholderAssetRef.get();
      if (!placeholderAssetSnapshot.exists) {
        throw new yup.ValidationError("Placeholder Asset missing", "Placeholder Asset missing", "");
      }
      const { isDeleted } = placeholderAssetSnapshot.data() as PlaceholderAssetReference;

      await placeholderAssetRef.set({
        isDeleted: !isDeleted,
        updatedAt: firestore.Timestamp.now(),
      }, { merge: true });

      await dispatch(fetchPlaceholderAssetReferences());

      await dispatch(fetchFileReferences());

      return {
        success: true,
        validationError: null,
      };
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        return {
          success: false,
          validationError: error,
        };
      } else {
        console.log('Error in deletePlaceholderAssetReference():', error);
        throw error;
      }
    }
  };
}

export default placeholderAssetSlice.reducer;