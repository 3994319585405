import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as yup from 'yup';
import {
  characterProfileConverter,
  characterProfileSchema,
} from './helpers';
import { generateFirestorePath } from 'src/helpers';
import firebase from 'firebase';
import 'firebase/firestore';
/** type imports */
import type { AsyncAppThunk } from 'src/app/store';
import type { CharacterProfile } from 'types';

const { firestore } = firebase;
interface SuccessReturn {
  success: true;
  validationError: null;
}
interface FailureReturn {
  success: false;
  validationError: yup.ValidationError;
}

type ThunkReturn = SuccessReturn | FailureReturn;

interface CharacterProfiles { [characterProfileId: string]: CharacterProfile }

interface CharacterProfilesState {
  characterProfiles: { [characterProfileId: string]: CharacterProfile };
  fetchingCharacterProfiles: boolean;
}

const initialState: CharacterProfilesState = {
  characterProfiles: {},
  fetchingCharacterProfiles: true,
};

const characterProfilesSlice = createSlice({
  name: 'characterProfiles',
  initialState,
  reducers: {
    setCharacterProfiles(state, action: PayloadAction<CharacterProfiles>): void {
      state.characterProfiles = action.payload;
      state.fetchingCharacterProfiles = false;
    },
    setToLoading(state): void {
      state.fetchingCharacterProfiles = true;
    },
  },
});

export const {
  setCharacterProfiles,
  setToLoading,
} = characterProfilesSlice.actions;

// thunk creator
export function fetchCharacterProfiles(): AsyncAppThunk<void> {
  return async function thunk(dispatch, getState): Promise<void> {
    try {
      const user = getState().userState.user;
      if (!user) {
        throw new Error('Not authenticated');
      }
      dispatch(setToLoading());
      const characterProfiles: CharacterProfiles = {};

      const characterProfilesSnapshot = await firestore()
        .collection(generateFirestorePath('characterProfiles'))
        .withConverter(characterProfileConverter)
        .orderBy('createdAt', 'desc')
        .get();

      characterProfilesSnapshot.forEach((characterProfile) => {
        if (characterProfile.exists) {
          characterProfiles[characterProfile.id] = characterProfile.data();
        }
      });

      dispatch(setCharacterProfiles(characterProfiles));
    } catch (error) {
      console.log('Error in fetchCharacterProfiles():', error);
    }
  };
}

export function addCharacterProfile(id: string, newCharacterProfile: Partial<CharacterProfile>): AsyncAppThunk<ThunkReturn> {
  return async function thunk(dispatch, getState): Promise<ThunkReturn> {
    try {
      const user = getState().userState.user;
      if (!user) {
        throw new Error('Not authenticated');
      }

      newCharacterProfile.createdBy = user.uid;

      const newProfile = await characterProfileSchema.validate(newCharacterProfile, {
        stripUnknown: true,
        context: {
          editMode: false,
        },
      });

      const profileRef = firestore()
        .collection(generateFirestorePath('characterProfiles'))
        .withConverter(characterProfileConverter)
        .doc(id);

      const newProfileSnapshot = await profileRef.get();

      if (newProfileSnapshot.exists) {
        throw new yup.ValidationError('That Character Profile already exists', 'That Character Profile already exists', '');
      }

      await profileRef.set(newProfile);

      await dispatch(fetchCharacterProfiles());

      return {
        success: true,
        validationError: null,
      };
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        return {
          success: false,
          validationError: error,
        };
      } else {
        console.log('Error in addCharacterProfile():', error);
        throw error;
      }
    }
  };
}

export function updateCharacterProfile(id: string, updatedCharacterProfile: Partial<CharacterProfile>): AsyncAppThunk<ThunkReturn> {
  return async function thunk(dispatch, getState): Promise<ThunkReturn> {
    try {
      const user = getState().userState.user;
      if (!user) {
        throw new Error('Not authenticated');
      }

      const updatedProfile = await characterProfileSchema.validate(updatedCharacterProfile, {
        stripUnknown: true,
        context: {
          editMode: true,
        }
      });

      const profileRef = firestore()
        .collection(generateFirestorePath('characterProfiles'))
        .withConverter(characterProfileConverter)
        .doc(id);

      const updatedProfileSnapshot = await profileRef.get();

      if (!updatedProfileSnapshot.exists) {
        throw new yup.ValidationError('That Character Profile does not exists', 'That Character Profile does not exists', '');
      }

      await profileRef.set(updatedProfile);

      await dispatch(fetchCharacterProfiles());

      return {
        success: true,
        validationError: null,
      };
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        return {
          success: false,
          validationError: error,
        };
      } else {
        console.log('Error in updateCharacterProfile():', error);
        throw error;
      }
    }
  };
}

export default characterProfilesSlice.reducer;