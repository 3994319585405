import React from 'react';
import {
  // useSelector,
  useDispatch,
} from 'react-redux';
import {
  // Form,
  // Select,
  Divider,
} from 'antd';
import {
  fetchFileReferences,
} from 'src/features/storage/storageSlice';
// import { transformFiles } from 'src/helpers';
/** type imports */
import type { AppDispatch } from 'src/app/store';
// import type { RootState } from 'src/app/rootReducer';
import type { FormInstance } from 'antd/es/form';
import type { FormikErrors } from 'formik';
import type {
  TravelNode,
  FormItemProps,
} from 'types';


interface Props {
  nodeDefinition: Partial<TravelNode>;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => any;
  handleChange: (eventOrPath: string | React.ChangeEvent<any>) => void | ((eventOrTextValue: string | React.ChangeEvent<any>) => void);
  handleBlur: (eventOrString: any) => void | ((e: any) => void);
  form: FormInstance;
  isSubmitting: boolean;
  errors: FormikErrors<{ id: string; nodeDefinition: Partial<TravelNode>; }>;
  setErrors: (errors: FormikErrors<{ id: string; nodeDefinition: Partial<TravelNode>; }>) => void;
}

const TravelNodeFormBody: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  // const {
  //   tagDefinitions,
  //   fetchingTagDefinitions,
  // } = useSelector((state: RootState) => state.tagDefinitionsState);

  // const {
  //   fileReferences: {
  //     // imageFiles,
  //     // videoFiles,
  //     // audioFiles,
  //   },
  //   fetchingFileReferences,
  // } = useSelector((state: RootState) => state.storageState);

  React.useEffect(() => {
    dispatch(fetchFileReferences());
  }, [dispatch]);

  const {
    // nodeDefinition,
    // setFieldValue,
    // handleChange,
    // handleBlur,
    // isSubmitting,
    errors,
    // form,
  } = props;

  // const { } = nodeDefinition;

  type QuestionFormErrors = {
    [K in keyof TravelNode]?: string;
  }

  // interface AnswerFormErrors {
  //   [answerDefKey: string]: string;
  // }

  function generateFormItemProps(itemName: keyof TravelNode): FormItemProps {
    const hasError = itemName in errors;
    return {
      name: ['nodeDefinition', itemName],
      validateStatus: hasError ? 'error' : '',
      hasFeedback: hasError,
      help: hasError ? (errors as QuestionFormErrors)[itemName] : false,
    };
  }

  // const imageOptions = transformFiles(imageFiles);
  // const videoOptions = transformFiles(videoFiles);
  // const audioOptions = transformFiles(audioFiles);

  // const audioPlaceholder = 'audio/example.mp3';
  // const imagePlaceholder = 'image/example.jpeg';
  // const videoPlaceholder = 'video/example.mp4';
  if (Object.keys(errors).length) {
    console.log(errors);
  }

  return (
    <React.Fragment>
      <Divider>Travel Configuration</Divider>
    </React.Fragment>
  );
};

export default TravelNodeFormBody;